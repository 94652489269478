import eledo from "./eledo";

const TEMPLATE_URL = '/template';
const LIST_URL = '/list';
const PRIVATE_URL = '?scope=mine&page=';
const PUBLIC_URL = '?scope=public&page=';
const COPY_URL = TEMPLATE_URL + 'Copy';
const TEMPLATE_CONNECT_URL = '/templateConnect';
const TEMPLATE_VERSION_CONTROL_URL = '/templateVersionControl';

const fetchTemplate = id => eledo.get(`${TEMPLATE_URL}/${id}`);
const fetchPrivateTemplates = (pageId, connectionId) => eledo.get(LIST_URL + PRIVATE_URL + pageId + (connectionId ? `&connection=${connectionId}` : ''));
const fetchPublicTemplates = (pageId, platform) => eledo.get(LIST_URL + PUBLIC_URL + pageId + (platform ? `&platform=${platform}` : ''));
const fetchAllTemplates = () => eledo.get(LIST_URL);
const createTemplate = templateData => eledo.post(TEMPLATE_URL, { ...templateData });
const createPdfTemplate = formValues => {
  const formData = new FormData();
  formValues.data['documentFormat'] = '5560e8acf1359d7778821b3a';
  formData.append('data',JSON.stringify(formValues.data));
  formData.append('file',formValues.file[0]);
  return eledo.post(TEMPLATE_URL, formData);
}
const persistTemplate = template => {
  if(template.file){
    const formData = new FormData();
    formData.append('data',JSON.stringify(template));
    formData.append('file',template.file[0]);
    return eledo.put(`${TEMPLATE_URL}/${template.id}`, formData);
  } else {
    return eledo.put(`${TEMPLATE_URL}/${template.id}`, template);
  }
};
const copyTemplate = templateId => eledo.get(`${COPY_URL}/${templateId}`);
const deleteTemplate = templateId => eledo.delete(`/template/${templateId}`);

const connectTemplate = (templateId, platform, connectionId, structureId) => eledo.post(`${TEMPLATE_CONNECT_URL}/${templateId}`, {platform: platform, connectionId: connectionId, structureId: structureId});
const versionControlTemplate = (templateId, command) => eledo.post(`${TEMPLATE_VERSION_CONTROL_URL}/${templateId}`, {command: command});

const templateService = {
  fetchTemplate,
  fetchPrivateTemplates,
  fetchPublicTemplates,
  fetchAllTemplates,
  createTemplate,
  createPdfTemplate,
  persistTemplate,
  copyTemplate,
  deleteTemplate,
  connectTemplate,
  versionControlTemplate
};
export default templateService;