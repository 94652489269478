import eledo from "_api/eledo"

const PRICING_PLANS_URL = '/pricingPlans';
const PLAN_CHANGE_URL = '/planChange';
const USER_PLAN_URL = '/plan';

const fetchPricingPlans = () => eledo.get(PRICING_PLANS_URL);
const fetchUserPlan = () => eledo.get(USER_PLAN_URL);
const changePricingPlan = (plan) => eledo.post(PLAN_CHANGE_URL, plan);

const pricingPlansService = {
  fetchPricingPlans,
  fetchUserPlan,
  changePricingPlan,
};

export default pricingPlansService;
