import { configureStore } from '@reduxjs/toolkit'
import reducers from "_reducers";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const store = configureStore({
  reducer: reducers
});

// const origDispatch = store.dispatch;
// store.dispatch = (data) => {console.log("dispatch", data); return origDispatch(data);}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

type DispatchFunc = () => AppDispatch

export const useAppDispatch: DispatchFunc = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store;