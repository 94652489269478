import { AuthService, ProvidedAuthData } from '_platform';
import { AuthData, AuthUserCreate } from 'graphql/AuthQueries';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const REMEMBER_ME_COOKIE = 'rememberMe';

export interface CookieAuthService extends AuthService {
  provideAuthData(): Promise<CookieProvidedAuthData | null>
}

export interface CookieProvidedAuthData extends ProvidedAuthData {
}

const storeAuthData = (data: AuthData) => {
  
  if (data?.rememberMe) {
    cookies.set(REMEMBER_ME_COOKIE, data.rememberMe, { path: '/' });
    return true;
  }
  return false;
}

const provideAuthData = (): Promise<CookieProvidedAuthData | null> => {
  const rememberMeToken = cookies.get(REMEMBER_ME_COOKIE);

  return new Promise(function(resolve, reject) {
    if(!rememberMeToken){
      resolve(null);
    }else{
      setTimeout(function() {
        resolve({type: REMEMBER_ME_COOKIE, token: rememberMeToken});
      }, 300);
    }
  })
}

const provideAuthUserCreate = (): Promise<AuthUserCreate | undefined> => {
  return new Promise(function(resolve, reject) {
    resolve(undefined);
  })
}

const connectionRequired = () => {
  return false;
}

const deleteAuthData = () => {
  cookies.remove(REMEMBER_ME_COOKIE, { path: '/' });
}

const cookieAuthService = {
  provideAuthData, connectionRequired, storeAuthData, deleteAuthData, provideAuthUserCreate
};
export default cookieAuthService;