import React, { useContext, useEffect, useState } from 'react';
import {Link, useLocation} from "react-router-dom";
import styles from './EledoTemplateTopToolbar.module.scss';
import {useNavigate} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
  addTemplatePage, persistTemplate,
  deleteTemplatePage, setActivePageId, versionControlTemplate
} from "_reducers/templateReducer";
import ReactTooltip from "react-tooltip";
import {
  EledoDocumentPaginator, EledoPendingContent, EledoLoader,
} from "components/library";
import {TEMPLATE__TYPES} from "model/enums";
import {showNotyf, useConfirmModal} from "hooks";
import NotyfContext from 'context/NotyfContext';
import { EledoArticlesModal } from 'components/EledoArticles/EledoArticlesModal';
import { PlatformContext } from 'PlatformContext';
import { Tracker } from 'Tracker';
import { AuthContext } from 'AuthContext';
const classnames = require("classnames");

const EledoTemplateTopToolbar = ({baseUrl = ""}) => {
  const platform = useContext(PlatformContext)
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()
  const [
    selectedTemplate, editedTemplate, activePageId,
    templateChanged,
    templateSettingsValid, fileNameFormatValid, templateStylesValid,
    loading,
    saving, savingError,
    templateVersion, activeVersion
  ] = useSelector(state => [
    state.templates.selectedTemplate,
    state.templates.editedTemplate,
    state.templates.activePageId,
    state.templates.templateChanged,
    state.templates.templateSettingsValid,
    state.templates.fileNameFormatValid,
    state.templates.templateStylesValid,
    state.templates.loading,
    state.templates.saving,
    state.templates.savingError,
    state.templates.selectedTemplate?.version,
    state.templates.selectedTemplate?.derived?.activeVersion
  ], shallowEqual);
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  const [articlesModalDisplayed, setArticlesModalDisplayed] = useState(false)

  const templateType = selectedTemplate?.type
  const isPdfEditor = [TEMPLATE__TYPES.FILLABLE_DOCUMENT, TEMPLATE__TYPES.XFA_DOCUMENT].some(tt => tt === templateType)
  const displayStylesButton = !isPdfEditor && (location.pathname.includes('main') || location.pathname.includes('background')) && !location.pathname.includes('style')
  const displayContentButton = !isPdfEditor && (location.pathname.includes('main') || location.pathname.includes('background')) && location.pathname.includes('style')

  useEffect(() => {
    if(savingError){
      showNotyf(savingError, notyf, "error");
    }
  }, [savingError, notyf])

  const { renderModal: renderConfirmModal, showConfirmModal } = useConfirmModal(
    'Close Unsaved Template?',
    `Your template is not saved, do you really want to close?`,
    'Save & Close!',
    'Leave Without Saving',
    async () => {
      await dispatch(persistTemplate(editedTemplate));
      navigate("/templates/mine");
    },
    () => navigate("/templates/mine"),
  );

  const closeHandler = () => {
    if(templateChanged) {
      showConfirmModal();
    } else {
      navigate("/templates/mine");
    }
  };

  const saveHandler = () => {
    if(templateSettingsValid && fileNameFormatValid && templateStylesValid && templateChanged) {
      dispatch(persistTemplate(editedTemplate));
    }
  };

  const activateHandler = () => {
    dispatch(versionControlTemplate({templateId: selectedTemplate.id, command: "ACTIVATE"}));
  }

  const addPageHandler = () => {
    dispatch(addTemplatePage());
  };

  const removePageHandler = () => {
    dispatch(deleteTemplatePage());
  };

  const selectPageHandler = (pageId) => {
    dispatch(setActivePageId(pageId));
  };

  return (
    <div className={classnames("section", "section-small", styles.EledoTemplateTopToolbar, styles.topToolbarShadow)}
         data-testid="template-top-toolbar">
      <div className={styles.titleContainer}>
        {loading && <EledoLoader/>}
        {!loading && selectedTemplate &&
          <h3 className={styles.templateTitle}>
            {editedTemplate?.name}
          </h3>}

        {!loading && selectedTemplate && selectedTemplate.type === TEMPLATE__TYPES.STATIC_PAGE &&
          <EledoDocumentPaginator currentPage={activePageId}
                                  pagesTotal={editedTemplate.documents.length}
                                  handleSelectPage={selectPageHandler}
                                  handleAddPage={addPageHandler}
                                  handleDeletePage={removePageHandler}/>
        }

      </div>


      <div className={styles.versionAndButtonContainer}>
        <div className={styles.buttonGroup}>
          <span data-tip="Guides"
                data-for="guides-tooltip">
            <span style={{margin: '11px 25px', cursor: 'pointer', fontSize: '1.5em'}}
                    onClick={() => {
                      setArticlesModalDisplayed(true);

                      const trackerUser = {
                        id: auth.user?.id || null,
                        level: auth.level
                      }

                      Tracker.event("action.click.button", trackerUser, {
                        click: {
                          button: 'docu_modal_open',
                          placement: isPdfEditor ? 'pdf_editor' : 'editor'
                        }
                      })
                    }}>
              <i className={"icon-book "} style={{marginRight: 0}}/>
            </span>
          </span>
          <ReactTooltip
              id="guides-tooltip"
              place="bottom"
              type="dark"
              effect="solid"
              multiline={true}
            />
          
          <EledoArticlesModal root={platform.documentationPaths.root} path={isPdfEditor ? platform.documentationPaths.pdfform : platform.documentationPaths.template}
            modalDisplayed={articlesModalDisplayed} onDismiss={() => setArticlesModalDisplayed(false)}/>
        </div>

        <EledoTemplateVersion/>
        
        { templateVersion > activeVersion &&
          <div className={styles.buttonGroup}>
            <span data-tip="Recent changes are not applied yet, you need to activate them manually."
                  data-for="activate-tooltip">
              <button className="button w-button"
                      onClick={activateHandler}>
                <EledoPendingContent state={saving}
                                    content={<React.Fragment><i className="icon-upload2"/> Activate </React.Fragment>}
                                    pendingContent="Activating..."/>
              </button>
            </span>
            <ReactTooltip
              id="activate-tooltip"
              place="bottom"
              type="dark"
              effect="solid"
              multiline={true}
            />
          </div>
        }

        <div className={styles.buttonGroup}>
          { displayStylesButton &&
            <button className="button outline w-button"
                    onClick={() => navigate( baseUrl + (location.pathname.includes('main') ? '/main' : '/background') + (location.pathname.includes('style') ? '' : '/style'))}>
              <i className="icon-quill1"/>
              Styles
            </button>
          }
          { displayContentButton &&
            <button className="button outline w-button"
                    onClick={() => navigate( baseUrl + (location.pathname.includes('main') ? '/main' : '/background') + (location.pathname.includes('style') ? '' : '/style'))}>
              <i className="icon-pencil"/>
              Content
            </button>
          }
          <span data-tip={templateSettingsValid && fileNameFormatValid && templateStylesValid ? templateChanged ? "" : "Your template is saved" : "Please check the validity of your input data"}
                data-for="save-invalid-tooltip" >
            <button className="button outline w-button"
                    onClick={saveHandler}
                    {...(templateChanged && (fileNameFormatValid && templateSettingsValid && templateStylesValid) ? {"className":"button w-button"} : {"aria-disabled": "true"})}>
              <EledoPendingContent state={saving}
                                   content={<React.Fragment><i className="icon-floppy-disk"/> Save </React.Fragment>}
                                   pendingContent="Saving..."/>
            </button>
          </span>
          <ReactTooltip
            id="save-invalid-tooltip"
            place="bottom"
            type="dark"
            effect="solid"
            multiline={true}
          />
          <button className={classnames("button", "outline", "w-button", styles.closeButton)}
                  onClick={closeHandler}>
            <i className="icon-cross"/>
            Close
          </button>
        </div>
      </div>
      { renderConfirmModal() }
    </div>
  );
}

const EledoTemplateVersion = () => {
  const [templateId, templateVersion, activeVersion] = useSelector(state => [
    state.templates.selectedTemplate?.id,
    state.templates.selectedTemplate?.version,
    state.templates.selectedTemplate?.derived?.activeVersion]);

  const dispatch = useDispatch();

  const undoVersionHandler = () => {
    dispatch(versionControlTemplate({templateId, command: "UNDO"}));
  }
  const previousVersionHandler = () => {
    dispatch(versionControlTemplate({templateId, command: "ROLLBACK"}));
  };

  return (
    <div className={styles.EledoTemplateVersion}>
      { templateVersion > activeVersion &&
        <>
          <div>
            <small>
              Active template version:&nbsp;{activeVersion}
            </small>
          </div>
          <div>
            <small>
              {`(`}<Link to="" className="button-link"
                        onClick={(e) => {e.preventDefault(); ReactTooltip.hide();
                          undoVersionHandler();
                        }}>
                <i className={classnames("icon-undo2", styles.rollbackIcon)}/>
                Undo changes
              </Link>{` )`}
            </small>
          </div>
        </>
      }
      { templateVersion <= activeVersion &&
        <>
          <div>
            <small>
              Current template version:&nbsp;{activeVersion}
            </small>
          </div>
          { templateVersion > 1 &&
            <div>
              <small>
                {`(`}<Link to="" className="button-link"
                          onClick={(e) => {e.preventDefault(); ReactTooltip.hide();
                            previousVersionHandler();
                          }}>
                  <i className={classnames("icon-undo2", styles.rollbackIcon)}/>
                  Rollback to {templateVersion - 1}
                </Link>{` )`}
              </small>
            </div>
          }
        </>
      }
    </div>
  )
};

export default EledoTemplateTopToolbar;
