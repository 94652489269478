import { useQuery } from '@apollo/client';
import { GET_HELPDESK_STATS, HelpdeskStats, HelpdeskStatsData, HelpdeskStatsVars } from 'graphql/HelpdeskMessageQueries';
import { useState, useEffect } from 'react'

function useHelpdeskStats({connectionId}: HelpdeskStatsVars) {
  const { loading, error, data } = useQuery<HelpdeskStatsData, HelpdeskStatsVars>(GET_HELPDESK_STATS, {variables:{connectionId}});
  const [helpdeskStats, setHelpdeskStats] = useState<HelpdeskStats>();

  useEffect(() => {
    if(!data) return;

    const helpdeskStats: HelpdeskStats = {
      unread: data.helpdeskStats.unread,
    }

    setHelpdeskStats(helpdeskStats);

  }, [data])

  return { loading, error, helpdeskStats }
}

export default useHelpdeskStats