import React, {useState} from "react";
import {EledoModal} from "components/library";
import classnames from "classnames";

function useConfirmModal(title, subTitle, yesText, noText,
                         yesHandler = () => {}, noHandler = () => {}, xHandler = () => {},
                         yesIsRed = false) {
  const [modalDisplayed, setModalDisplayed] = useState(false);

  const handleNo = () => {
    setModalDisplayed(false);
    noHandler();
  }

  const handleYes = () => {
    setModalDisplayed(false);
    yesHandler();
  };

  const handleX = () => {
    setModalDisplayed(false);
    xHandler();
  };

  const modalActions = (
    <React.Fragment>
      <button className={classnames("button", !yesIsRed && "red", "grow", "w-button")}
              onClick={handleNo}>
        {noText}
      </button>
      <button className={classnames("button", yesIsRed && "red", "grow", "w-button")}
              onClick={handleYes}>
        {yesText}
      </button>
    </React.Fragment>
  );

  const renderModal = () =>
    <EledoModal title={title}
                content={subTitle}
                displayed={modalDisplayed}
                actions={modalActions}
                onDismiss={handleX}/>;

  return {
    renderModal,
    showConfirmModal: () => setModalDisplayed(true),
  };
}

export default useConfirmModal;