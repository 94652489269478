import React, { useContext, useEffect } from 'react'
import PuzzleGenerator from './PuzzleGenerator';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { CREATE_PUZZLE_SESSION, CreatePuzzleSessionData, PuzzleSessionInput, PuzzleSessionVars } from 'graphql/PuzzleSession';
import { AuthContext } from 'AuthContext';
import { PuzzleStructure } from 'graphql/PuzzleStructures';

function PuzzleView({puzzleStructure, data, setData, onSubmit, onSkip}: {puzzleStructure: PuzzleStructure, data: any, setData: Function, onSubmit?: Function, onSkip?: Function}) {
  const auth = useContext(AuthContext);
  
  const [ createPuzzleSession ] = useMutation<CreatePuzzleSessionData, PuzzleSessionVars>(CREATE_PUZZLE_SESSION);

  useEffect(() => {
    const startTime = moment();
    console.log("show start");
    return () => {
      const endTime = moment();
      const diff = endTime.diff(startTime);
      console.log("show time", diff);
      
      const puzzleSession: PuzzleSessionInput = {
        structureId: puzzleStructure?.id,
        userId: auth.user?.id,
        connectionId: auth.connection?.id,
        start: startTime.toDate(),
        duration: diff
      }
      createPuzzleSession({ variables: { puzzleSession }})
    }
  }, [auth, puzzleStructure, createPuzzleSession])

  const structureStyle = {
    minWidth: puzzleStructure.width,
    minHeight: puzzleStructure.height
  }
  
  return (
    <div style={structureStyle}>
      { puzzleStructure?.component !== undefined &&
        <PuzzleGenerator structure={puzzleStructure.component} data={data} setData={setData} onSubmit={onSubmit} onSkip={onSkip} />
      }
    </div>
  )
}

export default PuzzleView