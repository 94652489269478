//todo select service context (first or second import) here

import { AuthData, AuthUserCreate } from "graphql/AuthQueries";
import {default as selectedContext} from "./eledo.context";

export interface ProvidedAuthData {
  type: string
  token: string
}

export interface AuthService {
  provideAuthData(): Promise<ProvidedAuthData | null>
  connectionRequired(): boolean
  storeAuthData(data: AuthData): void
  provideAuthUserCreate(): Promise<AuthUserCreate | undefined>
  deleteAuthData(): void
}

export interface PlatformData {
  serviceName: string
  platform: string
  structureId?: string
  isEmbedded: boolean
  accountAutoCreate: boolean
  authService: AuthService
  initSuccess: boolean
  documentationPaths: {
    root: string
    guides: string
    template: string
    pdfform: string
  }
}

export interface PlatformDataProvider {
  get(): Promise<PlatformData>
}

const SELECTED_PLATFORM_CONTEXT: PlatformDataProvider = selectedContext;
export default SELECTED_PLATFORM_CONTEXT;