import { useForm, FormProvider } from "react-hook-form"
import {
    FormModel,
    FormField,
    PdfEditorModalFormModel_TEXT,
    PdfEditorModalFormModel_BASIC,
    PdfEditorModalFormModel_CHECKBOX,
    PdfEditorModalFormModel_BC1,
    PdfEditorModalFormModel_BC2,
    PdfEditorModalFormModel_BC2_boxSize
} from "model"

import { FieldType } from "../EledoPdfEditor"
import { EledoModal, EledoFormGenerator, EledoButton } from "components/library"
import { EledoLabel } from "components/library/EledoFormsComponents"
import css from "./FormFieldModal.module.scss"
import { MouseEvent, useState } from "react"
import classNames from "classnames"
import { DataModel } from "graphql/DataModelQueries"

type FormFieldFormBase = {
  type: FieldType
  id: string | null
  desc: string | null
  expression: string | null
  font?: string
  weight?: string
  style?: string
  size?: number
  color?: string
  align?: number
  multiline?: boolean
  validation?: string
  options?: [string]

  custom: boolean
  initialEditing: boolean
}

type FormFieldFormText = FormFieldFormBase & {
  type: "Text"
}

type FormFieldFormCheckbox = FormFieldFormBase & {
  type: "Checkbox"
  boxSize?: number
}

type FormFieldFormImageUrl = FormFieldFormBase & {
  type: "ImageURL"
}

type FormFieldFormBarcode = FormFieldFormBase & {
  type: "BC1_EAN_8" | "BC1_EAN_13" | "BC1_CODE_39" | "BC1_CODE_93" | "BC1_CODE_128" | "BC2_AZTEC" | "BC2_DATA_MATRIX" | "BC2_PDF417" | "BC2_QR_CODE"
  boxSize?: number
  zoom?: number
}

export type FormFieldForm = FormFieldFormText | FormFieldFormCheckbox | FormFieldFormImageUrl | FormFieldFormBarcode

type FieldModalFormProps = {
  editedTemplate: any
  dataModel: DataModel
  onModalConfirmed: (data: FormFieldForm) => void
  onModalClosed: (data: FormFieldForm) => void
  onAdjustPosition: (event: MouseEvent<HTMLButtonElement>, data: FormFieldForm) => void
  onRemoveField: (data: FormFieldForm) => void
}



const useFormFieldModal = (props: FieldModalFormProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const formHandler = useForm<FormFieldForm>({mode: "onChange"})
  const formValues = formHandler.getValues()

  //this hook re-renders the component when 'type' field changes, enables to change generated form based on type change
  formHandler.watch("type")

  const openModal = (data: FormFieldForm) => {
    formHandler.reset(data)
    setOpen(true)
    // console.log("openModal", data)
  }

  const closeModal = () => {
    setOpen(false)
    props.onModalClosed(formValues)
  }

  const adjustPosition = (event: MouseEvent<HTMLButtonElement>) => {
    setOpen(false)
    props.onAdjustPosition(event, formValues)
  }

  const removeField = () => {
    setOpen(false)
    props.onRemoveField(formValues)
  }

  const getFormModel = (fieldType: FieldType, isCustom: boolean) => {
    let model
    switch(fieldType){
      case "Text":
        model =  PdfEditorModalFormModel_TEXT
        break
      case "Checkbox":
        model = PdfEditorModalFormModel_CHECKBOX
        break
      case "BC1_CODE_128":
      case "BC1_CODE_39":
      case "BC1_CODE_93":
      case "BC1_EAN_13":
      case "BC1_EAN_8":
        model = PdfEditorModalFormModel_BC1
        break
      case "BC2_AZTEC":
      case "BC2_DATA_MATRIX":
        model = PdfEditorModalFormModel_BC2_boxSize
        break
      case "BC2_PDF417":
      case "BC2_QR_CODE":
        model = PdfEditorModalFormModel_BC2
        break
      default:
        model = PdfEditorModalFormModel_BASIC
    }
    return isCustom ? model : getFormModelWithoutID(model)
  }

  const getFormModelWithoutID = (formModel: FormModel) => {
    const clearedFormModel = structuredClone(formModel)
    clearedFormModel.fields = clearedFormModel.fields.filter((field: FormField) => field.id !== 'desc')
    return clearedFormModel
  }

  const modalContent = (
    <FormProvider {...formHandler}>
      <form>
        { !formValues.custom &&
          <fieldset>
            <EledoLabel id="field-desc" label="Field ID" />
            <span id="field-desc" className={classNames("w-input", "disabled", css.formInput)}>{formValues.desc}</span>
          </fieldset>
        }

          <EledoFormGenerator
            formModel={getFormModel(formValues.type, formValues.custom)}
            editedDataObject={formValues}
            dataModel={props.dataModel}
          />

        <fieldset>
          <EledoLabel id="field-type" label="Data Type" />
          <span id="field-type" className={classNames("w-input", "disabled", css.formInput)}>{formHandler.getValues().type}</span>
        </fieldset>
        
        { formValues.options && formValues.options.length > 0 && 
          <fieldset>
            <EledoLabel id="field-options" label="Options" />
            <span id="field-options" className={classNames("w-input", "disabled", css.formInput)}>{formValues.options.join(", ")}</span>
          </fieldset>
        }
        
        { formValues.custom &&
          <>
            { formValues.id &&
              <EledoButton label="Adjust Position or Size" icon="enlarge" onClick={adjustPosition} />
            }

            { formValues.id &&
              <div className={css.dangerZone}>
                <EledoButton className={css.removeButton} label="Remove This Field" icon="bin" red onClick={removeField} />
              </div>
            }
          </>
        }
      </form>
    </FormProvider>
  )
  
  const modalActions = (
    <>
      { formValues.id === null
        ? <EledoButton
            label={formValues.initialEditing ? "Place" : "Adjust Position"}
            red grow
            icon="arrow-left2"
            onClick={adjustPosition}
          />
        : <EledoButton label="Close" red grow onClick={closeModal} icon="arrow-left2" />
      }
      { !formValues.initialEditing &&
        <EledoButton label={formValues.id === undefined ? 'Add' : 'OK'} primary grow icon="checkmark"
          onClick={formHandler.handleSubmit(data => { props.onModalConfirmed(data); setOpen(false) }, data => console.log("invalid", data))} />
      }
    </>
    )
  
    const renderModal = () =>
    (
      <EledoModal
        title="Edit PDF form field"
        content={modalContent}
        displayed={open}
        actions={modalActions}
        onDismiss={closeModal}
      />
    )

    return { openModal, renderModal }
}


export default useFormFieldModal