import { PlatformContext } from 'PlatformContext';
import React, { useContext, useEffect, useState } from 'react'
import useArticle from 'graphql/hooks/useArticle';
import useArticles from 'graphql/hooks/useArticles';
import Markdown from 'react-markdown';
import { Article, ArticleSearchData, ArticleSearchVars, GET_ARTICLE_SEARCH, SearchResult } from 'graphql/ArticleQueries';
import rehypeRaw from 'rehype-raw';
import { useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { EledoArticleVoteBox } from './EledoArticleVoteBox';
import { ArticleHistory } from 'hooks/useArticleHistory';

const EledoArticleView = ({root, path, history}: {root: string, path: string, history: ArticleHistory}) => {
  const platform = useContext(PlatformContext);
  const [searchMode, setSearchMode] = useState<boolean>(false)
  const [searchResults, setSearchResults] = useState<[SearchResult]>()
  
  const { loading: articleLoading, error: articleError, article } = useArticle({path: history.currentPath})
  const [ articleSearch ] = useLazyQuery<ArticleSearchData, ArticleSearchVars>(GET_ARTICLE_SEARCH)
  
  const {reset, register, handleSubmit} = useForm<ArticleSearchVars>({mode: 'onSubmit'})

  const articleNavigate = (href: string) => {
    setSearchMode(false)
    reset()
    
    if(href?.startsWith('/')) {
      history.navigate(href)
    } else if(href?.startsWith('https://')) {
      console.log("ignored link", href)
    } else {
      console.log("ignored link", href)
    }
  }

  const search = (data: ArticleSearchVars) => {
    setSearchMode(true)
    articleSearch({variables: {searchTerm: data.searchTerm, root, platform: platform.platform}})
      .then(result => {
        setSearchResults(result.data?.articleSearch.results)
      })
  }

  const searchClose = () => {
    setSearchMode(false)
    reset()
  }

  return (
    <>
          <div key="breadcrumbs" className="breadcrumbs" style={{display: 'flex', flexDirection: 'row'}}>
            { !searchMode &&
              <div style={{flexGrow: 1}}>
                { article?.parents.map((p, index, array) => (
                  <span key={"crumb_" + index}>
                    <span className="crumb-text" onClick={() => articleNavigate(p.path)}>
                      {p.title}
                    </span>
                    { array.length !== index + 1 &&
                      <span className="sep">&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                    }
                  </span>
                ))}
              </div>
            }
            { searchMode &&
              <div style={{width: '25%'}}>
                <span className="crumb-text" onClick={() => searchClose()}>
                  {"< Back"}
                </span>
              </div>
            }
            { !searchMode &&
              <div>
                { history.canGoBack &&
                  <button className="crumb-text" onClick={() => history.goBack()}>&lt; Back</button>
                }
                &nbsp;
                { history.canGoForward &&
                  <button className="crumb-text" onClick={() => history.goForward()}>&gt;</button>
                }
              </div>
            }
            <div>
              <div className="crumb-text">
                <form className={"w-form"} style={{marginBottom: 0}} onSubmit={handleSubmit(search)}>
                  <input placeholder='Search' className='w-input' style={{margin: '-8px 0px'}} {...register("searchTerm")} />
                </form>
              </div>
            </div>
          </div>
          { !articleLoading && article === undefined &&
            <h3>Article not found: {history.currentPath}</h3>
          }
          <div style={{display: 'flex', flexDirection: 'row', marginTop: '30px'}}>
            <div style={{width: '25%', padding: '0 15px', marginTop: '20px'}}>
              { !searchMode && article !== undefined &&
                <ArticleChildren article={article} onClick={articleNavigate}/>
              }
            </div>
            <div style={{width: '75%', padding: '0 15px'}}>
              <div style={{paddingBottom: '8rem', marginTop: '20px'}}>
                { !searchMode && article !== undefined &&
                  <>
                    <Markdown
                      rehypePlugins={[rehypeRaw]}
                      components={{
                        a({children, className, node, href}) {
                          return (<a href={""} onClick={(event) => {event.preventDefault(); href && articleNavigate(href)}}>{children}</a>)
                        }
                      }}
                    >{article.markdown || article.content}</Markdown>

                    <hr/>
                    <EledoArticleVoteBox articleId={article.id}/>
                  </>
                }
                { searchMode && searchResults &&
                  <Markdown
                    components={{
                      a({children, className, node, href}) {
                        return (<a href={""} onClick={(event) => {event.preventDefault(); href && articleNavigate(href)}}>{children}</a>)
                      }
                    }}
                    >{searchResults.map(r => "### [" + r.title + "](" + r.url + ")\n" + (r.message || "")).join("\n")}</Markdown>
                }
              </div>
            </div>
          </div>
          {}
    </>
  )
}

const ArticleChildren = ({article, onClick}: {article: Article, onClick: (path: string) => void}) =>{
  const platform = useContext(PlatformContext)

  const [parentId, setParentId] = useState<string>(article.id)
  const { loading: articlesLoading, error: articlesError, articles } = useArticles({parent: parentId, platform: platform.platform})

  useEffect(() => {
    setParentId(article.id)
  }, [article])

  useEffect(() => {
    if(articlesLoading === false && articles && articles.length === 0){
      setParentId(article.parent)
    }
  }, [article, articlesLoading, articles])

  return (
    <div className='menu-collapse collapse'>
      <div className='sidebar-card'>
        { articles !== undefined && articles.sort((a, b) => (a.order || 0) - (b.order || 0)).map(article => (
          <a href={""} key={article.id} className='sidebar-link w-inline-block' onClick={(event) => {event.preventDefault(); onClick(article.path)}}>
            <div>{article.title}</div>
          </a>
        ))}
      </div>
    </div>
  )
}

export default EledoArticleView