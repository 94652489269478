import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import classnames from "classnames";
import HelpdeskCKEditor4 from "components/templates/editor/HelpdeskCKEditor4/HelpdeskCKEditor4";
import useDocumentFormats from "hooks/useDocumentFormats";
const _ = require('lodash');

export const EledoTextField = ({ id, label="", path, validation={}, ...props }) => {
  const formHandler = useFormContext();

  return (
    <fieldset>
      <EledoLabel id={id} label={label}/>
      <input {...formHandler.register(formFieldName(id, path), validation)}
             {...props}
             data-testid={id}
      />
      <EledoErrorMessage id={id} path={path}/>
    </fieldset>
  );
}
EledoTextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  path: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  validation: PropTypes.object
};

export const EledoPasswordField = ({ id, label="", validation={}, path, ...props }) => {
  const [showPassword, setShowPassword] = React.useState("");
  const formHandler = useFormContext();

  const handleClickShowPassword = event => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <fieldset>
      <EledoLabel id={id} label={label}/>
      <div style={{position: "relative"}}>
        <input type={showPassword ? "text" : "password"}
               data-testid={id}
                 style={showPassword ? {} : {
                   fontSize: "larger",
                   fontWeight: "bolder",
                   fontFamily: "cursive",
                   paddingRight: "2.5rem",
                 }}
                 {...formHandler.register(formFieldName(id, path), validation)}
                 {...props}/>
          <a className=""
             href="/"
             style={{
               position: "absolute",
               bottom: "0.4rem",
               right: "0.8rem",
               color: "#8ba5a4",
               textDecoration: "none"
             }}
             onClick={handleClickShowPassword}
          >
            <i className={classnames({"icon-eye-blocked": showPassword, "icon-eye" : !showPassword})}/>
          </a>
        </div>
      <EledoErrorMessage id={id} path={path}/>
    </fieldset>
  );
};
EledoPasswordField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  path: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  validation: PropTypes.object
};

// export const EledoExpressionField = ({ id, label="", path, fields=[], variables,
//                                        validation={}, ...props}) => {
//   const formHandler = useFormContext();
//
//   //we have to register field, otherwise validation is ignored
//   formHandler.register(formFieldName(id, path), validation[id] ? validation[id] : validation);
//
//   return (
//     <fieldset>
//       <EledoLabel id={id} label={label}/>
//       <EledoExpressionFieldNew
//         formHandler={formHandler}
//         id={id}
//         data-testid={id}
//         defaultValue={props.defaultValue}
//         variables={variables}/>
//       <EledoErrorMessage id={id}/>
//     </fieldset>
//   );
// }
// EledoExpressionField.propTypes = {
//   id: PropTypes.string.isRequired,
//   label: PropTypes.string,
//   path: PropTypes.string,
//   defaultValue: PropTypes.string,
//   placeholder: PropTypes.string,
//   validation: PropTypes.object
// };

export const EledoTextArea = ({ id, label, path, validation={}, className, ...props }) => {
  const formHandler = useFormContext();

  return (
    <fieldset>
      <EledoLabel id={id} label={label}/>
      <textarea {...formHandler.register(formFieldName(id, path), validation)}
                id={id}
                data-testid={id}
                defaultValue={props.defaultValue}
                className={classnames("w-input", className)}
                {...props}/>
      <EledoErrorMessage id={id} path={path}/>
    </fieldset>
  );
};

EledoTextArea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  path: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  validation: PropTypes.object
};

export const EledoRichTextArea = ({ id, onChange, label, path, validation={},
                                    className = "", ...props }) => {
  const formHandler = useFormContext();

  //we have to register field, otherwise validation is ignored
  formHandler.register(formFieldName(id, path), validation);

  return (
    <fieldset>
      <EledoLabel id={id} label={label}/>
      <HelpdeskCKEditor4
                formHandler={formHandler}
                id={id}
                data-testid={id}
                defaultValue={props.defaultValue}
                className={classnames("w-input", className)}
                {...props}/>
      <EledoErrorMessage id={id} path={path}/>
    </fieldset>
  );
};

EledoRichTextArea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  path: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  validation: PropTypes.object
};

export const EledoSelect = ({ id, label, path, options=[], validation={}, required = false, ...props }) => {
  const formHandler = useFormContext();

  return (
    <fieldset>
      <EledoLabel id={id} label={label}/>
      <select {...formHandler.register(formFieldName(id, path), validation)}
              id={id}
              data-testid={id}
              name={formFieldName(id, path)}
              {...props}>
        <option value={null}
                {...("required" in validation || required ? {"disabled": true} : {})}>
          {props.defaultValue !== undefined && props.defaultValue !== null && "Please select an option"}
        </option>
        { options ? options.map(option => (
          <option value={option.value} key={option.value}>{option.label}</option>
        )) : null }
      </select>
      <EledoErrorMessage id={id} path={path}/>
    </fieldset>
  );
}
EledoSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  path: PropTypes.string,
  defaultValue: PropTypes.any,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  validation: PropTypes.object,
  noDefaultOption: PropTypes.bool,
};


export const EledoDocumentFormatSelect = ({ id, label, path, validation={}, required = false, defaultValue, ...props }) => {

  const formHandler = useFormContext();
  const { options } = useDocumentFormats();

  useEffect(() => {}, [options])

  if(!options || options.length <= 0){
    return (
      <fieldset>
        <EledoLabel id={id} label={label}/>
        Loading...
      </fieldset>
    )
  }
  
  if(required && !defaultValue && options && options.length > 0){
    defaultValue = options[0].value
    formHandler.setValue(id, defaultValue)
  }

  return (
    <fieldset>
    <EledoLabel id={id} label={label}/>
    <select {...formHandler.register(formFieldName(id, path), validation)}
            id={id}
            data-testid={id}
            defaultValue={defaultValue}
            name={formFieldName(id, path)}
            {...props}>
      <option value={null}
              {...("required" in validation || required ? {"disabled": true} : {})}>
        {props.defaultValue !== undefined && props.defaultValue !== null && "Please select an option"}
      </option>
      { options ? options.map(option => (
        <option value={option.value} key={option.value}>{option.label}</option>
      )) : null }
    </select>
    <EledoErrorMessage id={id} path={path}/>
  </fieldset>
  );
}
EledoDocumentFormatSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  path: PropTypes.string,
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  validation: PropTypes.object,
  noDefaultOption: PropTypes.bool,
};


export const EledoCheckboxField = ({ id, label, defaultValue, path,
                                     validation = {}, level = 0, ...props }) => {
  const formHandler = useFormContext();

  return (
    <div className="checkbox"
         style={{ marginLeft: (level * 1.5) + "rem"}}>
        <input className="col-lg-2"
               type="checkbox"
               style={{marginRight: "0.5rem"}}
               {...formHandler.register(formFieldName(id, path), validation)}
               id={id}
               data-testid={id}
               name={formFieldName(id, path)}
               defaultChecked={defaultValue}
               {...props}/>
      <label htmlFor={id} >{label}</label>
    </div>
  );
};
EledoCheckboxField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  path: PropTypes.string,
  value: PropTypes.bool,
  defaultValue: PropTypes.bool,
};

export const EledoCheckbox = ({id, label = 'Please set checkbox label', defaultValue = false,
                                onChangeHandler: onChange = () => {}, ...props}) => {
  const [_checked, _setChecked] = useState(defaultValue);

  const _handleChange = () => {
    if(!props.disabled) {
      const next = !_checked;
      _setChecked(next);
      onChange(next);
    }
  };

  return (
    <div className="checkbox"
         onClick={e => {e.stopPropagation(); _handleChange();}}
         style={{ cursor: props.disabled ? "default" : "pointer"}}>
      <input type="checkbox"
             style={{marginRight: "0.5rem"}}
             id={id}
             data-testid={id}
             name={label}
             defaultChecked={defaultValue}
             onClick={e => e.stopPropagation()}
             {...props}/>
      <label htmlFor={id} >{label}</label>
    </div>
  );
}
EledoCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChangeHandler: PropTypes.func
};

export const EledoRadioButtonField = ({id, path, groupName, label = 'Please set radio button label',
                                  defaultValue, validation = {},
                                onChangeHandler: onChange = () => {}, ...props}) => {
  const formHandler = useFormContext();

  useEffect(() => {
    formHandler.setValue(groupName, defaultValue);
  }, [formHandler, defaultValue, groupName])

  const value = formHandler.getValues()[groupName];

  return (
    <div className="radio" style={{marginTop: "-1rem"}}>
      <input type="radio"
             style={{marginRight: "0.5rem"}}
             {...formHandler.register(groupName, validation)}
             id={id}
             value={id}
             data-testid={id}
             />
      <label htmlFor={id} className={value === id ? "checked" : ""}>{label}</label>
    </div>
  );
}
EledoRadioButtonField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChangeHandler: PropTypes.func
};

export const EledoRadioButton = ({id, name, label = 'Please set radio button label',
                                  defaultValue = false, value,
                                onChangeHandler: onChange = () => {}, ...props}) => {
  const _handleChange = (e) => {
    if(!props.disabled) {
      onChange(e.target.id);
    }
  };

  return (
    <div className="radio"
         onClick={e => {e.stopPropagation(); _handleChange(e);}}
         style={{ cursor: props.disabled ? "default" : "pointer"}}>
      <input type="radio"
             style={{marginRight: "0.5rem"}}
             id={id}
             data-testid={id}
             name={name}
             value={value}
             onClick={e => e.stopPropagation()}
             {...props}/>
      <label htmlFor={id} className={value ? "checked" : ""}>{label}</label>
    </div>
  );
}
EledoRadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChangeHandler: PropTypes.func
};

export const EledoFileField = ({ id, label="", path, validation={}, ...props }) => {
  const formHandler = useFormContext();

  return (
    <fieldset>
      <EledoLabel id={id} label={label}/>
      <input {...formHandler.register(formFieldName(id, path), validation)}
             {...props}
             type="file"
             data-testid={id}
      />
      <EledoErrorMessage id={id} path={path}/>
    </fieldset>
  );
}
EledoFileField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  path: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  validation: PropTypes.object
};

export const EledoColorField = ({ id, label="", path, validation={}, ...props }) => {
  const formHandler = useFormContext();

  return (
    <fieldset>
      <EledoLabel id={id} label={label}/>
      <input {...formHandler.register(formFieldName(id, path), validation)}
             {...props}
             type="color"
             data-testid={id}
      />
      <EledoErrorMessage id={id} path={path}/>
    </fieldset>
  );
}
EledoColorField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  path: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  validation: PropTypes.object
};

export const EledoSubmitField = ({ id, label="", path, validation={}, ...props }) => {

  return (
    <div>
      <input value={label}
             type="submit"
             class="w-button button"
      />
      <EledoErrorMessage id={id} path={path}/>
    </div>
  );
}
EledoFileField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  path: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  validation: PropTypes.object
};

export const EledoErrorMessage = ({id, path}) => {
  const formHandler = useFormContext();
  if(formHandler?.formState?.errors) {
    const error = _.get(formHandler.formState.errors, formFieldName(id, path));
    if(error){
      return <p style={{minHeight: "1.4rem", height: "1.4rem", marginBottom: "-1.4rem"}}>
        <small className="error-message" role="alert">&nbsp;{error?.message}</small>
      </p>
    }
  }
  return null;
}
EledoErrorMessage.propTypes = {
  id: PropTypes.string,
  path: PropTypes.string,
};

export const EledoLabel = ({id, label, ...props}) => label ? <label htmlFor={id} {...props}>{label}:</label> : null;

export const formFieldName = (id, path) => {
  return path ? `${path}.${id}` : id;
}
