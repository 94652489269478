import { XFATemplateItemType } from "../EledoXfaEditor"

import { useEledoFormulaEditorModal } from "components/library/EledoFormulaEditor/useEledoFormulaEditorModal"
import styles from "./XfaTemplateItem.module.scss"


type XfaTemplateItemProps = {
  item: XFATemplateItemType
  onChange: (updatedItem: XFATemplateItemType) => void
}


const XfaTemplateItem = (props: XfaTemplateItemProps) => {
  const { renderFedModal, showFedModal} = useEledoFormulaEditorModal()

  const onExpressionChange = (updatedExpression: string) => {
    props.onChange({ ...props.item, expression: updatedExpression })
  }


  return (
    <div className={styles.itemWrapper}>
      <label>{props.item.label || props.item.id}</label>
      <input
        className='w-input'
        defaultValue={props.item.expression || ""}
        onFocus={(event) => showFedModal(event.target.value, "", props.item.valueType, (expression) => onExpressionChange(expression))}
        autoComplete="off"
        type="text"
      />
      { props.item.valueType !== "Boolean" && props.item.options && props.item.options.length > 0 && <span style={{color: "gray"}}>Available options: {props.item.options.join(", ")}</span>}
      { renderFedModal() }
    </div>
  )
}


export default XfaTemplateItem