import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  menuHidden: false
}

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    showMenu: state => {return {menuHidden: false}},
    hideMenu: state => {return {menuHidden: true}},
  },
});

export const { showMenu, hideMenu } = menuSlice.actions;
export default menuSlice.reducer;