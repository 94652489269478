import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import connectionsService from "_api/connectionsService";

const loadingStates = {detailLoading: true, listLoading: true, error: null};
const forgetStates = {forgetting: false, forgetError: null, forgotten: null};
const validateStates = {validating: false, validateError: true, validated: null};
const initialState = {
  list: [],
  ...loadingStates,
  ...forgetStates,
  ...validateStates,
};

export const fetchConnections = createAsyncThunk(
  'connections/fetchConnections',
  async () => {
    const response = await connectionsService.fetchConnections();
    return response.data.connections;
  }
);

export const forgetConnection = createAsyncThunk(
  'connections/forgetConnection',
  async id => {
    const response = await connectionsService.forgetConnection(id);
    return response.data;
  }
);

export const validateConnection = createAsyncThunk(
  'connections/validateConnection',
  async id => {
    const response = await connectionsService.validateConnection(id);
    return response.data;
  }
);

const connectionsSlice = createSlice({
  name: 'connections',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchConnections.pending, state => {
        state.list = [];
        state.listLoading = true;
        state.error = null;
      })
      .addCase(fetchConnections.fulfilled, (state, action) => {
        state.list = action.payload;
        state.listLoading = false;
      })
      .addCase(fetchConnections.rejected, (state, action) => {
        state.list = [];
        state.listLoading = false;
        state.error = action.error;
      })

      .addCase(forgetConnection.pending, state => {
        state.forgetLoading = true;
        state.forgetError = null;
      })
      .addCase(forgetConnection.fulfilled, (state, action) => {
        state.forgotten = action.payload;
        state.forgetLoading = false;
      })
      .addCase(forgetConnection.rejected, (state, action) => {
        state.forgetLoading = false;
        state.forgetError = action.error;
      })

      .addCase(validateConnection.pending, state => {
        state.validated = null;
        state.validating = true;
        state.validateError = null;
      })
      .addCase(validateConnection.fulfilled, (state, action) => {
        if(action.payload?.id && action.payload?.status) {
          state.list.find(conn => conn.id === action.payload.id).status = action.payload.status;
        }
        state.validated = action.payload;
        state.validating = false;
      })
      .addCase(validateConnection.rejected, (state, action) => {
        state.validated = null;
        state.validating = false;
        state.validateError = action.error;
      });
  }
});

export default connectionsSlice.reducer;