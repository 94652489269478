import { KeyboardEventHandler, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { tokenize } from '../process_tokens/tokenize'
import { setCursor_andFocus } from './utils/setCursor_andFocus'
import { useTokenClasses } from './useTokenClasses'
import { useCaretPosition } from './useCaretPosition'
import { useCaretIdentifier } from './useCaretIdentifier'

import { TToken } from '../process_tokens/TToken'

import css from './FedInput.module.scss'
import './utils/tokenColors.scss'
import { useFed } from 'featuresFed/fed_plugins/_context/useFed'
import { IToken } from 'chevrotain'



type Props = {
  id: string
  formula: string
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>
  on_formula?: (formula: string) => void
  onChange_tokens?: (ts: IToken[]) => void
  on_tokensOrig?: (tsO: IToken[]) => void
  on_caretPos?: (c: number) => void
  on_caretIdentifier?: (c: string) => void
}

const FedInput = forwardRef((props: Props, ref) => {

  const {log} = useFed()
  // const [formula, set_formula] = useState(props.formula)
  const [doReadCaretAt, set_doReadCaretAt] = useState<Date | null>(null)
  const [tokens, set_tokens] = useState<TToken[]>([])
  const refTextarea = useRef<HTMLTextAreaElement>(null)
  // const [wantedCursor, set_wantedCursor] = useState(-1)


  const { getTokenClasses } = useTokenClasses()
  const { caretPosition } = useCaretPosition({
    formula: props.formula,
    refTextarea,
    doReadCaretAt,
    onChange_caretPosition: props.on_caretPos
  })
  const { identifierStart } = useCaretIdentifier({
    tokens,
    caretPosition,
    onChange_caretIdentifier: props.on_caretIdentifier
  })

  useImperativeHandle(ref, () => ({
    replaceToken
  }))

  function replaceToken(needle: string, repl: string) {

    log('FedInput', 1)

    let replPos = (identifierStart >= 0) ? identifierStart : caretPosition
    // console.log('replaceToken', identifierStart, caretPosition, replPos)

    function strSplice(s: string, at: number, nDel: number, sInsert: string) {
      return s.substring(0, at) + (sInsert || '') + s.substring(at + nDel)
    }
    let f = strSplice(props.formula, replPos, needle.length, repl)

    // set_formula(f)
    // triggerFormulaOnChange(f)
    triggerOutsideChange(f)

    // update cursor
    let wantCursor = replPos + repl.length
    if (repl[repl.length - 1] === ')') // fn
      wantCursor -= repl.length - repl.indexOf('(') - 1
    _applyWantedCursor_inTimeout(wantCursor)
    // console.log('wantedCursor, pos, wantCursor', wantedCursor, wantCursor)
  }

  function triggerOutsideChange(f: string) {
    // console.log('triggerOutsideChange')
    props.on_formula?.(f)
  }

  const _updateTokenizing = (f: string) => {
    // console.log('_setNewFormula')

    let { tokensOrig, tokensWithWs } = tokenize(f)
    set_tokens(tokensWithWs as any)

    props.on_tokensOrig?.(tokensOrig)
    props.onChange_tokens?.(tokensWithWs as any)
  }

  function _applyWantedCursor_inTimeout(pos: number) {
    setTimeout(() => {
      setCursor_andFocus(refTextarea, pos)
      set_doReadCaretAt(new Date() as any)
    })
  }

  // useEffect(() => {
  //   _updateTokenizing(formula)
  //   triggerOutsideChange(formula)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formula]) // ci pridam _updateTokenizing alebo triggerOutsideChange, v oboch pripadoch sa mi spravi ifinite loop

  useEffect(() => {
    _updateTokenizing(props.formula)
  }, [props.formula])

  function onTextareaChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const f = e.target.value
    triggerOutsideChange(f)
    // set_formula(f)
    // console.log('onTextareaChange', formula, ' -> ', f)
  }

  return (
      <div className={css.editor}>
        <div className={css.tokensColors + ' ' + css.textareaLook}>
          {tokens && tokens.map((t, i) =>
            <span key={i} className={getTokenClasses(t)}>
              {t['text']}
            </span>
          )}
        </div>
        <textarea
          id={props.id}
          ref={refTextarea}
          onKeyDown={props.onKeyDown}
          className={css.cursorOverlayColors + ' ' + css.textareaLook}
          value={props.formula}
          onChange={e => onTextareaChange(e)}
          spellCheck={false}
        />
      </div>
  )
})

export { FedInput }
