import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';

const EledoContentWrapper = ( { menuHidden, shrink=true, ...props }) => {
  const [wrapperClass, setWrapperClass] = useState('');

  useEffect(() => {
    setWrapperClass(menuHidden ? 'eledoEditorContainer' : '')
  }, [menuHidden]);

  return (
    <div className={classnames("eledoContainer", "content-wrapper", wrapperClass, "stretch-vertically")}
         style={{
           flex: shrink ? 0 : "auto",
           position: "absolute",
           width: "100vw",
           height: shrink ? "auto" : "100vh",
           maxHeight: "100vh",
           overflowY: shrink ? "auto" : "hidden",
           overflowX: "hidden"
         }}>
      {props.children}
    </div>
  );
};

// Props type checking
EledoContentWrapper.propTypes = {
  menuHidden: PropTypes.bool.isRequired,
  shrink: PropTypes.bool,
};

export default EledoContentWrapper;
