import { useState, useContext } from 'react';
import {BASE_URL, API_URL} from "_api/constants";
import {CKEditorEventAction, useCKEditor} from "ckeditor4-react";
import { AuthContext } from 'AuthContext';

const HelpdeskCKEditor4 = ({id, formHandler}) => {
  const [ element, setElement ] = useState();
  const auth = useContext(AuthContext);
  const apiKey = auth.apiToken;

  const getConfig = () => {
    const ckeConfig = {};
    
    ckeConfig.autoGrow = true;

    ckeConfig.filebrowserBrowseUrl = `${BASE_URL}/images/helpdesk/browse`;
    ckeConfig.filebrowserImageBrowseUrl = `${BASE_URL}/images/helpdesk/browse`;
    ckeConfig.filebrowserUploadUrl = `${API_URL}/imageUpload?folder=helpdesk`;
    ckeConfig.filebrowserImageUploadUrl = `${API_URL}/imageUpload?folder=helpdesk`;
    ckeConfig.filebrowserWindowWidth = 1000;
    ckeConfig.filebrowserWindowHeight = 700;
    ckeConfig.language = "en";

    if(apiKey){
      ckeConfig.plugins = "dialogui,dialog,basicstyles,notification,button,toolbar,clipboard,enterkey,entities,floatingspace,wysiwygarea,indent,indentlist,fakeobjects,link,list,undo,image,uploadimage,filebrowser,autogrow";
    }else{
      ckeConfig.plugins = "dialogui,dialog,basicstyles,notification,button,toolbar,clipboard,enterkey,entities,floatingspace,wysiwygarea,indent,indentlist,fakeobjects,link,list,undo,autogrow";
    }

    return ckeConfig;
  }

  useCKEditor( {
    element,
    config: getConfig(),
    editorUrl: `${BASE_URL}/modules.gz/ckeditor/ckeditor.js`,
    // editorUrl: `http://localhost/eledo-editor/tapestry/TapGUI/src/main/webapp/ckeditor/ckeditor.js`,
    // editorUrl: `http://localhost/eledo/tapestry/TapGUI/src/main/webapp/ckeditor-prod/ckeditor/ckeditor.js`,
    subscribeTo: [ 'change', 'fileUploadRequest', 'fileUploadForm' ],
    initContent: '',
    dispatchEvent: ( { type, payload } ) => {
      if(type === CKEditorEventAction.change) {
        const data = payload.editor.getData();
        formHandler.setValue(id, data);
        formHandler.trigger(id);
      }else if(type === CKEditorEventAction.fileUploadRequest) {
        const xhr = payload.data.fileLoader.xhr;

        xhr.setRequestHeader( 'Api-Key', apiKey);
      } else if(type === '__CKE__fileUploadForm') {
        payload.data.apiKeyInput.setAttribute('value', apiKey);
      }
    },
  } );

  return (
    <div className="editor-container">
      <div ref={setElement} />
    </div>
  )
};

export default HelpdeskCKEditor4;
