
export function getHumanReadable(err: String[], formula: string) {
  if (!err?.length || !formula) return

  let er = ''+err
  // console.log('🌳❌ '+er)

  if (er.startsWith("NoViableAltException")) {

    if (er.endsWith("but found: ')'"))
      return 'You might have one extra parenthesis at the end ")"'

    if (er.endsWith("but found: ''"))
      return 'Incomplete data expression'
  }
    
  if (er.startsWith('NotAllInputParsedException: Redundant input, expecting EOF but found: )'))
    return 'You might have one extra parenthesis at the end ")"'

  if (er.startsWith('NotAllInputParsedException: Redundant input, expecting EOF but found: ('))
    return 'Functions must be followed by parentheses - no whitespace allowed.'

  if (er.startsWith("MismatchedTokenException: Expecting token of type --> Rparen"))
    return 'Please close the equation with right-side parenthesis ")"'

  return er
}
