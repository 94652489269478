import { FieldValues, RegisterOptions } from "react-hook-form";
import {FormFieldTypes} from "./enums";
import {
  barcode1DTypeSelectOptions,
  barcode2DTypeSelectOptions,
  barcodeZoomSelectOptions,
  fixedSizeSelectOptions,
  fontFamilySelectOptions, fontSizeSelectOptions,
  fontStyleSelectOptions,
  fontWeightSelectOptions,
  localeSelectOptions,
  textAlignSelectOptions,
  textDirectionSelectOptions,
  timezoneSelectOptions,
} from "./selectOptions";

export const CreateTemplateFormValidation = {
  templateName: {required: "Template name is required.", maxLength: {value: 50, message: "You may provide at most 50 characters for Template Name."}},
  id: {required: "Please select the document format."},
  document: {required: "A PDF file is required."}
};

export type FormModel = {
  fields: FormField[]
  validation: {
    [key: string]: RegisterOptions<FieldValues, any> | undefined
  }
}

export type FormField = {
  id: string
  label: string
  type: FormFieldTypes
  variables?: string
  level?: number
  options?: { label: string, value: string|number }[]
  required?: boolean
}

export const SettingsFormModel: FormModel = {
  fields:  [
    { id: "name", label: "Template Name", type: FormFieldTypes.SHORT_STRING },
    { id: "fileNameFormat", label: "File Name Format", variables: "", type: FormFieldTypes.EXPRESSION },
    { id: "formatId", label: "Document Format", type: FormFieldTypes.DOCUMENT_FORMAT_SELECT },
    { id: "locale", label: "Locale", type: FormFieldTypes.SELECT, options: localeSelectOptions },
    { id: "timezone", label: "Timezone", type: FormFieldTypes.SELECT, options: timezoneSelectOptions },
    { id: "description", label: "Description", type: FormFieldTypes.LONG_STRING },
    { id: "webhook", label: "Form Webhook", type: FormFieldTypes.SHORT_STRING },
    { id: "embed", label: "Make resulting PDF parseable by Eledo", type: FormFieldTypes.CHECKBOX },
  ],
  validation: {
    name: {required: "Template name is required.", maxLength: {value: 50, message: "Maximum is 50 characters."}},
    fileNameFormat: {maxLength: {value: 500, message: "Maximum is 500 characters."}},
    id: {required: "Please select the document format."},
    locale: {required: "Please select the document locale."},
    timezone: {},
    description: {maxLength: {value: 250, message: "Maximum is 250 characters."}},
    webhook: {},
    embed: {},
  }
};

export const FillableSettingsFormModel: FormModel = {
  fields:  [
    { id: "name", label: "Template Name", type: FormFieldTypes.SHORT_STRING },
    { id: "fileNameFormat", label: "File Name Format", variables: "", type: FormFieldTypes.EXPRESSION },
    { id: "locale", label: "Locale", type: FormFieldTypes.SELECT, options: localeSelectOptions },
    { id: "timezone", label: "Timezone", type: FormFieldTypes.SELECT, options: timezoneSelectOptions },
    { id: "description", label: "Description", type: FormFieldTypes.LONG_STRING },
    { id: "webhook", label: "Form Webhook", type: FormFieldTypes.SHORT_STRING },
    { id: "embed", label: "Make resulting PDF parseable by Eledo", type: FormFieldTypes.CHECKBOX },
    { id: "flatten", label: "Finalize (flatten) PDF Form document", type: FormFieldTypes.CHECKBOX },
    { id: "direction", label: "Default Text Direction", type: FormFieldTypes.SELECT, options: textDirectionSelectOptions },
  ],
  validation: {
    name: {required: "Template name is required.", maxLength: {value: 50, message: "Maximum is 50 characters."}},
    fileNameFormat: {maxLength: {value: 500, message: "Maximum is 500 characters."}},
    id: {required: "Please select the document format."},
    locale: {required: "Please select the document locale."},
    timezone: {},
    description: {maxLength: {value: 250, message: "Maximum is 250 characters."}},
    webhook: {},
    embed: {},
    flatten: {},
    direction: {}
  }
}

export const AttachedFileSettingsFormModel: FormModel = {
  fields:  [
    { id: "file", label: "Replace PDF Form file", type: FormFieldTypes.FILE },
    { id: "submitReplace", label: "Replace PDF Form file", type: FormFieldTypes.SUBMIT },
  ],
  validation: {
  }
}

export const SecurityFormModel: FormModel = {
  fields:  [
    { id: "canPrint", label: "Print", type: FormFieldTypes.CHECKBOX },
    { id: "canPrintDegraded", label: "Print Degraded quality only", type: FormFieldTypes.CHECKBOX, level: 1 },
    { id: "canModify", label: "Modify", type: FormFieldTypes.CHECKBOX },
    { id: "canAssembleDocument", label: "Assemble Document", type: FormFieldTypes.CHECKBOX, level: 1 },
    { id: "canModifyAnnotations", label: "Modify Annotations, Add Comments", type: FormFieldTypes.CHECKBOX, level: 1 },
    { id: "canFillInForm", label: "Fill in Form, Sign", type: FormFieldTypes.CHECKBOX, level: 2 },
    { id: "canExtractContent", label: "Extract Content", type: FormFieldTypes.CHECKBOX },
    { id: "canExtractForAccessibility", label: "Extract for Accessibility", type: FormFieldTypes.CHECKBOX, level: 1 },
    { id: "userPwd", label: "Document open password", variables: "", type: FormFieldTypes.EXPRESSION },
    { id: "ownerPwd", label: "Owner password", variables: "", type: FormFieldTypes.EXPRESSION },
  ],
  validation: {}
};

export const PdfEditorModalFormModel_BASIC: FormModel = { //J: mohol by byť aj CHECKBOX & B1_BARCODE
  fields: [
    { id: "desc", label: "Field ID", type: FormFieldTypes.SHORT_STRING, required: true },
    { id: "expression", label: "Data (expression)", type: FormFieldTypes.EXPRESSION },
  ],
  validation: {
    desc: {
      required: "Field ID is required.",
      maxLength: {value: 50, message: "Maximum is 50 characters."},
      pattern: {value: /^[a-z_][a-z0-9_]*$/, message: "Only a-z0-9_ are allowed, starting with a-z_."},
    },
  }
}

export const PdfEditorModalFormModel_TEXT: FormModel = {
  fields: [
    ...PdfEditorModalFormModel_BASIC.fields,
    { id: "font", label: "Font Family", type: FormFieldTypes.SELECT, options: fontFamilySelectOptions, required: true },
    { id: "size", label: "Font Size", type: FormFieldTypes.SELECT, options: fontSizeSelectOptions, required: true },
    { id: "weight", label: "Font Weight", type: FormFieldTypes.SELECT, options: fontWeightSelectOptions },
    { id: "style", label: "Font Style", type: FormFieldTypes.SELECT, options: fontStyleSelectOptions },
    { id: "color", label: "Text Color", type: FormFieldTypes.COLOR },
    { id: "align", label: "Text align", type: FormFieldTypes.SELECT, options: textAlignSelectOptions, required: true },
    { id: "multiline", label: "Multiline", type: FormFieldTypes.CHECKBOX }
  ],
  validation: {
    ...PdfEditorModalFormModel_BASIC.validation,
    size: { valueAsNumber: true },
    align: { valueAsNumber: true }
  }
}

export const PdfEditorModalFormModel_CHECKBOX: FormModel = {
  fields: [
    ...PdfEditorModalFormModel_BASIC.fields,
    { id: "boxSize", label: "Box Size", type: FormFieldTypes.SELECT, options: fixedSizeSelectOptions, required: true },
    { id: "color", label: "Color", type: FormFieldTypes.COLOR },
  ],
  validation: {
    ...PdfEditorModalFormModel_BASIC.validation,
    boxSize: { valueAsNumber: true }
  }
}

export const PdfEditorModalFormModel_BC1: FormModel = {
  fields: [
    ...PdfEditorModalFormModel_BASIC.fields,
    { id: "type", label: "Barcode type", type: FormFieldTypes.SELECT, options: barcode1DTypeSelectOptions, required: true },
    { id: "zoom", label: "Zoom", type: FormFieldTypes.SELECT, options: barcodeZoomSelectOptions, required: true },
    { id: "color", label: "Color", type: FormFieldTypes.COLOR },
  ],
  validation: {
    ...PdfEditorModalFormModel_BASIC.validation,
    zoom: { valueAsNumber: true }
  }
}

export const PdfEditorModalFormModel_BC2: FormModel = {
  fields: [
    ...PdfEditorModalFormModel_BASIC.fields,
    { id: "type", label: "Barcode type", type: FormFieldTypes.SELECT, options: barcode2DTypeSelectOptions, required: true },
    { id: "zoom", label: "Zoom", type: FormFieldTypes.SELECT, options: barcodeZoomSelectOptions, required: true },
    { id: "color", label: "Color", type: FormFieldTypes.COLOR },
  ],
  validation: {
    ...PdfEditorModalFormModel_BASIC.validation,
    zoom: { valueAsNumber: true }
  }
}
export const PdfEditorModalFormModel_BC2_boxSize: FormModel = {
  fields: [
    ...PdfEditorModalFormModel_BASIC.fields,
    { id: "type", label: "Barcode type", type: FormFieldTypes.SELECT, options: barcode2DTypeSelectOptions, required: true },
    { id: "boxSize", label: "Box Size", type: FormFieldTypes.SELECT, options: fixedSizeSelectOptions, required: true },
    { id: "zoom", label: "Zoom", type: FormFieldTypes.SELECT, options: barcodeZoomSelectOptions, required: true },
    { id: "color", label: "Color", type: FormFieldTypes.COLOR },
  ],
  validation: {
    ...PdfEditorModalFormModel_BASIC.validation,
    boxSize: { valueAsNumber: true },
    zoom: { valueAsNumber: true }
  }
}