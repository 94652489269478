import React from 'react';
import PropTypes from "prop-types";
import classnames from "classnames";

const EledoPagination = ({pages, selectedPage, onSelectPage}) => {
  if(Number.isNaN(pages) || pages <= 1) return null;
  return (
    <div className="pagination">
      {Array.from(Array(pages).keys()).map(page => {
        const pageId = page + 1;
        const sidePagesClass = pageId === 1 ? "first" : (pageId < pages) ? "" : "last";
        const selectedClass = selectedPage && pageId === selectedPage ? "" : "none";
        return (
          <div className={classnames("number", sidePagesClass, selectedClass)}
               key={`pagination-${pageId}`}
               onClick={() => onSelectPage(pageId)}>
            <div>{pageId}</div>
          </div>
        );
      })}
    </div>
  );
}

EledoPagination.propTypes = {
  pages: PropTypes.number,
  selectedPage: PropTypes.number,
  onSelectPage: PropTypes.func,
}

export default EledoPagination;
