import { gql } from "@apollo/client"
import { PuzzleStructure } from "./PuzzleStructures"


export interface PuzzleRequest {
  id?: string
  userId?: string
  connectionId?: string
  structureId?: string
  structure?: PuzzleStructure
  event?: string
  state?: number
}

export interface PuzzleRequestData {
  puzzleRequest: PuzzleRequest
}

export interface PuzzleRequestVars {
  id?: string
  puzzleRequest?: PuzzleRequestInput
  result?: number
}

export interface PuzzleRequestInput {
  userId?: string
  connectionId?: string
  structureId?: string
  event?: string
  state?: number
}

export interface CreatePuzzleRequestData {
  createPuzzleRequest: PuzzleRequest
}

export interface ConfirmPuzzleRequestData {
  confirmPuzzleRequest: PuzzleRequest
}

export const GET_PUZZLE_REQUEST = gql`
  query getPuzzleRequest($id: ObjectId!) {
    puzzleRequest(id: $id) { id }
  }
`;

export const CREATE_PUZZLE_REQUEST = gql`
  mutation createPuzzleRequest($puzzleRequest: PuzzleRequestInput) {
    createPuzzleRequest(puzzleRequest: $puzzleRequest) { id }
  }
`;

export const CONFIRM_PUZZLE_REQUEST = gql`
  mutation confirmPuzzleRequest($id: ObjectId!, $result: Int) {
    confirmPuzzleRequest(id: $id, result: $result) { id }
  }
`