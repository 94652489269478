import classnames from "classnames"

import css from "./RectFloatingButton.module.scss"


type RectFloatingButtonProps = {
  icon: string
  title: string
  tooltipId: string
  red?: boolean
  onClick: () => void
}


const RectFloatingButton = (props: RectFloatingButtonProps) => {
  return (
    <button
      className={classnames('button', 'w-button', 'outline', 'rounded', 'white-bg', props.red && 'red', css.rectFloatingButton, props.icon)}
      data-tip={props.title}
      data-for={props.tooltipId}
      onMouseDown={(event) => event.stopPropagation()}
      onClick={props.onClick}
    >
      <i className={`icon-${props.icon}`} />
    </button>
  )
}


export default RectFloatingButton