import { useState } from "react";
import styles from './EledoExpressionFieldNew.module.scss';
import { EledoLabel, formFieldName } from "../EledoFormsComponents";
import { useFormContext } from "react-hook-form";
import classnames from "classnames";
import { useEledoFormulaEditorModal } from "../EledoFormulaEditor/useEledoFormulaEditorModal";

const EledoExpressionFieldNew = ({id, label = "", path, validation={}, defaultValue, onChangeHandler = (value, valid) => {}, dataModel}) => {

  const [error] = useState(false);
  const [touched] = useState(false);
  const formHandler = useFormContext();
  const { renderFedModal, showFedModal} = useEledoFormulaEditorModal(dataModel)
// console.log("defaultValue", defaultValue)
  return (
    <>
      <div className={styles.EledoExpressionFieldNew}>
        <fieldset>
          <EledoLabel id={id} label={label}/>
          <div className={styles.Container}>
            <input data-testid={id}
                  {...formHandler.register(formFieldName(id, path), validation)}
                  id={id}
                  className={classnames(styles.ExpressionInput, "valid")}
                  onFocus={(e) => showFedModal(e.target.value, "", 'Any', (f) => {console.log("save", f);formHandler.setValue(id, f); formHandler.trigger(id);})}
                  defaultValue={defaultValue}
                  autoComplete="off" type="text"/>
            <div className={styles.ExpNotification}>
              <span className={styles.Hint}
                    style={{display: touched ? "none" : "block"}}>=</span>
              <span className={styles.Ok}
                    style={{display: (touched && !error) ? "block" : "none"}}>
                <i className="icon-checkmark"/>
              </span>
              <span className={styles.Error}
                    style={{display: (touched && error) ? "block" : "none"}}>
                <i className="icon-cross"/>
              </span>
            </div>
          </div>
        </fieldset>
      </div>

      { renderFedModal() }
    </>
  )
}

export default EledoExpressionFieldNew;
