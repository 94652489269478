import React, {useEffect} from 'react';
import styles from './EledoCreateRoot.module.scss';
import { EledoError, EledoLoader } from "components/library";
import { useParams} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import { fetchTemplate, resetTemplate} from "_reducers/templateReducer";
import classnames from "classnames";

const EledoCreateRoot = () => {

  const menuHidden = useSelector(state => state.menu.menuHidden);
  const [ templateLoading, error, templateId, templateVersion, templateType, template ] = useSelector(state => [
    state.templates.templateLoading,
    state.templates.error,
    state.templates.editedTemplate.id,
    state.templates.editedTemplate.realVersion,
    state.templates.editedTemplate.type,
    state.templates.editedTemplate
  ], shallowEqual)
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(fetchTemplate(id));
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(resetTemplate());
    };
  }, [dispatch]);

  return (
    <div className={classnames(styles.EledoCreateRoot, "stretch-vertically")} data-testid="EledoCreateRoot">
        <div className={classnames("section", "section-small", styles.EledoTemplateTopToolbar, styles.topToolbarShadow)}
          data-testid="template-top-toolbar">
          <div className={styles.titleContainer}>
            <h3 className={styles.templateTitle}>
              {template?.name}
            </h3>
          </div>
        </div>

        <div className={`mainContent stretch-vertically vertical-scroll`}>
          { templateLoading &&
            <div className="editor-container">
              <div>
                <EledoLoader/>
              </div>
            </div>
          }
          { error &&
            <EledoError title="Template was not loaded properly"
              description={error}/>
          }
          { !templateLoading && templateType >= 0 &&
            <></>
          }
        </div>
    </div>
  )
};

export default EledoCreateRoot;
