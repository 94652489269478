import React, {useEffect} from "react";
import {EledoPagination} from "components/library";
import utils from "utils";

function usePagination(selectedPage, itemsTotal, onPageSelected, onLoad = null) {
  useEffect(() => {
    if(onLoad) {
      onLoad(selectedPage);
    } else {
      handlePageSelected(selectedPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageSelected = (page) => {
    onPageSelected(page);
  };

  const renderPagination = () => <div className="pagination-wrap">
    <div/>
    <EledoPagination pages={Math.ceil(itemsTotal / utils.ITEMS_PER_PAGE)}
                     selectedPage={selectedPage}
                     onSelectPage={(page) => handlePageSelected(page)}/>
  </div>;

  return renderPagination;
}

export default usePagination;