import { useState, useEffect } from "react"
import { useApolloClient, useQuery } from "@apollo/client"
import { GET_GENERATION_LOGS, GenerationLogsData, GenerationLog, GenerationLogVars } from "graphql/GenerationLogQueries"


function useGenerationLogs({days, limit, page}: GenerationLogVars) {
  const client = useApolloClient()
  const { loading, error, data } = useQuery<GenerationLogsData, GenerationLogVars>(GET_GENERATION_LOGS, {variables:{days, limit, page}})
  const [generationLogs, set_generationLogs] = useState<GenerationLog[]>([])
  const [total, setTotal] = useState<number>(0)

  useEffect(() => {
    if (!data)
      return

    const _generationLogs: GenerationLog[] = data.generationLogs.items.map(log => structuredClone(log))

    set_generationLogs(_generationLogs)
    setTotal(data.generationLogs.total)
  }, [data, client])

  return { loading, error, generationLogs, total }
}


export default useGenerationLogs