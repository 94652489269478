import { useEffect, useState } from 'react'

import { traverseFind } from '../../zzz_tree/traverse/traverse'
import { useFedFunctions } from '../../fed_core/lang_fns/useFedFunctions'
import { useFed } from '../_context/useFed'

import { TNode } from 'featuresFed/fed_core/process_ast/TNode'
import { TVarType } from 'featuresFed/fed_core/lang_vars/TVar'


/*
  ako by som to riesil..
  - najdem nodu na ktorej som podla start a end
  - budem iterovat hore az po parenta ktory je typu fn
  - zistim kolkaty parameter to je
  - vypytam si definiciu z funkcii
  - zoberiem typ parametra na danej pozicii
  - nastavim
*/

export function useCaretFnAndParam(nodes: TNode | undefined, caretPosition: number, requiredType: TVarType) {

  const {log} = useFed()

  const [parentFnName, set_parentFnName] = useState<string | undefined>()
  const [currWantType, set_currWantType] = useState<TVarType>(requiredType)
  const { getFnParamInfo } = useFedFunctions()
  // const { getVarType } = useFedVariables()


  const _findCaretNode = (caretPos: number): TNode | undefined => {
    let nodeToken: TNode | undefined = traverseFind(nodes, n =>
      n.token?.length > 0 && caretPos >= n.start && caretPos <= n.end)

    if (nodeToken) {
        log('findCaretNode', 'nodeToken '+nodeToken.token+' '+nodeToken)
      return nodeToken
    }

    let nodeFnParams = traverseFind(nodes, n => {
      if (n.start === undefined)
        console.warn('something is bad, fnParams doesnt have start')

      return n.isFnParams && caretPos >= n.start && caretPos <= n.end
    })
    log('did we find fnParams?', nodeFnParams)

    log('findCaretNode', nodeFnParams)

    if (nodeFnParams) {
      log('findCaretNode', 'nodeFnParams ' + nodeFnParams.token)
      return nodeFnParams
    }

    log('findCaretNode', 'NONE')
  }

  const _findParentFn = (n: TNode | undefined): TNode | undefined => {
    while (n) {
      if (n.rule === 'fn') return n
      n = n.parent
    }
    return
  }

  const _findNthParam = (n: TNode | undefined, caretPos: number): number => {

    // if we only have fnParams, then its empty (i think so, will debug if no..)
    if (n?.rule === 'fnParams') {

      // toto je ze nema v parsovanom texte nie su childy este
      // nie ze funkcia nema children
      if (!n.kids.length)
        return 0

      let at = 0
      for (let i=0; i < n.kids.length; i++) {
        const kid = n.kids[i]
        if (kid.token !== 'Comma')
          continue

        if (caretPos <= kid.end)
          return at

        at++
      }

      return at
    }

    while (n) {
      if (n.rule === 'fnParams')
        return _findNthParam(n, caretPos)

      n = n.parent
    }
    return -1
  }


  useEffect(() => {

    set_parentFnName(undefined)
    set_currWantType('String')

    const caretNode = _findCaretNode(caretPosition)
    log('CP 1. caretNode', caretPosition+' '+caretNode)
    if (!caretNode){
      set_currWantType(requiredType)
      return
    }

    const parentFn = _findParentFn(caretNode.parent)
    // log('CP 2. parentFn', parentFn)
    if (!parentFn) return

    let fnName = parentFn.kids[0].text
    if (fnName.endsWith('('))
      fnName = fnName.slice(0, -1)
    set_parentFnName(fnName)
    log('set_parentFnName', fnName)

    // log('CP 3. fnName', fnName)
    const nthParam = _findNthParam(caretNode, caretPosition)
    log('CP 4. nthParam', nthParam)
    if (nthParam === -1) return

    const paramInfo = getFnParamInfo(fnName, nthParam)
    // log('CP 5. paramInfo', paramInfo)
    if (!paramInfo) return

    // this will return either token type (Number, String, Bool..), or variable type, or function output type
    let currWantType: TVarType = paramInfo.type

    // log('getReturnType', returnType)

    set_currWantType(currWantType)
    log('set_currWantType', currWantType)

    // set_currParam({
    //   fnName: fnName,
    //   caretPos: caretPosition,
    //   param: {
    //     name: paramInfo.name,
    //     type: paramInfo.type,
    //     nth: nthParam,
    //   },
    //   token: {
    //     type: caretNode.token,
    //     returnType: returnType,
    //     text: caretNode.text,
    //   },
    // })
    /* eslint-disable react-hooks/exhaustive-deps */    // SEE BELOW
  }, [nodes, caretPosition, requiredType])
  // }, [nodes, caretPosition, getFnParamInfo, getReturnType, findCaretNode, findParentFn, findNthParam])



  return { currWantType, parentFnName }
}


// BELOW..
// nejako sa to trigeruje dokola ked dam functions ako , aj ked sa nemeni ani caret position, ani content
// funkcie sa predpokladam tiez nemenia, cize jedine co sa imho meni je nodes
// neviem teraz ze ako to checknut, kazdopadne dam sem hardcoded check na fixnutie, a neskor sa k tomu vratim
// pre istotu som dole vyhodit aj funkcie z efektu a nechal len nodes a caretPosition
// uvidim ci to pomoze
// -> uhm, pici, pomohlo to..
// no ale to je tym ze som tam dal tie useCallback
// lebo teraz mi to dava warning ze:
// > React Hook useEffect has missing dependencies: 'findCaretNode'
// to si nechavam na neskor pochopit to
// ale nateraz poucenie, ze ked ta dam tu funkciu tak sa mi to zacykli
// cize nemal by som to tam davat kym sa projekt neuzavrie

