import { ApolloClient, ApolloProvider, createHttpLink, defaultDataIdFromObject, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AuthContext } from 'AuthContext';
import React, { useContext } from 'react'

function Apollo({children}: {children: JSX.Element}) {

  const context = useContext(AuthContext);

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_ELEDO_BASE_URL + '/api/graphql',
  });
  
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        "Api-Key": context.apiToken ? context.apiToken : "",
      }
    }
  });
  
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      dataIdFromObject: (o, c) => {
        switch(o.__typename){
          case 'DataModelField':
            return `${o.__typename}:${o.id}:${o.name}`
          default:
            return defaultDataIdFromObject(o)
        }
      }
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network'
      },
      query: {
        fetchPolicy: 'network-only'
      },
      mutate: {
        fetchPolicy: 'network-only'
      }
    }
  })

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}


export default Apollo;