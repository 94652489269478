import React, {useEffect, useState} from 'react';
import styles from './EledoPropertyList.module.scss';
import {useFormContext} from "react-hook-form";
import {EledoErrorMessage} from "components/library/EledoFormsComponents";
import {VALIDATION__MAX50} from "model";
import _ from "lodash";
import classnames from "classnames";
import utils from "utils";
import {nanoid} from "nanoid";

const EledoPropertyList = ({ fields = [], defaultValues= [], path }) => {
  const formHandler = useFormContext();
  const [values, setValues] = useState(undefined);

  useEffect(() => {
    if(values === undefined) {
      const copy = utils.deepCopy(defaultValues);
      copy.forEach(v => {if(!v.id) v.id = nanoid();})
      setValues(copy);
    }
  }, [values, setValues, defaultValues]);

  const _handleAddRow = e => {
    e.preventDefault();
    const newObj = {id: nanoid()};
    for (const f of fields) {
      newObj[f.id] = f.type === "number" ? 0 : "";
    }
    const newValues = [...utils.deepCopy(values), utils.deepCopy(newObj)];
    setValues(newValues);
    saveValuesToHookFormAndValidate(newValues);
  };

  const _handleDeleteRow = (e, valId) => {
    e.preventDefault();
    const deleted = utils.deepCopy(values).filter(val => val.id !== valId);
    setValues(deleted);
    saveValuesToHookFormAndValidate(deleted);
  };

  const saveValuesToHookFormAndValidate = (newValues) => {
    formHandler.setValue(path, utils.deepCopy(newValues));
    formHandler.trigger();
  }

  return (
    <div className={styles.EledoPropertyList} data-testid="EledoPropertyList">
      <table>
        <thead>
          <tr>
            {fields?.map((f, index) => <th key={index}>{f.label}</th>)}
          </tr>
        </thead>
        <tbody>
        {values?.map((val, vi) =>
          <tr key={`val-${val.id}`}>
            {fields?.map((f, fi) => (
              <td key={`field-${val.id}-${f.id}`}>
                <input
                  type={f.type}
                  defaultValue={values[vi][f.id]}
                  name={`${path}[${vi}][${f.id}]`}
                  {...formHandler.register(`${path}[${vi}][${f.id}]`, {
                    required: {value: true, message: `${_.capitalize(f.label)} is required.`},
                    maxLength: VALIDATION__MAX50,
                  })}
                  onChange={(e) => {
                    const _values = utils.deepCopy(values);
                    _values[vi][f.id] = e.target.value;
                    setValues(_values);
                  }}
                />
                {/*{`${path}[${vi}][${f.id}]`}*/}
                <EledoErrorMessage id={`${path}[${vi}][${f.id}]`}/>
              </td>
            ))}
            <td>
              <button className="button w-button red none small-space"
                      onClick={(e) => _handleDeleteRow(e, val.id)}>
                <i className="icon-bin" style={{marginRight: 0}}/>
              </button>
            </td>
          </tr>
        )}
        </tbody>
      </table>

      <button className={classnames("button", "w-button", "outline", styles.addRowButton)}
              onClick={_handleAddRow}>
        <i className="icon-plus"/>
        Add row
      </button>
    </div>
  );
}


export default EledoPropertyList;
