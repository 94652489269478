import { TToken } from './TToken'
import { MyLexer } from './MyLexer'
import { IToken } from 'chevrotain'

function tokenize(s: string): { tokensOrig: IToken[], tokensWithWs: TToken[] } {

    const res = MyLexer.tokenize(s)

    let tokensOrig = res.tokens
    if (!tokensOrig) {
        console.error('unexpected')
        return { tokensOrig: [], tokensWithWs: [] }
    }

    let myTokens: TToken[] = []
    for (let i = 0; i < tokensOrig.length; i++) {
        let t_orig = tokensOrig[i]
        let myToken: TToken = {
            text: t_orig['image'],
            type: t_orig.tokenType['name'],
            startOffset: t_orig.startOffset,
            endOffset: t_orig.endOffset,
            _orig: t_orig,
        }
        myTokens.push(myToken)
    }

    let tokensWithWs = _getWithWs(myTokens)

    return {
        tokensOrig,
        tokensWithWs
    }
}

function _getWithWs(tokens: TToken[]): TToken[] {

    let out: TToken[] = []

    let currPos = 0
    for (let i = 0; i < tokens.length; i++) {

        let t = tokens[i]
        // console.log('start to end', token['startOffset'], token['endOffset'])
        let spaces = t._orig['startOffset'] - currPos
        if (spaces > 0) {
            const ws = { text: ' '.repeat(spaces), type: 'Ws', startOffset:  currPos, endOffset: currPos + spaces, _orig: t._orig }
            out.push(ws)
        }

        out.push(t)
        currPos = t._orig['endOffset'] + 1
    }

    return out
}

export { tokenize }
