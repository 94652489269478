import React, {useContext, useState} from 'react';
import styles from './EledoCreateTemplate.module.scss';
import {
  CreateTemplateFormValidation as validation,
} from "model";
import {EledoCreateTemplateTypes, TEMPLATE__TYPES as ttypes} from "model/enums";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {EledoMainPageTitle, EledoPendingContent} from "components/library";
import {EledoCheckbox, EledoDocumentFormatSelect, EledoTextField} from "components/library/EledoFormsComponents";
import {showNotyf, useShowMenu} from "hooks";
import {createPdfTemplate, createTemplate} from "_reducers/templateReducer";
import {FormProvider, useForm} from "react-hook-form";
import ReactTooltip from "react-tooltip";
import classnames from 'classnames';
import NotyfContext from 'context/NotyfContext';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const EledoCreateTemplate = () => {
  const navigate = useNavigate()
  
  const [creating, creatingError] = useSelector(state => [
    state.templates.creating, state.templates.creatingError
  ], shallowEqual);

  const { type } = useParams();

  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  const handleCreateTemplate = async (data) => {
    let response;
    if(type === EledoCreateTemplateTypes.uploadPdf) {
      data["data"]["templateType"] = ttypes.FILLABLE_DOCUMENT;
      response = await dispatch(createPdfTemplate(data));
    } else if(type === EledoCreateTemplateTypes.convertWord) {
      data["data"]["templateType"] = -1;
      response = await dispatch(createPdfTemplate(data));
    } else if(type === EledoCreateTemplateTypes.staticPages) {
      data["templateType"] = ttypes.STATIC_PAGE;
      response = await dispatch(createTemplate(data));
    } else {
      data["templateType"] = ttypes.DYNAMIC_FLOW;
      response = await dispatch(createTemplate(data));
    }
    if(response?.payload?.data?.error) {
      showNotyf(response.payload.data.error, notyf, "error");
    }
    if(response?.payload?.templateId) {
      navigate(`/template/${response.payload.templateId}`);
    }
  };

  useShowMenu();
  // useErrorNotyf(creatingError);

  return (
    <div className={classnames(styles.EledoCreateTemplate, "section", "my-section")}
         style={{marginBottom: "2rem"}} data-testid="EledoCreateTemplate">

      <TypeSwitch templateType={type}/>

      <div className={styles.formContainer}>
        { (type === EledoCreateTemplateTypes.dynamicFlow || type === EledoCreateTemplateTypes.staticPages) &&
          <StaticAndDynamicForm creating={creating} creatingError={creatingError} handleCreateTemplate={handleCreateTemplate}/>
        }
        { type === EledoCreateTemplateTypes.uploadPdf  &&
          <FillableDocumentForm type={type} creating={creating} creatingError={creatingError} handleCreateTemplate={handleCreateTemplate}/>
        }
        { type === EledoCreateTemplateTypes.convertWord &&
          <div className="col-wrapper">
            <div className="snow-card">
              <h1>In development</h1>
              <p>This feature is currently in development and should be available soon. We recommend you to try the other ways to create Eledo template.</p>
            </div>
          </div>
        }
      </div>
    </div>
  )
};

const StaticAndDynamicForm = ({creating, handleCreateTemplate}) => {
  const formHandler = useForm({mode: "all"});
  const { handleSubmit, formState: { isValid } } = formHandler

  return (
    <FormProvider {...formHandler}>
      <form className="w-form" onSubmit={handleSubmit((data) => handleCreateTemplate(data))}>
        <fieldset>
          <EledoTextField id="templateName" label="Template Name:" reguired="true" validation={{required: "Template name is required.", maxLength: {value: 50, message: "You may provide at most 50 characters for Template Name."}}}/>
        </fieldset>

        <fieldset>
          <EledoDocumentFormatSelect id="documentFormat" label="Document Format:" required="true"/>
        </fieldset>

        <span data-tip={isValid ? "" : "You need to specify the template name"}
              data-for={isValid ? "" : 'button-tooltip'}>
                <button className={classnames("button", "center-block", styles.submitButton)}
                        data-submit-mode="normal"
                        name="submit_0" type="submit"
                        {...(isValid ? {} : {"aria-disabled": "true"})}>
                  <EledoPendingContent state={creating}
                                      content="Create"
                                      pendingContent="Creating..."/>
                </button>
              </span>

        <ReactTooltip
          id="button-tooltip"
          place="right"
          type="warning"
          effect="float"
          multiline={true}
        />
      </form>
    </FormProvider>
  )
};

const FillableDocumentForm = ({type, creating, handleCreateTemplate}) => {
  const { register, handleSubmit, formState: { isValid, errors } } = useForm({mode: "all"});
  const [flatten, setFlatten] = useState(true);

  return <form className="w-form" onSubmit={handleSubmit((data) => {
    data["data"]["flatten"] = flatten;
    handleCreateTemplate(data);
  })}>
    <fieldset>
      <label htmlFor="data.templateName">Template Name:</label>
      <input {...register("data.templateName", validation.templateName)} id="data.templateName"/>
      {errors.data?.templateName && <p><small className="error-message" role="alert">{errors.data?.templateName?.message}</small></p>}
    </fieldset>

    <fieldset>
      <label htmlFor="file">{ type === EledoCreateTemplateTypes.uploadPdf ? 'PDF' : 'Word'} File:</label>
      <input type="file" {...register("file", validation.document)} accept={ type === EledoCreateTemplateTypes.uploadPdf ? "application/pdf" : "application/docx"}/>
      {errors["file"] && <p><small className="error-message" role="alert">{errors["file"].message}</small></p>}
    </fieldset>

    { type === EledoCreateTemplateTypes.uploadPdf &&
      <fieldset>
        <EledoCheckbox id="flatten" defaultValue={flatten} label="Finalize (flatten) PDF Form document once filled in"
                      onChangeHandler={(checked) => setFlatten(checked)}/>
      </fieldset>
    }

    <span data-tip={isValid ? "" : "You need to specify the template name"}
          data-for={isValid ? "" : 'button-tooltip'}>
            <button className={classnames("button", "center-block", styles.submitButton)}
                    data-submit-mode="normal"
                    name="submit_0" type="submit"
                    {...(isValid ? {} : {"aria-disabled": "true"})}>
              <EledoPendingContent state={creating}
                                   content="Create"
                                   pendingContent="Creating..."/>
            </button>
          </span>

    <ReactTooltip
      id="button-tooltip"
      place="right"
      type="warning"
      effect="float"
      multiline={true}
    />
  </form>;
};

const TypeSwitch = ({templateType, onChangeTemplateType}) => {
  const navigate = useNavigate()

  return (
    <div>
      <EledoMainPageTitle title="Which template type do you want to create?"
                          subtitle="Please select one option and then specify the template name and document format."
                          align="center"/>

      <div className={classnames("row", styles.typeSwitch)}>
        <div className="col-wrapper my-col-wrapper">
          <TypeSwitchButton
            setup={switchButtonsSetup.dynamic}
            active={templateType === EledoCreateTemplateTypes.dynamicFlow}
            onClick={() => navigate("/templates/new/dynamicFlow")}
          />
          <TypeSwitchButton
            setup={switchButtonsSetup.static}
            active={templateType === EledoCreateTemplateTypes.staticPages}
            onClick={() => navigate("/templates/new/staticPages")}
          />
          <TypeSwitchButton
            setup={switchButtonsSetup.pdf}
            active={templateType === EledoCreateTemplateTypes.uploadPdf}
            onClick={() => navigate("/templates/new/uploadPdf")}
          />
          <TypeSwitchButton
            setup={switchButtonsSetup.word}
            active={templateType === EledoCreateTemplateTypes.convertWord}
            onClick={() => navigate("/templates/new/convertWord")}
          />
        </div>
      </div>
    </div>
  );
};

const switchButtonsSetup = {
  dynamic: {
    title: "Dynamic Flow",
    description: "Design one \"infinite\" document flow which will be automatically split into pages when document is generated.",
    note: null,
    icon: "icon-infinite",
  },
  static: {
    title: "Static Pages",
    description: "Design every document page separately.",
    note: "Be aware! The dynamic content not fitting in one page will be rendered to a new page. This type allows you to have different page sizes in one document.",
    icon: "icon-stack",
  },
  pdf: {
    title: "Fillable PDF",
    description: "Upload your PDF Form document and setup how to populate form values.",
    note: "",
    icon: "icon-file-pdf",
  },
  word: {
    title: "Convert Word",
    description: "Upload your Word document and get it converted into Dynamic Flow template.",
    note: "",
    icon: "icon-file-word",
  }
}

const TypeSwitchButton = ({setup, active, onClick}) => (
  <button className={classnames("col-33", "button", "w-button",
                               {'none': !active, 'light-border': !active})}
          onClick={() => onClick()}>
    <i className={classnames("sub-page-hero-img", setup.icon, styles.switchIcon)}/>
    <h3 className={styles.switchTitle}>{setup.title}</h3>
    <p>{setup.description}</p>
    { setup.note && <p><small style={{fontStyle: "italic"}}>{setup.note}</small></p> }
  </button>
);

export default EledoCreateTemplate;