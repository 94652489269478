import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./EledoProgressBar.module.scss";

const EledoProgressBar = ({
                        label,
                        backgroundColor = styles.progressBgColor,
                        visualParts = [
                          // {
                          //   percentage: "20%",
                          //   color: "white"
                          // }
                        ]
                      }) => {
  const [widths, setWidths] = useState(
    visualParts.map(() => {
      return 0;
    })
  );

  useEffect(() => {
    const id = requestAnimationFrame(() => {
      setWidths(
        visualParts.map(item => {
          return item.percentage;
        })
      );
    });
    return () => cancelAnimationFrame(id);
  }, [visualParts]);

  return (
    <>
      <div className={styles.progressLabel}>{label}</div>
      <div
        className={styles.progressVisualFull}
        // to change the background color dynamically
        style={{
          backgroundColor
        }}
      >
        {visualParts.map((item, index) => {
          // map each part into separate div and each will be animated
          // because of the "transition: width 2s;" css in class "progressVisualPart"
          // and because of the new width ("widths[index]", previous one was 0)
          return (
            <div
              // There won't be additional changes in the array so the index can be used
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
              style={{
                width: widths[index],
                // setting the actual color of bar part
                backgroundColor: item.color
              }}
              className={styles.progressVisualPart}
            />
          );
        })}
      </div>
    </>
  );
};
export default EledoProgressBar;
EledoProgressBar.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  backgroundColor: PropTypes.string,
  visualParts: PropTypes.array,
}
