import React, { useEffect, useContext, useState } from 'react';
import styles from './EledoTemplateRoot.module.scss';
import {
  EledoTemplateTopToolbar, EledoEditorRoot,
  EledoStylesEditor, EledoInputFields, EledoSettings,
  EledoSecurity, EledoApi, EledoStorage, EledoTemplateMenu
} from '..';
import { EledoContentWrapper, EledoError, EledoLoader } from "components/library";
import {Navigate, Route, Routes, useParams } from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";
import {connectTemplate, fetchTemplate, resetTemplate} from "_reducers/templateReducer";
import classnames from "classnames";
import { AuthContext } from 'AuthContext';
import { PlatformContext } from 'PlatformContext';
import { useAppDispatch } from '_store';
import { activateRequestsByEvent } from '_reducers/puzzleRequests';
import { useLazyQuery } from '@apollo/client';
import { GET_FONTS } from 'graphql/FontQueries';
import { GET_DATA_MODEL } from 'graphql/DataModelQueries';

const EledoTemplateRoot = ({readOnly}) => {
  const platform = useContext(PlatformContext);
  const menuHidden = useSelector(state => state.menu.menuHidden);
  const [ templateLoading, error, templateId, templateVersion, templateType, activePageId, fontFamilies, dataModelId ] = useSelector(state => [
    state.templates.templateLoading,
    state.templates.error,
    state.templates.editedTemplate.id,
    state.templates.editedTemplate.realVersion,
    state.templates.editedTemplate.type,
    state.templates.activePageId,
    state.templates.editedTemplate.fonts,
    state.templates.editedTemplate.dataModel
  ], shallowEqual)
  const structureId = platform.structureId;
  const auth = useContext(AuthContext);
  const connectionId = auth.connection?.id;
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const [ getFonts ] = useLazyQuery(GET_FONTS);

  const [ getDataModel ] = useLazyQuery(GET_DATA_MODEL, { id: dataModelId})

  const [fonts, setFonts] = useState()
  const [fontsLoading, setFontsLoading] = useState(true)
  const [dataModel, setDataModel] = useState()
  const [dataModelLoading, setDataModelLoading] = useState(true)

  const baseUrl = (readOnly ? "/view/" : "/template/") + id

  useEffect(() => {
    if(templateLoading)
      return
    
    getFonts({variables: {families: fontFamilies, limit: 100}})
      .then(result => {
        setFonts(result.data.fonts?.items)
      })
      .catch(e => {
        console.log("Failed to load fonts", e)
      })
      .finally(() => {
        setFontsLoading(false)
      })

    getDataModel({variables: {id: dataModelId}})
      .then(result => {
        setDataModel(result.data?.dataModel)
      })
      .catch(e => {
        console.log("Failed to load data model", e)
      })
      .finally(() => {
        setDataModelLoading(false)
      })
  }, [templateLoading, dataModelId, fontFamilies, getDataModel, getFonts])

  useEffect(() => {
    if(platform.isEmbedded && !readOnly && connectionId) {
      dispatch(connectTemplate({templateId: id, platform: platform, connectionId, structureId}));
    } else {
      dispatch(fetchTemplate(id));
    }
  }, [dispatch, platform, id, structureId, connectionId, readOnly]);

  useEffect(() => {
    dispatch(activateRequestsByEvent("enter_template_editor"));
    return () => {
      dispatch(resetTemplate());
      dispatch(activateRequestsByEvent("leave_template_editor"));
    };
  }, [dispatch]);

  return (
    <div className={classnames(styles.EledoTemplateRoot, "stretch-vertically")} data-testid="EledoTemplateRoot">
      <EledoTemplateMenu baseUrl={baseUrl}/>

      <EledoContentWrapper menuHidden={menuHidden} shrink={false} id="template-wrapper">

        <EledoTemplateTopToolbar baseUrl={baseUrl}/>

        <div className={`mainContent stretch-vertically vertical-scroll`}>
          { (templateLoading || fontsLoading || dataModelLoading) &&
            <div className="editor-container">
              <div>
                <EledoLoader/>
              </div>
            </div>
          }
          { error &&
            <EledoError title="Template was not loaded properly"
              description={error}/>
          }
          { !(templateLoading || fontsLoading || dataModelLoading) && templateType >= 0 &&
            <Routes key={`${templateId}-${templateVersion}`}>
              <Route path="main" exact element={<EledoEditorRoot isBackground={false} fonts={fonts} dataModel={dataModel}/>}/>
              <Route path="main/style" exact element={<EledoStylesEditor isBackground={false} key={"fg"}/>}/>
              <Route path="background" exact element={<EledoEditorRoot isBackground={true}/>}/>
              <Route path="background/style" exact element={<EledoStylesEditor isBackground={true} key={"bg"}/>}/>
              <Route path="input-fields" element={<EledoInputFields dataModel={dataModel}/>}/>
              <Route path="settings" element={<EledoSettings key={activePageId} dataModel={dataModel}/>}/>
              <Route path="security" element={<EledoSecurity/>}/>
              <Route path="api" element={<EledoApi/>}/>
              <Route path="storage" element={<EledoStorage/>}/>
              <Route path="*" element={<Navigate to="main"/>}/>
            </Routes>
          }
        </div>
      </EledoContentWrapper>
    </div>
  )
};

export default EledoTemplateRoot;
