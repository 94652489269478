import eledo from "./eledo";

const SPENDING_URL = "/spending?page=";
const PAYMENTS_URL = "/payments?page=";
const PAYMENT_RECEIPT_URL = "/paymentReceipt/";

const fetchSpending = pageId => eledo.get(SPENDING_URL + pageId);
const fetchPayments = pageId => eledo.get(PAYMENTS_URL + pageId);
const fetchPaymentReceipt = paymentId => eledo.get(PAYMENT_RECEIPT_URL + paymentId, { responseType: 'blob' })

const spendingService = { fetchSpending, fetchPayments, fetchPaymentReceipt };
export default spendingService;