import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from 'AuthContext';


const PrivateRoute = ({ children }) => {
  const auth = useContext(AuthContext)
  const location = useLocation()

  if (auth.user) {
    return children
  }

  if (!auth.canLetLogin) {
    return <Navigate to="/start" state={{ from: location }} replace />
  }

  return <Navigate to="/login" state={{ from: location }} replace />
}

export default PrivateRoute;