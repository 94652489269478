import { PlatformData, PlatformDataProvider } from "_platform";
import cookieAuthService, { CookieAuthService } from "./eledo.online/cookieAuthService";

interface EledoContext extends PlatformData {
  authService: CookieAuthService
}

const eledoContext: EledoContext = {
  serviceName: 'ELEDO.ONLINE',
  platform: "eledo",
  isEmbedded: false,
  accountAutoCreate: false,
  authService: cookieAuthService,
  initSuccess: true,
  documentationPaths: {
    root: "documentation",
    guides: "/documentation/guides",
    template: "/documentation/guides/template_building",
    pdfform: "/documentation/guides/filling_in_PDF_form"
  }
};

const eledoContextProvider = (): Promise<EledoContext> => {

  return new Promise(function(resolve, reject) {
    setTimeout(function() {
      resolve(eledoContext);
    }, 300);
  })
}

export default { get: eledoContextProvider } as PlatformDataProvider;