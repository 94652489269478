import React, { useContext, useEffect } from 'react'
import EledoArticleView from './EledoArticleView'
import { EledoModal } from 'components/library'
import styles from './EledoArticlesModal.module.scss';
import useArticleHistory from 'hooks/useArticleHistory';
import { Tracker } from 'Tracker';
import { AuthContext } from 'AuthContext';

export const EledoArticlesModal = ({root, path, modalDisplayed, onDismiss} : {root: string, path: string, modalDisplayed: boolean, onDismiss: Function}) => {
  const auth = useContext(AuthContext);
  const history = useArticleHistory(path)
  
  useEffect(() => {
    modalDisplayed && Tracker.pageView(history.currentPath, {
      id: auth.user?.id || null,
      level: auth.level
    });
  }, [modalDisplayed])


  const modalContent = (
    <div style={{marginRight: '0.5rem'}}>
      <EledoArticleView root={root} path={path} history={history}/>
    </div>
  )
  
  const modalActions = (
    <>
    </>
  )
  
  return (
    <>
      <EledoModal wrapClass={styles.ModalWrap}
                  modalClass={styles.Modal}
                  titleClass={styles.ModalTitle}
                  actionsClass={styles.ModalActions}
                  divider={false}
                  content={modalContent}
                  displayed={modalDisplayed}
                  topless={true}
                  actions={modalActions}
                  onDismiss={() => onDismiss()}/>
    </>
  )
}
