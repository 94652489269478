import React, { useContext, useState } from 'react';
import styles from './EledoHelpdesk.module.scss';
import {useErrorNotyf, useShowMenu} from "hooks";
import {
  EledoRichTextArea, EledoMainPageTitle,
  EledoError, EledoLoader, EledoPendingContent, EledoPagination
} from "components/library";
import {useForm, FormProvider} from "react-hook-form";
import classnames from 'classnames';
import sanitizeHtml, { IOptions } from 'sanitize-html';
import { AuthContext } from 'AuthContext';
import { PlatformContext } from 'PlatformContext';
import useHelpdeskMessages from 'graphql/hooks/useHelpdeskMessages';
import { useMutation } from '@apollo/client';
import { CREATE_HELPDESK_MESSAGE, GET_HELPDESK_MESSAGES, HelpdeskMessage, HelpdeskMessageCreateData, HelpdeskMessageCreateVars } from 'graphql/HelpdeskMessageQueries';

const EledoHelpdesk = () => {
  const auth = useContext(AuthContext);
  const connectionId = auth.connection?.id;

  const limit = 20;
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [search] = useState<string | undefined>(undefined);

  const [open, setOpen] = useState<boolean>(false);
  const { loading, error, helpdeskMessages, total } = useHelpdeskMessages({connectionId, search, page: selectedPage, limit: limit});

  useErrorNotyf(error); 
  useShowMenu();

  const renderListError = () =>
    <EledoError title={error?.message}
                description={(<React.Fragment>
                  We are sorry &#9785; We could not load your messages. <br/><br/>
                  {error?.message?.toLocaleLowerCase() === "network error" && "Please check your internet connection."}
                </React.Fragment>)}/>;

  return (
    <div className={classnames(styles.EledoHelpdesk, "container", "animated-container")} data-testid="EledoHelpdesk">
      <EledoMainPageTitle
        title="Helpdesk Message Center"
        subtitle="We provide offline helpdesk support free of charge. Leave us a message and we get back to you as soon as possible."
      />

      <div className="section my-color-section">
        { !open &&
            <button className="w-button button" onClick={() => {setOpen(true)}}>New Message</button>
        }
        { open && 
          <div className="section snow my-color-section">
            <MessageForm page={selectedPage} limit={limit} onSent={() => {setOpen(false)}}/>
          </div>
        }

        <h2>Your Previous Messages:</h2>

        <div className="pagination-wrap">
          <div/>
          <EledoPagination pages={Math.ceil(total / limit)}
                      selectedPage={selectedPage}
                      onSelectPage={(page) => setSelectedPage(page)}/>
        </div>

        {error && renderListError()}
        {!loading && !error && <MessageList messages={helpdeskMessages}/>}
        {loading &&
          <div className="flex-center"><EledoLoader/></div>
        }

        <div className="pagination-wrap">
          <div/>
          <EledoPagination pages={Math.ceil(total / limit)}
                      selectedPage={selectedPage}
                      onSelectPage={(page) => setSelectedPage(page)}/>
        </div>
      </div>
    </div>
  );
}

const MessageForm = ({page, limit, onSent}: {page: number, limit: number, onSent: Function}) => {
  const auth = useContext(AuthContext);
  const connectionId = auth.connection?.id;
  const platform = useContext(PlatformContext);
  const structureId = platform.structureId;
  const formHandler = useForm<HelpdeskMessage>({mode: "onChange"});
  const { formState: {isValid} } = formHandler;

  const [sending, setSending] = useState<boolean>(false);

  const [ createHelpdeskMessage ] = useMutation<HelpdeskMessageCreateData, HelpdeskMessageCreateVars>(CREATE_HELPDESK_MESSAGE,
    { refetchQueries: [ { query: GET_HELPDESK_MESSAGES, variables: { connectionId, page, limit } } ] });

  const onSubmit = (data: HelpdeskMessage) => {
    if(data.message.length > 0 && isValid) {
      setSending(true);
      createHelpdeskMessage({ variables: {connectionId, structureId, message: data.message}})
      .then(() => {
        formHandler.reset();
        onSent();
        setSending(false);
      })
      .catch(() => {
        setSending(false);
      })
    }
  }

  return (
    <FormProvider {...formHandler}>
      <form className="w-form" style={{marginBottom: 0}}
            onSubmit={formHandler.handleSubmit(onSubmit)}>
        <h2 style={{marginBottom: "0.5rem"}}>Contact us:</h2>
        <EledoRichTextArea id="message"
                          onChange={formHandler}
                          label={""}
                          path={""}
                          placeholder="Describe your problem in detail here, please."
                          defaultValue={""} rows={6}
                          validation={{
                            required: {value: true, message: "Some message text is required."},
                            maxLength: {value: 500, message: "The message cannot have more than 500 characters."},
                          }}/>
        <button className="button w-button"
                type="submit"
                {...(!isValid ? {"aria-disabled": "true"} : {})}>
          <EledoPendingContent state={sending}
                              content={<React.Fragment><i className={classnames("icon-rocket", styles.ButtonIcon)}/>Send to Helpdesk</React.Fragment>}
                              pendingContent="Sending..."/>
        </button>
      </form>
    </FormProvider>
  );
}

const htmlSafeOptions:IOptions = {
  allowedTags: [
    'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
    // Text content
    'blockquote', 'dd', 'div', 'dl', 'dt', 'figcaption', 'figure',
    'hr', 'li', 'main', 'ol', 'p', 'pre', 'ul',
    // Inline text semantics
    'a', 'abbr', 'b', 'bdi', 'bdo', 'br', 'cite', 'code', 'data', 'dfn',
    'em', 'i', 'kbd', 'mark', 'q',
    's', 'samp', 'small', 'span', 'strong', 'sub', 'sup', 'time', 'u', 'var', 'wbr',
    // Table content
    'caption', 'col', 'colgroup', 'table', 'tbody', 'td', 'tfoot', 'th',
    'thead', 'tr',
    // Image
    'img'
  ],
  disallowedTagsMode: 'discard',
  allowedAttributes: {
    a: [ 'href', 'name', 'target' ],
    img: [ 'src', 'alt', 'title', 'width', 'height', 'loading' ]
  },
  selfClosing: [ 'img', 'br', 'hr'],
  allowedSchemes: [ 'https', 'mailto', 'tel' ],
  allowedSchemesByTag: {},
  allowedSchemesAppliedToAttributes: [ 'href', 'src', 'cite' ],
  allowProtocolRelative: true,
  enforceHtmlBoundary: false
};


const MessageList = ({messages}: {messages: HelpdeskMessage[] | undefined}) => {

  if(!messages) {
    return <div>You've got no previous communication.</div>;
  }

  return (
    <div className={classnames(styles.MessageList)} style={{marginTop: "2rem"}}>
      {messages && messages.map(m =>
        <div className={classnames("w-layout-grid", m.type === 1 ? styles.LeftGrid : styles.RightGrid)} key={m.id}>
          <div className={classnames(styles.Icon, styles.IconLeft)}>
            { m.type === 1 && <i className="icon-user"/> }
          </div>
          <div>
            <div dangerouslySetInnerHTML={{__html: sanitizeHtml(m.message, htmlSafeOptions)}} className={classnames("snow-card", styles.Text)}>
            </div>
            <div className={styles.Date}>
              <small>{new Date(m.date).toLocaleString()}</small>
            </div>
          </div>
          <div className={classnames(styles.Icon, styles.IconRight)}>
            { m.type === 2 && <i className="icon-lifebuoy"/> }
          </div>
        </div>
      )}
    </div>
  );
}

export default EledoHelpdesk;
