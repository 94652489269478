import {FormFieldTypes} from "./enums";
import {countrySelectOptions} from "./selectOptions";
import {EMAIL_VALIDATION_PATTERN} from "utils";

const PATH_BILLING = "accounting.billTo";
const PATH_NOTIF = "notifications";

export const VALIDATION__MAX50 = {value: 50, message: "Maximum is 50 characters."};
export const VALIDATION__MAX250 = {value: 250, message: "Maximum is 250 characters."};

export const BillingFormModel = {
  fields:  [
    { id: "full_name", path: PATH_BILLING, label: "Full name", type: FormFieldTypes.SHORT_STRING },
    { id: "address_line_1", path: PATH_BILLING, label: "Address line 1", type: FormFieldTypes.SHORT_STRING },
    { id: "address_line_2", path: PATH_BILLING, label: "Address line 2", type: FormFieldTypes.SHORT_STRING },
    { id: "admin_area_2", path: PATH_BILLING, label: "City", type: FormFieldTypes.SHORT_STRING },
    { id: "postal_code", path: PATH_BILLING, label: "Postal code", type: FormFieldTypes.SHORT_STRING },
    { id: "country_code", path: PATH_BILLING, label: "Country", type: FormFieldTypes.SELECT, options: countrySelectOptions },
    { id: "tax_id", path: PATH_BILLING, label: "Tax ID", type: FormFieldTypes.SHORT_STRING },
  ],
  validation: {
    country: {},
    full_name: {maxLength: VALIDATION__MAX50},
    address_line_1: {maxLength: VALIDATION__MAX50},
    address_line_2: {maxLength: VALIDATION__MAX50},
    admin_area_2: {maxLength: VALIDATION__MAX50},
    postal_code: {maxLength: VALIDATION__MAX50},
    tax_id: {maxLength: VALIDATION__MAX50},
  }
};

export const BillingFormModel2 = {
  fields:  [
    { id: "email", label: "E-mail", type: FormFieldTypes.SHORT_STRING },
    { id: "full_name", label: "Full name", type: FormFieldTypes.SHORT_STRING },
    { id: "address_line_1", label: "Address line 1", type: FormFieldTypes.SHORT_STRING },
    { id: "address_line_2", label: "Address line 2", type: FormFieldTypes.SHORT_STRING },
    { id: "admin_area_2", label: "City", type: FormFieldTypes.SHORT_STRING },
    { id: "postal_code", label: "Postal code", type: FormFieldTypes.SHORT_STRING },
    { id: "country_code", label: "Country", type: FormFieldTypes.SELECT, options: countrySelectOptions },
    { id: "tax_id", label: "Tax ID", type: FormFieldTypes.SHORT_STRING },
  ],
  validation: {
    email: {required: {value: true, message: "E-mail is required."}, pattern: {value: EMAIL_VALIDATION_PATTERN, message: "Please enter a valid email."}},
    full_name: {required: {value: true, message: "Full Name is required."}, maxLength: VALIDATION__MAX50},
    address_line_1: {required: {value: true, message: "Address Line 1 is required."}, maxLength: VALIDATION__MAX250},
    address_line_2: {maxLength: VALIDATION__MAX250},
    admin_area_2: {required: {value: true, message: "City is required."}, maxLength: VALIDATION__MAX50},
    postal_code: {required: {value: true, message: "Postal Code is required."}, maxLength: VALIDATION__MAX50},
    country: {required: {value: true, message: "Country is required."}},
    tax_id: {maxLength: VALIDATION__MAX50},
  }
};

export const OperationalNotificationsFormModel = {
  fields: [
    { id: "warnLimit80", path: PATH_NOTIF, label: "Send notification email when 80% of subscription document limit is consumed.", type: FormFieldTypes.CHECKBOX },
    { id: "warnLimit100", path: PATH_NOTIF, label: "Send notification email when 100% of subscription document limit is consumed.", type: FormFieldTypes.CHECKBOX },
    { id: "helpdeskReply", path: PATH_NOTIF, label: "Send notification email when we reply to your Helpdesk query.", type: FormFieldTypes.CHECKBOX },
    { id: "monthlyReport", path: PATH_NOTIF, label: "Send Eledo Summary at the end of your monthly period.", type: FormFieldTypes.CHECKBOX },
    { id: "opsEmail", path: PATH_NOTIF, label: "Email for Operational notifications", type: FormFieldTypes.SHORT_STRING },
  ],
  validation: {
    warnLimit80: {},
    warnLimit100: {},
    helpdeskReply: {},
    opsEmail: {pattern: {value: EMAIL_VALIDATION_PATTERN, message: "Please enter a valid email."}}
  }
}

export const PaymentNotificationsFormModel = {
  fields: [
    { id: "paymentReceived", path: PATH_NOTIF, label: "Send notification email when we receive a Payment from you.", type: FormFieldTypes.CHECKBOX },
    { id: "paymentEmail", path: PATH_NOTIF, label: "Email for Payment notifications", type: FormFieldTypes.SHORT_STRING },
  ],
  validation: {
    paymentReceived: {},
    notifications: {pattern: {value: EMAIL_VALIDATION_PATTERN, message: "Please enter a valid email."}}
  }
}

export const TableFieldIdValidation = {
  required: "Name is required.",
  maxLength: VALIDATION__MAX50,
};

export const TableFieldStringValueValidation = {
  maxLength: VALIDATION__MAX50,
}

export const TableFieldNumericValueValidation = {
  required: "Value is required.",
}
