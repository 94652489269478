import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import pricingPlansService from "_api/pricingPlansService";

const plansInitialState = {
  level: null,
  nextLevel: null,
  plans: [],
  customPlan: null,
  customPlanNext: null,
};
const userPlanInitialState = {
  currentPlan: null,
  currentPlanLevel: 0,
  nextPlan: null,
  nextPlanLevel: 0,
  nextPlanStart: null,
};
const loadingStates = {plansLoading: false, error: null};
const userPlanStates = {userPlansLoading: false, userPlansError: false};
const changePlanStates = {changing: false, changeError: null};
const initialState = {
  plans: plansInitialState,
  userPlan: userPlanInitialState,
  planChange: null,
  ...loadingStates,
  ...userPlanStates,
  ...changePlanStates,
  stripePromise: null
};

export const fetchPricingPlans = createAsyncThunk(
  'pricingPlans/fetchPricingPlans',
  async () => {
    const response = await pricingPlansService.fetchPricingPlans();
    return response.data;
  }
);

export const fetchUserPlan = createAsyncThunk(
  'pricingPlans/fetchUserPlan',
  async () => {
    const response = await pricingPlansService.fetchUserPlan();
    return response.data;
  }
);

export const changePricingPlan = createAsyncThunk(
  'pricingPlans/changePricingPlan',
  async (plan, { rejectWithValue }) => {
    return pricingPlansService.changePricingPlan(plan)
      .then(response => response.data)
      .catch(error => rejectWithValue({data: error?.data, status: error?.status}))
  }
);

const pricingPlansSlice = createSlice({
  name: 'pricingPlans',
  initialState,
  reducers: {
    applyResponse: (state, action) => {
      state.planChange = action.payload.planChange;
      state.changeError = action.payload.error;
    },
    storeStripePromise: (state, action) => {
      state.stripePromise = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPricingPlans.pending, state => {
        state.plans = plansInitialState;
        state.plansLoading = true;
        state.error = null;
      })
      .addCase(fetchPricingPlans.fulfilled, (state, action) => {
        state.plans = action.payload;
        state.plansLoading = false;
      })
      .addCase(fetchPricingPlans.rejected, (state, action) => {
        state.plans = plansInitialState;
        state.plansLoading = false;
        state.error = action.error;
      })

      .addCase(fetchUserPlan.pending, state => {
        state.userPlan = plansInitialState;
        state.userPlanLoading = true;
        state.userPlanError = null;
      })
      .addCase(fetchUserPlan.fulfilled, (state, action) => {
        state.userPlan = action.payload;
        state.userPlanLoading = false;
      })
      .addCase(fetchUserPlan.rejected, (state, action) => {
        state.userPlan = plansInitialState;
        state.userPlanLoading = false;
        state.userPlanError = action.userPlanError;
      })

      .addCase(changePricingPlan.pending, state => {
        state.changing = true;
        state.changeError = null;
      })
      .addCase(changePricingPlan.fulfilled, (state, action) => {
        state.planChange = action.payload;
        state.changing = false;
      })
      .addCase(changePricingPlan.rejected, (state, action) => {
        state.changing = false;
        state.changeError = action.payload?.data?.error;
      })
  }
});

export const { applyResponse, storeStripePromise } = pricingPlansSlice.actions;
export default pricingPlansSlice.reducer;