import React, { useRef } from 'react';
import styles from './EledoSpending.module.scss';
import classnames from "classnames";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {fetchSpending} from "_reducers/spendingReducer";
import {fetchPayments} from "_reducers/paymentsReducer";
import {
  EledoMainPageTitle, EledoLoader,
  EledoError, EledoProgressBar, EledoTabs
} from "components/library";
import {usePagination} from "hooks";
import spendingService from '_api/spendingService';
import { Link } from 'react-router-dom';

const EledoSpending = () => {
  //!!the following constants need to be here to activate the data display:
  // eslint-disable-next-line no-unused-vars
  const [spendingLoading, paymentsLoading] = useSelector(state => [state.spending.loading, state.payments.loading], shallowEqual);
  const downloadLinkRef = useRef(null)

  return (
    <div className={styles.EledoSpending} data-testid="EledoSpending">
      <div className={styles.TopToolbar}>
        <EledoMainPageTitle title="Spending & Payments" subtitle=""/>
      </div>

      <EledoTabs id="spendingTabs">
        <div label="Spending">
          <SpendingList/>
        </div>
        <div label="Payments">
          <PaymentsTable downloadLinkRef={downloadLinkRef}/>
        </div>
      </EledoTabs>

      <a href="#download" ref={downloadLinkRef}> </a>

    </div>
  );
}

const renderError = (error, text) =>
  <EledoError title={error?.message}
              description={(<React.Fragment>
                We are sorry &#9785; We could not load your {text}. <br/><br/>
                {error?.message?.toLocaleLowerCase() === "network error" && "Please check your internet connection."}
              </React.Fragment>)}/>;

const SpendingList = () => {
  const {list: data, loading, error} = useSelector(state => state.spending);
  const [ selectedPage, itemsTotal ] = useSelector(state => [
    state.spending.selectedPage, state.spending.itemsTotal ]);
  const dispatch = useDispatch();
  const renderPagination = usePagination(selectedPage, itemsTotal, pageId => dispatch(fetchSpending(pageId)));

  if(loading) return <div className="flex-center"><EledoLoader/></div>;
  if(error) return renderError(error, "spending");
  if(data && data.length === 0) return <div>You have no spending reports yet.</div>

  return data && <div className={classnames("container", styles.SpendingContainer)}>

    {renderPagination()}

    {data.map(s => {
      const percentagePlanUsed = s.dc/s.planLimit*100 + "%";
      const creditUsed = Math.min(s.dc - s.planLimit, s.credit);
      const percentageCreditUsed = ((s.dc - s.planLimit)/s.credit)*100 + "%";
      const overquota = s.dc - s.planLimit - s.credit;
      const percentageOverquota = 100 * overquota / (Math.ceil(overquota / 1000) * 1000) + "%";

      return <div key={s.id} className={styles.Row}>
        <div className={styles.SpendingTitleContainer}>
          <h4>{new Date(parseInt(s.start)).toLocaleDateString()} - {new Date(parseInt(s.end)).toLocaleDateString()}</h4>
          <h4>{s.planName}</h4>
        </div>
        <h5 className={styles.DocumentsCreated}>Documents created: {s.dc}</h5>
        <div>
          <div className={styles.SpendingGrid}>
            <div>Plan used:</div>
            <div>
              <EledoProgressBar
                visualParts={[{percentage: percentagePlanUsed, color: styles.primaryGreen}]}
              />
            </div>
            <div className='text-right'>{Math.min(s.dc, s.planLimit)}/{s.planLimit}</div>

            {(s.dc - s.planLimit > 0) &&
              <React.Fragment>
                <div>Credit used:</div>
                <div>
                  <EledoProgressBar
                    visualParts={[{percentage: percentageCreditUsed, color: styles.accentYellow}]}
                  />
                </div>
                <div className='text-right'>{creditUsed}/{s.credit}</div>
              </React.Fragment>
            }

            {(overquota > 1) &&
              <>
                <div>Overquota:</div>
                <div className={styles.Overquota}>
                  <EledoProgressBar
                    backgroundColor="white"
                    visualParts={[{percentage: percentageOverquota, color: styles.accentRed}]}
                  />
                </div>
                <div className='text-right'>{overquota}</div>
              </>
            }
          </div>
        </div>
      </div>
    })}

    {!error && !loading && renderPagination()}

  </div>;
}

const PaymentsTable = (downloadLinkRef) => {
  const {list: data, loading, error} = useSelector(state => state.payments);
  const [itemsTotal, selectedPage] = useSelector(state => [
    state.payments.itemsTotal, state.payments.selectedPage
  ] , shallowEqual);
  const dispatch = useDispatch();

  const renderPagination = usePagination(selectedPage, itemsTotal, pageId => dispatch(fetchPayments(pageId)));

  return data && <div className={classnames("container", styles.PaymentsContainer)}>

    {renderPagination()}

    {renderPaymentsHeader()}

    {loading && <div className="flex-center"><EledoLoader/></div>}
    {error && renderError(error, "payments")}
    {data && data.length === 0 && <div className={styles.noData}>You have no payments yet.</div>}
    {!loading && !error && data && data.length > 0 &&
      data.map(p => <div key={p.id} className={classnames("w-layout-grid", "data-grid", styles.dataGrid)}>
        <div className="_wf-table-cell">
          <div className="tile-title">Date</div>
          <div>{new Date(parseInt(p.date)).toLocaleDateString()}</div>
        </div>

        <div className="_wf-table-cell">
          <div className="tile-title">Period</div>
          <div>{new Date(parseInt(p.periodStart)).toLocaleDateString()} - {new Date(parseInt(p.periodEnd)).toLocaleDateString()}</div>
        </div>

        <div className="_wf-table-cell">
          <div className="tile-title">Service</div>
          <div>{p.service}</div>
        </div>

        <div className="_wf-table-cell">
          <div className="tile-title">Received</div>
          <div>{p.received ? new Date(parseInt(p.received)).toLocaleDateString() : ""}</div>
        </div>

        <div className="_wf-table-cell">
          <div className="tile-title">Method</div>
          <div>{p.method}</div>
        </div>

        <div className="_wf-table-cell">
          <div className="tile-title">Amount</div>
          <div>{(+p.amount).toFixed(2)}&nbsp;{p.currency}</div>
        </div>

        <div className="_wf-table-cell">
          <div className="tile-title">Receipt</div>
          <div>
            { p.receiptId && 
              <>
                <a href="#download" onClick={ () => {
                  spendingService.fetchPaymentReceipt(p.id)
                    .then(res => {
                      return res.data;
                    }).then(blob => {
                        const href = window.URL.createObjectURL(blob);
                        const a = downloadLinkRef.downloadLinkRef.current;
                        a.download = p.receiptId + ".pdf";
                        a.href = href;
                        a.click();
                        a.href = '';
                    }).catch(err => console.error(err));

                }}>{p.receiptId}</a>
              </>
            }
            { p.manual && !p.received &&
              <Link className="w-button button" to={'/pricing/change?paymentRequest=' + p.id}>Pay</Link>
            }
            </div>
        </div>

      </div>)
    }

    {!error && !loading && renderPagination()}
  </div>;
}

const renderPaymentsHeader = () => <div className={classnames("w-layout-grid", "header-grid", styles.headerGrid, styles.dataGrid)}>
  <div className="_wf-table-cell">Date</div>
  <div className="_wf-table-cell">Period</div>
  <div className="_wf-table-cell">Service</div>
  <div className="_wf-table-cell">Received</div>
  <div className="_wf-table-cell">Payment Method</div>
  <div className="_wf-table-cell">Amount</div>
  <div className="_wf-table-cell">Receipt</div>
</div>

export default EledoSpending;
