import {useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocumentFormats } from '_reducers/documentFormatsReducer';

function useDocumentFormats() {
  const dispatch = useDispatch();

  const [ options, documentFormats, listLoaded, listLoading ] = useSelector((state) => [ state.documentFormats.options,
    state.documentFormats.documentFormats,
    state.documentFormats.listLoaded,
    state.documentFormats.listLoading ]);

  useEffect(() => {
    if(!listLoaded && !listLoading){
      dispatch(fetchDocumentFormats({pageId: 1, limit: 40}))
    }
  }, [ listLoaded, listLoading, dispatch ]);

  return {
    options,
    documentFormats
  }
}

export default useDocumentFormats