import { gql } from "@apollo/client"
import { PuzzleComponentStructure } from "components/puzzle/PuzzleStructure"


export interface PuzzleStructure {
  id?: string
  name?: string
  title?: string
  width?: number
  height?: number
  component?: PuzzleComponentStructure
}

export interface PuzzleStructureData {
  puzzleStructure: PuzzleStructure
}

export interface PuzzleStructureVars {
  name?: string
}

export const GET_PUZZLE_STRUCTURE = gql`
  query getPuzzleStructure($name: String!) {
    puzzleStructure(name: $name) { id name title width height component }
  }
`;

