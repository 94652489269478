import React, {useState, useContext } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {saveTemplate} from "_reducers/templateReducer";
import {API_URL, BASE_URL} from "_api/constants";
import {CKEditorEventAction, useCKEditor} from "ckeditor4-react";
import utils from "utils";
import { AuthContext } from 'AuthContext';
import { useEledoFormulaEditorModal } from 'components/library/EledoFormulaEditor/useEledoFormulaEditorModal';

const EledoCKEditor4 = ({isBackground, fonts, dataModel}) => {
  const editedTemplate = useSelector(state => state.templates.editedTemplate);
  const activePageId = useSelector(state => state.templates.activePageId );
  const auth = useContext(AuthContext);
  const apiKey = auth.apiToken;
  const document = editedTemplate.documents[activePageId].innerDocument;
  const initData = isBackground ? document.bgContent : document.content;
  const dispatch = useDispatch();
  const [ element, setElement ] = useState();
  const {renderFedModal, showFedModal} = useEledoFormulaEditorModal(dataModel)

  const getConfig = ( document, isBackground ) => {
    const format = document.format;
    const renderConfig = document.renderConfig;
    const width = format.pixelWidth * renderConfig.srcDpi / renderConfig.dstDpi;
    const height = format.pixelHeight * renderConfig.srcDpi / renderConfig.dstDpi;

    const ckeConfig = {};
    if(!isBackground){
      ckeConfig.contentsCss = document.style
    }else{
      ckeConfig.contentsCss = document.bgStyle;
    }
    ckeConfig.width = "100%";
    if(isBackground || document.type === 10){
      ckeConfig.height = height + 10;
    }else{
      ckeConfig.height = "100%";
      ckeConfig.autoGrow = true;
      ckeConfig.autoGrow_onStartup = true;
      ckeConfig.autoGrow_bottomSpace = 50;
      ckeConfig.autoGrow_minHeight = height;
    }
    ckeConfig.documentWidth = width;
    ckeConfig.documentHeight = height;

    ckeConfig.filebrowserBrowseUrl = `${BASE_URL}/images/browse`;
    ckeConfig.filebrowserImageBrowseUrl = `${BASE_URL}/images/browse`;
    ckeConfig.filebrowserUploadUrl = `${API_URL}/imageUpload?`;
    ckeConfig.filebrowserImageUploadUrl = `${API_URL}/imageUpload?`;
    ckeConfig.filebrowserWindowWidth = 1000;
    ckeConfig.filebrowserWindowHeight = 700;
    ckeConfig.language = "en";

    return ckeConfig;
  }

  const setDocumentSize = (ckinstance) => {
    let widthHeight = 'width:'+ ckinstance.config.documentWidth + 'px !important;';
    if(ckinstance.config.autoGrow !== true)
      widthHeight += 'height:'+ ckinstance.config.documentHeight +'px !important;';
    const css = [];
    css.push( 'html{height:100% !important;margin-left:auto;margin-right:auto;box-sizing: border-box;-webkit-box-sizing:border-box;'
      +'-moz-box-sizing:border-box;box-shadow:1px 1px 4px #EBEBEB;-moz-box-shadow:1px 1px 4px #EBEBEB;'
      +'-webkit-box-shadow:1px 1px 4px #EBEBEB;outline:none;border:1px solid #CCC;'
      +'overflow-x:hidden;' + widthHeight + '}'
      +'body{overflow:visible;}' );
    ckinstance.addContentsCss(css);

    const fontsCss = fonts && fonts.map(f => {
      return `@font-face { font-family: '${f.title}'; src: url('${f.fontUrl}') format('truetype'); font-weight: ${f.weight}; font-style: ${f.style}; }`
    })

    ckinstance.addContentsCss(fontsCss)
  }

  useCKEditor( {
    element,
    config: getConfig(document, isBackground),
    editorUrl: `${BASE_URL}/modules.gz/ckeditor/ckeditor.js`,
    // editorUrl: `http://localhost/eldedo-editor/tapestry/TapGUI/src/main/webapp/ckeditor/ckeditor.js`, // toto sa nepouziva, iba url v heklpdeskcdkeditor4 sa pouziva..
    subscribeTo: [ 'beforeLoad', 'pluginsLoaded', 'mode', 'change', 'fileUploadRequest', 'fileUploadForm' ],
    initContent: initData,
    dispatchEvent: ( { type, payload } ) => {
      if (type === CKEditorEventAction.beforeLoad) {
        window.CKEDITOR.DgVariables = editedTemplate.derived.variables;

        // add button
        // payload.editor.ui.addButton( 'Abbr', {
        //   label: 'Insert Abbreviation',
        //   command: 'abbr',
        //   toolbar: 'insert'
        // });


      } else if (type === CKEditorEventAction.loaded || type === CKEditorEventAction.pluginsLoaded || type === CKEditorEventAction.mode) {
        setDocumentSize(payload.editor);
        
        window.CKEDITOR.EledoFed_open = (val, context, requiredType) => {
          showFedModal(val, context, requiredType, (newFormula) => {
            const applyValue = window.CKEDITOR.EledoFed_applyToCk
            if(applyValue){
              applyValue(newFormula)
            }
          })
        }
      } else if(type === CKEditorEventAction.change) {
        const data = payload.editor.getData();
        const newTemplate = utils.deepCopy(editedTemplate);
        if(isBackground) {
          newTemplate.documents[activePageId].innerDocument.bgContent = data;
        } else {
          newTemplate.documents[activePageId].innerDocument.content = data;
        }
        dispatch(saveTemplate(newTemplate));
      } else if(type === CKEditorEventAction.fileUploadRequest) {
        const xhr = payload.data.fileLoader.xhr;
        xhr.setRequestHeader( 'Api-Key', apiKey);
      } else if(type === '__CKE__fileUploadForm') {
        payload.data.apiKeyInput.setAttribute('value', apiKey);
      }
    },
  } );

  return (
    <div className="editor-container">
      <div ref={setElement} />

      {renderFedModal()}

    </div>
  )
};

export default EledoCKEditor4;
