import { gql } from "@apollo/client"
import { DefaultVars } from "./queries"


export interface Article {
  id: string
	version: number
	activeVersion: number
	realVersion: number
	title: string
	slug: string
	root: string
	path: string
	parent: string
	parents: [Article]
	order: number
	published: boolean
	nomenu: boolean
	index: boolean
	description: string
	markdown: string
	content: string
}

export interface ArticlesData {
  articles: {
    items: Article[],
    total: number
  }
}

export interface ArticlesVars extends DefaultVars {
  parent?: string
	platform?: string
}

export interface ArticleData {
  article: Article
}

export interface ArticleVars extends DefaultVars {
	id?: string
	path?: string
}

export type SearchResult = {
	title: string
	message: string
	url: string
	score: number
}

export type ArticleSearchData = {
	articleSearch: {
		results: [SearchResult]
	}
}

export type ArticleSearchVars = {
	searchTerm: string
	root: string
	articleId?: string
	platform?: string
	limit?: number
	page?: number
}

export type ArticleVoteData = Boolean

export type ArticleVoteVars = {
	id: string
	platform?: string
	voteHelpful?: boolean
	comment?: string
}

export const GET_ARTICLES = gql`
  query getArticles($ids: [ObjectId!], $parent: String, $platform: String, $limit: Int, $page: Int, $search: String) {
    articles(ids: $ids, parent: $parent, platform: $platform, limit: $limit, page: $page, search: $search) { total items { id title description slug path parent order } }
  }
`;

export const GET_ARTICLE = gql`
  query getArticle($id: ObjectId, $path: String) {
    article(id: $id, path: $path) { id version activeVersion realVersion title slug path parent parents { id title slug path } order nomenu description markdown content }
  }
`;

export const GET_ARTICLE_SEARCH = gql`
	query getArticleSearch($searchTerm: String!, $root: String!, $articleId: ObjectId, $platform: String, $limit: Int, $page: Int) {
		articleSearch(searchTerm: $searchTerm, root: $root, articleId: $articleId, platform: $platform, limit: $limit, page: $page) { results { title message url score } }
	}
`

export const VOTE_ARTICLE = gql`
	mutation voteArticle($id: ObjectId!, $platform: String, $voteHelpful: Boolean, $comment: String) {
		voteArticle(id: $id, platform: $platform, voteHelpful: $voteHelpful, comment: $comment)
	}
`