import { useMutation } from '@apollo/client';
import { AuthContext } from 'AuthContext';
import { PlatformContext } from 'PlatformContext';
import { EledoPageLoader } from 'components/library';
import { AuthDataData, AuthVars, GET_AUTH } from 'graphql/AuthQueries';
import React, { useContext, useEffect, useState } from 'react'

const AutoLogin = ({children}: {children: JSX.Element}) => {

  const platform = useContext(PlatformContext)

  const auth = useContext(AuthContext)

  const [appLoading, setAppLoading] = useState(true);
  
  const [doAuth] = useMutation<AuthDataData, AuthVars>(GET_AUTH, {
    onCompleted: (data, clientOptions) => {
      if(data){
        const vars = clientOptions?.variables as AuthVars;
        auth.login(data.auth, vars.type)
        setAppLoading(false);
      }
    },
    onError: (error) => {
      console.log(error);
      setAppLoading(false);
    }});

  useEffect(() => {
    if(platform === undefined){
      return
    }

    if(platform.accountAutoCreate){
      platform.authService.provideAuthUserCreate()
        .then(authCreate => {
          platform.authService.provideAuthData()
          .then(authData => {
            if(authData){
                doAuth({ variables: { type: authData.type, token: authData.token, create: authCreate }})
            } else {
              setAppLoading(false);
            }
          });
        })
    } else {
      platform.authService.provideAuthData()
      .then(authData => {
        if(authData){
            doAuth({ variables: { type: authData.type, token: authData.token }})
        } else {
          setAppLoading(false);
        }
      });
    }
  }, [platform, doAuth])

  if(appLoading) {
    return <EledoPageLoader text="Logging in..."/>;
  }

  return children
}

export default AutoLogin
