import React, {useEffect} from 'react';
import './EledoSecurity.scss';
import {useShowMenu} from "hooks";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useForm, FormProvider} from "react-hook-form";
import utils from "utils";
import {saveTemplateSecurityChanged, saveTemplateSecurityValid} from "_reducers/templateReducer";
import {EledoFormGenerator} from "components/library";
import {SecurityFormModel as formModel} from "model";

const EledoSecurity = () => {
  const selectedTemplate = useSelector(state => state.templates.selectedTemplate);
  const error = useSelector(state => state.templates.error, shallowEqual);
  const formHandler = useForm({mode: "all"});
  //!!the following two lines need to be here to activate the hook-form:
  // eslint-disable-next-line no-unused-vars
  const { formState: {isValid}, watch } = formHandler;
  const dispatch = useDispatch();

  useShowMenu();

  useEffect(() => {
    dispatch(saveTemplateSecurityValid(isValid));
  }, [isValid, dispatch]);

  useEffect(() => {
    const subscription = watch((data) => {
      if (selectedTemplate?.id) {
        dispatch(saveTemplateSecurityChanged(utils.deepCopy(data)));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, dispatch, selectedTemplate]);

  return (
    <div className="EledoSecurity section section-small" data-testid="EledoSecurity">
      <h3>Template Security</h3>
      <FormProvider {...formHandler}>
        <form>
          { !error &&
            selectedTemplate?.documents[0]?.innerDocument?.renderConfig?.protection &&
            <SecurityForm selectedTemplate={selectedTemplate}/>
          }
        </form>
      </FormProvider>
    </div>
  );
}

const SecurityForm = ({selectedTemplate}) =>
  <React.Fragment>
    <EledoFormGenerator
      formModel={formModel}
      editedDataObject={selectedTemplate.documents[0].innerDocument.renderConfig.protection}
      dataModelId={selectedTemplate.dataModel}
    />
  </React.Fragment>

export default EledoSecurity;
