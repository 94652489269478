import { gql } from "@apollo/client"
import { User } from "./UserQueries"


export interface HelpdeskMessage {
  id: string
  date: string
	message: string
	connectionId: string
	structureId: string
	admin: User
	source: number
	type: number
	status: number
}

export interface HelpdeskMessages {
  items: [HelpdeskMessage]
  total: number
}

export interface HelpdeskMessagesData {
  helpdeskMessages: HelpdeskMessages
}

export interface HelpdeskMessagesVars {
  connectionId?: string
  search?: string
  limit?: number
  page?: number
}

export interface HelpdeskStats {
  unread: number
}

export interface HelpdeskStatsData {
  helpdeskStats: HelpdeskStats
}

export interface HelpdeskStatsVars {
  connectionId?: string
}

export interface HelpdeskMessageCreateVars {
  message: string
  email?: string
  connectionId?: string
  structureId?: string
}

export interface HelpdeskMessageCreateData {
  createHelpdeskMessage: HelpdeskMessage
}

export const GET_HELPDESK_MESSAGES = gql`
  query getHelpdeskMessages($connectionId: ObjectId, $search: String, $limit: Int, $page: Int) {
    helpdeskMessages(connectionId: $connectionId, search: $search, limit: $limit, page: $page) { items { id date message connectionId structureId admin { id name surname } type status } total }
  }
`;

export const GET_HELPDESK_STATS = gql`
  query getHelpdeskStats($connectionId: ObjectId) {
    helpdeskStats(connectionId: $connectionId) { unread }
  }
`;

export const CREATE_HELPDESK_MESSAGE = gql`
  mutation createHelpdeskMessage($message: String!, $email: String, $connectionId: ObjectId, $structureId: String) {
    createHelpdeskMessage(message: $message, email: $email, connectionId: $connectionId, structureId: $structureId) { id }
  }
`;


