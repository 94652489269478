import { gql } from "@apollo/client"

export interface GenerationLogsData {
    generationLogs: GenerationLogs
}

export interface GenerationLogs {
    total: number
    items: GenerationLog[]
}

export type GenerationLog = {
    id: string
    template?: GenerationLogTemplate
    source: string
    logLevel: number
    documents: number
    pages: number
    preloadTime: number
    genTime: number
    uploadTime: number
    actionsTime: number
    type: number
    messages: GenerationLogMessage[]
}

export type GenerationLogMessage = {
    id: string
    message: string
    severity: number
}

export type GenerationLogTemplate = {
    id: string
    name: string
}

export type GenerationLogVars = {
    days?: number
    limit?: number
    page?: number
}

export const GET_GENERATION_LOGS = gql`
  query getLogs($days: Int, $limit: Int, $page: Int) {
    generationLogs(days: $days, limit: $limit, page: $page) {
        total
        items {
            id
            template {
                id
                name
            }
            source
            logLevel
            documents
            pages
            preloadTime
            genTime
            uploadTime
            actionsTime
            type
            messages {
                id
                message
                severity
            }
        }
    }
  }
`