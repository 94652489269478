
export function setCursor_andFocus(refTa: any, pos: number) {
    // console.log('setCursor_andFocus', pos)

    if (!refTa || !refTa.current || pos < 0)
        return

    const c = refTa.current
    c.focus()
    c.selectionStart = pos
    c.selectionEnd = pos
}
