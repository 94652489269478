import React from 'react';
import styles from './EledoLoader.module.scss';

const EledoLoader = () => (
  <div className={styles.EledoLoader}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default EledoLoader;
