import { gql } from "@apollo/client"


export type DataModel = {
  id: string
  source: string
  classId: string
  updated: Date
  classes: [DataModelClass]
}

export type DataModelClass = {
  id: string
	name: string
	plain: boolean
	textExpr: string
	fields: [DataModelField]
}

export type DataModelField = {
  id: string
	name: string
	type: string
	plainArray: boolean
	values: [string]
	labels: [string]
	formula: string
	extType: string
	relType: number
	relClass: string
	relField: string
	relAggr: string
	textExpr: string
}

export type DataModelData = {
  dataModel: DataModel
}

export type DataModelVars = {
  id?: string
}

export const GET_DATA_MODEL = gql`
  query getDataModel($id: ObjectId!) {
    dataModel(id: $id) { id source classId updated classes { id name fields { id name type relClass } } }
  }
`;
