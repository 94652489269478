import React, {useEffect, useState, useContext} from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import styles from './EledoPricingChange.module.scss';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {applyResponse, changePricingPlan, storeStripePromise} from "_reducers/pricingPlansReducer";
import {useErrorNotyf, useShowMenu} from "hooks";
import {
  EledoMainPageTitle, EledoError,
  EledoLoader, EledoPricingPlan, EledoRadioButton, EledoFormGenerator
} from "components/library";
import ReactTooltip from "react-tooltip";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from './StripeCheckoutForm';
import {BillingFormModel2 as billingModel2} from "model";
import { FormProvider, useForm } from 'react-hook-form';
import utils from "utils";
import { AuthContext } from 'AuthContext';
import usePuzzle from 'hooks/usePuzzle';

const errors = {
  subscription: "Please select a subscription type.",
  payment: "Please select a payment method."
}

const EledoPricingChange = () => {
  const auth = useContext(AuthContext);
  const [planChange, loading, error] = useSelector(state => [
    state.pricing.planChange,
    state.pricing.changing,
    state.pricing.changeError
  ], shallowEqual);

  const dispatch = useDispatch();
  const {planId: targetPlanId} = useParams();
  const location = useLocation();

  useErrorNotyf(error);
  useShowMenu();

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    if (query.get('success')) {
      const clientSecret = query.get('payment_intent_client_secret');

      const newPlanChange = {levelRequest: parseInt(targetPlanId), step: 3, responses: {
                              stripeSessionId: query.get('session_id'),
                              stripePaymentIntentId: query.get('payment_intent'),
                              stripePaymentIntentClientSecret: clientSecret} };
                              
      dispatch(changePricingPlan(newPlanChange));
        //.then(() => {myHistory.push(window.location.pathname)});

    } else if (query.get('canceled')) {
      
      const newPlanChange = {levelRequest: parseInt(targetPlanId), step: 3 };
      dispatch(applyResponse({error: "Order cancelled", planChange: newPlanChange}))
      // myHistory.push(window.location.pathname);
    } else if (query.get('paymentRequest')) {
      dispatch(changePricingPlan({paymentRequest: query.get('paymentRequest')}));
    } else {
      if(targetPlanId >= 0) {
        //BUG: When changePricingPlan returns error (HTTP 500) then the component loops infinitely and dispatches another changePricingPlan requests
        dispatch(changePricingPlan({levelRequest: parseInt(targetPlanId)}));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [dispatch, location.search, targetPlanId]);

  const handleSubmitStep1 = (subscriptionType, paymentMethod) => {
    const origin = window.location.origin;
    const pathname = window.location.pathname;
    const newPlanChange = {...planChange,
                            subscriptionType,
                            paymentMethod,
                            inline: paymentMethod === "CreditCard",
                            billTo: { email: auth.email, name: auth.name},
                            successUrl: origin + pathname + '?success=true&session_id={CHECKOUT_SESSION_ID}',
                            failureUrl: origin + pathname + '?canceled=true'
                          };
    dispatch(changePricingPlan(newPlanChange));
  }

  const handleSubmitStep2 = (billTo) => {
    const newPlanChange = {...planChange, billTo: billTo };
    dispatch(changePricingPlan(newPlanChange));
  }

  const handleSubmitStep3 = (subscriptionId, orderId) => {
    const newPlanChange = {...planChange, responses: { paypalSubscriptionId: subscriptionId, paypalOrderId: orderId } };
    dispatch(changePricingPlan(newPlanChange));
  }

  const handleBack = () => {
    dispatch(changePricingPlan({...planChange, step: planChange.step - 2}));
  };

  const renderError = () => (
    <>
      <EledoError title={error}
                  description={error}/>
      <button className="w-button button outline"
              style={{float: "right", marginTop: "1.5rem"}}
              onClick={() => handleBack()}>
        Back
      </button>
    </>
  )

  return (
    <div className={styles.EledoPricingChange} data-testid="EledoPricingChange">
      { planChange &&
        <EledoMainPageTitle title={planChange.paymentRequest ? "One Time Payment" : "Plan change request"} subtitle=""/>
      }

      <div className="container section section-small">
        {!loading && !error && planChange && (
          <div className="col-wrapper">
            <div className="col-33">
              { planChange.paymentRequest &&
                <EledoPricingPlan request={{service: planChange.service, paymentPrice: planChange.paymentPrice}}/>
              }
              { !planChange.paymentRequest &&
                <EledoPricingPlan plan={planChange.planLimits}/>
              }
            </div>
            <div className="col-66">
              { planChange.step === 1 &&
                <Step1 handleSubmit={handleSubmitStep1}
                        handleBack={handleBack}
                        planChange={planChange}
                        planId={targetPlanId}/>
              }
              { planChange.step === 2 &&
                <Step2 handleSubmit={handleSubmitStep2}
                        planChange={planChange}
                        handleBack={handleBack}/>
              }
              { planChange.step === 3 &&
                <Step3 handleSubmit={handleSubmitStep3}
                        planChange={planChange}
                        handleBack={handleBack}/>
              }
              { planChange.step === 4 &&
                <Step4 planChange={planChange}/>
              }
            </div>
          </div>
        )}
        {!loading && error && renderError()}
      </div>

      { loading && <div className="flex-center"><EledoLoader/></div> }
    </div>
  )
};

const Step1 = ({handleSubmit, handleBack, planChange}) => {
  const [valid, setValid] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(planChange.paymentMethod);
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(planChange.subscriptionType);

  useEffect(() => {
    if(!valid && selectedSubscriptionType && selectedPaymentMethod) {
      setValid(true);
    }
  }, [selectedSubscriptionType, selectedPaymentMethod, setSubmitted, valid]);

  const handleSTChange = (value) => setSelectedSubscriptionType(value);
  const handlePMChange = (value) => setSelectedPaymentMethod(value);

  if(!planChange.success){
    return (
        <>
          <EledoError title='Failed to setup payment session'
                      description={planChange.error}/>
          <button className="w-button button outline"
                  style={{float: "right", marginTop: "1.5rem"}}
                  onClick={() => handleBack()}>
            Back
          </button>
        </>
    )
  } else {
    return (
      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(selectedSubscriptionType, selectedPaymentMethod);
      }}>
  
        { planChange.subscriptionTypes && planChange.subscriptionTypes.length > 0 &&
          <>
            <h3>Subscription Period</h3>
      
            <p><small className="error-message" role="alert">
              {submitted && selectedSubscriptionType === null && errors.subscription}
            </small></p>
      
            <div className={styles.RadioGroup}
                onChange={(e) => handleSTChange(e.target.id)}>
              {planChange.subscriptionTypes.map(st => (
                <div key={st.key} style={{marginTop: "-1rem"}}>
                  <EledoRadioButton id={st.key}
                                    key={st.key}
                                    label={st.value}
                                    value={st.key.equals(selectedSubscriptionType)}
                                    name="subscriptionType"
                  />
                </div>
              ))}
            </div>
            <p>
              Plan will start after we receive payment from you and will continue for period
              of one month. At the end of Monthly period, you will be charged automatically
              on recurring basis and new period will begin. You can cancel your subscription
              at any time without penalty.
            </p>
          </>
        }
  
        <h3>Payment Method</h3>
  
        <p><small className="error-message" role="alert">
          {submitted && selectedPaymentMethod === null && errors.payment}
        </small></p>
  
        <div className={styles.RadioGroup}
             onChange={(e) => handlePMChange(e.target.id)}>
          {planChange.paymentMethods.map(pm => (
            <div key={pm.key} style={{marginTop: "-1rem"}}>
              <EledoRadioButton id={pm.key}
                                label={pm.value}
                                value={pm.key.equals(selectedPaymentMethod)}
                                name="paymentMethod"/>
            </div>
          ))}
        </div>
  
        <p style={{marginBottom: "2rem"}}>
          For any other way of payment, please <Link to={'/helpdesk'}>contact us</Link>.
        </p>
  
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          <button className="w-button button"
                  type="submit"
                  data-tip={valid ?
                    "" : "Please check the validity of your data"}
                  data-for="continue-button-tooltip"
                  {...(valid ? {} : {"aria-disabled": "true"})}>
            Continue
            <ReactTooltip
              id="continue-button-tooltip"
              place="right"
              type="warning"
              effect="float"
              multiline={true}/>
          </button>
          <Link className="w-button button outline"
                to={planChange.paymentRequest ? "/spending" : "/pricing"}>
            Cancel
          </Link>
        </div>
      </form>
    );
  }
}

const Step2 = ({handleSubmit, handleBack, planChange}) => {
  const [billTo, setBillTo] = useState(planChange.billTo || {});
  const formHandler = useForm({mode: "all"});
  const { formState: {isValid}, watch } = formHandler;


  useEffect(() => {
  }, [isValid]);
  
  useEffect(() => {
    const subscription = watch((data) => {
      setBillTo(utils.deepCopy(data));
    });
    return () => subscription.unsubscribe();
  }, [watch]);


  return (
    <FormProvider {...formHandler}>
      <form onSubmit={formHandler.handleSubmit(() => {
          if(isValid){
            handleSubmit(billTo);
          }
      })}>

        <h3>Billing Address</h3>
        {/* {errors && <EledoError title={errors}
                  description={errors}/>} */}

        <EledoFormGenerator
                formModel={billingModel2}
                editedDataObject={billTo}/>

        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          <button className="w-button button"
                  type="submit"
                  data-for="continue-button-tooltip"
                  >
            Continue
            <ReactTooltip
              id="continue-button-tooltip"
              place="right"
              type="warning"
              effect="float"
              multiline={true}/>
          </button>
          <button className="w-button button outline"
                style={{float: "right"}}
                onClick={() => handleBack()}>
                Back
          </button>
        </div>
      </form>
    </FormProvider>
  );
}

const Step3 = ({handleSubmit, planChange, handleBack}) => {
  const { renderPuzzle, submitPuzzleResponse } = usePuzzle("subscription_cancellation_survey");

  return (
    <React.Fragment>
      { !planChange.success &&
        <EledoError title='Failed to setup payment session'
                    description={planChange.error}/>
      }

      { planChange.success &&
        <div className={styles.Payments}>
          <h3>
            { planChange.paymentDescription }
          </h3>

          { planChange.paymentPrice > 0 && planChange.paymentMethod === "PayPal" &&
            <Step2PayPal handleSubmit={handleSubmit}
                        handleBack={handleBack}
                        planChange={planChange}/>
          }

          { planChange.paymentPrice > 0 && planChange.paymentMethod === "CreditCard" &&
            <Step2CreditCard handleSubmit={handleSubmit}
                        handleBack={handleBack}
                        planChange={planChange}/>
          }

          { planChange.paymentPrice === 0 && planChange.subscriptionType === "Cancel" &&
            <React.Fragment>
              
              { renderPuzzle() }
              
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <button className="w-button button red"
                  onClick={ () => {submitPuzzleResponse(); handleSubmit(null, null);}}>
                  Cancel subscription
                </button>
                <button className="w-button button outline"
                  style={{float: "right"}}
                  onClick={() => handleBack()}>
                  Back
                </button>
              </div>
            </React.Fragment>
          }

          { planChange.paymentPrice === 0 && planChange.subscriptionType !== "Cancel" &&
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
              <button className="w-button button"
                onClick={ () => {handleSubmit(null, null, null)}}>
                Confirm
              </button>
              <button className="w-button button outline"
                style={{float: "right"}}
                onClick={() => handleBack()}>
                Back
              </button>
            </div>
          }

        </div>
      }
      
    </React.Fragment>
  );
  }

const Step2PayPal = ({handleSubmit, handleBack, planChange}) => (
  <>
    <PayPalScriptProvider options={{ "client-id": "AcC0ME7VIMDauXg67dLuZSrM4_K4S_Bcx6JM2ebqL_g4EBBJGUfeePAyYxFedDcuNPn763LE-zy--voH",
              vault: planChange.settings.type === "PaypalSubscription",
              currency: 'EUR',
              intent: planChange.settings.type === "PaypalSubscription" ? "subscription" : "capture" }}>
      { planChange.settings.type === "PaypalOrder" &&
        <PayPalButtons
        createOrder={(data, actions) => {
            return actions.order.create({
              intent: "CAPTURE",
              purchase_units: [
                { amount: {
                  currency_code: planChange.settings.currencyCode,
                  value: planChange.settings.amount },
                  custom_id: planChange.settings.customId
                } ],
                description: planChange.settings.description,
                custom_id: planChange.settings.customId,
                application_context: {brand_name: "Eledo by HLS s.r.o."}
              });
            }}
            onApprove={(data, actions) => {
              return actions.order.capture().then((details) => {
                //details.id = paypalOrderId
                //details.facilitatorAccessToken = paypalFacilitatorAccessToken
                //TODO: send those information to PlanChange API and continue to step from reply (should be 3)
                handleSubmit(null, details.id);
              });
            }}
        />
      }
      { planChange.settings.type === "PaypalSubscription" &&
        <PayPalButtons
        createSubscription={(data, actions) => {
          if(planChange.settings.subscriptionId){
            return actions.subscription.revise({
              plan_id: planChange.settings.subscriptionId,
              application_context: {brand_name: "Eledo by HLS s.r.o."}
            });
          }else{
            return actions.subscription.create({
              plan_id: planChange.settings.planId,
              custom_id: planChange.settings.customId,
              application_context: {brand_name: "Eledo by HLS s.r.o."}
            });
          }
        }}
        onApprove={(data, actions) => {
          //data.subscriptionID = paypalSubscriptionId
          //data.orderID = paypalOrderId
          //data.facilitatorAccessToken = paypalFacilitatorAccessToken
          //TODO: send those information to PlanChange API and continue to step from reply (should be 3)
          handleSubmit(data.subscriptionID, data.orderID);
        }}
        />
      }
    </PayPalScriptProvider>

    <button className="w-button button outline"
      style={{float: "right", marginTop: "1.5rem"}}
      onClick={() => handleBack()}>
      Back
    </button>
  </>
);

const Step2CreditCard = ({handleSubmit, handleBack, planChange}) => {
  const stripePromise = useSelector(state => state.pricing.stripePromise);
  const clientSecret = planChange?.settings?.clientSecret;
  const options = { clientSecret };

  const dispatch = useDispatch();

  if(!stripePromise){
    loadStripe.setLoadParameters({advancedFraudSignals: false});
    //Stripe Prod
    loadStripe("pk_live_51LBdPnCtUioj6T2LbqnSeCLU0D1MYQ6M1EBFYqpMXPuO0AJHcZFbyyy0A5xH5vZrgEyaBXTFHXLegvatrXRq3vUv00L2etuvGA")
    //Stripe Test
    // loadStripe("pk_test_51LBdPnCtUioj6T2LYOdMZUYjofj0yGxqtO9t4JKja6hBgpDN5tn7ZFxibmyNzibhzydnuK3aF1OEPtS0FLhfjxoJ00mO5K8Xuy")
      .then((promise) => {
        dispatch(storeStripePromise(promise));
      })

  }
  
  // window.location = planChange.settings.redirectUrl;
  if(!stripePromise){
    return (
             <div className="flex-center"><EledoLoader/></div>
    );
  } else {
    return (
      <>
        { clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <StripeCheckoutForm handleBack={handleBack}/>
          </Elements>
        )}
      </>
    )
  }
};

const Step4 = ({planChange}) => (
  <React.Fragment>
    <div className={styles.Payments}>
      <h3>
        { planChange.paymentDescription }
      </h3>

      { planChange.success && 
        <>
          <h3>Your plan change has been processed successfully!</h3>
          <p>Don't hesitate to contact Eledo Helpdesk in case of any questions or issues.</p>
        </>
      }
      { planChange.error &&
        <h3>
          { planChange.error }
        </h3>
      }

    </div>
  </React.Fragment>
)

export default EledoPricingChange;