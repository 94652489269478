import axios from "axios";
import {API_URL} from "./constants";

let authContext

export const injectAuthContext = auth => {
  authContext = auth
}

const eledoAxios = axios.create({
  baseURL: API_URL
});

eledoAxios.interceptors.request.use(request => {
  const apiKey = authContext.apiToken;

  if(request?.data?.file) {
    request.headers = {
      'api-key': apiKey,
      'Content-Type': 'multipart/form-data',
    }
  } else {
    request.headers = {
      'api-key': apiKey,
      'Content-Type': 'application/json;charset=UTF-8',
    }
  }
  return request;
});

eledoAxios.interceptors.response.use(response => {
    return response;
  },
  async error => {
    if (error?.response?.status === 401){
      authContext.logout();
    }else if(error?.response?.status === 400 
      ) {
        // history.push("/login")
    }
    return Promise.reject(error.response)
  }
);
export default eledoAxios;
