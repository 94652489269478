import React from 'react';
import styles from './EledoNavbar.module.scss';
import {Link, NavLink, useMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";

const EledoNavbar = ({mainItems, footerItems, baseUrl = ""}) => {
  const menuHidden = useSelector(state => state.menu.menuHidden);

  return (
    <nav data-testid="navbar">
      <div className={classnames(styles.navbarLeft, menuHidden ? styles.leftMenuDisplayOnHover : styles.leftMenuDisplayAlways)}>
        <NavbarHeader baseUrl={baseUrl}/>
        <NavbarMenu menuItems={mainItems} baseUrl={baseUrl}/>
        <NavbarFooter menuItems={footerItems} baseUrl={baseUrl}/>
      </div>
    </nav>
  );
};

export const NavbarHeader = () => (
  <div className={styles.navbarHeader}>
    <div className={styles.logo}>
      <Link to="/templates/mine">
        <img src={process.env.PUBLIC_URL + "/assets/images/Eledo-logoW-icon.svg"} loading="lazy" height="50" alt="Eledo logo left side"/>
        <span className={classnames(styles.navLabel, styles.logoLabel)}>
          <img src={process.env.PUBLIC_URL + "/assets/images/Eledo-logoW-text.svg"} loading="lazy" height="41" alt="Eledo logo right side"/>
        </span>
      </Link>
    </div>
  </div>
);

const renderItems = (menuItems, baseUrl) => (
  <React.Fragment>
    { menuItems &&
      menuItems.map( (item, index) => renderItem(item, baseUrl, index))
    }
  </React.Fragment>
)

const NavbarMenu = ({ menuItems, baseUrl }) => (
  <ul className={classnames(styles.navbarBody, styles.navList)}>
    { renderItems(menuItems, baseUrl) }
  </ul>
);

const NavbarFooter = ({ menuItems, baseUrl }) => (
  <div className={styles.navbarFooter}>
    <ul className={styles.navList}>
      { renderItems(menuItems, baseUrl) }
    </ul>
  </div>
);

const renderItem = (item, baseUrl, index) => (
  'action' in item ?
    <EledoMenuAction label={item.label} icon={item.icon} action={item.action} key={`menuaction-${index}`}/>
    : <EledoMenuItem label={item.label} icon={item.icon} to={baseUrl + item.to} badge={item.badge}
                     key={`menuitem-${index}`}/>
  );

// const renderSubmenu = (submenuItems, baseUrl, index) => (
//   <ul className={styles.subMenu}>
//     {submenuItems.map( (el, subindex) =>
//       <EledoMenuItem label={el.label} icon={el.icon} to={baseUrl + el.to}
//                      key={`submenuItem-${index}-${subindex*100}`}/>
//     )}
//   </ul>
// );

export const EledoMenuItem = ({ label, to, icon, activeOnlyWhenExact = false,
                                submenu = false, badge, ...props }) => {
  let match = useMatch({
    path: to,
    end: activeOnlyWhenExact
  });

  const liElem = () => (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <li className={classnames(styles.navItem, {"active" : match != null})}>
        <i className={`icon-${icon}`}/>
        <span className={classnames("white", styles.navLabel)}>{label}</span>
        { badge > 0 &&
          <span className={classnames(styles.badge)}>{badge}</span>
        }
        { submenu && <i className={classnames("w-icon-slider-right", styles.submenuIcon)}/> }
        { submenu && props.children }
      </li>
      { !submenu &&
        <div className={styles.renderTemplateStyle}>
          { props.children }
        </div>
      }
    </div>
  );

  return !to ?
    <div className={classnames("nav-link", styles.navLink)}>{liElem()}</div>
    : <NavLink className={classnames("nav-link", styles.navLink, match != null && styles.navLinkActive)}
               to={to}>
        {liElem()}
      </NavLink>
}

export const EledoMenuAction = ({ label, icon, action, disabled = false }) => (
  <div className={classnames("nav-link", styles.navLink)}
       onClick={(e) => {e.preventDefault(); action();}}
       {...(disabled ? {"aria-disabled": "true"} : {})}>
    <li className={styles.navItem}>
      <i className={`icon-${icon}`}/>
      <span className={classnames("white", styles.navLabel)}>{label}</span>
    </li>
  </div>
);

// Props type checking
EledoNavbar.propTypes = {
  mainItems: PropTypes.array.isRequired,
  footerItems: PropTypes.array.isRequired,
  baseUrl: PropTypes.string,
};
NavbarHeader.propTypes = {
  baseUrl: PropTypes.string,
};
NavbarMenu.propTypes = {
  menuItems: PropTypes.array.isRequired,
  baseUrl: PropTypes.string
};
NavbarFooter.propTypes = {
  menuItems: PropTypes.array.isRequired,
  baseUrl: PropTypes.string,
};
EledoMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.string,
  activeOnlyWhenExact: PropTypes.bool,
};
EledoMenuAction.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired
};

export default EledoNavbar;
