import { gql } from "@apollo/client"
import { Connection, User } from "./UserQueries"
import { PuzzleRequest } from "./PuzzleRequests"


export interface AuthData {
  user: User | null
  connection: Connection | null
  level: number
  apiToken?: string
  rememberMe?: string
  roles?: string[]
  requests?: PuzzleRequest[]
}

export interface AuthDataData {
  auth: AuthData
}

export interface AuthVars {
  type: string
  login?: string
  password?: string
  rememberMe?: Boolean
  token?: string
  create?: AuthUserCreate
}

export interface AuthUserCreate {
  email: string
  emailVerified: Boolean
  name: string
  surname: string
  countryCode: string
  language: string
  timezone: string
  locale: string
}

export interface GenerateApiKeyData {
  apiToken: string
}

export interface GenerateApiKeyVars {

}

export const GET_AUTH = gql`
  mutation auth($type: String, $login: String, $password: String, $rememberMe: Boolean, $token: String, $create: AuthUserCreate) {
    auth(type: $type, login: $login, password: $password, rememberMe: $rememberMe, token: $token, create: $create) { user { id login name surname } connection { id name surname admin } 
    level apiToken rememberMe roles requests { id structure { id name title width height } event } }
  }
`;

export const GENERATE_NEW_API_KEY = gql`
  mutation newApiKey {
    newApiKey { apiToken }
  }
`;


