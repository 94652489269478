import eledo from "./eledo";

const DOCUMENT_FORMATS_URL = "/documentFormats?";

const fetchDocumentFormats = (pageId, limit) => {
    const searchParams = new URLSearchParams();
    if(pageId) {
      searchParams.append("page", pageId);
    }
    if(limit) {
      searchParams.append("limit", limit);
    }
    return eledo.get(DOCUMENT_FORMATS_URL + searchParams.toString());
}

const documentFormatsService = { fetchDocumentFormats };
export default documentFormatsService;
