import React, { lazy, Suspense } from 'react';

const LazyEledoModal = lazy(() => import('./EledoModal'));

const EledoModal = props => (
  <Suspense fallback={null}>
    <LazyEledoModal {...props} />
  </Suspense>
);

export default EledoModal;
