import { useApolloClient, useQuery } from '@apollo/client';
import { GET_HELPDESK_MESSAGES, GET_HELPDESK_STATS, HelpdeskMessage, HelpdeskMessagesData, HelpdeskMessagesVars, HelpdeskStatsData, HelpdeskStatsVars } from 'graphql/HelpdeskMessageQueries';
import { useState, useEffect } from 'react'

function useHelpdeskMessages({connectionId, search, limit, page}: HelpdeskMessagesVars) {
  const client = useApolloClient();
  const { loading, error, data } = useQuery<HelpdeskMessagesData, HelpdeskMessagesVars>(GET_HELPDESK_MESSAGES, {variables:{connectionId, search, limit, page}});
  const [helpdeskMessages, setHelpdeskMessages] = useState<HelpdeskMessage[]>();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if(!data) return;

    const helpdeskMessages: HelpdeskMessage[] = data.helpdeskMessages.items.map(({...props}) => ({...props}))

    setHelpdeskMessages(helpdeskMessages)
    setTotal(data.helpdeskMessages.total)

    //reload stats to clear unread messages notification (TODO: improve this)
    client.query<HelpdeskStatsData, HelpdeskStatsVars>({ query: GET_HELPDESK_STATS, variables: { connectionId }, fetchPolicy: 'network-only' });

  }, [data, client, connectionId])

  return { loading, error, helpdeskMessages, total }
}

export default useHelpdeskMessages