import { gql } from "@apollo/client"


export interface PuzzleResponse {
  id?: string
  structureId?: string
  userId?: string
  connectionId?: string
  response: Object
}

export interface PuzzleResponseData {
  puzzleResponse: PuzzleResponse
}

export interface PuzzleResponseVars {
  id?: string
  puzzleResponse: PuzzleResponseInput
}

export interface PuzzleResponseInput {
  structureId?: string
  userId?: string
  connectionId?: string
  response: Object
}

export interface CreatePuzzleResponseData {
  createPuzzleResponse: PuzzleResponse
}

export const GET_PUZZLE_RESPONSE = gql`
  query getPuzzleResponse($id: ObjectId!) {
    puzzleResponse(id: $id) { id }
  }
`;

export const CREATE_PUZZLE_RESPONSE = gql`
  mutation createPuzzleResponse($puzzleResponse: PuzzleResponseInput) {
    createPuzzleResponse(puzzleResponse: $puzzleResponse) { id }
  }
`;
