import { TFunc } from 'featuresFed/fed_core/lang_fns/TFunc'
import { TVar } from 'featuresFed/fed_core/lang_vars/TVar'
import { DataModel } from 'graphql/DataModelQueries'
import { createContext } from 'react'

export type FedContextData = {
  dataModel?: DataModel | null
  functions?: TFunc[]
  context?: string
  dbg?: any
}

export const FedContext = createContext<FedContextData>({})

