import connectionsReducer from "./connectionsReducer";
import structuresReducer from "./structuresReducer";
import menuReducer from "./menuReducer";
import logsReducer from "./logsReducer";
import userReducer from "./userReducer";
import eledoTabsReducer from "./eledoTabsReducer";
import templateReducer from "./templateReducer";
import spendingReducer from "./spendingReducer";
import paymentsReducer from "./paymentsReducer";
import pricingPlansReducer from "./pricingPlansReducer";
import documentFormatsReducer from "./documentFormatsReducer";
import puzzleRequests from "./puzzleRequests";

const reducers = {
  templates: templateReducer,
  menu: menuReducer,
  connections: connectionsReducer,
  structures: structuresReducer,
  documentFormats: documentFormatsReducer,
  logs: logsReducer,
  user: userReducer,
  tabs: eledoTabsReducer,
  pricing: pricingPlansReducer,
  spending: spendingReducer,
  payments: paymentsReducer,
  // registration: registrationReducer,
  puzzleRequests: puzzleRequests
};
export default reducers;