/* eslint-disable no-extend-native */
import React from 'react';
import { createRoot } from 'react-dom/client';

import EledoApp from './components/EledoApp';

//import reportWebVitals from './reportWebVitals';
import * as serviceWorker from "./serviceWorker";
import Root from "./components/Root";
import { AuthProvider } from 'AuthContext';
import Apollo from 'Apollo';
import { PlatformProvider } from 'PlatformContext';

import { Tracker } from 'Tracker';
import AutoLogin from 'AutoLogin';
import { BrowserRouter } from 'react-router-dom';

String.prototype.firstIndexOfRegex = function(regex, fromIndex) {
  const str = fromIndex ? this.substring(fromIndex) : this;
  const match = str.match(regex);
  return match ? str.indexOf(match[0]) + fromIndex : -1;
};
String.prototype.indexOfRegex = String.prototype.firstIndexOfRegex;
String.prototype.lastIndexOfRegex = function(regex, fromIndex) {
  const str = fromIndex ? this.substring(0, fromIndex) : this;
  const match = str.match(regex);
  return match ? str.lastIndexOf(match[match.length-1]) : -1;
};
String.prototype.equals = function(s) {
  if(s === null || s === undefined) return false;
  if (this.length !== s?.length) {
    return false;
  }
  return this.localeCompare(s) === 0;
}

Tracker.init();

const root = createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter basename="app">
    <PlatformProvider>
      <AuthProvider>
        <Apollo>
          <AutoLogin>
            <Root>
              <React.StrictMode>
                <EledoApp/>
              </React.StrictMode>
            </Root>
          </AutoLogin>
        </Apollo>
      </AuthProvider>
    </PlatformProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();