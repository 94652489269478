import {
  EledoLabel, EledoPasswordField, EledoSelect
} from "components/library";
import {localeSelectOptions, timezoneSelectOptions} from "model";
import React, { useContext } from "react";
import {Link} from 'react-router-dom';
import { useDispatch } from "react-redux";
import { setActiveTab } from "_reducers/eledoTabsReducer";
import { PlatformContext } from "PlatformContext";

const TabGeneral = ({ userInfo, planInfo }) => {
  const platform = useContext(PlatformContext);
  const dispatch = useDispatch();
  const isEmbedded = platform.isEmbedded;
  return userInfo ? (
    <div className="w-form" style={{marginBottom: "2rem"}}>
      <fieldset>
        <EledoLabel id="login" label="Account name"/>
        <div>{userInfo?.login}</div>
      </fieldset>

      { planInfo?.currentPlan && 
        <fieldset>
          <EledoLabel id="pricingPlan" label="Pricing plan" style={{padding: "1rem 0 0.5rem 0"}}/><br/>
          <Link className="button outline w-button"
                to="/user/plan"
                onClick={ () => {
                    dispatch(setActiveTab({tabsComponentId: "userProfileTabs", activeTabId: 4}));
                  }
                }>
            {planInfo.currentPlan.name}
          </Link>
        </fieldset>
      }

      <fieldset>
        <EledoLabel id="locale" label="Locale"/>
        <EledoSelect id="locale"
                     options={localeSelectOptions}
                     defaultValue={userInfo.locale}
                     validation={{required: "Locale is required."}}/>
      </fieldset>

      <fieldset>
        <EledoLabel id="timezone" label="Timezone"/>
        <EledoSelect id="timezone"
                     options={timezoneSelectOptions}
                     defaultValue={userInfo.timezone}
                     validation={{required: "Timezone is required."}}/>
      </fieldset>

      { !isEmbedded &&
        <fieldset>
          <EledoLabel id="oldPassword" label="Old password"/>
          <EledoPasswordField id="oldPassword"
                              validation={userInfo.newPassword || userInfo.repeatPassword ? {required: "Old password is required to change your password."} : {}}/>
        </fieldset>
      }
      { !isEmbedded &&
        <fieldset>
          <EledoLabel id="newPassword" label="New password"/>
          <EledoPasswordField id="newPassword"
                              validation={userInfo.repeatPassword ? {required: "New password is required to change your password."} : {}}/>
        </fieldset>
      }
      { !isEmbedded &&
        <fieldset>
          <EledoLabel id="repeatPassword" label="Repeat new password"/>
          <EledoPasswordField id="repeatPassword"
                              validation={userInfo.newPassword ? {required: "Please repeat the new password for security purposes."} : {}}/>
        </fieldset>
      }
    </div>
  ) : null;
}

export default TabGeneral;