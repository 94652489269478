import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import styles from './EledoPricing.module.scss';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {fetchPricingPlans} from "_reducers/pricingPlansReducer";
import {useErrorNotyf, useShowMenu} from "hooks";
import {
  EledoMainPageTitle, EledoError,
  EledoLoader, EledoPricingPlan
} from "components/library";
import classnames from "classnames";
const _ = require('lodash');


const EledoPricing = () => {
  const [{level, nextLevel, plans, customPlan, customPlanNext}, loading, error]
    = useSelector(state => [
      state.pricing.plans, state.pricing.plansLoading, state.pricing.error,
    ], shallowEqual);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [hasCustomPlan, setHasCustomPlan] = useState(false);
  const [hasCustomPlanNext, setHasCustomPlanNext] = useState(false);
  const dispatch = useDispatch();

  useErrorNotyf(error);
  useShowMenu();

  const setupVariables = () => {
    if (level != null &&  plans.length > 0) {
      setCurrentLevel(level === nextLevel ? level : nextLevel);
    }
    setHasCustomPlan(customPlan ? true : false);
    setHasCustomPlanNext(customPlanNext && !_.isEqual(customPlanNext, customPlan));
    //uncomment the next line for testing purposes:
    //setCurrentLevel(2);
  }

  useEffect(() => {
    dispatch(fetchPricingPlans());
  }, [dispatch]);

  useEffect(() => {
    setupVariables();
  }, [plans, customPlan, customPlanNext]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderError = () =>
    <EledoError title={error?.message}
                description={(<React.Fragment>
                  We are sorry &#9785; We could not load any pricing plans. <br/><br/>
                  {error.message.toLocaleLowerCase() === "network error" && "Please check your internet connection."}
                </React.Fragment>)}/>;

  const renderPlans = () => (
    <React.Fragment>
      { plans.map((plan, index) => (
        <div className="col-25" key={index}>
          <EledoPricingPlan plan={plan} showButton={index > 0} planButton={
            (currentLevel !== null && index !== null) &&
              (index > 0 || currentLevel !== 0
                ? <PlanButton planIndex={index} currentLevel={currentLevel}/>
                : <div className={styles.buttonPlaceholder}>&nbsp;</div>)
          }/>
        </div>
      )) }
    </React.Fragment>
  );

  const renderEnterprise = () => (
    <>
      { hasCustomPlan &&
        <div className={classnames("col-40", styles.EnterprisePlan)}>
          <EledoPricingPlan plan={customPlan} showButton={true} planButton={
            (currentLevel !== null) && <PlanButton planIndex={4} currentLevel={currentLevel}/>
          }/>
        </div>
      }
      { hasCustomPlanNext &&
        <div className={classnames("col-40", styles.EnterprisePlan)}>
          <EledoPricingPlan plan={customPlanNext} showButton={true} planButton={
            (currentLevel !== null) && <PlanButton planIndex={5} currentLevel={currentLevel}/>
          }/>
        </div>
      }

      { !hasCustomPlan && !hasCustomPlanNext &&
        <div className={classnames("col-40", styles.EnterprisePlan)}>
          <EledoPricingPlan plan={{name:"Custom plan", price: "Individual", documents: "5 000+",templates:"27+", support:"Dedicated team"}} showButton={true} planButton={
            <div className="text-center" style={{margin: "1rem"}}>
              <Link to="/helpdesk" className="w-button button outline" style={{width: "80%"}}>Contact</Link>
            </div>}/>
        </div>
      }
    </>
  );

  const renderOverquota = () =>
    plans && customPlan ? (
      <div className="container section section-small" style={{marginBottom: "3rem"}}>
        <h1>Limits and Overquota Pricing</h1>

        <div className={styles.eleComparisonRow} style={{marginTop: "2rem"}}>
          <div className={styles.eleColWide}/>
          {plans.map(p => <div key={p.name}><strong>{p.name}</strong></div>)}
          <div><strong>{customPlan.name}</strong></div>
        </div>
        <div className={styles.eleComparisonRow}>
          <div className={classnames(styles.eleColWide, "text-capitalize")}>
            <strong>documents</strong>
          </div>
          {plans.map(p => <div key={p.name}>{p.documents}</div>)}
          <div>{customPlan.documents}</div>
        </div>
        <div className={styles.eleComparisonRow}>
          <div className={styles.eleColWide}>
            <strong>per 25 documents</strong>
          </div>
          {plans.map(p => <div key={p.name}>{p.overquota ? p.oqPrice : "-"}</div>)}
          <div>{customPlan.overquota ? customPlan.oqPrice : "-"}</div>
        </div>
      </div>
    ) : null;

  return (
    <div className={styles.EledoPricing} data-testid="EledoPricing">
      <EledoMainPageTitle title="Pricing" subtitle=""/>

      { error && renderError() }
      {!error &&
        <div className="container section section-small">
          { !loading &&
            <div className="col-wrapper">
              { renderPlans() }
              { renderEnterprise() }
            </div>
          }
          { loading && <div className="flex-center"><EledoLoader/></div> }
        </div>
      }

      { !loading && !error &&
        renderOverquota()
      }
    </div>
  );
};

const PlanButton = ({currentLevel, planIndex}) => {
  const color = currentLevel === planIndex ? "red" : (currentLevel > planIndex ? "yellow" : "");
  return <Link className={classnames("w-button", "button", color)}
                 style={{width: "8.5rem"}}
                 to={`/pricing/change/${planIndex}`}>
    { currentLevel < planIndex && "Upgrade" }
    { currentLevel > planIndex && "Downgrade" }
    { currentLevel === planIndex && "Cancel" }
  </Link>
}

export default EledoPricing;