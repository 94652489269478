import React, { createContext, useEffect, useState } from 'react'
import SELECTED_PLATFORM_CONTEXT, { PlatformData } from '_platform'
import { EledoPageLoader } from 'components/library';
import { useNavigate } from 'react-router-dom';

const PlatformContext = createContext<PlatformData>({} as PlatformData);

const PlatformProvider = ({children}: {children: JSX.Element}) => {
  
  const [ platform, setPlatform ] = useState<PlatformData | null>(null);

  const navigate = useNavigate()
  
  useEffect(() => {
    SELECTED_PLATFORM_CONTEXT.get()
      .then(data => {
        setPlatform(data);
      })
  })

  if(platform === null){
    return (
        <EledoPageLoader text="Waiting for platform initialization..."/>
    )
  }

  if(platform.initSuccess !== true){
    navigate("/platformFailed");
  }

  return (
    <PlatformContext.Provider value={platform}>
      {children}
    </PlatformContext.Provider>
  )

}

export { PlatformContext, PlatformProvider };