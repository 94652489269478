import { CenteredRectCoordinates, RectCoordinates } from "./ResizableRectangle"


export const getLength = (deltaX: number, deltaY: number) => Math.sqrt(deltaX * deltaX + deltaY * deltaY)

export const degToRadian = (deg: number) => deg * Math.PI / 180

const cos = (deg: number) => Math.cos(degToRadian(deg))
const sin = (deg: number) => Math.sin(degToRadian(deg))

export const getNewCenteredCoordinates = (direction: string, centeredCoordinates: CenteredRectCoordinates, deltaW: number, deltaH: number, minWidth: number, minHeight: number) => {
  const widthFlag = (centeredCoordinates.width < 0 ? -1 : 1)
  const heightFlag = (centeredCoordinates.height < 0 ? -1 : 1)
  centeredCoordinates.width = Math.abs(centeredCoordinates.width)
  centeredCoordinates.height = Math.abs(centeredCoordinates.height)

  function changeWidthAndDeltaW() {
    const expectedWidth = (centeredCoordinates.width + deltaW)
    if (expectedWidth > minWidth) {
      centeredCoordinates.width = expectedWidth
    } else {
      deltaW = (minWidth - centeredCoordinates.width)
      centeredCoordinates.width = minWidth
    }
  }

  function changeHeightAndDeltaH() {
    const expectedHeight = centeredCoordinates.height + deltaH
    if (expectedHeight > minHeight) {
      centeredCoordinates.height = expectedHeight
    } else {
      deltaH = (minHeight - centeredCoordinates.height)
      centeredCoordinates.height = minHeight
    }
  }

  switch(direction) {
    case  "left":
      deltaW = -deltaW
      break
    case  "top":
      deltaH = -deltaH
      break
    case "bottomLeft":
      deltaW = -deltaW
      break
    case "topLeft":
      deltaW = -deltaW
      deltaH = -deltaH
      break
    case "topRight":
      deltaH = -deltaH
      break
    default:
      break
  }

  switch(direction) {
    case  "left":
    case  "right":
      changeWidthAndDeltaW()
      break
    case  "top":
    case  "bottom": 
      changeHeightAndDeltaH()
      break
    case "topLeft":
    case "topRight":
    case "bottomLeft": 
    case "bottomRight":
      changeWidthAndDeltaW()
      changeHeightAndDeltaH()
      break
    default:
      break
  }

  switch (direction) {
    case "right": {
      centeredCoordinates.centerX += (deltaW / 2 * cos(centeredCoordinates.rotateAngle))
      centeredCoordinates.centerY += (deltaW / 2 * sin(centeredCoordinates.rotateAngle))
      break
    }
    case "left": {
      centeredCoordinates.centerX -= (deltaW / 2 * cos(centeredCoordinates.rotateAngle))
      centeredCoordinates.centerY -= (deltaW / 2 * sin(centeredCoordinates.rotateAngle))
      break
    }
    case "bottom": {
      centeredCoordinates.centerX -= (deltaH / 2 * sin(centeredCoordinates.rotateAngle))
      centeredCoordinates.centerY += (deltaH / 2 * cos(centeredCoordinates.rotateAngle))
      break
    }
    case "top": {
      centeredCoordinates.centerX += (deltaH / 2 * sin(centeredCoordinates.rotateAngle))
      centeredCoordinates.centerY -= (deltaH / 2 * cos(centeredCoordinates.rotateAngle))
      break
    }
    case "topLeft": {
      centeredCoordinates.centerX -= (deltaW / 2 * cos(centeredCoordinates.rotateAngle) - deltaH / 2 * sin(centeredCoordinates.rotateAngle))
      centeredCoordinates.centerY -= (deltaW / 2 * sin(centeredCoordinates.rotateAngle) + deltaH / 2 * cos(centeredCoordinates.rotateAngle))
      break
    }
    case "topRight": {
      centeredCoordinates.centerX += (deltaW / 2 * cos(centeredCoordinates.rotateAngle) + deltaH / 2 * sin(centeredCoordinates.rotateAngle))
      centeredCoordinates.centerY += (deltaW / 2 * sin(centeredCoordinates.rotateAngle) - deltaH / 2 * cos(centeredCoordinates.rotateAngle))
      break
    }
    case "bottomLeft": {
      centeredCoordinates.centerX -= (deltaW / 2 * cos(centeredCoordinates.rotateAngle) + deltaH / 2 * sin(centeredCoordinates.rotateAngle))
      centeredCoordinates.centerY -= (deltaW / 2 * sin(centeredCoordinates.rotateAngle) - deltaH / 2 * cos(centeredCoordinates.rotateAngle))
      break
    }
    case "bottomRight": {
      centeredCoordinates.centerX += (deltaW / 2 * cos(centeredCoordinates.rotateAngle) - deltaH / 2 * sin(centeredCoordinates.rotateAngle))
      centeredCoordinates.centerY += (deltaW / 2 * sin(centeredCoordinates.rotateAngle) + deltaH / 2 * cos(centeredCoordinates.rotateAngle))
      break
    }
    default:
      break
  }

  return {
    centerX: centeredCoordinates.centerX,
    centerY: centeredCoordinates.centerY,
    width: centeredCoordinates.width * widthFlag,
    height: centeredCoordinates.height * heightFlag,
    rotateAngle: centeredCoordinates.rotateAngle,
  }
}

const cursorStartMap: {[key: string]: number} = { top: 0, topRight: 1, right: 2, bottomRight: 3, bottom: 4, bottomLeft: 5, left: 6, topLeft: 7 }
const cursorDirectionArray = [ "n", "ne", "e", "se", "s", "sw", "w", "nw" ]
const cursorMap: {[key: number]: number} = { 0: 0, 1: 1, 2: 2, 3: 2, 4: 3, 5: 4, 6: 4, 7: 5, 8: 6, 9: 6, 10: 7, 11: 8 }

export const getCursor = (rotateAngle: number, direction: string) => {
  const increment = cursorMap[ Math.floor(rotateAngle / 30) ]
  const index = cursorStartMap[ direction ]
  const newIndex = (index + increment) % 8
  return cursorDirectionArray[ newIndex ]
}

export const centerToTopLeft = ({ centerX, centerY, width, height, rotateAngle }: CenteredRectCoordinates) => ({
  top: centerY - height / 2,
  left: centerX - width / 2,
  width,
  height,
  rotateAngle
})

export const topLeftToCenter = ({ top, left, width, height, rotateAngle }: RectCoordinates) => ({
  centerX: left + width / 2,
  centerY: top + height / 2,
  width,
  height,
  rotateAngle,
})

export const shouldHide = (direction: string, centeredWidth: number, centeredHeight: number, hideSize: number) =>
  ((["t","b"].includes(direction)) && centeredWidth < hideSize) ||
  ((["l","r"].includes(direction)) && centeredHeight < hideSize)