import { useState, useEffect, useRef, useCallback } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { nanoid } from "nanoid"
import ReactTooltip from "react-tooltip"
import classnames from "classnames"

import { saveTemplate } from "_reducers/templateReducer"

import { PdfEditorPage } from "./PdfDocumentPage/PdfDocumentPage"
import { RectCoordinates } from "./ResizableRectangle/ResizableRectangle"

import ResizableRectangle from "./ResizableRectangle/ResizableRectangle"
import TopToolbar from "./TopToolbar/TopToolbar"
import PdfDocumentPage from "./PdfDocumentPage/PdfDocumentPage"
import RectFloatingButton from "./RectFloatingButton/RectFloatingButton"
import { FormFieldForm } from "./FieldModalForm/useFormFieldModal"
import css from "./EledoPdfEditor.module.scss"
import useFormFieldModal from "./FieldModalForm/useFormFieldModal"
import { DataModel } from "graphql/DataModelQueries"

const ALL_FIELD_TYPES = [
  "Text",
  "ImageURL",
  "Checkbox",
  "BC1_EAN_8", "BC1_EAN_13", "BC1_CODE_39", "BC1_CODE_93", "BC1_CODE_128",
  "BC2_AZTEC", "BC2_DATA_MATRIX", "BC2_PDF417", "BC2_QR_CODE",
] as const;
type FieldTypeTuple = typeof ALL_FIELD_TYPES;
export type FieldType = FieldTypeTuple[number];
export function isValidFieldType(value: string): value is FieldType {
  return ALL_FIELD_TYPES.includes(value as FieldType)
}

export type FieldState = "none" | "add-start" | "drawing" | "adjust-position" | "edited"

export enum WidgetState {
  original = 0,
  custom = 1,
  removed = 2
}

export type Widget = {
  fieldId: string | null
  page: number
  idx: number
  state: WidgetState
  box: {
    height: number
    width: number
    x: number
    y: number
    rotateAngle: number
  }
  font?: string
  weight?: string
  style?: string
  size?: number
  color?: string
  boxSize?: number
  zoom?: number
}

export type Field = {
  id?: string
  type: FieldType
  desc: string
  custom: boolean
  expression?: string
  validation?: string
  options?: [string]
  font?: string
  size?: number
  align: number
  multiline: boolean
}

const INITIAL_FIELD_COORDINATES: RectCoordinates = {
  top: 0,
  left: 0,
  width: 0,
  height: 0,
  rotateAngle: 0
}

const INITIAL_FIELD_SIZE: {[key: string]: {width: number, height: number}} = {
  Checkbox: { width: 21, height: 21 },
  BC1_EAN_8: { width: 70, height: 64 },
  BC1_EAN_13: { width: 98, height: 64 },
  BC1_CODE_39: { width: 139, height: 64 },
  BC1_CODE_93: { width: 119, height: 64 },
  BC1_CODE_128: { width: 89, height: 64 },
  BC2_AZTEC: { width: 64, height: 64 },
  BC2_DATA_MATRIX: { width: 64, height: 64 },
  BC2_PDF417: { width: 154, height: 40 },
  BC2_QR_CODE: { width: 49, height: 49 },
}

const NEW_WIDGET_TEMPLATE: Widget = {
  fieldId: null,
  page: 0,
  idx: 0,
  state: WidgetState.custom,
  box: { x: 0, y: 0, width: 0, height: 0, rotateAngle: 0 },
  font: "Liberation Sans",
  size: 14,
  color: "#000000",
}

const NEW_FIELD_TEMPLATE: Field = {
  type: "Text",
  desc: "",
  custom: true,
  font: "Liberation Sans",
  size: 14,
  align: 0,
  multiline: false
}

const DEFAULT_ZOOMS: {[key: string]: number} = {
  BC1_EAN_8: 70,
  BC1_EAN_13: 98,
  BC1_CODE_39: 139,
  BC1_CODE_93: 119,
  BC1_CODE_128: 89,
  BC2_PDF417: 154,
  BC2_QR_CODE: 49
}
const EledoPdfEditor = ({dataModel}: {dataModel: DataModel}) => {
  const [fieldState, set_fieldState] = useState<FieldState>("none")
  const [focusedPageIndex, set_focusedPageIndex] = useState<number>(0)
  const [selectedWidget, set_selectedWidget] = useState<Widget | null>(null)
  const [selectedField, set_selectedField] = useState<Field | null>(null)
  const [fieldCoordinates, set_fieldCoordinates] = useState<RectCoordinates>(INITIAL_FIELD_COORDINATES)
  const [newFieldType, set_newFieldType] = useState<FieldType>()
  const [initialEditing, set_initialEditing] = useState<boolean>(false)
  const [isDragging, set_isDragging] = useState<boolean>(false)
  const [autoDrag, set_autoDrag] = useState<boolean>(false)
  const pageCountRef = useRef<number>(0)
  const focusedPageRef = useRef<HTMLDivElement | null>(null)
  const rectangleRef = useRef<HTMLDivElement | null>(null)

  
  const [editedTemplate, activePageId] = useSelector((state: any) => [
    state.templates.editedTemplate,
    state.templates.activePageId
  ], shallowEqual)
  const dispatch = useDispatch()

  const _applyFormValues = (field: Field, widget: Widget, data: FormFieldForm) => {
    // console.log("applying form", field, widget, data)
    field.type = data.type
    field.desc = (data.desc ? data.desc : field.desc)
    field.expression = data.expression || undefined
    widget.color = data.color
    
    if(data.type === "Text") {
      field.font = data.font
      widget.font = field.font
      widget.weight = data.weight
      widget.style = data.style
      field.size = data.size
      widget.size = field.size
      field.align = data.align || 0
      field.multiline = data.multiline || false
    } else if(data.type === "ImageURL") {
    } else if(data.type === "Checkbox"){
      //only set the widget's box by boxSize for custom created components
      if(field.custom === true && data.boxSize){
        widget.boxSize = data.boxSize
        widget.box = {
          ...widget.box,
          width: data.boxSize / 3,
          height: data.boxSize / 3
        }
      }
    } else {
      if(data.zoom){
        widget.zoom = data.zoom
        
        if(_isBarcode1DType(data.type)) {
          widget.box = {
            ...widget.box,
            width: DEFAULT_ZOOMS[data.type] * (data.zoom || 1),
          }
          // console.log("1d changed", widget.box)
        } else if(_isBarcode2DType(data.type)){
          if(data.boxSize){
            widget.boxSize = data.boxSize

            let newWidth = 0
            let newHeight = 0
  
            if (data.type === "BC2_AZTEC" || data.type === "BC2_DATA_MATRIX") {
              newWidth = newHeight = widget.boxSize
            }
  
            let ratio: number = 1, majorSize: "width" | "height" = "width"
  
            if (data.type === "BC2_PDF417" || data.type === "BC2_QR_CODE") {
              ratio = (data.type === "BC2_PDF417" ? 3.85 : ratio)
              widget.zoom = widget.zoom || 1
              newWidth = DEFAULT_ZOOMS[data.type] * widget.zoom
              newHeight = majorSize === "width"
                ? (DEFAULT_ZOOMS[data.type] * widget.zoom) / ratio
                : (DEFAULT_ZOOMS[data.type] * widget.zoom) * ratio
            }
  
            if (data.type !== "BC2_DATA_MATRIX" && widget.zoom === 4) {
              widget.zoom = 3
            }
  
            if (data.type === "BC2_DATA_MATRIX" && widget.zoom === 3) {
              widget.zoom = 4
            }
            widget.box = {
              ...widget.box,
              width: newWidth,
              height: newHeight
            }
            // console.log("changing widget box", widget.box)
          }
        }
      }
    }
  }
  
  const _updateTemplate = (data: FormFieldForm) => {
    if (!selectedWidget || !selectedField)
      return

    const isNewField = (!selectedField.id ? true : false)
    const currentTemplate = structuredClone(editedTemplate)
    const document = currentTemplate.documents[activePageId].innerDocument
    const fieldId = isNewField
      ? "field" + nanoid()
      : selectedField.id || "field" + nanoid()

    if (isNewField || !selectedField.id) {
      selectedField.id = fieldId
      selectedWidget.fieldId = fieldId
    }

    _applyFormValues(selectedField, selectedWidget, data)
    
    document.pdFields[fieldId] = selectedField
    document.pages.forEach((page: any) => {
      page.widgets = page.widgets.filter((_widget: Widget) => !(_widget.fieldId === selectedWidget.fieldId))
    })
    document.pages[focusedPageIndex].widgets.push(selectedWidget)

    return currentTemplate
  }

  // MAIN LOGIC
  const onInitNewField = (fieldType: FieldType) => {
    if (!fieldType)
      return

    // console.log("✨ Init new field", fieldType)
    set_fieldState("add-start")
    set_newFieldType(fieldType)

    if (fieldType === "Checkbox" || _isBarcode1DType(fieldType) || _isBarcode2DType(fieldType)) {
      set_initialEditing(true)
      finishDrawing(fieldType)
    }
  }

  const onStartDrawingField = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, pageIdx: number) => {
    // console.log("✨ Start drawing field")
    const boundingElement = event.currentTarget.getBoundingClientRect()
    let initialLeftPosition = (event.clientX - boundingElement.left)
    let initialTopPosition = (event.clientY - boundingElement.top)
    let initialWidth = 10
    let initialHeight = 10

    const newCoordinates: RectCoordinates = {
      top: initialTopPosition,
      left: initialLeftPosition,
      width: initialWidth,
      height: initialHeight,
      rotateAngle: 0
    }

    set_focusedPageIndex(pageIdx)
    set_fieldState("drawing")
    
    resizeField(newCoordinates, boundingElement.width, boundingElement.height)

    const onInitialResize = (event: MouseEvent) => {
      let newWidth = (event.clientX - initialLeftPosition - boundingElement.left)
      let newHeight = (event.clientY - initialTopPosition - boundingElement.top)
      const boundX = (newWidth + initialLeftPosition)
      const boundY = (newHeight + initialTopPosition)

      if (boundX < 0 || boundX > boundingElement.width || newWidth < 10)
        newWidth = initialWidth

      if (boundY < 0 || boundY > boundingElement.height || newHeight < 10)
        newHeight = initialHeight
      
      resizeField({...newCoordinates, width: newWidth, height: newHeight}, boundingElement.width, boundingElement.height)
      initialWidth = newWidth
      initialHeight = newHeight
    }

    const onInitialResizeStop = () => {
      document.removeEventListener("mousemove", onInitialResize)
      document.removeEventListener("mouseup", onInitialResizeStop)
    }

    document.addEventListener("mousemove", onInitialResize)
    document.addEventListener("mouseup", onInitialResizeStop)
  }

  const resizeField = (coordinates: RectCoordinates, pageWidth: number, pageHeight: number) => {
    // console.log("✨ Resize field")
    set_fieldState(!selectedField ? "drawing" : "adjust-position")
    let newTop: number | null = null
    let newLeft: number | null = null
    let newWidth: number | null = null
    let newHeight: number | null = null

    if (coordinates.top >= 0 && coordinates.height <= pageHeight - coordinates.top) {
      newTop = Math.round(coordinates.top)
      newHeight = Math.round(coordinates.height)
    }

    if(coordinates.left >= 0 && coordinates.width <= pageWidth - coordinates.left) {
      newLeft = Math.round(coordinates.left)
      newWidth = Math.round(coordinates.width)
    }

    set_fieldCoordinates((prev) => ({
      top: newTop != null ? newTop : prev.top,
      left: newLeft != null ? newLeft : prev.left,
      width: newWidth != null ? newWidth : prev.width,
      height: newHeight != null ? newHeight : prev.height,
      rotateAngle: coordinates.rotateAngle
    }))
  }

  const dragField = (newLeft: number, newTop: number) => {
    // console.log("✨ Drag field")
    set_isDragging(true)
    set_fieldCoordinates((prev) => ({
      ...prev,
      top: newTop,
      left: newLeft
    }))
  }

  const onModalClosed = (data: FormFieldForm) => {

    // console.log("✨ Modal closed", data)
    const isNewField = data.id === undefined
    if (isNewField && !data.initialEditing) {
      set_fieldState("drawing")
      return
    }

    resetAll()
  }

  const onAdjustFieldPosition = (event: React.MouseEvent<HTMLButtonElement>, data: FormFieldForm) => {
    if (!selectedField || !selectedWidget)
      return

    _applyFormValues(selectedField, selectedWidget, data)
    
    // console.log("✨ Adjust field position")
    const isNewField = selectedField.id ? false : true
    set_fieldState(isNewField ? "drawing" : "adjust-position")

    if (isNewField && initialEditing && focusedPageRef.current && event) {
      const boundingElement = focusedPageRef.current.getBoundingClientRect()
      const initialTopPosition = event.clientY - boundingElement.top
      const initialLeftPosition = (event.clientX - boundingElement.left)
      set_fieldCoordinates({
        top: initialTopPosition,
        left: initialLeftPosition,
        width: selectedWidget.box.width,
        height: selectedWidget.box.height,
        rotateAngle: 0
      })
      set_autoDrag(true)
    } else {
      set_fieldCoordinates({
        top: selectedWidget.box.y,
        left: selectedWidget.box.x,
        width: selectedWidget.box.width,
        height: selectedWidget.box.height,
        rotateAngle: 0
      })
    }
  }

  const onModalConfirmed = async(modalFormData: FormFieldForm) => {
    if (!selectedField)
      return

    // console.log("✨ Modal confirmed")
    const updatedTemplate = _updateTemplate(modalFormData)
    dispatch(saveTemplate(updatedTemplate))
    resetAll()
  }

  const onRemoveField = async () => {
    if (!selectedWidget || !selectedField || !selectedField.id)
      return

    const currentTemplate = structuredClone(editedTemplate)

    const widgets = currentTemplate.documents[activePageId].innerDocument.pages[focusedPageIndex].widgets
    currentTemplate.documents[activePageId].innerDocument.pages[focusedPageIndex].widgets = widgets.filter(
      (widget: Widget) => widget.fieldId !== selectedWidget.fieldId
    )

    const fields = currentTemplate.documents[activePageId].innerDocument.pdFields
    delete fields[selectedField.id]

    dispatch(saveTemplate(currentTemplate))
    resetAll()
  }
  const { openModal, renderModal } = useFormFieldModal({
    editedTemplate,
    dataModel,
    onModalConfirmed: onModalConfirmed,
    onModalClosed: onModalClosed,
    onAdjustPosition: onAdjustFieldPosition,
    onRemoveField: onRemoveField
  })

  const editField = useCallback((pageIdx: number, field: Field, widget: Widget, initialEditing: boolean) => {
    if (!field || !widget)
      return

    const isNewField = (!field.id ? true : false)
    if (
      (selectedField && selectedField.id !== field.id) ||
      (!isNewField && (fieldState !== "none" && fieldState !== "adjust-position"))
    )
      return

    // console.log("✨ Edit field")
    set_focusedPageIndex(pageIdx)
    set_selectedWidget(structuredClone(widget))
    set_selectedField(structuredClone(field))
    set_fieldState("edited")

    let data: FormFieldForm = {
      id: field.id || null,
      type: field.type,
      desc: field.desc,
      expression: field.expression || null,
      font: widget.font || field.font || "Liberation Sans",
      weight: widget.weight,
      style: widget.style,
      size: widget.size || field.size || 14,
      color: widget.color || "#000000",
      align: field.align,
      multiline: field.multiline,
      validation: field.validation,
      options: field.options,

      custom: field.custom,
      initialEditing: initialEditing
    }

    if(data.type !== "Text" && data.type !== "ImageURL"){
      data.boxSize = widget.boxSize

      if(data.type !== "Checkbox"){
        data.zoom = widget.zoom
      }
    }
    openModal(data)
  }, [fieldState, openModal, selectedField])

  const finishDrawing = useCallback((_fieldType: FieldType | null = null) => {
    // console.log("✨ Finish drawing")
    const draftedFieldType = selectedField ? selectedField.type : (_fieldType || newFieldType)
    if (!draftedFieldType)
      return

    const draftedWidget = selectedWidget
      ? structuredClone(selectedWidget)
      : structuredClone(NEW_WIDGET_TEMPLATE)

    draftedWidget.box = {
      x: fieldCoordinates.left,
      y: fieldCoordinates.top,
      width: Math.abs(fieldCoordinates.width || INITIAL_FIELD_SIZE[draftedFieldType].width),
      height: Math.abs(fieldCoordinates.height || INITIAL_FIELD_SIZE[draftedFieldType].height),
      rotateAngle: fieldCoordinates.rotateAngle
    }
    draftedWidget.page = focusedPageIndex

    if(draftedFieldType === "Checkbox"){
      draftedWidget.boxSize = draftedWidget.boxSize || 64
    }

    if (_isBarcode1DType(draftedFieldType) || draftedFieldType === "BC2_AZTEC" || draftedFieldType === "BC2_DATA_MATRIX") {
      draftedWidget.boxSize = draftedWidget.boxSize || 64
      draftedWidget.zoom = (draftedWidget.zoom ? draftedWidget.zoom : 1)
    }

    if (draftedFieldType === "BC2_PDF417" || draftedFieldType === "BC2_QR_CODE") {
      draftedWidget.zoom = (draftedWidget.zoom ? draftedWidget.zoom : 1)
    }

    const draftedField = selectedField
      ? structuredClone(selectedField)
      : structuredClone(NEW_FIELD_TEMPLATE)

    draftedField.type = draftedFieldType

    if(!_fieldType){
      set_initialEditing(false)
    }

    autoDrag && set_autoDrag(false)
    editField(focusedPageIndex, draftedField, draftedWidget, _fieldType !== null)
  }, [newFieldType, fieldCoordinates, focusedPageIndex, selectedWidget, selectedField, autoDrag, editField])

  // REACT UTILS
  const resetAll = () => {
    // console.log("✨ Reset all")
    set_fieldState("none")
    set_selectedWidget(null)
    set_selectedField(null)
    set_fieldCoordinates(INITIAL_FIELD_COORDINATES)
    set_newFieldType(undefined)
    set_initialEditing(false)
    set_isDragging(false)
    set_autoDrag(false)
  }


  // UTILS
  const _isFieldInStates = (states: string | string[]) => {
    if(!states)
      return false

    if(!(states instanceof Array))
      return states === fieldState

    return states.some(state => state === fieldState)
  }

  const _isBarcode1DType = (fieldType?: FieldType) => {
    return fieldType === "BC1_EAN_8" || fieldType === "BC1_EAN_13" || fieldType === "BC1_CODE_39" || fieldType === "BC1_CODE_93" || fieldType === "BC1_CODE_128"
  }

  const _isBarcode2DType = (fieldType?: FieldType) => {
    switch (fieldType) {
      case "BC2_AZTEC":
      case "BC2_DATA_MATRIX":
      case "BC2_PDF417":
      case "BC2_QR_CODE":
        return true
      default:
        return false
    }
  }

  const _hasDocumentPages = () => {
    if (!editedTemplate || (!activePageId && activePageId !== 0)) {
      return false
    }

    const _pageCount = editedTemplate.documents[activePageId].innerDocument.pages.length
    if (!_pageCount)
      return false

    pageCountRef.current = _pageCount
    return true
  }

  const _isResizeAllowed = () => {
    if (!selectedField) {
      if (_isBarcode1DType(newFieldType) || _isBarcode2DType(newFieldType) || newFieldType === "Checkbox")
        return false
      
      return true
    }
    
    if (_isBarcode1DType(selectedField.type) || _isBarcode2DType(selectedField.type) || selectedField.type === "Checkbox")
      return false

    return true
  }

  useEffect(() => {
    const isInDrawingState = (fieldState === "drawing" || fieldState === "adjust-position" || fieldState === "add-start")
    if (!isInDrawingState)
      return

    const handleKeydownInDrawing = (event: KeyboardEvent) => {
      // console.log("✨ Handle keydown in drawing state")
      if (event.key === "Escape")
        resetAll()

      if (event.key === "Enter" && isInDrawingState) {
        finishDrawing()
        set_isDragging(false)
        autoDrag && set_autoDrag(false)
      }
    }

    document.addEventListener("keydown", handleKeydownInDrawing)
    
    return () => {
      document.removeEventListener("keydown", handleKeydownInDrawing)
    }
  }, [fieldState, autoDrag, finishDrawing])


  return (
    <div className={(classnames(css.editor, _isFieldInStates(["drawing", "adjust-position"]) && css.disabled))}>
      <TopToolbar rectState={fieldState} onInitNewField={(fieldType: FieldType) => onInitNewField(fieldType)} />
      <div className={css.document}>
        { _hasDocumentPages() && editedTemplate.documents[activePageId].innerDocument.pages.map((page: PdfEditorPage, index: number) => ( 
          <PdfDocumentPage
            key={`page-${page.index}`}
            page={page}
            pdFields={editedTemplate.documents[activePageId].innerDocument.pdFields}
            selectedField={selectedField}
            fieldState={fieldState}
            isFocused={page.index === focusedPageIndex}
            isDrawing={_isFieldInStates(["add-start", "drawing", "adjust-position"])}
            isDragging={isDragging}
            addCursor={_isFieldInStates(["add-start"])}
            onStartDrawing={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => _isFieldInStates("add-start") && onStartDrawingField(event, index)}
            onEditField={(pageIdx: number, field: Field, widget: Widget) => editField(pageIdx, field, widget, false)}
            onMouseEnter={(pageDiv: HTMLDivElement | null) => {
              if (isDragging || fieldState === "add-start" || fieldState === "adjust-position") {
                focusedPageRef.current = pageDiv
                set_focusedPageIndex(page.index)
              }
            }}
            onScreenMajority={(pageDiv: HTMLDivElement | null) => {
              focusedPageRef.current = pageDiv
              set_focusedPageIndex(page.index)
            }}
          >
            { _isFieldInStates(["drawing", "adjust-position"]) && focusedPageIndex === page.index &&
              <ResizableRectangle
                ref={rectangleRef}
                coordinates={fieldCoordinates}
                selectedField={selectedField}
                selectedWidget={selectedWidget}
                newFieldType={newFieldType}
                pageCount={pageCountRef.current}
                isDragging={isDragging}
                autoDrag={autoDrag}
                allowResize={_isResizeAllowed()}
                parentElement={focusedPageRef}
                onResize={(coordinates: RectCoordinates) => resizeField(coordinates, page.width, page.height)}
                onDragStart={() => set_autoDrag(false)}
                onDrag={(deltaX: number, deltaY: number) => dragField(deltaX, deltaY)}
                onDragEnd={() => set_isDragging(false)}
                onDoubleClick={() => finishDrawing()}
              >
                <div className={css.rectFloatingButtons}>
                  <RectFloatingButton icon="checkmark" title="Finish Positioning" tooltipId="check-button-tooltip__finished" onClick={() => finishDrawing()} />
                  <RectFloatingButton icon="bin" title="Throw Away" red tooltipId="check-button-tooltip__throw-away" onClick={resetAll} />
                </div>
              </ResizableRectangle>
            }
          </PdfDocumentPage>
        ))}
      </div>

      { renderModal() }

      <ReactTooltip id="check-button-tooltip__finished" place="right" type="dark" effect="float" multiline={false} />
      <ReactTooltip id="check-button-tooltip__throw-away" place="right" type="dark" effect="float" multiline={false} />
    </div>
  )
}


export default EledoPdfEditor