import { ReactNode, forwardRef, ForwardedRef } from "react"
import classnames from "classnames"

import { Field, FieldType, Widget } from "../EledoPdfEditor"
import { topLeftToCenter, centerToTopLeft, getNewCenteredCoordinates, degToRadian, getLength, getCursor, shouldHide } from "./utils"
import css from "./ResizableRectangle.module.scss"


export type RectCoordinates = {
  top: number
  left: number
  width: number
  height: number
  rotateAngle: number
}

export type CenteredRectCoordinates = {
  centerY: number
  centerX: number
  width: number
  height: number
  rotateAngle: number
}

type Direction = "top" | "right" | "bottom" | "left" | "topLeft" | "topRight" | "bottomRight" | "bottomLeft"

type ResizableRectangleProps = {
  coordinates: RectCoordinates
  newFieldType?: FieldType
  selectedField: Field | null
  selectedWidget: Widget | null
  pageCount: number
  minWidth?: number
  minHeight?: number
  hideSize?: number
  directions?: Direction[]
  rotateAngle?: number
  parentRotateAngle?: number
  isDragging: boolean
  autoDrag: boolean
  allowResize: boolean
  rotatable?: boolean
  parentElement: any
  children: ReactNode
  onResizeStart?: () => void
  onResize: (coordinates: RectCoordinates) => void
  onResizeEnd?: () => void
  onRotateStart?: () => void
  onRotate?: (angle: number) => void
  onRotateEnd?: () => void
  onDragStart?: () => void
  onDrag: (deltaX: number, deltaY: number) => void
  onDragEnd?: () => void
  onDoubleClick: () => void
}


const ResizableRectangle = forwardRef((
  {
    coordinates = {top: 0, left:0, width:0, height:0, rotateAngle: 0},
    newFieldType,
    selectedField = null,
    selectedWidget = null,
    pageCount = 0,
    minWidth = 10,
    minHeight = 10,
    hideSize = 40,
    directions = ["top", "right", "bottom", "left", "topLeft", "topRight", "bottomRight", "bottomLeft"],
    rotateAngle = 0,
    parentRotateAngle = 0,
    isDragging = false,
    autoDrag = false,
    allowResize = false,
    parentElement = null,
    children = null,
    onResizeStart = () => {return},
    onResize = () => {return},
    onResizeEnd = () => {return},
    onDragStart = () => {return},
    onDrag = () => {return},
    onDragEnd = () => {return},
    onDoubleClick = () => {return}
  }: ResizableRectangleProps,
  ref: ForwardedRef<HTMLDivElement>) => {
  const centeredCoordinates = topLeftToCenter(coordinates)

  // RESIZE LOGIC
  const handleResize = (length: number, alpha: number, centeredCoordinates: CenteredRectCoordinates, direction: string | null) => {
    if (!onResize || !direction)
      return

    const beta = (alpha - degToRadian(rotateAngle + parentRotateAngle))
    const deltaW = (length * Math.cos(beta))
    const deltaH = (length * Math.sin(beta))
    const newCenteredCoordinates: CenteredRectCoordinates = getNewCenteredCoordinates(
      direction, { ...centeredCoordinates, rotateAngle: rotateAngle },
      deltaW, deltaH, minWidth, minHeight
    )
    const newCoordinates: RectCoordinates = centerToTopLeft(newCenteredCoordinates)
    onResize(newCoordinates)
  }

  const startResize = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, cursor: string) => {
    event.stopPropagation()
    const mouseButton = event.button
    if (mouseButton !== 0)
      return

    document.body.style.cursor = cursor
    const initialClientX = event.clientX
    const initialClientY = event.clientY

    const direction = event.currentTarget.getAttribute('aria-details')
    onResizeStart && onResizeStart()

    const onMove = (event: MouseEvent) => {
      event.stopPropagation()
      const { clientX, clientY } = event
      const deltaX = clientX - initialClientX
      const deltaY = clientY - initialClientY
      const alpha = Math.atan2(deltaY, deltaX)
      const deltaL = getLength(deltaX, deltaY)
      const centeredCoordinates = topLeftToCenter(coordinates)
      handleResize(deltaL, alpha, centeredCoordinates, direction)
    }

    const onUp = () => {
      document.body.style.cursor = 'auto'
      document.removeEventListener('mousemove', onMove)
      document.removeEventListener('mouseup', onUp)
      onResizeEnd && onResizeEnd()
    }

    document.addEventListener('mousemove', onMove)
    document.addEventListener('mouseup', onUp)
  }

  // DRAG LOGIC
  const handleDrag = (deltaX: number, deltaY: number) => {
    if (!onDrag)
      return

    onDrag(deltaX, deltaY)
  }

  const startDrag = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    event.preventDefault()
    onDragStart && onDragStart()
    if (!ref || typeof ref === "function")
      return

    if (!ref.current)
      return

    let currentX = event.clientX
    let currentY = event.clientY
    let currentLeft = coordinates.left
    let currentTop = coordinates.top
    let currentDocumentPageIndex = parseInt(parentElement.current.id.replace(/[^0-9]/g, ""))
    let prevMaxAdditionY = 0

    const onMove = (event: MouseEvent) => {
      event.stopPropagation()
      if (!ref.current)
        return

      const boundaryElement = parentElement.current.getBoundingClientRect()
      const rectangleElement = ref.current.getBoundingClientRect()
      const newDocumentPageIndex = parseInt(parentElement.current.id.replace(/[^0-9]/g, ""))
      let newMouseX = event.clientX
      let newMouseY = event.clientY
      const minX = (boundaryElement.left)
      const maxAdditionX = (newMouseX - rectangleElement.left) <= rectangleElement.width ? (newMouseX - rectangleElement.left) : rectangleElement.width
      const maxX = (boundaryElement.left + boundaryElement.width - coordinates.width + maxAdditionX)
      const minY = (boundaryElement.top)
      const maxAdditionY = (newMouseY - rectangleElement.top) <= rectangleElement.height ? (newMouseY - rectangleElement.top) : rectangleElement.height
      const maxY = (boundaryElement.top + boundaryElement.height - coordinates.height + maxAdditionY)
      const activeMinY = (newDocumentPageIndex === 0)
      const activeMaxY = (newDocumentPageIndex+1 === pageCount)

      if (newDocumentPageIndex > currentDocumentPageIndex) {
        const newTop = 0 - prevMaxAdditionY
        handleDrag(currentLeft, newTop)
        currentTop = newTop
        currentDocumentPageIndex = newDocumentPageIndex
        return
      }

      if (newDocumentPageIndex < currentDocumentPageIndex) {
        const pageGap = window.getComputedStyle(parentElement.current).marginBottom.replace(/[^0-9]/g, "") || '32'
        const newTop = (boundaryElement.height - rectangleElement.height + (parseInt(pageGap)))
        currentTop = newTop
        currentDocumentPageIndex = newDocumentPageIndex
        return
      }

      if (newMouseX < minX) {
        newMouseX = minX
      }
      if (newMouseX > maxX) {
        newMouseX = maxX
      }
      if (newMouseY < minY && activeMinY) {
        newMouseY = minY
      }
      if (newMouseY > maxY && activeMaxY) {
        newMouseY = maxY
      }

      let deltaX = (newMouseX - currentX)
      let deltaY = (newMouseY - currentY)

      const newLeft =
        (currentLeft + deltaX >= 0) &&
        ((currentLeft + deltaX + coordinates.width) <= boundaryElement.width)
          ? currentLeft + deltaX
          : currentLeft
          
      let newTop: number
      if (activeMinY && currentTop + deltaY <= 0) {
        newTop = currentTop
      }
      else if (activeMaxY && currentTop + deltaY + coordinates.height >= boundaryElement.height)
        newTop = currentTop
      else
        newTop = currentTop + deltaY

      handleDrag(newLeft, newTop)
      currentX = newMouseX
      currentY = newMouseY
      currentLeft = newLeft
      currentTop = newTop
      prevMaxAdditionY = maxAdditionY
    }

    const onWheel = (wheelEvent: WheelEvent) => {
      event.stopPropagation()
      const boundaryElement = parentElement.current.getBoundingClientRect()
      const newTop = currentTop + wheelEvent.deltaY >= 0 && (currentTop + wheelEvent.deltaY + coordinates.height) <= boundaryElement.height
          ? currentTop + wheelEvent.deltaY
          : currentTop
      handleDrag(currentLeft, newTop)
      currentTop = newTop
    }

    const onEnd = (event: MouseEvent | KeyboardEvent) => {
      if (event instanceof KeyboardEvent && (event.key !== "Escape" && event.key !== "Enter"))
        return

      document.removeEventListener("mousemove", onMove)
      document.removeEventListener("mouseup", onEnd)
      document.removeEventListener("wheel", onWheel)
      autoDrag && document.removeEventListener("click", onEnd)
      onDragEnd && onDragEnd()
    }
    
    document.addEventListener("mousemove", onMove)
    document.addEventListener("mouseup", onEnd)
    document.addEventListener("wheel", onWheel)
    autoDrag && document.addEventListener("click", onEnd)
  }

  // ROTATE LOGIC - not fully implemented (not working now)
  // const handleRotate = (angle: number, startAngle: number) => {
  //   if (!onRotate)
  //     return

  //   let _rotateAngle = Math.round(startAngle + angle)
  //   if (_rotateAngle >= 360)
  //     _rotateAngle -= 360
  //   else if (_rotateAngle < 0)
  //     _rotateAngle += 360
    
  //   if (_rotateAngle > 356 || _rotateAngle < 4)
  //     _rotateAngle = 0
  //   else if (_rotateAngle > 86 && _rotateAngle < 94)
  //     _rotateAngle = 90
  //   else if (_rotateAngle > 176 && _rotateAngle < 184)
  //     _rotateAngle = 180
  //   else if (_rotateAngle > 266 && _rotateAngle < 274)
  //     _rotateAngle = 270

  //   onRotate(_rotateAngle)
  // }


  return (
    <div
      ref={ref}
      className={classnames(
        css.resizableRectangle,
        newFieldType && css[newFieldType],
        selectedField && css[selectedField.type],
        selectedWidget && css["zoom"+ selectedWidget.zoom],
        isDragging && css.dragging
      )}
      style={{
        width: Math.abs(centeredCoordinates.width),
        height: Math.abs(centeredCoordinates.height),
        transform: `rotate(${centeredCoordinates.rotateAngle}deg)`,
        left: centeredCoordinates.centerX - Math.abs(centeredCoordinates.width) / 2,
        top: centeredCoordinates.centerY - Math.abs(centeredCoordinates.height) / 2,
      }}
      onMouseDown={startDrag}
      onMouseMove={(event) => autoDrag && startDrag(event)}
      onDoubleClick={onDoubleClick}
    >
      {/*{*/}
      {/*  rotatable &&*/}
      {/*  <div className={css.rotate} onMouseDown={startRotate}>*/}
      {/*    <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">*/}
      {/*      <path*/}
      {/*        d="M10.536 3.464A5 5 0 1 0 11 10l1.424 1.425a7 7 0 1 1-.475-9.374L13.659.34A.2.2 0 0 1 14 .483V5.5a.5.5 0 0 1-.5.5H8.483a.2.2 0 0 1-.142-.341l2.195-2.195z"*/}
      {/*        fill="#eb5648"*/}
      {/*        fillRule="nonzero"*/}
      {/*      />*/}
      {/*    </svg>*/}
      {/*  </div>*/}
      {/*}*/}

      { allowResize && directions.map((direction) => {
          if (shouldHide(direction, centeredCoordinates.width, centeredCoordinates.height, hideSize))
            return <div key={direction} className={css.hidden} />

          const cursor = `${getCursor(rotateAngle + parentRotateAngle, direction)}-resize`;
          return (
            <div
              key={direction}
              style={{cursor}}
              aria-details={direction}
              className={classnames(css[direction], css.resizableHandler)}
              onMouseDown={(event) => startResize(event, cursor)}
            />
          )
        })
      }

      { directions.map((direction) => {
          if (shouldHide(direction, centeredCoordinates.width, centeredCoordinates.height, hideSize))
            return <div key={direction} className={css.hidden} />

          return (
            <div key={direction} className={classnames(css[direction], css.square, !allowResize && css.noresize)} />
          )
        })
      }

      { children }
    </div>
  )
})


export default ResizableRectangle