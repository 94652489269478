import {useContext, useEffect} from 'react';
import {hideMenu, showMenu} from "_reducers/menuReducer";
import { useDispatch } from "react-redux";
import NotyfContext from "context/NotyfContext";
import PropTypes from "prop-types";

export function useHideMenu() {
  useShowHideMenu(hideMenu);
}

export function useShowMenu() {
  useShowHideMenu(showMenu);
}

const useShowHideMenu = (showHide) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showHide());
  }, [dispatch, showHide]);
};

export function useErrorNotyf(error, message = null) {
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    if(error && (error.error || error.message)) {
      const msg = error.error ? error.error : error.message;
      notyf.error({
        position: {x:'right', y: 'top'},
        message: message ? message : msg,
        dismissible: true,
      });
      console.error("ERROR LOG: ", msg, error.stack);
    }
  }, [error, notyf, message]);
}

export function showNotyf(message, notyf, type = "success") {
  switch(type) {
    case "success":
      notyf.success({
        position: {x:'right', y: 'top'},
        message: message ? message : "Success!",
        dismissible: true,
      });
      break;
    case "error":
      notyf.error({
        position: {x:'right', y: 'top'},
        message: message ? message : "Error!",
        dismissible: true,
      });
      break;
    default:
      if (!message) return;
      notyf.open({
        position: {x:'right', y: 'top'},
        message: message,
        dismissible: true,
        background: "#222",
      });
      break;
  }
}
showNotyf.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  notyf: PropTypes.object.isRequired,
  type: PropTypes.string,
}

export { default as useCopyTemplate } from './useCopyTemplate';
export { default as useDeleteTemplate } from './useDeleteTemplate';
export { default as useConfirmModal } from './useConfirmModal';
export { default as usePagination } from './usePagination';