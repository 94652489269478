import React, {useEffect} from 'react';
import './EledoSettings.scss';
import {FormProvider, useForm} from "react-hook-form";
import {useShowMenu} from "hooks";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {EledoFormGenerator} from "components/library";
import {SettingsFormModel, FillableSettingsFormModel, AttachedFileSettingsFormModel} from "model";
import {
  persistTemplate,
  saveTemplateSettingsChanged,
  saveTemplateSettingsValid
} from "_reducers/templateReducer";
import utils from "utils";
import { TEMPLATE__TYPES } from 'model/enums';
import useDocumentFormats from 'hooks/useDocumentFormats';

const EledoSettings = ({dataModel}) => {
  const [editedTemplate, activePage] = useSelector((state) => [state.templates.editedTemplate, state.templates.activePageId], shallowEqual);
  // const [ documentFormats ] = useSelector((state) => [ state.documentFormats.documentFormats ]);
  const { documentFormats } = useDocumentFormats();
  const formHandler = useForm({mode: "onChange"});
  const { formState: {isValid, errors}, watch, handleSubmit } = formHandler;
  const dispatch = useDispatch();
  const editedObject = {
    ...editedTemplate,
    formatId: editedTemplate.documents[activePage].innerDocument.format?.id,
    flatten:  editedTemplate.documents[activePage].innerDocument.renderConfig?.flatten,
    direction: editedTemplate.documents[activePage].innerDocument.renderConfig?.direction
  }

  useEffect(() => {
    dispatch(saveTemplateSettingsValid(!errors || Object.keys(errors).length <= 0));
  }, [isValid, errors, dispatch]);

  useEffect(() => {
    const subscription = watch((data) => {
      const formatId = data.formatId;
      if(formatId){
        const format = documentFormats.filter(df => df.id === formatId);
        if(format && format.length > 0){
          data.pixelWidth = format[0].pixelWidth
          data.pixelHeight = format[0].pixelHeight
        }
      }
      dispatch(saveTemplateSettingsChanged(utils.deepCopy(data)));
      
    });
    return () => subscription.unsubscribe();
  }, [watch, dispatch, documentFormats]);

  const handleCreateTemplate = async (data) => {
    const obj = { ...editedTemplate }
    obj.file = data.file;
    dispatch(persistTemplate(obj));
  };

  useShowMenu();

  let formModel;
  switch(editedTemplate.type){
    case TEMPLATE__TYPES.DYNAMIC_FLOW:
    case TEMPLATE__TYPES.STATIC_PAGE:
      formModel = SettingsFormModel;
      break;
    case TEMPLATE__TYPES.FILLABLE_DOCUMENT:
      formModel = FillableSettingsFormModel;
      break;
    default:
      break;
  }

  return (
    <div className="EledoSettings section section-small" data-testid="EledoSettings">
      <h3>Settings</h3>

      <FormProvider {...formHandler}>
        <form onSubmit={handleSubmit((data) => handleCreateTemplate(data))}>
          <EledoFormGenerator
            formModel={formModel}
            editedDataObject={editedObject}
            dataModel={dataModel}/>

          { editedTemplate.type === TEMPLATE__TYPES.FILLABLE_DOCUMENT &&
            <>
              <h3>Attached PDF File</h3>
              <EledoFormGenerator
                formModel={AttachedFileSettingsFormModel}
                editedDataObject={editedObject}
                />
            </>
          }
        </form>
      </FormProvider>

    </div>
  );
};

export default EledoSettings;


