import {
  EledoCheckboxField,
  EledoColorField,
  EledoDocumentFormatSelect,
  EledoFileField,
  EledoPasswordField,
  EledoRadioButtonField,
  EledoSelect,
  EledoSubmitField,
  EledoTextArea,
  EledoTextField
} from "./EledoFormsComponents";
import React from "react";
import {FormFieldTypes} from "model/enums";
import {EledoExpressionFieldNew, EledoLoader} from "components/library";

const _ = require('lodash');

/**
 * Example of use can be found in `template/EledoSettings` component.
 * Do not use without FormProvider and an enclosing form tag.
 */
const EledoFormGenerator = ({formModel, editedDataObject, ...props}) => {
  const {fields, validation} = formModel;

  return (
    <React.Fragment>
      {!editedDataObject && <div style={{padding: "2rem 0", display: "flex", justifyContent: "center"}}><EledoLoader/></div>}
      {editedDataObject && fields.map(f => {
        const fieldValidation = validation[f.id] ? validation[f.id] : {};
        return renderField(f, fieldValidation, editedDataObject, null, props);
      })}
    </React.Fragment>
  );
};

const getDefaultValue = (editedDataObject, field) => {
  return _.get(editedDataObject, field.path ? `${field.path}.${field.id}` : field.id);
}

const renderField = (field, validation, editedDataObject, onChangeHandler, props) => {
  const fieldProps = {
    id: field.id,
    label: field.label,
    key: field.id,
    path: field.path,
    level: field.level ? field.level : 0,
    required: field.required ? field.required : false,
    validation: validation,
    dataModel: props.dataModel,
    defaultValue: getDefaultValue(editedDataObject, field)
  }
  switch(field.type) {
    case FormFieldTypes.SHORT_STRING: return <EledoTextField {...fieldProps}/>;
    case FormFieldTypes.LONG_STRING: return <EledoTextArea {...fieldProps}/>;
    case FormFieldTypes.SELECT: return <EledoSelect {...fieldProps} options={field.options}/>;
    case FormFieldTypes.EXPRESSION: return <EledoExpressionFieldNew {...fieldProps}/>;
    case FormFieldTypes.PASSWORD: return <EledoPasswordField {...fieldProps}/>;
    case FormFieldTypes.CHECKBOX: return <EledoCheckboxField {...fieldProps}/>
    case FormFieldTypes.RADIO: return <EledoRadioButtonField groupName={field.groupName} {...fieldProps}/>;
    case FormFieldTypes.DOCUMENT_FORMAT_SELECT: return <EledoDocumentFormatSelect {...fieldProps}/>
    case FormFieldTypes.FILE: return <EledoFileField {...fieldProps}/>;
    case FormFieldTypes.COLOR: return <EledoColorField {...fieldProps}/>;
    case FormFieldTypes.SUBMIT: return <EledoSubmitField {...fieldProps}/>;
    case FormFieldTypes.CUSTOM:
      return field?.renderField && field.renderField(validation, onChangeHandler);
    default: return null;
  }
};

export default EledoFormGenerator;