import React, {useContext} from "react";
import styles from 'components/library/EledoNavbar/EledoNavbar.module.scss';
import {EledoMenuItem, NavbarHeader, EledoMenuAction} from "components/library";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import {useConfirmModal, useCopyTemplate, useDeleteTemplate} from "hooks";
import {persistTemplate} from "_reducers/templateReducer";
import {TEMPLATE__TYPES as ttypes} from "model/enums";
import { PlatformContext } from "PlatformContext";

const commonItems = [
  {to: "/input-fields", label: "Input Fields", icon: "list2"},
  {to: "/settings", label: "Settings", icon: "cog"},
  {to: "/security", label: "Template Security", icon: "key"},
];
const apiItem = {to: "/api", label: "API", icon: "tree"};
//const storageItem = {to: "/storage", label: "Storage", icon: "upload2"};
const templateMainItem = {to: "/main", label: "Main", icon: "file-text1"};
const templateBgItem = {to: "/background", label: "Background", icon: "contrast"};
const pdfMenuItem = {to: "/pdf-editor", label: "Main Document", icon: "file-text1"};

const EledoTemplateMenu = ({baseUrl = ""}) => {
  const platform = useContext(PlatformContext);
  const isEmbedded = platform.isEmbedded;
  const templateType = useSelector(state => state.templates.selectedTemplate?.type);
  const isPdfEditor = [ttypes.FILLABLE_DOCUMENT, ttypes.XFA_DOCUMENT].some(tt => tt === templateType)
  const menuHidden = useSelector(state => state.menu.menuHidden);
  const [selectedTemplate, templateChanged, editedTemplate] = useSelector(state => [
    state.templates.selectedTemplate,
    state.templates.templateChanged,
    state.templates.editedTemplate,
  ], shallowEqual);
  const { copyHandler } = useCopyTemplate(false, selectedTemplate.id, selectedTemplate.name);
  const { deleteHandler, renderDeleteModal } = useDeleteTemplate(selectedTemplate.id, selectedTemplate.name);
  const dispatch = useDispatch();
  const { renderModal: renderConfirmModal, showConfirmModal } = useConfirmModal(
    'Your template is not saved',
    `Your template is not saved.\nSave before copying?`,
    'Save & Copy!',
    'Cancel',
    async () => {
      await dispatch(persistTemplate(editedTemplate));
      await copyHandler(selectedTemplate.id);
    }
  );

  const handleCopy = async () => {
    if(templateChanged) {
      showConfirmModal()
    } else {
      await copyHandler(selectedTemplate.id);
    }
  }

  const handleDelete = () => {
    deleteHandler();
  }

  return (
    <nav data-testid="navbar">
      <div className={classnames(styles.navbarLeft, menuHidden ? styles.leftMenuDisplayOnHover : styles.leftMenuDisplayAlways)}>
        <NavbarHeader/>

        <ul className={classnames(styles.navbarBody, styles.navList)}>
          { templateType === isPdfEditor ?
            <EledoMenuItem label={pdfMenuItem.label}
                           icon={pdfMenuItem.icon}
                           to={baseUrl + pdfMenuItem.to}/>
            :
            <React.Fragment>
              <EledoMenuItem label={templateMainItem.label}
                             icon={templateMainItem.icon}
                             to={baseUrl + templateMainItem.to}
                             activeOnlyWhenExact={false}>

              </EledoMenuItem>
              { !isPdfEditor &&
                <EledoMenuItem label={templateBgItem.label}
                              icon={templateBgItem.icon}
                              to={baseUrl + templateBgItem.to}
                              activeOnlyWhenExact={false}>

                </EledoMenuItem>
              }
            </React.Fragment>
          }
          { commonItems.map( (item, index) => (
            <EledoMenuItem label={item.label}
                           icon={item.icon}
                           to={baseUrl + item.to}
                           key={`menuitem-${index}`}/>
          ))}
          { !isEmbedded &&
            <EledoMenuItem label={apiItem.label}
                           icon={apiItem.icon}
                           to={baseUrl + apiItem.to}/>
          }
        </ul>

        <div className={styles.navbarFooter}>
          <ul className={styles.navList}>
            <span data-tip={templateChanged ? "The template must be saved first" : ""}
                  data-for="copy-invalid-tooltip">
              <EledoMenuAction label={"Copy"} icon={"copy"}
                               action={handleCopy}/>
            </span>
            <ReactTooltip
              id="copy-invalid-tooltip"
              place="bottom"
              type="dark"
              effect="float"
              multiline={true}
            />
            <EledoMenuAction label={"Delete"} icon={"bin"}
                             action={handleDelete}/>
          </ul>
        </div>
      </div>
      { renderDeleteModal() }
      { renderConfirmModal() }
    </nav>
  );
};
EledoTemplateMenu.propTypes = {
  baseUrl: PropTypes.string,
  isPdfTemplate: PropTypes.bool,
};

export default EledoTemplateMenu;