import styles from 'components/library/EledoNavbar/EledoNavbar.module.scss';
import {
  EledoMenuAction, EledoMenuItem, NavbarHeader,
} from "components/library";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useContext } from 'react';
import { PlatformContext } from 'PlatformContext';
import { AuthContext } from 'AuthContext';
import useHelpdeskStats from 'graphql/hooks/useHelpdeskStats';
import { useNavigate } from 'react-router-dom';

//todo: lets remove bg-template route in the future, put it in the same page as template
//todo: change route names to be inspired by current eledo routes
const commonItems = [
  {to: "/templates/mine", label: "My Templates", icon: "file-text1"},
  {to: "/templates/public", label: "Public Templates", icon: "earth"},
  // {to: "/templates/company", label: "Company Templates", icon: "list2"},
  {to: "/connections", label: "Connections", icon: "link"},
  {to: "/logs", label: "Logs", icon: "quill1"},
  {to: "/documentation", label: "Documentation", icon: "book"}
];
const helpdeskItem = {to: "/helpdesk", label: "Helpdesk", icon: "lifebuoy", badge: 0}
const footerItems = [
  helpdeskItem,
  {to: "/spending", label: "Spending", icon: "stats-dots"},
  {to: "/pricing", label: "Pricing", icon: "credit-card"},
];

const profileItem = {to: "/user", label: "Profile", icon: "user"};
const profileSubItemMyProfile = {to: "/user", label: "My Profile", icon: "user"};
const profileSubItemLogout = {label: "Logout", icon: "exit"};

const EledoAppMenu = ({baseUrl = ""}) => {
  const platform = useContext(PlatformContext);
  const auth = useContext(AuthContext);
  const isEmbedded = platform.isEmbedded;
  const {helpdeskStats} = useHelpdeskStats({connectionId: auth.connection?.id});
  helpdeskItem.badge = helpdeskStats?.unread;
  const navigate = useNavigate()

  return (
    <nav data-testid="navbar">
      <div className={classnames(styles.navbarLeft, styles.leftMenuDisplayAlways)}>
        <NavbarHeader/>

        <ul className={classnames(styles.navbarBody, styles.navList)}>
          { commonItems.map( (item, index) => (
            <EledoMenuItem label={item.label}
                           icon={item.icon}
                           to={baseUrl + item.to}
                           key={`menuitem-${index}`}/>
          ))}
        </ul>

        <div className={styles.navbarFooter}>
          <ul className={styles.navList}>
            { footerItems.map( (item, index) => (
              <EledoMenuItem label={item.label}
                             icon={item.icon}
                             to={baseUrl + item.to}
                             badge={item.badge}
                             key={`menuitem-${index}`}/>
            ))}
            <EledoMenuItem label={profileItem.label}
                           icon={profileItem.icon}
                           to={profileItem.to}
                           submenu={!isEmbedded}>
              { !isEmbedded &&
                <ul className={styles.subMenu}>
                  <EledoMenuAction label={profileSubItemMyProfile.label}
                                   icon={profileSubItemMyProfile.icon}
                                   action={() => navigate(baseUrl + profileSubItemMyProfile.to)}
                  />
                    <EledoMenuAction label={profileSubItemLogout.label}
                                     icon={profileSubItemLogout.icon}
                                     action={() => auth.logout()}/>
                </ul>
              }
            </EledoMenuItem>
          </ul>
        </div>
      </div>
    </nav>
  );
};
EledoAppMenu.propTypes = {
  baseUrl: PropTypes.string,
  isPdfTemplate: PropTypes.bool,
};

export default EledoAppMenu;