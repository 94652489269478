import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {changePricingPlan, fetchUserPlan} from "_reducers/pricingPlansReducer";
import {useErrorNotyf, useShowMenu} from "hooks";
import {
  EledoPricingPlan, EledoError, EledoLoader, EledoRadioButton,
} from "components/library";
import {nanoid} from "nanoid";

const TabPricing = () => {
  const [
    {
      currentPlan, nextPlan, nextPlanStart,
      subscriptionType, subscriptionTypes, paymentMethod, paymentMethods,
      nextSubscriptionType, nextPaymentMethod,
    }, loading, error, planChange
  ] = useSelector(state => [
    state.pricing.userPlan, state.pricing.userPlanLoading, state.pricing.userPlanError, state.pricing.planChange
  ], shallowEqual);
  const dispatch = useDispatch();

  useErrorNotyf(error);
  useShowMenu();

  useEffect(() => {
    dispatch(fetchUserPlan());
  }, [dispatch]);

  if(planChange && planChange.step === 11 && planChange.responses?.clientPortalUrl) {
    window.location = planChange.responses.clientPortalUrl;
  }

  const renderError = () =>
    <EledoError title={error?.message}
                description={(<React.Fragment>
                  We are sorry &#9785; We could not load your pricing plans. <br/><br/>
                  {error.message.toLocaleLowerCase() === "network error" && "Please check your internet connection."}
                </React.Fragment>)}/>;

  return (
    <div data-testid="TabPricing">
      { error && renderError() }
      {!error && !loading && currentPlan !== null &&
        <PricingPlanWithForm title="Your current plan"
                             plan={currentPlan}
                             subscription={{subscriptionType, subscriptionTypes}}
                             payment={{paymentMethod, paymentMethods}}/>
      }
      {
        !error && !loading && nextPlan !== null &&
        <PricingPlanWithForm title={`Your next plan starting ${nextPlanStart ? new Date(nextPlanStart).toLocaleDateString() : ""}`}
                             plan={nextPlan}
                             subscription={{subscriptionType: nextSubscriptionType, subscriptionTypes}}
                             payment={{paymentMethod: nextPaymentMethod, paymentMethods}}/>
      }
      { loading && <div className="flex-center"><EledoLoader/></div> }
    </div>
  );
}

export const PricingPlanWithForm = ({title, plan, subscription, payment}) => {
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <div className="section my-color-section">
        <h2 className="section-heading">{title}</h2>

        <div className="col-wrapper">
          <div className="col-50">
            <EledoPricingPlan plan={plan}/>
          </div>

          <div className="col-50">
            <div style={{marginLeft: "1rem"}}>
              <div>
                <Link className="w-button button" to={'/pricing'}>Change or Cancel Plan</Link>
              </div>
              { plan.price > 0 && payment.paymentMethod === "CreditCard" &&
                <div style={{marginTop: "2rem"}}>
                  <a href="#/" className="w-button button" onClick={() => { dispatch(changePricingPlan({step: 10, successUrl: window.location.href })); }}>Change Payment Details</a>
                </div>
              }
              { plan.price > 0 && payment.paymentMethod === "PayPal" &&
                <div style={{marginTop: "2rem"}}>
                <p>Payment Details cannot be changed for PayPal payment method. If you don't have a PayPal account and your subscription fails, you will need to cancel it and subscribe again to Eledo.</p>
              </div>
            }
              <PricingPlanDetails plan={plan}
                                  subscription={subscription}
                                  payment={payment}/>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export const PricingPlanDetails = ({plan, subscription, payment}) => {
  const uniqueId = nanoid();
  return (
    <React.Fragment>
      <h3>Subscription Period</h3>

      { subscription.subscriptionTypes.map(st => (
          <EledoRadioButton id={`subscription-${uniqueId}-${st.key}`}
                            key={`subscription-${uniqueId}-${st.key}`}
                            label={st.value}
                            value={st.key.equals(subscription.subscriptionType)}
                            disabled/>
        )
      )}

      <h3>Payment Method</h3>

      { payment.paymentMethods.map(pm => (
          <EledoRadioButton id={`payment-${uniqueId}-${pm.key}`}
                            key={`payment-${uniqueId}-${pm.key}`}
                            label={pm.value}
                            value={pm.key.equals(payment.paymentMethod)}
                            disabled/>
        )
      )}

    </React.Fragment>
  );
};

export default TabPricing;
