import React, { useContext } from 'react'
import EledoArticleView from './EledoArticleView'
import { PlatformContext } from 'PlatformContext'
import useArticleHistory from 'hooks/useArticleHistory'

function EledoArticlesPage() {
  const platform = useContext(PlatformContext)
  const history = useArticleHistory("/" + platform.documentationPaths.root)
  
  return (
    <div style={{padding: '20px 30px'}}>
      <EledoArticleView root={platform.documentationPaths.root} path={"/" + platform.documentationPaths.root} history={history}/>
    </div>
  )
}

export default EledoArticlesPage