import ReactTooltip from "react-tooltip"
import classnames from "classnames"

import { LOG__TYPES__INFO, LOG__SEVERITY_INFO } from "model/enums"
import { GenerationLog } from "graphql/GenerationLogQueries"
import utils from "utils"

import TemplateLink from "../TemplateLink/TemplateLink"
import css from "./LogsTableLine.module.scss"


type LogTableLineProps = {
  log: GenerationLog
  onLogLineClicked: (logId: string) => void
}

const LogTableLine = (props: LogTableLineProps) => {
  return (
    <div
      className={classnames('w-layout-grid', 'data-grid', 'logDetail', css.dataGrid, LOG__SEVERITY_INFO[props.log.logLevel]?.color)}
      onClick={() => props.onLogLineClicked(props.log.id)}
    >
      <div className='_wf-table-cell'>
        <div className={classnames('tile-title', css.tileTitle)}>Date</div>
        <div>{utils.objectIdToDate(props.log.id)}</div> 
      </div>

      <div id="w-node-_8f485d4a-1d97-cad0-f4f3-52cb96d63492-dd191fb9" className='_wf-table-cell'>
        <div className={classnames('tile-title', css.tileTitle)}>Type</div>
          <div data-tip={`Log Type: ${LOG__TYPES__INFO[props.log.type]?.desc}`} data-for={"log-type-tooltip"}>
            <i className={LOG__TYPES__INFO[props.log.type]?.icon} />
          </div>
          <ReactTooltip id="log-type-tooltip" place="top" type="dark" effect="float" multiline={false} />
      </div>

      <div id="w-node-_8f485d4a-1d97-cad0-f4f3-52cb96d63492-dd191fb9" className='_wf-table-cell'>
        <div className={classnames('tile-title', css.tileTitle)}>Level</div>
        <div data-tip={`Log Level: ${LOG__SEVERITY_INFO[props.log.logLevel]?.desc}`} data-for={"log-level-tooltip"}>
          <i className={LOG__SEVERITY_INFO[props.log.logLevel]?.icon}/>
        </div>
        <ReactTooltip id="log-level-tooltip" place="top" type="dark" effect="float" multiline={false} />
      </div>

      <div id="w-node-_8f485d4a-1d97-cad0-f4f3-52cb96d63492-dd191fb9" className='_wf-table-cell'>
        <div className={classnames('tile-title', css.tileTitle)}>Template</div>
        <TemplateLink templateName={props.log.template?.name} templateId={props.log.template?.id} />
      </div>

      <div className='_wf-table-cell'>
        <div className={classnames('tile-title', css.tileTitle)}>Documents / Pages</div>
        <div>{props.log.documents}/{props.log.pages}</div>
      </div>

      <div className='_wf-table-cell'>
        <div className={classnames('tile-title', css.tileTitle)}>Took Time (ms)</div>
        <div>{props.log.preloadTime + props.log.genTime + props.log.uploadTime + props.log.actionsTime} ms</div>
      </div>
    </div>
  )
}


export default LogTableLine