import React, { useContext, useEffect } from "react";
import {Provider} from "react-redux";
import store from "_store";
import {injectAuthContext} from "_api/eledo";
import { AuthContext } from "AuthContext";

const Root = props => {

  const auth = useContext(AuthContext);

  useEffect(() => {
    injectAuthContext(auth);
  }, [auth])

  return (
    <Provider store={store}>
      {props.children}
    </Provider>
  )
}

export default Root;
