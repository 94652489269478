import { Link } from "react-router-dom"

type TemplateLinkProps = {
  templateId?: string | undefined
  templateName?: string | undefined
}

const TemplateLink = (props: TemplateLinkProps) => {
  return (props.templateName && props.templateId
    ? <div><Link to={`/template/${props.templateId}`}>{props.templateName}</Link></div>
    : <div>{props.templateName ? props.templateName : "-"}</div>
  )
}


export default TemplateLink