import { shallowEqual, useDispatch, useSelector } from "react-redux"

import { saveTemplate } from "_reducers/templateReducer"

import { EledoError } from "components/library"
import XfaTemplateContainer from "./container/XfaTemplateContainer"
import styles from "./EledoXfaEditor.module.scss"
import { TVarType } from "featuresFed/fed_core/lang_vars/TVar"


export interface XFATemplateElementType {
  id: string
  type: string
  label: string | null
  expression: string | null
}

export interface XFATemplateContainerType extends XFATemplateElementType {
  type: "Container",
  isArray: boolean,
  items: XFATemplateElementType[]
}

export interface XFATemplateItemType extends XFATemplateElementType {
  type: "Item",
  valueType: TVarType,
  validation: string,
  options: string[]
}


const EledoXfaEditor = () => {
  const editedTemplate = useSelector((state: any) => state.templates.editedTemplate, shallowEqual)
  const dispatch = useDispatch()


  const onXfaTemplateTreeChange = (changedXfaTemplateTree: XFATemplateContainerType) => {
    const updatedTemplate = structuredClone(editedTemplate)
    const document = updatedTemplate.documents[0].innerDocument
    document.xfaTemplate = changedXfaTemplateTree
    dispatch(saveTemplate(updatedTemplate))
  }


  if (!editedTemplate?.documents[0]?.innerDocument?.xfaTemplate)
    return <EledoError title="We're sorry, We couldn't load the xfa template." description="Please contact Eledo Helpdesk." />
    
  return (
    <div className={styles.documentWrapper}>
      <XfaTemplateContainer container={editedTemplate?.documents[0]?.innerDocument?.xfaTemplate} isRoot onChange={(updatedTree) => onXfaTemplateTreeChange(updatedTree)} />
    </div>
  )
}


export default EledoXfaEditor