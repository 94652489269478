import { useState } from 'react'
import { EledoModal } from 'components/library'
import { EledoFormulaEditor } from './EledoFormulaEditor'
import { TVarType } from 'featuresFed/fed_core/lang_vars/TVar'
import { DataModel } from 'graphql/DataModelQueries'

export function useEledoFormulaEditorModal(dataModel?: DataModel) {

  const [visible, set_visible] = useState<boolean>(false)
  const [formula, set_formula] = useState<string>('')
  const [context, setContext] = useState<string>('')
  const [requiredType, setRequiredType] = useState<TVarType>('Any')
  const [onConfirm, setOnConfirm] = useState<Function | null>(null)

  function cancel() {
    set_visible(false)
  }

  function apply() {
//    console.log('EledoEditorRoot formula', formula)
    if (onConfirm)
      onConfirm(formula)
    set_visible(false)
  }
  function onClose() {
    set_visible(false)  // todo, needed?
  }

  function showFedModal(formula: string, context: string, requiredType: TVarType, onConfirm: (newFormula: string) => void) {
    // console.log("showFedModal formula:", formula, "context:", context, "requiredType:", requiredType)
    set_formula(formula)
    setContext(context)
    setRequiredType(requiredType)
    setOnConfirm(() => onConfirm)
    set_visible(true)
  }

  const renderEditor = (
    <>
      <div style={{ width: '900px', height: '400px' }}>
        <EledoFormulaEditor
          formula={formula}
          context={context}
          requiredType={requiredType}
          dataModel={dataModel}
          onChange={f => set_formula(f)}
          onConfirm={apply}
          onCancel={cancel}
        />
      </div>
    </>
  )

  const renderFedModal = () => (
    <EledoModal
      style={{ zIndex: 99999 }}
      content={renderEditor}
      displayed={visible}
      actions={<></>}
      onDismiss={onClose}
      topless={true}
    />
  )

  return { renderFedModal, showFedModal }
}
