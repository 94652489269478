import React from 'react';
import EledoPendingIcon from "./EledoPendingIcon";
import PropTypes from "prop-types";

const EledoPendingContent = ({state, content, pendingContent, showPendingIcon = true}) =>
  state ?
    <React.Fragment>{showPendingIcon && <EledoPendingIcon style={{marginRight: "0.75em"}}/>} {pendingContent} </React.Fragment> :
    <React.Fragment>{content}</React.Fragment>;
EledoPendingContent.propTypes = {
  state: PropTypes.bool.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  pendingContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  showPendingIcon: PropTypes.bool
}

export default EledoPendingContent;
