import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import spendingService from "_api/spendingService";
import utils from "utils";

const initialState = {
  selectedPage: 1,
  itemsTotal: 0,
  list: [],
  loading: false,
  error: null,
};

export const fetchPayments = createAsyncThunk(
  'payments/fetchPayments',
  async pageId => {
    const response = await spendingService.fetchPayments(pageId);
    return {selectedPage: pageId, ...response.data};
  }
);

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPayments.pending, state => {
      state.list = [];
      state.itemsTotal = 0;
      state.loading = true;
      state.error = null;
    })
    builder.addCase(fetchPayments.fulfilled, (state, action) => {
      state.list = action.payload.periods;
      state.itemsTotal = action.payload.total;
      state.selectedPage = utils.getSelectedPage(action.payload.selectedPage, action.payload.total);
      state.loading = false;
    });
    builder.addCase(fetchPayments.rejected, (state, action) => {
      state.list = [];
      state.loading = false;
      state.itemsTotal = 0;
      state.selectedPage = 1;
      state.error = action.error;
    });
  }
});

export default paymentsSlice.reducer;