import { XFATemplateElementType, XFATemplateContainerType, XFATemplateItemType } from "../EledoXfaEditor"

import XfaTemplateContainer from "../container/XfaTemplateContainer"
import XfaTemplateItem from "../item/XfaTemplateItem"
import styles from "./XfaTemplateElementNode.module.scss"


type XfaTemplateElementProps = {
  node: XFATemplateContainerType
  updateTree: (node: XFATemplateContainerType) => void
}


const XfaTemplateElementNode = (props: XfaTemplateElementProps) => {
  const updateLocalTree = (modifiedNode: XFATemplateElementType, idx: number) => {
    const newNode = structuredClone(props.node)
    newNode.items[idx] = modifiedNode

    props.updateTree(newNode)
  }


  return (
    <div className={styles.itemsContainer}>
      { props.node.items.map((item: XFATemplateElementType, itemIndex) => {
        if (item.type === "Container")
          return (
            <XfaTemplateContainer
              key={itemIndex}
              container={item as XFATemplateContainerType}
              onChange={(updatedItem) => updateLocalTree(updatedItem, itemIndex)}
            />
          )
        else if (item.type === "Item")
          return (
            <XfaTemplateItem
              key={itemIndex}
              item={item as XFATemplateItemType}
              onChange={(updatedItem) => updateLocalTree(updatedItem, itemIndex)}
            />
          )
        
        return null
      }) }
    </div>
  )
}


export default XfaTemplateElementNode