import { useEffect, useRef, ReactNode } from "react"
import classnames from "classnames"

import { Widget, Field, FieldState } from "../EledoPdfEditor"

import css from "./PdfDocumentPage.module.scss"


export type PdfEditorPage = {
  width: number
  height: number
  index: number
  thumbnailUrl: string
  widgets: Widget[]
  pdFields: object
}

type PdfDocumentPageProps = {
  page: PdfEditorPage
  pdFields: {[key: string]: Field}
  selectedField: Field | null,
  fieldState: FieldState
  isFocused: boolean
  isDrawing: boolean
  isDragging: boolean
  addCursor: boolean
  onStartDrawing: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, pageIdx: number) => void
  onEditField: (pageIdx: number, field: Field, widget: Widget) => void
  onScreenMajority: (pageDiv: HTMLDivElement | null) => void
  onMouseEnter: (pageDiv: HTMLDivElement | null) => void
  children: ReactNode
}


const PdfDocumentPage = (props: PdfDocumentPageProps) => {
  const allowScreenMajority = useRef<boolean>(true)
  const pageRef = useRef<HTMLDivElement | null>(null)


  useEffect(() => {
    if (!pageRef.current)
      return

    var observer = new IntersectionObserver(function(entries) {
      if (entries[0].isIntersecting === true && allowScreenMajority.current) {
        props.onScreenMajority(pageRef.current)
      }
    }, { threshold: [0.45] })
    
    observer.observe(pageRef.current)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    allowScreenMajority.current = !(props.fieldState === "drawing" || props.fieldState === "adjust-position")
  }, [props.fieldState])


  return (
    <div
      ref={pageRef}
      id={`document-page-${props.page.index}`}
      className={classnames(css.page, props.addCursor && css.addCursor, props.isDrawing && css.drawing)}
      style={{width: `${props.page.width}px`, height: `${props.page.height}px`}}
      onMouseDown={(event) => props.onStartDrawing(event, props.page.index)}
      onMouseEnter={(event) => props.onMouseEnter(event.currentTarget)}
    >
      { props.children }
      <div className={classnames(css.eventTrigger, props.isDragging && css.dragging)}></div>
      <img loading="lazy" alt={`Page ${props.page.index+1} background`} src={props.page.thumbnailUrl} />
      { props.page.widgets && props.page.widgets.map((widget, index) => {
        const field = Object.values(props.pdFields).find(field => field.id === widget.fieldId)
        if (!field)
          return (<></>)
                
        return (
          <div
            key={`${props.page.index}-${field.id}-${widget.idx}-${index}`}
            data-field-id={field.id}
            data-field-desc={field.desc}
            className={classnames(
              css.field,
              css[field.type],
              css["zoom" + widget.zoom],
              (props.fieldState === "adjust-position" && props.selectedField && props.selectedField.id === field.id) && css.hidden,
              props.isDragging && css.recDragging
            )}
            style={{
              top: `${widget.box.y}px`,
              left: `${widget.box.x}px`,
              width: `${widget.box.width}px`,
              height: `${widget.box.height}px`,
              lineHeight: `${widget.box.height}px`,
              fontFamily: widget.font || 'inherit',
              fontSize: widget.size ? widget.size + 'px' : 'inherit',
              color: widget.color || '#000',
              textAlign: field.align === 2
                ? 'right'
                : field.align === 1
                  ? 'center'
                  : 'left'
            }}
            onClick={() => props.onEditField(props.page.index, field, widget)}
          >{field.expression}</div>
        )
      })}
    </div>
  )
}


export default PdfDocumentPage