import classnames from "classnames"

import { XFATemplateContainerType } from "../EledoXfaEditor"
import { useEledoFormulaEditorModal } from "components/library/EledoFormulaEditor/useEledoFormulaEditorModal"

import XfaTemplateElementNode from "../element/XfaTemplateElementNode"
import styles from "./XfaTemplateContainer.module.scss"


type XfaTemplateContainerProps = {
  container: XFATemplateContainerType
  isRoot?: boolean
  onChange: (node: XFATemplateContainerType) => void
}


const XfaTemplateContainer = (props: XfaTemplateContainerProps) => {
  const { renderFedModal, showFedModal} = useEledoFormulaEditorModal()


  const onExpressionChange = (updatedExpression: string) => {
    props.onChange({ ...props.container, expression: updatedExpression })
  }

  
  return (
    <fieldset className={(classnames(styles.wrapper, props.isRoot && styles.rootWrapper))}>
      <legend>
        <label>{props.container.id} {props.container.isArray && <span style={{fontWeight: "normal", color: "gray"}}>(Repeatable)</span>}</label>
      </legend>
      { props.container.isArray &&
        <>Repeat by: <input
          className='w-input'
          defaultValue={props.container.expression || ""}
          onFocus={(event) => showFedModal(event.target.value, "", 'Array', (expression) => onExpressionChange(expression))}
          autoComplete="off"
          type="text"
        />
        </>
      }
      <XfaTemplateElementNode node={props.container} updateTree={props.onChange} />
    
      { renderFedModal() }
    </fieldset>
  )
}


export default XfaTemplateContainer