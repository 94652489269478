import { splitContext, useFedVariables } from '../../fed_core/lang_vars/useFedVariables'
import { TOption } from './utils/TOption'
import { TVar } from 'featuresFed/fed_core/lang_vars/TVar'


export function useAutocompleteOptions() {

  const { getVarChildren } = useFedVariables()

  function getVarOptions(ident: string): TOption[] {

    let arrV = _findVarOptions(ident)

    return arrV
  }


  function _findVarOptions(ident: string): TOption[] {

    // let parentPath = ident.split('.').slice(0, -1).join('.')  // its like split('.', -1)
    const {context, field} = splitContext(ident)
    // console.log('findVarOptions', ident, context, field)
    const srcVars = getVarChildren(context) || []
    // console.log('srcVars', srcVars)
    let arrV: TOption[] = addOptionsByFilter(srcVars, field, fnStartsWith)
    // console.log("arrV", arrV)
    return arrV
  }

  return {
    getVarOptions,
  }
}

export const fnStartsWith = (fnName: String, needle: string) => {
  if (needle[needle.length-1] === '(')
    fnName += '('
  return fnName.toLowerCase().startsWith(needle.toLowerCase())
}


export const addOptionsByFilter = (vars: TVar[], needle: string, fnMatch: (name: string, needle: string) => boolean): TOption[] => {
  const out: TOption[] = []
  vars && vars.forEach(v => {
    if (!fnMatch(v.name, needle))
      return
    const o: TOption = {
       ...v,
       outputType: v.type,
       info: ' (' + v.type + ')',
       identType: 'identType-' + v.type
    }
    out.push(o)
  })
  return out
}
