import { TVarType } from "featuresFed/fed_core/lang_vars/TVar"


export function doesParamFit(identType: string, currWantType: TVarType) {
  // console.log('doesParamFit identType=', identType, 'currParam=', currParam?.param?.type)
  // console.log('doesParamFit identType=', identType, 'currParam=', currParam)

  if (currWantType === null || currWantType === undefined)
    return true

  let paramType = currWantType
  if (paramType === 'Any')
    return true

  if (identType === paramType)
    return true

  return false
}
