import { EledoModal } from 'components/library';
import { CONFIRM_PUZZLE_REQUEST, ConfirmPuzzleRequestData, PuzzleRequestVars } from 'graphql/PuzzleRequests';
import React, { useContext, useEffect, useState } from 'react'
import usePuzzle from 'hooks/usePuzzle'
import { useMutation } from '@apollo/client';
import { useAppDispatch } from '_store';
import { removeRequest } from '_reducers/puzzleRequests';
import { AuthContext } from 'AuthContext';

function PuzzleModal() {

  const dispatch = useAppDispatch();
  const auth = useContext(AuthContext)
  const puzzleRequests = auth.requests
  const request = puzzleRequests && puzzleRequests[0];

  const [modalDisplayed, setModalDisplayed] = useState(false);
  // const [index, setIndex] = useState<number>(0);
  const [ confirmRequest ] = useMutation<ConfirmPuzzleRequestData, PuzzleRequestVars>(CONFIRM_PUZZLE_REQUEST, { ignoreResults: true });
  
  useEffect(() => {}, [confirmRequest])

  const handleNext = (result: number) => {

    if(request !== undefined){
      confirmRequest({variables: { id: request.id, result: result }})
        .then(() => {request.id && dispatch(removeRequest(request))})
    }

    // if(puzzleRequests.length > index){
    //   setIndex(index + 1);
    //   setModalDisplayed(true);
    // } else {
    //   setModalDisplayed(false);
    // }

    // if(puzzleRequests.length <= 1){
    //   setModalDisplayed(false);
    // }
  }

  const handleX = () => {
    handleNext(3);
  };

  const ModalContent = ({name, onLoaded}: {name: string, onLoaded: Function}) => {
    const { loading, renderPuzzle } = usePuzzle(name, (i: number) => handleNext(i === 1 ? 1 : 2), () => handleNext(0));

    useEffect(() => {
      if(!loading){
        onLoaded();
      }
    }, [onLoaded, loading, renderPuzzle])
    
    return (
      renderPuzzle()
    );
  }
  
  const modalActions = (
    <React.Fragment>
    </React.Fragment>
  );

  if(request !== undefined && request.structure?.name !== undefined){
    return (
      <EledoModal title={request.structure.title}
                  displayed={modalDisplayed}
                  actions={modalActions}
                  onDismiss={handleX}>
                    <ModalContent name={request.structure.name} onLoaded={() => { if(!modalDisplayed)setModalDisplayed(true)}}/>
      </EledoModal>
    )
  }

  return null;
}

export default PuzzleModal