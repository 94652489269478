import { useQuery } from '@apollo/client';
import { GET_PUZZLE_STRUCTURE, PuzzleStructure, PuzzleStructureData, PuzzleStructureVars } from 'graphql/PuzzleStructures';
import { useState, useEffect } from 'react'

function usePuzzleStructure({name}: PuzzleStructureVars) {
  const { loading, error, data } = useQuery<PuzzleStructureData, PuzzleStructureVars>(GET_PUZZLE_STRUCTURE, {variables:{name}});
  const [puzzleStructure, setPuzzleStructure] = useState<PuzzleStructure>();

  useEffect(() => {
    if(!data) return;

    const puzzleStructure: PuzzleStructure = {
      id: data.puzzleStructure.id,
      name: data.puzzleStructure.name,
      title: data.puzzleStructure.title,
      width: data.puzzleStructure.width,
      height: data.puzzleStructure.height,
      component: data.puzzleStructure.component
    }

    setPuzzleStructure(puzzleStructure);

  }, [data])

  return { loading, error, puzzleStructure }
}

export default usePuzzleStructure