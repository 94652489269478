import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import userService from "_api/userService";
import utils from "utils";
const _ = require('lodash');

const formStates = {userChanged: false, userValid: true};
const loadingStates = {loading: false, saving: false};
const savingStates = {error: null, savingError: null};
const accountTerminationStates = {
  status: null,
  loading: false,
  error: null,
  closeAccount: {
    loading: false,
    error: null,
  },
  cancelCloseAccount: {
    loading: false,
    error: null,
  }
};
const initialState = {
  user: undefined,
  editedUser: undefined,
  ...formStates,
  ...loadingStates,
  ...savingStates,
  termination: accountTerminationStates,
};

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (authParams, thunkApi) => {
    const response = await userService.fetchUser();
    return response.data;
  }
);

export const persistUser = createAsyncThunk(
  'user/persistUser',
  async user => {
    const response = await userService.persistUser(user);
    return response.data;
  }
);

export const wasCloseAccountRequested = createAsyncThunk(
  'user/wasCloseAccountRequested',
  async () => {
    const response = await userService.wasCloseAccountRequested();
    return response.data;
  }
);

export const closeAccount = createAsyncThunk(
  'user/closeAccount',
  async () => {
    const response = await userService.closeAccount();
    return response.data;
  }
);

export const cancelCloseAccount = createAsyncThunk(
  'user/cancelCloseAccount',
  async () => {
    const response = await userService.cancelCloseAccount();
    return response.data;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser: (state, action) => {
      state.editedUser = { ...state.editedUser, ...action.payload };
      state.editedUser.accounting = {
        ...state.editedUser.accounting,
        ...action.payload.accounting,
        billTo: {
          ...state.editedUser.accounting.billTo,
          ...action.payload.accounting.billTo,
        }
      };
      state.userChanged = !_.isEqual(state.user, state.editedUser);
    },
    saveUser_userValid: (state, action) => {
      state.userValid = action.payload;
      return state;
    }
  },
  extraReducers: builder => {
    builder
      //#region fetchUser
      .addCase(fetchUser.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        addEmptyPasswordFields(action.payload);
        state.user = action.payload;
        state.editedUser = utils.deepCopy(action.payload);
        state.userValid = true;
        state.userChanged = false;
        state.loading = false;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        const newState = {...initialState};
        newState.loading = false;
        newState.error = action.error;
        return newState;
      })
      //#endregion

      //region persistUser
      .addCase(persistUser.pending, (state, action) => {
        state.saving = true;
        state.savingError = null;
      })
      .addCase(persistUser.fulfilled, (state, action) => {
        addEmptyPasswordFields(action.payload);
        state.user = action.payload;
        state.editedUser = utils.deepCopy(action.payload);
        state.userValid = true;
        state.userChanged = false;
        state.saving = false;
      })
      .addCase(persistUser.rejected, (state, action) => {
        state.saving = false;
        state.savingError = action.error;
      })
      //#endregion

      //#region wasCloseAccountRequested
      .addCase(wasCloseAccountRequested.pending, (state, action) => {
        state.termination.loading = true;
        state.termination.error = null;
      })
      .addCase(wasCloseAccountRequested.fulfilled, (state, action) => {
        state.termination.status = action.payload.requested;
        state.termination.loading = false;
      })
      .addCase(wasCloseAccountRequested.rejected, (state, action) => {
        state.termination.loading = false;
        state.termination.error = action.error;
      })
      //#endregion

      //#region closeAccount
      .addCase(closeAccount.pending, (state, action) => {
        state.termination.closeAccount.loading = true;
        state.termination.closeAccount.error = null;
      })
      .addCase(closeAccount.fulfilled, (state, action) => {
        state.termination.status = true;
        state.termination.closeAccount.loading = false;
      })
      .addCase(closeAccount.rejected, (state, action) => {
        state.termination.closeAccount.loading = false;
        state.termination.closeAccount.error = action.error;
      })
    //#endregion

      //#region cancelCloseAccount
      .addCase(cancelCloseAccount.pending, (state, action) => {
        state.termination.cancelCloseAccount.loading = true;
        state.termination.cancelCloseAccount.error = null;
      })
      .addCase(cancelCloseAccount.fulfilled, (state, action) => {
        state.termination.status = false;
        state.termination.cancelCloseAccount.loading = false;
      })
      .addCase(cancelCloseAccount.rejected, (state, action) => {
        state.termination.cancelCloseAccount.loading = false;
        state.termination.cancelCloseAccount.error = action.error;
      })
    //#endregion
  }
});

const addEmptyPasswordFields = obj => {
  obj.newPassword = "";
  obj.oldPassword = "";
  obj.repeatPassword = "";
}

export const { saveUser, saveUser_userValid } = userSlice.actions;
export default userSlice.reducer;