import { useFedVariables } from '../lang_vars/useFedVariables'
import { TToken } from '../process_tokens/TToken'

// zatial to dam sem, ale chcel by som mat zvlast subor na to
export function useTokenClasses() {

  const {getVarType} = useFedVariables()

  function getTokenClasses(t: TToken): string {
    let className = 'tokenType-' + t.type

    if (t.type === 'Variable') {
      className += ' varType-' + getVarType(t.text)
    } 
  
    return className
  }
  
  return {getTokenClasses}
}
