import eledo from "_api/eledo";

const LOGS_URL = '/logs?page=';
const LOG_DETAIL_URL = '/logDetail/'

const fetchLogs = pageId => eledo.get(LOGS_URL + pageId);
const fetchLogDetail = logId => eledo.get(LOG_DETAIL_URL + logId);

const logsService = {
  fetchLogs, fetchLogDetail
};
export default logsService;