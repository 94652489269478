import classnames from "classnames"

import { GenerationLog } from "graphql/GenerationLogQueries"

import { EledoLoader } from "components/library"
import LogTableLine from "../LogsTableLine/LogsTableLine"
import css from "./LogsTable.module.scss"


type LogsTableProps = {
  logs: GenerationLog[]
  loading: boolean
  onLogLineClicked: (logId: string) => void
}


const LogsTable = (props: LogsTableProps) => {
  return (
    <div className='container'>
      <div className={classnames('w-layout-grid', 'header-grid', css.headerGrid)}>
        <div className='_wf-table-cell'>Date</div>
        <div className='_wf-table-cell'>Type</div>
        <div className='_wf-table-cell'>Level</div>
        <div className='_wf-table-cell'>Template</div>
        <div className='_wf-table-cell'>Documents / Pages</div>
        <div className='_wf-table-cell'>Took Time</div>
      </div>
      { props.loading
        ? <div className='flex-center'><EledoLoader/></div>
        : props.logs.length === 0
          ? <div className='flex-center my-section'>You have no logs yet.</div>
          : props.logs.map(log => 
              <LogTableLine key={log.id} log={log} onLogLineClicked={props.onLogLineClicked} />
            )
      }
    </div>
  )
}


export default LogsTable