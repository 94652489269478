import TagManager from "react-gtm-module";

export type TrackerUser = {
  id: string | null
  level: number
}

const init = () => {
  TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID || "",
      dataLayer: [ 'config', process.env.REACT_APP_GTM_ID, {
        cookie_flags: 'SameSite=None;Secure;Partitioned'
      }]
    });
}

const pageView = (path: string, user: TrackerUser) => {
  // console.log("pageView", path, user)
  if(path === undefined){
    return;
  }

  // const oldPush = window.dataLayer.push
  // window.dataLayer.push = function() {
  //   const states = [].slice.call(arguments, 0)
    
  //   const value = oldPush.apply(window.dataLayer, states)
    
  //   console.log(window.dataLayer)
    
  //   return value
  // }

  TagManager.dataLayer({
    dataLayer: {
      event: "page",
      page:{ path: path, platform: process.env.REACT_APP_PLATFORM, type: mapPathToPageType(path)},
      user: user,
      action: null
    }
  })

  // window.dataLayer.push = oldPush
}

const event = (event: string, user: TrackerUser, action: Object | null) => {
  TagManager.dataLayer({
    dataLayer: {
      event: event,
      page: { platform: process.env.REACT_APP_PLATFORM},
      user: user,
      action: action
    }
  })
}

const mapPathToPageType = (path: string) => {
  if(path === undefined || path === null){
    return "home";
  }

  if(path.startsWith("/templates")){
    return "template.list";
  }
  if(path.startsWith("/template/")){
    return "template.editor";
  }
  if(path.startsWith("/connections")){
    return "connections";
  }
  if(path.startsWith("/logs")){
    return "logs";
  }
  if(path.startsWith("/documentation")){
    return "documentation";
  }
  if(path.startsWith("/helpdesk")){
    return "helpdesk";
  }
  if(path.startsWith("/login")){
    return "account.login";
  }
  if(path.startsWith("/spending")){
    return "account.spending";
  }
  if(path.startsWith("/user")){
    return "account.profile";
  }
  if(path.startsWith("/pricing")){
    return "pricing";
  }
  return "other";
}

export const Tracker = { init, pageView, event }