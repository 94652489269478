
import { useContext } from 'react'

import { FedContext } from '../../fed_plugins/_context/FedContext'

import { TVar, TVarType } from './TVar'
import { DataModelField } from 'graphql/DataModelQueries'

export function useFedVariables() {

    const config = useContext(FedContext)

    const dataModel = config.dataModel

    const getVarTree = (context: string, path: string, search: string): TVar[] => {

      const contextParts = context.split('.')
      const uniques = new Set<String | undefined>()

      let root = dataModel?.classes.find((c) => c.id === dataModel.classId)
      uniques.add(root?.id)

      // Find 'root' based on the context
      contextParts.map(s => {

        const field = root?.fields.find((f) => f.id === s);
        if(field === undefined)
          return undefined
        root = dataModel?.classes.find((c) => c.id === field?.relClass)

        //this is to prevent loops in the data model tree, any loop is terminated here
        if(setContains(uniques, root?.id)){
          return
        }

        uniques.add(root?.id)
        return field
      })

      if(root === undefined){
        return []
      }

      // if the path is empty, return context's root level or search result
      if(path?.length === 0){
        if(search.length > 0){
          return fulltextSearch(root.fields, search)
        }
        return root.fields.map(f => ({
          name: f.id,
          type: f.type,
          title: f.name
        } as TVar))
      }

      // find fields based on the 'path'
      const pathParts = path.split('.')
      const pathFields = pathParts.map(s => {

        const field = root?.fields.find((f) => f.id === s);
        if(field === undefined)
          return undefined
        root = dataModel?.classes.find((c) => c.id === field?.relClass)

        //this is to prevent loops in the data model tree, any loop is terminated here
        if(setContains(uniques, root?.id)){
          return
        }

        uniques.add(root?.id)
        return field
      })

      const pathRemainder = pathFields[pathFields.length - 1] === undefined ? pathParts[pathParts.length - 1] : undefined

      let tree = [] as TVar[]
      // at the lowest level we either search or displays fields starting with 'pathRemainder'
      if(root){
        if(search.length > 0){
          tree = fulltextSearch(root.fields, search)
        } else {
          tree = root.fields.filter(f => pathRemainder === undefined || f.id.startsWith(pathRemainder))
          .map(f => ({
            name: f.id,
            type: f.type,
            title: f.name
          } as TVar))
        }
      }

      // going backward of 'result2' we build up a full TVar tree containing lowest level result and all the nodes up the 'path'
      for(let i = pathFields.length - 1;i >= 0;i--){
        const field = pathFields[i];
        if(field){
          const node = {
            name: field.id,
            type: field.type,
            title: field.name,
            children: tree || [] as TVar[]
          } as TVar
          tree = [node]
        }
      }

      return tree
    }

    const setContains = (s: Set<String | undefined>, text: String | undefined): boolean  => {
      const sa = Array.from(s);
      for (let i = 0; i < sa.length; i++) {
        const value = sa[i]
        if(value === text)
          return true
      }

      return false
    }

    const fulltextSearch = (fields: [DataModelField], search: string): TVar[] => {
      const uniques = new Set<String>()
      return fulltextSearchInternal(uniques, { count: 0}, fields, search)
    }

    const fulltextSearchInternal = (uniques: Set<String>, count: {count: number}, fields: [DataModelField], search: string): TVar[] => {
      const result = [] as TVar[]

      for(let i = 0;i < fields.length; i++){
        const field = fields[i]

        if(field.relClass === undefined || field.relClass === null){
          if(field.id.toLowerCase().indexOf(search) >= 0 || field.name.toLowerCase().indexOf(search) >= 0){
            result.push({
              name: field.id,
              type: field.type,
              title: field.name
            } as TVar)
            count.count++
          }
        } else {
          const c = dataModel?.classes.find((c) => c.id === field?.relClass)
          if(c){
            //this is to prevent loops in the data model tree, any loop is terminated here
            if(!setContains(uniques, c.id)){
              const uniques2 = new Set(uniques)
              uniques2.add(c.id)
              const sub = fulltextSearchInternal(uniques2, count, c.fields, search)
              if(sub.length > 0){
                result.push({
                  name: field.id,
                  type: field.type,
                  title: field.name,
                  children: sub
                } as TVar)
                count.count++
              }
            }
          }
        }
        if(count.count > 100)
          break
      }
      
      return result
    }

    function findField(path: string): DataModelField | undefined {
      if(dataModel === undefined)
        return

      const splits = path.split('.')
      const uniques = new Set<String | undefined>()
      let root = dataModel?.classes.find((c) => c.id === dataModel.classId)
      
      uniques.add(root?.id)
      
      const result = splits.map(s => {

        const field = root?.fields.find((f) => f.id === s);
        if(field === undefined)
          return undefined
        root = dataModel?.classes.find((c) => c.id === field?.relClass)

        //this is to prevent loops in the data model tree, any loop is terminated here
        if(setContains(uniques, root?.id)){
          return
        }

        uniques.add(root?.id)
        return field
      })

      const field = result[result.length - 1]

      return field
    }

    function getVar(path: string): TVar | undefined {
      const field = findField(path)
      
      if(field === undefined)
        return

      return {
        name: field.id,
        type: field.type,
        title: field.name
      } as TVar
    }

    // empty is root
    function getVarChildren(path: string): TVar[] | null {
      if(path === ""){
        const root = dataModel?.classes.find((c) => c.id === dataModel.classId)
        return root?.fields.map(f => ({
          name: f.id,
          type: f.type,
          title: f.name
        } as TVar)) || null
      }
      
      const field = findField(path)

      if(field === undefined)
        return null

      const c = dataModel?.classes.find((c) => c.id === field?.relClass)

      return c?.fields.map(f => ({
        name: f.id,
        type: f.type,
        title: f.name
      } as TVar)) || null
    }

    function getVarType(path: string): TVarType {
      let v = getVar(path)
      if (!v)
        return '?'

      return v.type
    }

    return { getVar, getVarChildren, getVarType, getVarTree }
}

export const splitContext = (s: string):{context: string, field: string} => {
  const splits = s.split('.')
  const context = splits.slice(0, splits.length - 1).join('.')
  const field = splits[splits.length - 1]
  
  return {context, field}
}


