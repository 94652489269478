import utils from "utils";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import templateService from "_api/templateService";
const _ = require('lodash');

const formStates = {templateChanged: false, templateSettingsValid: true, templateSecurityValid: true, templateStylesValid: true, fileNameFormatValid: true};
const loadingStates = {templatesLoading: true, templateLoading: true, error: null};
const savingStates = {saving: false, savingError: null};
const createStates = {creating: false, creatingError: null};
const copyStates = {copying: false, copyingError: null};
const deleteStates = {deleting: false, deletingError: null};
const connectingStates = {connecting: false, connectingError: null};
const initialTemplateState = {
  id: null,
  slug: null,
  keywords: null,
  name: null,
  description: null,
  locale: null,
  timezone: null,
  type: -1,
  fileNameFormat: null,
  bulk: false,
  embed: false,
  documents: [
    {
      innerDocument: {
        type: 10,
        content: null,
        bgContent: null,
        style: null,
        bgStyle: null,
        documentFormat: null,
        fmt: null,
        renderConfig: {
          protection: {
            "userPwd": null,
            "ownerPwd": null,
            "canPrint": true,
            "canModify": true,
            "canExtractContent": true,
            "canModifyAnnotations": true,
            "canFillInForm": true,
            "canExtractForAccessibility": true,
            "canAssembleDocument": true,
            "canPrintDegraded": true
          }
        },
        renderCondition: null
      }
    }
  ],
  actions: [],
  fields: {},
  webhook: null,
  meta: null,
  derived: {
    variables: [],
    allFields: []
  },
  version: -1,
  realVersion: -1,
};
const initialState = {
  selectedTemplate: initialTemplateState,
  private: {
    selectedPage: 1,
    total: 0,
    list: [],
  },
  public: {
    selectedPage: 1,
    total: 0,
    list: [],
  },
  editedTemplate: utils.deepCopy(initialTemplateState),
  activePageId: 0,
  ...formStates,
  ...loadingStates,
  ...savingStates,
  ...createStates,
  ...copyStates,
  ...deleteStates,
  ...connectingStates
};

export const fetchTemplate = createAsyncThunk(
  'templates/fetchTemplate',
  async (templateId, { rejectWithValue }) => {
    return templateService.fetchTemplate(templateId)
      .then(response => response.data)
      .catch(error => rejectWithValue({data: error?.data, status: error?.status}))
  }
);

export const fetchPrivateTemplates = createAsyncThunk(
  'templates/fetchPrivateTemplates',
  async (data, { rejectWithValue }) => {
    const {pageId, connectionId} = data;
    return templateService.fetchPrivateTemplates(pageId, connectionId)
        .then(response => ({ selectedPage: pageId, ...response.data}))
        .catch(error => rejectWithValue({data: error?.data, status: error?.status}))
  }
);

export const fetchPublicTemplates = createAsyncThunk(
  'templates/fetchPublicTemplates',
  async (data, { rejectWithValue}) => {
    const {pageId, platform} = data;
    return templateService.fetchPublicTemplates(pageId, platform)
      .then(response => ({ selectedPage: pageId, ...response.data}))
      .catch(error => rejectWithValue({data: error?.data, status: error?.status}))
  }
);

export const fetchAllTemplates = createAsyncThunk(
  'templates/fetchAllTemplates',
  async ({ rejectWithValue }) => {
    return templateService.fetchAllTemplates()
      .then(response => response.data.templates)
      .catch(error => rejectWithValue({data: error?.data, status: error?.status}))
  }
);

export const createTemplate = createAsyncThunk(
  'templates/createTemplate',
  async (formValues, { rejectWithValue }) => {
    return templateService.createTemplate(formValues)
      .then(response => response.data)
      .catch(error => rejectWithValue({data: error?.data, status: error?.status}))
  }
);

export const createPdfTemplate = createAsyncThunk(
  'templates/createPdfTemplate',
  async (formValues, { rejectWithValue }) => {
    return templateService.createPdfTemplate(formValues)
      .then(response => response.data)
      .catch(error => rejectWithValue({data: error?.data, status: error?.status}))
  }
);

export const persistTemplate = createAsyncThunk(
  'templates/persistTemplate',
  async (template, { rejectWithValue }) => {
    const templateCopy = { ...template }
    delete templateCopy.derived;
    return templateService.persistTemplate(templateCopy)
      .then(response => response.data)
      .catch(error => rejectWithValue({data: error?.data, status: error?.status}))
  }
);

export const copyTemplate = createAsyncThunk(
  'templates/copyTemplate',
  async (templateId, { rejectWithValue }) => {
    return templateService.copyTemplate(templateId)
      .then(response => ({data: response.data, templateId}))
      .catch(error => rejectWithValue({data: error?.data, status: error?.status}))
  }
);

export const deleteTemplate = createAsyncThunk(
  'templates/deleteTemplate',
  async (templateId, { rejectWithValue }) => {
    return templateService.deleteTemplate(templateId)
      .then(response => ({data: response.data, templateId}))
      .catch(error => rejectWithValue({data: error?.data, status: error?.status}))
  }
);

export const connectTemplate = createAsyncThunk(
  'templates/connectTemplate',
  async (data, { rejectWithValue }) => {
    const {templateId, platform, connectionId, structureId} = data;
    return templateService.connectTemplate(templateId, platform, connectionId, structureId)
      .then(response => response.data)
      .catch(error => rejectWithValue({data: error?.data, status: error?.status}))
  }
);

export const versionControlTemplate = createAsyncThunk(
  'templates/versionControlTemplate',
  async (data, { rejectWithValue }) => {
    const {templateId, command} = data;
    return templateService.versionControlTemplate(templateId, command)
      .then(response => response.data)
      .catch(error => rejectWithValue({data: error?.data, status: error?.status}))
  }
);

const templateSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    addTemplatePage: state => {
      const pageCopy = utils.deepCopy(state.editedTemplate.documents[state.activePageId]);
      pageCopy.innerDocument.content = null;
      state.editedTemplate.documents = utils.insert(state.editedTemplate.documents, state.activePageId + 1, pageCopy);
      state.templateChanged = !_.isEqual(state.selectedTemplate, state.editedTemplate);
      state.activePageId += 1;
    },
    deleteTemplatePage: state => {
      const newPageId = (state.activePageId === state.editedTemplate.documents.length - 1) ? (state.activePageId - 1) : state.activePageId;
      state.editedTemplate.documents = state.editedTemplate.documents.filter((e, i) => i !== state.activePageId);
      state.activePageId = newPageId;
      state.templateChanged = !_.isEqual(state.selectedTemplate, state.editedTemplate);
    },
    saveTemplate: (state, action) => {
      state.editedTemplate = utils.deepCopy(action.payload);
      state.templateChanged = !_.isEqual(state.selectedTemplate, action.payload);
    },
    saveTemplateStyle: (state, action) => {
      let templateCopy = utils.deepCopy(state.editedTemplate);
      templateCopy.documents[state.activePageId].innerDocument.style = action.payload;
      state.editedTemplate = templateCopy ? templateCopy : state.editedTemplate;
      state.templateChanged = !_.isEqual(state.selectedTemplate, templateCopy);
    },
    saveTemplateSettingsChanged: (state, action) => {
      const settings = action.payload;

      const editedTemplate = {
          ...utils.deepCopy(state.editedTemplate),
          name: settings.name,
          fileNameFormat: settings.fileNameFormat,
          locale: settings.locale,
          timezone: settings.timezone,
          description: settings.description,
          embed: settings.embed,
          webhook: settings.webhook,
        };

        const page = state.activePageId;
        if(settings.flatten !== undefined){
          editedTemplate.documents[page].innerDocument.renderConfig.flatten = settings.flatten;
          editedTemplate.documents[page].innerDocument.renderConfig.direction = settings.direction;
        }

        if(settings.formatId){
          editedTemplate.documents[page].innerDocument.format.id = settings.formatId;
          editedTemplate.documents[page].innerDocument.format.pixelWidth = settings.pixelWidth;
          editedTemplate.documents[page].innerDocument.format.pixelHeight = settings.pixelHeight;
        }

      state.editedTemplate = editedTemplate;
      state.templateChanged = !_.isEqual(state.selectedTemplate, state.editedTemplate);
    },
    saveTemplateSettingsValid: (state, action) => {
      state.templateSettingsValid = action.payload;
    },
    saveTemplateSecurityChanged: (state, action) => {
      const editedTemplate = { ...state.editedTemplate };
      editedTemplate.documents.forEach(d => d.innerDocument.renderConfig.protection = action.payload);
      state.editedTemplate = editedTemplate;
      state.templateChanged = !_.isEqual(state.selectedTemplate, state.editedTemplate);
    },
    saveTemplateSecurityValid: (state, action) => {
      state.templateSecurityValid = action.payload;
    },
    saveTemplateStylesValid: (state, action) => {
      state.templateStylesValid = action.payload;
    },
    setActivePageId: (state, action) => {
      state.activePageId = action.payload;
    },
    resetTemplate: (state, action) => {
      state.selectedTemplate = utils.deepCopy(initialTemplateState);
      state.editedTemplate = utils.deepCopy(initialTemplateState);
    }
  },
  extraReducers: builder => {
    builder

      //#region fetchTemplate
      .addCase(fetchTemplate.pending, state => {
        state.templateLoading = true;
        state.error = null;
      })
      .addCase(fetchTemplate.fulfilled, (state, action) => {
        setTemplateToState(state, action.payload);
        state.activePageId = 0;
        state.templateLoading = false;
        state.error = action?.payload?.error;
        state.savingError = null;
      })
      .addCase(fetchTemplate.rejected, (state, action) => {
        state.template = initialTemplateState;
        state.templateLoading = false;
        state.error = action?.payload?.data?.error;
      })
      //#endregion

      //#region fetchPrivateTemplates
      .addCase(fetchPrivateTemplates.pending, state => {
        state.private.list = [];
        //state.private.total = 0;
        state.templatesLoading = true;
        state.error = null;
      })
      .addCase(fetchPrivateTemplates.fulfilled, (state, action) => {
        state.private.list = [...action.payload.templates];
        state.private.total = action.payload.total;
        state.private.selectedPage = utils.getSelectedPage(action.payload.selectedPage, action.payload.total);
        state.templatesLoading = false;
      })
      .addCase(fetchPrivateTemplates.rejected, (state, action) => {
        state.templatesLoading = false;
        state.private.total = 0;
        state.private.selectedPage = 1;
        state.error = action?.payload?.error;
      })
      //#endregion

      //#region fetchPublicTemplates
      .addCase(fetchPublicTemplates.pending, state => {
        state.public.list = [];
        //state.public.total = 0;
        state.templatesLoading = true;
        state.error = null;
      })
      .addCase(fetchPublicTemplates.fulfilled, (state, action) => {
        state.public.list = [...action.payload.templates];
        state.public.total = action.payload.total;
        state.public.selectedPage = utils.getSelectedPage(action.payload.selectedPage, action.payload.total);
        state.templatesLoading = false;
      })
      .addCase(fetchPublicTemplates.rejected, (state, action) => {
        state.templatesLoading = false;
        state.templatesTotal = 0;
        state.selectedPage = 1;
        state.error = action?.payload?.error;
      })
      //#endregion

      //#region fetchAllTemplates
      .addCase(createTemplate.pending, state => {
        state.creating = true;
        state.creatingError = null;
      })
      .addCase(createTemplate.fulfilled, (state, action) => {
        setTemplateToState(state, action.payload);
        state.activePageId = 0;
        state.creating = false;
        state.creatingError = null;
      })
      .addCase(createTemplate.rejected, (state, action) => {
        state.creating = false;
        state.creatingError = action?.payload?.data?.error;
      })
      //#endregion

      //#region createTemplate
      .addCase(fetchAllTemplates.pending, (state, action) => {
        state.public.list = [];
        state.private.list = [];
        state.templatesLoading = true;
        state.error = null;
      })
      .addCase(fetchAllTemplates.fulfilled, (state, action) => {
        state.private.list = action.payload.slice(0, action.payload.findIndex((e) => e.id === null));
        state.public.list = action.payload.slice(action.payload.findIndex((e) => e.id === null) + 1, action.payload.length);
      })
      .addCase(fetchAllTemplates.rejected, (state, action) => {
        state.templatesLoading = false;
        state.error = action?.payload?.error;
      })
      //#endregion

      //#region createPdfTemplate
      .addCase(createPdfTemplate.pending, state => {
        state.creating = true;
        state.creatingError = null;
      })
      .addCase(createPdfTemplate.fulfilled, (state, action) => {
        setTemplateToState(state, action.payload);
        state.activePageId = 0;
        state.creating = false;
        state.creatingError = null;
      })
      .addCase(createPdfTemplate.rejected, (state, action) => {
        state.creating = false;
        state.creatingError = action?.payload?.data?.error;
      })
      //#endregion

      //#region persistTemplate
      .addCase(persistTemplate.pending, state => {
        state.saving = true;
        state.savingError = null;
      })
      .addCase(persistTemplate.fulfilled, (state, action) => {
        state.saving = false;
        state.savingError = action?.payload?.error;
        if(state.savingError){
          setTemplateToState(state, action.payload.template);
        }else{
          setTemplateToState(state, action.payload);
        }
      })
      .addCase(persistTemplate.rejected, (state, action) => {
        state.saving = false;
        state.savingError = action?.payload?.data?.error;
      })
      //#endregion

      //#region copyTemplate
      .addCase(copyTemplate.pending, state => {
        state.copying = true;
        state.copyingError = null;
      })
      .addCase(copyTemplate.fulfilled, (state, action) => {
        state.copying = false;
        setTemplateToState(state, action.payload);
      })
      .addCase(copyTemplate.rejected, (state, action) => {
        state.copying = false;
        state.copyingError = action?.payload?.data?.error;
      })
      //#endregion

      //#region deleteTemplate
      .addCase(deleteTemplate.pending, state => {
        state.deleting = true;
        state.deletingError = null;
      })
      .addCase(deleteTemplate.fulfilled, (state, action) => {
        state.deleting = false;
        if(action.payload) {
          state.private.list = state.private.list.filter(t => t.id !== action.payload.templateId);
        }
      })
      .addCase(deleteTemplate.rejected, (state, action) => {
        state.deleting = false;
        state.deletingError = action?.payload?.data?.error;
      })
      //#endregion

      //#region connectTemplate
      .addCase(connectTemplate.pending, state => {
        state.templateLoading = true;
        state.savingError = null;
      })
      .addCase(connectTemplate.fulfilled, (state, action) => {
        setTemplateToState(state, action.payload);
        state.activePageId = 0;
        state.templateLoading = false;
      })
      .addCase(connectTemplate.rejected, (state, action) => {
        state.templateLoading = false;
        state.savingError = action?.payload?.data?.error;
        state.error = action?.payload?.data?.error;
      })
      //#endregion

      //#region versionControlTemplate
      .addCase(versionControlTemplate.pending, state => {
        state.saving = true;
        state.savingError = null;
      })
      .addCase(versionControlTemplate.fulfilled, (state, action) => {
        state.saving = false;
        state.savingError = action?.payload?.error;
        if(state.savingError){
          setTemplateToState(state, action.payload.template);
        }else{
          setTemplateToState(state, action.payload);
        }
      })
      .addCase(versionControlTemplate.rejected, (state, action) => {
        state.saving = false;
        state.savingError = action?.payload?.data?.error;
      })
      //#endregion
  },
});

const setTemplateToState = (state, template) => {
  state.selectedTemplate = utils.deepCopy(template);
  state.editedTemplate = utils.deepCopy(template);
  state.templateChanged = false;
  state.templateSettingsValid = true;
  state.fileNameFormatValid = true;
  state.templateSecurityValid = true;
  state.templateStylesValid = true;
};

export const {
  addTemplatePage,
  deleteTemplatePage,
  saveTemplate,
  saveTemplateStyle,
  saveTemplateSettingsChanged,
  saveTemplateSettingsValid,
  saveTemplateSecurityChanged,
  saveTemplateSecurityValid,
  saveTemplateStylesValid,
  setActivePageId,
  resetTemplate,
} = templateSlice.actions;
export default templateSlice.reducer;
