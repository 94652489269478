import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import structuresService from "_api/structuresService";

const loadingStates = {listLoading: true, error: null};
const initialState = {
  list: [],
  ...loadingStates
};

export const fetchStructures = createAsyncThunk(
  'structures/fetchStructures',
  async () => {
    const response = await structuresService.fetchStructures();
    return response.data.structures;
  }
);

const structuresSlice = createSlice({
  name: 'structures',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchStructures.pending, state => {
        state.list = [];
        state.listLoading = true;
        state.error = null;
      })
      .addCase(fetchStructures.fulfilled, (state, action) => {
        state.list = action.payload;
        state.listLoading = false;
      })
      .addCase(fetchStructures.rejected, (state, action) => {
        state.list = [];
        state.listLoading = false;
        state.error = action.error;
      })
  }
});

export default structuresSlice.reducer;