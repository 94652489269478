export const ITEMS_PER_PAGE = 20;

export const EMAIL_VALIDATION_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index)
];

const deepCopy = obj => {
  return JSON.parse(JSON.stringify(obj));
}

export const notyfSuccess = (notyf, message) => {
  notyf.success({
    position: {x: 'right', y: 'top'},
    message: message,
    dismissible: true,
  });
};

export const notyfError = (notyf, message) => {
  notyf.error({
    position: {x: 'right', y: 'top'},
    message: message,
    dismissible: true,
  });
};

const getSelectedPage = (page, total) => {
  if(!page || Number.isNaN(page) || page > Math.ceil(total / ITEMS_PER_PAGE)) {
    return 1;
  }
  return page;
};

const formatNumber = (number, decimalPlaces) => {
  return (Math.round(number * 100) / 100).toFixed(decimalPlaces);
};

const objectIdToDate = (objectId) => {
  const timeStamp = parseInt(objectId.substr(0,8), 16)*1000
  const date = new Date(timeStamp)
  const formattedDate = formatDate(date)
  return formattedDate
}

const formatDate = (date) => {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  const time = getTimeFromDate(date)

  return `${day}.${month}.${year} - ${time}`
}

const getTimeFromDate = (date) => {
  function addZero(i) {
    if (i < 10) {i = "0" + i}
    return i
  }
  
  const h = addZero(date.getHours())
  const m = addZero(date.getMinutes())
  const s = addZero(date.getSeconds())
  const time = h + ":" + m + ":" + s
  return time
}

const utils = {
  ITEMS_PER_PAGE,
  insert,
  deepCopy,
  notyfSuccess,
  notyfError,
  getSelectedPage,
  formatNumber,
  objectIdToDate
};
export default utils;