import { TFunc } from './TFunc'

export const _funcs: TFunc[] = [
  {
    name: 'NOT',
    returnType: 'Boolean',
    desc: '',
    baseParams: 1,
    optionalParams: null,
    params: [
      { name: 'B', type: 'Boolean' }
    ],
    category: 'Logical',
    doc: ''
  },
  {
    name: 'AND',
    returnType: 'Boolean',
    desc: '',
    baseParams: 2,
    optionalParams: 'n',
    params: [
      { name: 'B1', type: 'Boolean' },
      { name: 'B2', type: 'Boolean' },
      { name: 'Bn', type: 'Boolean', optional: true }
    ],
    category: 'Logical',
    doc: ''
  },
  {
    name: 'OR',
    returnType: 'Boolean',
    desc: '',
    baseParams: 2,
    optionalParams: 'n',
    params: [
      { name: 'B1', type: 'Boolean' },
      { name: 'B2', type: 'Boolean' },
      { name: 'Bn', type: 'Boolean', optional: true }
    ],
    category: 'Logical',
    doc: ''
  },
  {
    name: 'IF',
    returnType: 'Any',
    desc: '',
    baseParams: 3,
    optionalParams: null,
    params: [
      { name: 'C', type: 'Boolean' },
      { name: 'T', type: 'Any', returnType: true },
      { name: 'F', type: 'Number', returnType: true }     // pozor, upravene na testing..
    ],
    category: 'General',
    doc: ''
  },
  {
    name: 'SWITCH',
    returnType: 'Any',
    desc: '',
    baseParams: 3,
    optionalParams: '2n',
    params: [
      { name: 'C', type: 'Boolean' },
      { name: 'K1', type: 'Any', returnType: true },
      { name: 'V1', type: 'Any', returnType: true },
      { name: 'Kn', type: 'Any', returnType: true, optional: true },
      { name: 'Vn', type: 'Any', returnType: true, optional: true }
    ],
    category: 'General',
    doc: ''
  },
  {
    name: 'ABS',
    returnType: 'Number',
    desc: '',
    baseParams: 1,
    optionalParams: null,
    params: [
      { name: 'N', type: 'Number' }
    ],
    category: 'Numeric',
    doc: ''
  },
  {
    name: 'MAX',
    returnType: 'Number',
    desc: '',
    baseParams: 1,
    optionalParams: 'n',
    params: [
      { name: 'N1', type: 'Number' },
      { name: 'Nn', type: 'Number', optional: true }
    ],
    category: 'Numeric',
    doc: ''
  },
  {
    name: 'MIN',
    returnType: 'Number',
    desc: '',
    baseParams: 1,
    optionalParams: 'n',
    params: [
      { name: 'N1', type: 'Number' },
      { name: 'Nn', type: 'Number', optional: true }
    ],
    category: 'Numeric',
    doc: ''
  },
  {
    name: 'SIZE',
    returnType: 'Number',
    desc: '',
    baseParams: 1,
    optionalParams: null,
    params: [
      { name: 'N1', type: 'Array' }
    ],
    category: 'Array',
    doc: ''
  },
  {
    name: 'SUM',
    returnType: 'Number',
    desc: '',
    baseParams: 2,
    optionalParams: null,
    params: [
      { name: 'A', type: 'Array' },
      { name: 'N', type: 'Number' }
    ],
    category: 'Array',
    doc: ''
  },
  {
    name: 'FOR',
    returnType: 'Array',
    desc: '',
    baseParams: 2,
    optionalParams: 1,
    params: [
      { name: 'From', type: 'Number' },
      { name: 'To', type: 'Number' },
      { name: 'Step', type: 'Number', optional: true }
    ],
    category: 'General',
    doc: ''
  },
  {
    name: 'NEXTCOUNTER',
    returnType: 'Number',
    desc: '',
    baseParams: 1,
    optionalParams: null,
    params: [
      { name: 'Counter', type: 'String' }
    ],
    category: 'General',
    doc: ''
  },
  {
    name: 'NUM',
    returnType: 'String',
    desc: '',
    baseParams: 1,
    optionalParams: 2,
    params: [
      { name: 'N', type: 'Number' },
      { name: 'Z', type: 'Number' },
      { name: 'D', type: 'Number' }
    ],
    category: 'Numeric',
    doc: ''
  },
  {
    name: 'CURR',
    returnType: 'String',
    desc: '',
    baseParams: 1,
    optionalParams: 2,
    params: [
      { name: 'N', type: 'Number' },
      { name: 'Z', type: 'Number' },
      { name: 'D', type: 'Number' }
    ],
    category: 'Numeric',
    doc: ''
  },
  {
    name: 'ROUND',
    returnType: 'Number',
    desc: 'Round half up',
    baseParams: 2,
    optionalParams: null,
    params: [
      { name: 'N', type: 'Number' },
      { name: 'D', type: 'Number' }
    ],
    category: 'Numeric',
    doc: ''
  },
  {
    name: 'ROUNDUP',
    returnType: 'Number',
    desc: 'Round up',
    baseParams: 2,
    optionalParams: null,
    params: [
      { name: 'N', type: 'Number' },
      { name: 'D', type: 'Number' }
    ],
    category: 'Numeric',
    doc: ''
  },
  {
    name: 'ROUNDDOWN',
    returnType: 'Number',
    desc: 'Round down',
    baseParams: 2,
    optionalParams: null,
    params: [
      { name: 'N', type: 'Number' },
      { name: 'D', type: 'Number' }
    ],
    category: 'Numeric',
    doc: ''
  },
  {
    name: 'CEILING',
    returnType: 'Number',
    desc: '',
    baseParams: 1,
    optionalParams: null,
    params: [
      { name: 'N', type: 'Number' }
    ],
    category: 'Numeric',
    doc: ''
  },
  {
    name: 'FLOOR',
    returnType: 'Number',
    desc: '',
    baseParams: 1,
    optionalParams: null,
    params: [
      { name: 'N', type: 'Number' }
    ],
    category: 'Numeric',
    doc: ''
  },
  {
    name: 'PMT',
    returnType: 'Number',
    desc: '',
    baseParams: 5,
    optionalParams: null,
    params: [
      { name: 'Rate', type: 'Number' },
      { name: 'Months', type: 'Number' },
      { name: 'PresentValue', type: 'Number' },
      { name: 'FutureValue', type: 'Number' },
      { name: 'AtPeriodBegin', type: 'Boolean' }
    ],
    category: 'Numeric',
    doc: ''
  },
  {
    name: 'REPLACE',
    returnType: 'String',
    desc: '',
    baseParams: 3,
    optionalParams: null,
    params: [
      { name: 'Text', type: 'String' },
      { name: 'Find', type: 'String' },
      { name: 'Replace', type: 'String' }
    ],
    category: 'Text',
    doc: ''
  },
  {
    name: 'CONTAINS',
    returnType: 'Boolean',
    desc: '',
    baseParams: 2,
    optionalParams: null,
    params: [
      { name: 'Text', type: 'String' },
      { name: 'Find', type: 'String' }
    ],
    category: 'Text',
    doc: ''
  },
  {
    name: 'INDEXOF',
    returnType: 'Number',
    desc: '',
    baseParams: 2,
    optionalParams: null,
    params: [
      { name: 'Text', type: 'String' },
      { name: 'Find', type: 'String' }
    ],
    category: 'Text',
    doc: ''
  },
  {
    name: 'SUBSTRING',
    returnType: 'String',
    desc: '',
    baseParams: 2,
    optionalParams: 1,
    params: [
      { name: 'Text', type: 'String' },
      { name: 'Start', type: 'Number' },
      { name: 'End', type: 'Number', optional: true }
    ],
    category: 'Text',
    doc: ''
  },
  {
    name: 'DATE',
    returnType: 'String',
    desc: '',
    baseParams: 1,
    optionalParams: 1,
    params: [
      { name: 'D', type: 'Date' },
      { name: 'F', type: 'String', optional: true }
    ],
    category: 'Date',
    doc: ''
  },
  {
    name: 'TO_DATE',
    returnType: 'Date',
    desc: '',
    baseParams: 1,
    optionalParams: 1,
    params: [
      { name: 'D', type: 'String' },
      { name: 'F', type: 'String', optional: true }
    ],
    category: 'Date',
    doc: ''
  },
]