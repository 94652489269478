import { TToken } from '../process_tokens/TToken'
import { TNode } from './TNode'
import { MyParser } from './MyParser'

const parser = new MyParser()

/*
    toto me klucovy zamer, ze ked sa debuguje realny vysledok ast, tak tam je nekonecno vnoreni vzdy lebo kazde pravidlo je vnorenie
    toto mi vracia strom kde sa vsetky vnorenie collapsnu na jednu uroven, s vynimkou prazdnych params
    lebo tie chcem necollapsnute, aby som vedel ze som vnutri funkcie..
*/
export function parseAst(tokens) {
    parser.reset()
    parser.input = tokens
    let ast = parser.expression()

    // if (parser.errors) console.log(parser.errors)

    let nodes = _makeSimpleTree(ast)

    return {
        ast: ast,
        nodes: nodes,
        errors: parser.errors,
    }
}

function _makeSimpleTree(n, parent = null) {

    if (!n)
        return

    let token = n.tokenType?.name
    // if (token === 'Comma' ||
    if (token === 'Lparen' ||
        token === 'Rparen')
        return

    // TOKEN
    if (n.tokenType)
        return {
            IS_TYPE: 'TOKEN',
            token: n.tokenType.name,
            start: n.startOffset,
            end: n.endOffset,
            text: n.image,
            parent,
        }

    // else RULE
    let out = {
        IS_TYPE: 'RULE',
        rule: n.name,
        parent,
        kids: [],
        text: '(RULE)',
        start: -1,//'! no init because it is rule',
        end: -1,//'! n/o init because it is rule',
    }

    let kidsByName = {}    // we only care about lparen, fnparams, rparen

    let kids = []
    for (const k in n.children) {
        let childrenOfType = n.children[k]
        for (let i = 0; i < childrenOfType.length; i++) {
            let ch = childrenOfType[i]
            let name = ch.name || ch.tokenType?.name
            let kid = _makeSimpleTree(ch, out)
            // console.log(name)
            // console.log(name, kid)
            kidsByName[name] = kid || ch
            if (kid)
                kids.push(kid)
        }
    }

    // console.log(kidsByName)
    // this is for special purpose of adding offsets for fnParams so that we can suggest params in editor..
    // fnParams otherwise doesnt have start and end offsets, so it is impossible to catch it in the traversing
    // (it could be helpful if we had these start/end for all rule nodes, dunno why its not default in chevrotain)
    if (kidsByName['fnParams']) {
        // let kid_Lparen = kidsByName['Lparen']
        let FnStartLp = kidsByName['FnStartLp']
        let FnParams = kidsByName['fnParams']
        let Rparen = kidsByName['Rparen']

        FnParams.start = FnStartLp.end
        FnParams.end = Rparen.startOffset
        FnParams.isFnParams = true
    }

    out.kids = kids

    let mSkippable = {
        'expression': 1,
        'atomicExpr': 1,
        'opExpr': 1,
    }
    if (kids.length === 1 && mSkippable[out.rule]) { // flatten depth
        let k = kids[0]
        k.flattened = k.flattened || []
        k.flattened.push(out.rule)
        k.parent = parent   // remember parent..
        return k
    }

    return out
}

// export function printAst(n, lvl = 0) {
//     if (!n) return '?'

//     let ind = '  '.repeat(lvl)
//     let s = ''
//     s += ind
//     s += n.rule || n.token
//     s += ' '.repeat(Math.max(30 - s.length, 0))
//     if (n.token)
//         s += ind + n.s + ' \t\t\t' + n.start + '-' + n.end
//     s += '\n'

//     if (n.kids)
//         n.kids.forEach(k => {
//             s += printAst(k, lvl + 1)
//         })

//     return s
// }
