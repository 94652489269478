export const textDirectionSelectOptions = [
  { value: "LTR", label: "LTR - Left to Right" },
  { value: "RTL", label: "RTL - Right to Left" }
];

export const localeSelectOptions = [
  { value: "sq", label: "Albanian" },
  { value: "sq_AL", label: "Albanian (Albania)" },
  { value: "ar", label: "Arabic" },
  { value: "ar_DZ", label: "Arabic (Algeria)" },
  { value: "ar_BH", label: "Arabic (Bahrain)" },
  { value: "ar_EG", label: "Arabic (Egypt)" },
  { value: "ar_IQ", label: "Arabic (Iraq)" },
  { value: "ar_JO", label: "Arabic (Jordan)" },
  { value: "ar_KW", label: "Arabic (Kuwait)" },
  { value: "ar_LB", label: "Arabic (Lebanon)" },
  { value: "ar_LY", label: "Arabic (Libya)" },
  { value: "ar_MA", label: "Arabic (Morocco)" },
  { value: "ar_OM", label: "Arabic (Oman)" },
  { value: "ar_QA", label: "Arabic (Qatar)" },
  { value: "ar_SA", label: "Arabic (Saudi Arabia)" },
  { value: "ar_SD", label: "Arabic (Sudan)" },
  { value: "ar_SY", label: "Arabic (Syria)" },
  { value: "ar_TN", label: "Arabic (Tunisia)" },
  { value: "ar_AE", label: "Arabic (United Arab Emirates)" },
  { value: "ar_YE", label: "Arabic (Yemen)" },
  { value: "be", label: "Belarusian" },
  { value: "be_BY", label: "Belarusian (Belarus)" },
  { value: "bg", label: "Bulgarian" },
  { value: "bg_BG", label: "Bulgarian (Bulgaria)" },
  { value: "ca", label: "Catalan" },
  { value: "ca_ES", label: "Catalan (Spain)" },
  { value: "zh", label: "Chinese" },
  { value: "zh_CN", label: "Chinese (China)" },
  { value: "zh_HK", label: "Chinese (Hong Kong)" },
  { value: "zh_SG", label: "Chinese (Singapore)" },
  { value: "zh_TW", label: "Chinese (Taiwan)" },
  { value: "hr", label: "Croatian" },
  { value: "hr_HR", label: "Croatian (Croatia)" },
  { value: "cs", label: "Czech" },
  { value: "cs_CZ", label: "Czech (Czech Republic)" },
  { value: "da", label: "Danish" },
  { value: "da_DK", label: "Danish (Denmark)" },
  { value: "nl", label: "Dutch" },
  { value: "nl_BE", label: "Dutch (Belgium)" },
  { value: "nl_NL", label: "Dutch (Netherlands)" },
  { value: "en", label: "English" },
  { value: "en_AU", label: "English (Australia)" },
  { value: "en_CA", label: "English (Canada)" },
  { value: "en_IN", label: "English (India)" },
  { value: "en_IE", label: "English (Ireland)" },
  { value: "en_MT", label: "English (Malta)" },
  { value: "en_NZ", label: "English (New Zealand)" },
  { value: "en_PH", label: "English (Philippines)" },
  { value: "en_SG", label: "English (Singapore)" },
  { value: "en_ZA", label: "English (South Africa)" },
  { value: "en_GB", label: "English (United Kingdom)" },
  { value: "en_US", label: "English (United States)" },
  { value: "et", label: "Estonian" },
  { value: "et_EE", label: "Estonian (Estonia)" },
  { value: "fi", label: "Finnish" },
  { value: "fi_FI", label: "Finnish (Finland)" },
  { value: "fr", label: "French" },
  { value: "fr_BE", label: "French (Belgium)" },
  { value: "fr_CA", label: "French (Canada)" },
  { value: "fr_FR", label: "French (France)" },
  { value: "fr_LU", label: "French (Luxembourg)" },
  { value: "fr_CH", label: "French (Switzerland)" },
  { value: "de", label: "German" },
  { value: "de_AT", label: "German (Austria)" },
  { value: "de_DE", label: "German (Germany)" },
  { value: "de_GR", label: "German (Greece)" },
  { value: "de_LU", label: "German (Luxembourg)" },
  { value: "de_CH", label: "German (Switzerland)" },
  { value: "el", label: "Greek" },
  { value: "el_CY", label: "Greek (Cyprus)" },
  { value: "el_GR", label: "Greek (Greece)" },
  { value: "iw", label: "Hebrew" },
  { value: "iw_IL", label: "Hebrew (Israel)" },
  { value: "hi", label: "Hindi" },
  { value: "hi_IN", label: "Hindi (India)" },
  { value: "hu", label: "Hungarian" },
  { value: "hu_HU", label: "Hungarian (Hungary)" },
  { value: "is", label: "Icelandic" },
  { value: "is_IS", label: "Icelandic (Iceland)" },
  { value: "in", label: "Indonesian" },
  { value: "in_ID", label: "Indonesian (Indonesia)" },
  { value: "ga", label: "Irish" },
  { value: "ga_IE", label: "Irish (Ireland)" },
  { value: "it", label: "Italian" },
  { value: "it_IT", label: "Italian (Italy)" },
  { value: "it_CH", label: "Italian (Switzerland)" },
  { value: "ja", label: "Japanese" },
  { value: "ja_JP", label: "Japanese (Japan)" },
  { value: "ja_JP_JP_#u-ca-japanese", label: "Japanese (Japan,JP)" },
  { value: "ko", label: "Korean" },
  { value: "ko_KR", label: "Korean (South Korea)" },
  { value: "lv", label: "Latvian" },
  { value: "lv_LV", label: "Latvian (Latvia)" },
  { value: "lt", label: "Lithuanian" },
  { value: "lt_LT", label: "Lithuanian (Lithuania)" },
  { value: "mk", label: "Macedonian" },
  { value: "mk_MK", label: "Macedonian (Macedonia)" },
  { value: "ms", label: "Malay" },
  { value: "ms_MY", label: "Malay (Malaysia)" },
  { value: "mt", label: "Maltese" },
  { value: "mt_MT", label: "Maltese (Malta)" },
  { value: "no", label: "Norwegian" },
  { value: "no_NO", label: "Norwegian (Norway)" },
  { value: "no_NO_NY", label: "Norwegian (Norway,Nynorsk)" },
  { value: "pl", label: "Polish" },
  { value: "pl_PL", label: "Polish (Poland)" },
  { value: "pt", label: "Portuguese" },
  { value: "pt_BR", label: "Portuguese (Brazil)" },
  { value: "pt_PT", label: "Portuguese (Portugal)" },
  { value: "ro", label: "Romanian" },
  { value: "ro_RO", label: "Romanian (Romania)" },
  { value: "ru", label: "Russian" },
  { value: "ru_RU", label: "Russian (Russia)" },
  { value: "sr", label: "Serbian" },
  { value: "sr_BA", label: "Serbian (Bosnia and Herzegovina)" },
  { value: "sr__#Latn", label: "Serbian (Latin)" },
  { value: "sr_BA_#Latn", label: "Serbian (Latin,Bosnia and Herzegovina)" },
  { value: "sr_ME_#Latn", label: "Serbian (Latin,Montenegro)" },
  { value: "sr_RS_#Latn", label: "Serbian (Latin,Serbia)" },
  { value: "sr_ME", label: "Serbian (Montenegro)" },
  { value: "sr_CS", label: "Serbian (Serbia and Montenegro)" },
  { value: "sr_RS", label: "Serbian (Serbia)" },
  { value: "sk", label: "Slovak" },
  { value: "sk_SK", label: "Slovak (Slovakia)" },
  { value: "sl", label: "Slovenian" },
  { value: "sl_SI", label: "Slovenian (Slovenia)" },
  { value: "es", label: "Spanish" },
  { value: "es_AR", label: "Spanish (Argentina)" },
  { value: "es_BO", label: "Spanish (Bolivia)" },
  { value: "es_CL", label: "Spanish (Chile)" },
  { value: "es_CO", label: "Spanish (Colombia)" },
  { value: "es_CR", label: "Spanish (Costa Rica)" },
  { value: "es_CU", label: "Spanish (Cuba)" },
  { value: "es_DO", label: "Spanish (Dominican Republic)" },
  { value: "es_EC", label: "Spanish (Ecuador)" },
  { value: "es_SV", label: "Spanish (El Salvador)" },
  { value: "es_GT", label: "Spanish (Guatemala)" },
  { value: "es_HN", label: "Spanish (Honduras)" },
  { value: "es_MX", label: "Spanish (Mexico)" },
  { value: "es_NI", label: "Spanish (Nicaragua)" },
  { value: "es_PA", label: "Spanish (Panama)" },
  { value: "es_PY", label: "Spanish (Paraguay)" },
  { value: "es_PE", label: "Spanish (Peru)" },
  { value: "es_PR", label: "Spanish (Puerto Rico)" },
  { value: "es_ES", label: "Spanish (Spain)" },
  { value: "es_US", label: "Spanish (United States)" },
  { value: "es_UY", label: "Spanish (Uruguay)" },
  { value: "es_VE", label: "Spanish (Venezuela)" },
  { value: "sv", label: "Swedish" },
  { value: "sv_SE", label: "Swedish (Sweden)" },
  { value: "th", label: "Thai" },
  { value: "th_TH", label: "Thai (Thailand)" },
  { value: "th_TH_TH_#u-nu-thai", label: "Thai (Thailand,TH)" },
  { value: "tr", label: "Turkish" },
  { value: "tr_TR", label: "Turkish (Turkey)" },
  { value: "uk", label: "Ukrainian" },
  { value: "uk_UA", label: "Ukrainian (Ukraine)" },
  { value: "vi", label: "Vietnamese" },
  { value: "vi_VN", label: "Vietnamese (Vietnam)"}
];

export const timezoneSelectOptions = [
  { value: "Etc/GMT+12", label: "(GMT -12:00) International Date Line West" },
  { value: "Etc/GMT+11", label: "(GMT -11:00) Coordinated Universal Time-11" },
  { value: "Pacific/Samoa", label: "(GMT -11:00) Samoa" },
  { value: "Pacific/Honolulu", label: "(GMT -10:00) Hawaii" },
  { value: "America/Anchorage", label: "(GMT -09:00) Alaska" },
  { value: "America/Tijuana", label: "(GMT -08:00) Baja California" },
  { value: "America/Los_Angeles", label: "(GMT -08:00) Pacific Time (US & Canada)" },
  { value: "America/Phoenix", label: "(GMT -07:00) Arizona" },
  { value: "America/Chihuahua", label: "(GMT -07:00) Chihuahua, La Paz, Mazatlan" },
  { value: "America/Denver", label: "(GMT -07:00) Mountain Time (US & Canada)" },
  { value: "America/Guatemala", label: "(GMT -06:00) Central America" },
  { value: "America/Chicago", label: "(GMT -06:00) Central Time (US & Canada)" },
  { value: "America/Mexico_City", label: "(GMT -06:00) Guadalajara, Mexico City, Monterrey" },
  { value: "America/Regina", label: "(GMT -06:00) Saskatchewan" },
  { value: "America/Bogota", label: "(GMT -05:00) Bogota, Lima, Quito" },
  { value: "America/New_York", label: "(GMT -05:00) Eastern Time (US & Canada)" },
  { value: "America/Indianapolis", label: "(GMT -05:00) Indiana (East)" },
  { value: "America/Asuncion", label: "(GMT -04:00) Asuncion" },
  { value: "America/Halifax", label: "(GMT -04:00) Atlantic Time (Canada)" },
  { value: "America/Cuiaba", label: "(GMT -04:00) Cuiaba" },
  { value: "America/La_Paz", label: "(GMT -04:00) Georgetown, La Paz, Manaus, San Juan" },
  { value: "America/Santiago", label: "(GMT -04:00) Santiago" },
  { value: "America/Caracas", label: "(GMT -04:30) Caracas" },
  { value: "America/St_Johns", label: "(GMT -03:30) Newfoundland" },
  { value: "America/Sao_Paulo", label: "(GMT -03:00) Brasilia" },
  { value: "America/Buenos_Aires", label: "(GMT -03:00) Buenos Aires" },
  { value: "America/Cayenne", label: "(GMT -03:00) Cayenne, Fortaleza" },
  { value: "America/Godthab", label: "(GMT -03:00) Greenland" },
  { value: "America/Montevideo", label: "(GMT -03:00) Montevideo" },
  { value: "Etc/GMT+2", label: "(GMT -02:00) Coordinated Universal Time-02 / Mid-Atlantic" },
  { value: "Atlantic/Azores", label: "(GMT -01:00) Azores" },
  { value: "Atlantic/Cape_Verde", label: "(GMT -01:00) Cape Verde Is." },
  { value: "Africa/Casablanca", label: "(GMT) Casablanca" },
  { value: "Etc/GMT", label: "(GMT) Coordinated Universal Time" },
  { value: "Europe/London", label: "(GMT) Dublin, Edinburgh, Lisbon, London" },
  { value: "Atlantic/Reykjavik", label: "(GMT) Monrovia, Reykjavik" },
  { value: "Europe/Berlin", label: "(GMT +01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
  { value: "Europe/Budapest", label: "(GMT +01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
  { value: "Europe/Bratislava", label: "(GMT +01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
  { value: "Europe/Paris", label: "(GMT +01:00) Brussels, Copenhagen, Madrid, Paris" },
  { value: "Europe/Warsaw", label: "(GMT +01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
  { value: "Africa/Lagos", label: "(GMT +01:00) West Central Africa" },
  { value: "Asia/Amman", label: "(GMT +02:00) Amman" },
  { value: "Asia/Beirut", label: "(GMT +02:00) Beirut" },
  { value: "Africa/Cairo", label: "(GMT +02:00) Cairo" },
  { value: "Asia/Damascus", label: "(GMT +02:00) Damascus" },
  { value: "Africa/Johannesburg", label: "(GMT +02:00) Harare, Pretoria" },
  { value: "Europe/Kiev", label: "(GMT +02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
  { value: "Asia/Jerusalem", label: "(GMT +02:00) Jerusalem" },
  { value: "Africa/Windhoek", label: "(GMT +02:00) Windhoek" },
  { value: "Europe/Istanbul", label: "(GMT +02:00) Athens, Bucharest, Istanbul" },
  { value: "Europe/Minsk", label: "(GMT +02:00) Minsk" },
  { value: "Asia/Baghdad", label: "(GMT +03:00) Baghdad" },
  { value: "Asia/Riyadh", label: "(GMT +03:00) Kuwait, Riyadh" },
  { value: "Europe/Moscow", label: "(GMT +03:00) Moscow, St. Petersburg, Volgograd" },
  { value: "Africa/Nairobi", label: "(GMT +03:00) Nairobi" },
  { value: "Asia/Tehran", label: "(GMT +03:30) Tehran" },
  { value: "Asia/Dubai", label: "(GMT +04:00) Abu Dhabi, Muscat" },
  { value: "Asia/Baku", label: "(GMT +04:00) Baku" },
  { value: "Indian/Mauritius", label: "(GMT +04:00) Port Louis" },
  { value: "Asia/Tbilisi", label: "(GMT +04:00) Tbilisi" },
  { value: "Asia/Yerevan", label: "(GMT +04:00) Yerevan" },
  { value: "Asia/Kabul", label: "(GMT +04:30) Kabul" },
  { value: "Asia/Yekaterinburg", label: "(GMT +05:00) Ekaterinburg" },
  { value: "Asia/Karachi", label: "(GMT +05:00) Islamabad, Karachi" },
  { value: "Asia/Tashkent", label: "(GMT +05:00) Tashkent" },
  { value: "Asia/Calcutta", label: "(GMT +05:30) Chennai, Kolkata, Mumbai, New Delhi" },
  { value: "Asia/Colombo", label: "(GMT +05:30) Sri Jayawardenepura" },
  { value: "Asia/Katmandu", label: "(GMT +05:45) Kathmandu" },
  { value: "Asia/Almaty", label: "(GMT +06:00) Astana" },
  { value: "Asia/Dhaka", label: "(GMT +06:00) Dhaka" },
  { value: "Asia/Rangoon", label: "(GMT +06:30) Yangon (Rangoon)" },
  { value: "Asia/Novosibirsk", label: "(GMT +06:00) Novosibirsk" },
  { value: "Asia/Bangkok", label: "(GMT +07:00) Bangkok, Hanoi, Jakarta" },
  { value: "Asia/Krasnoyarsk", label: "(GMT +07:00) Krasnoyarsk" },
  { value: "Asia/Shanghai", label: "(GMT +08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
  { value: "Asia/Irkutsk", label: "(GMT +08:00) Irkutsk" },
  { value: "Asia/Singapore", label: "(GMT +08:00) Kuala Lumpur, Singapore" },
  { value: "Australia/Perth", label: "(GMT +08:00) Perth" },
  { value: "Asia/Taipei", label: "(GMT +08:00) Taipei" },
  { value: "Asia/Ulaanbaatar", label: "(GMT +08:00) Ulaanbaatar" },
  { value: "Asia/Tokyo", label: "(GMT +09:00) Osaka, Sapporo, Tokyo" },
  { value: "Asia/Seoul", label: "(GMT +09:00) Seoul" },
  { value: "Asia/Yakutsk", label: "(GMT +09:00) Yakutsk" },
  { value: "Australia/Adelaide", label: "(GMT +09:30) Adelaide" },
  { value: "Australia/Darwin", label: "(GMT +09:30) Darwin" },
  { value: "Australia/Brisbane", label: "(GMT +10:00) Brisbane" },
  { value: "Australia/Sydney", label: "(GMT +10:00) Canberra, Melbourne, Sydney" },
  { value: "Pacific/Port_Moresby", label: "(GMT +10:00) Guam, Port Moresby" },
  { value: "Australia/Hobart", label: "(GMT +10:00) Hobart" },
  { value: "Asia/Vladivostok", label: "(GMT +10:00) Vladivostok" },
  { value: "Asia/Magadan", label: "(GMT +11:00) Magadan" },
  { value: "Pacific/Guadalcanal", label: "(GMT +11:00) Solomon Is., New Caledonia" },
  { value: "Pacific/Auckland", label: "(GMT +12:00) Auckland, Wellington" },
  { value: "Etc/GMT-12", label: "(GMT +12:00) Coordinated Universal Time+12" },
  { value: "Pacific/Fiji", label: "(GMT +12:00) Fiji, Marshall Is." },
  { value: "Asia/Kamchatka", label: "(GMT +12:00) Petropavlovsk-Kamchatsky - Old" },
  { value: "Pacific/Tongatapu", label: "(GMT +13:00) Nuku'alofa" },
];

export const countrySelectOptions = [
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AG", label: "Antigua &amp;amp; Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia &amp;amp; Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BR", label: "Brazil" },
  { value: "VG", label: "British Virgin Islands" },
  { value: "BN", label: "Brunei" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CA", label: "Canada" },
  { value: "CV", label: "Cape Verde" },
  { value: "KY", label: "Cayman Islands" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CG", label: "Congo - Brazzaville" },
  { value: "CD", label: "Congo - Kinshasa" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "CI", label: "Côte d’Ivoire" },
  { value: "HR", label: "Croatia" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "ET", label: "Ethiopia" },
  { value: "FK", label: "Falkland Islands" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GT", label: "Guatemala" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong SAR China" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IE", label: "Ireland" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Laos" },
  { value: "LV", label: "Latvia" },
  { value: "LS", label: "Lesotho" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MK", label: "Macedonia" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia" },
  { value: "MD", label: "Moldova" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "AN", label: "Netherlands Antilles" },
  { value: "NC", label: "New Caledonia" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk Island" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PW", label: "Palau" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn Islands" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "Réunion" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russia" },
  { value: "RW", label: "Rwanda" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "Sao Tomé &amp;amp; Príncipe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "KR", label: "South Korea" },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SH", label: "St. Helena" },
  { value: "KN", label: "St. Kitts &amp;amp; Nevis" },
  { value: "LC", label: "St. Lucia" },
  { value: "PM", label: "St. Pierre &amp;amp; Miquelon" },
  { value: "VC", label: "St. Vincent &amp;amp; Grenadines" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard &amp;amp; Jan Mayen" },
  { value: "SZ", label: "Swaziland" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "TW", label: "Taiwan" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania" },
  { value: "TH", label: "Thailand" },
  { value: "TG", label: "Togo" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad &amp;amp; Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks &amp;amp; Caicos Islands" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States" },
  { value: "UY", label: "Uruguay" },
  { value: "VU", label: "Vanuatu" },
  { value: "VA", label: "Vatican City" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Vietnam" },
  { value: "WF", label: "Wallis &amp;amp; Futuna" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" },
];

export const fontFamilySelectOptions = [
  {value: "", label: "Auto"},
  {value: "Liberation Sans", label: "Liberation Sans"},
  {value: "Liberation Serif", label: "Liberation Serif"},
  {value: "Arvo", label: "Arvo"},
  {value: "Cairo", label: "Cairo"},
  {value: "Courier Prime", label: "Courier Prime"},
  {value: "Economica", label: "Economica"},
  {value: "Electrolize", label: "Electrolize"},
  {value: "Montserrat", label: "Montserrat"},
  {value: "Mulish", label: "Mulish"},
  {value: "Noto Sans Arabic", label: "Noto Sans Arabic"},
  {value: "Noto Emoji", label: "Noto Emoji"},
  {value: "Noto Sans", label: "Noto Sans"},
  {value: "Noto Sans Display", label: "Noto Sans Display"},
  {value: "Noto Sans Thai", label: "Noto Sans Thai"},
  {value: "Noto Sans Mono", label: "Noto Sans Mono"},
  {value: "Noto Serif", label: "Noto Serif"},
  {value: "Noto Serif Display", label: "Noto Serif Display"},
  {value: "Noto Serif Thai", label: "Noto Serif Thai"},
  {value: "Open Sans", label: "Open Sans"},
  {value: "Poppins", label: "Poppins"},
  {value: "PT Mono", label: "PT Mono"},
  {value: "Roboto", label: "Roboto"},
  {value: "TH Sarabun New", label: "TH Sarabun New"},
  {value: "Ubuntu", label: "Ubuntu"},
  {value: "David Libre", label: "David Libre"},
  {value: "Questrial", label: "Questrial"},
  {value: "Raleway", label: "Raleway"},
  {value: "Nanum Gothic", label: "Nanum Gothic"},
  {value: "Sawarabi Gothic", label: "Sawarabi Gothic"},
  {value: "Noto Sans Georgian", label: "Noto Sans Georgian"},
  {value: "Noto Serif Georgian", label: "Noto Serif Georgian"},
  {value: "DejaVu Sans Mono", label: "DejaVu Sans Mono"},
];

export const fontSizeSelectOptions = [
  {value: 0, label: "Auto"},
  {value: 4, label: "4"},
  {value: 5, label: "5"},
  {value: 6, label: "6"},
  {value: 7, label: "7"},
  {value: 8, label: "8"},
  {value: 9, label: "9"},
  {value: 10, label: "10"},
  {value: 11, label: "11"},
  {value: 12, label: "12"},
  {value: 14, label: "14"},
  {value: 16, label: "16"},
  {value: 18, label: "18"},
  {value: 20, label: "20"},
  {value: 22, label: "22"},
  {value: 24, label: "24"},
  {value: 26, label: "26"},
  {value: 28, label: "28"},
  {value: 36, label: "36"},
  {value: 48, label: "48"},
  {value: 72, label: "72"},
];

export const fontWeightSelectOptions = [
  {value: "normal", label: "Normal"},
  {value: "bold", label: "Bold"},
]

export const fontStyleSelectOptions = [
  {value: "normal", label: "Normal"},
  {value: "italic", label: "Italic"},
]

export const textAlignSelectOptions = [
  {value: 0, label: "Left"},
  {value: 1, label: "Center"},
  {value: 2, label: "Right"}
]

export const fixedSizeSelectOptions = [
  {value: 32, label: "Small"},
  {value: 64, label: "Medium"},
  {value: 96, label: "Large"},
  {value: 128, label: "Extra"},
]

export const barcode1DTypeSelectOptions = [
  {value: "BC1_EAN_8", label: "EAN-8"},
  {value: "BC1_EAN_13", label: "EAN-13"},
  {value: "BC1_CODE_39", label: "Code 39"},
  {value: "BC1_CODE_93", label: "Code 93"},
  {value: "BC1_CODE_128", label: "Code 128"},
]

export const barcode2DTypeSelectOptions = [
  {value: "BC2_AZTEC", label: "Aztec"},
  {value: "BC2_DATA_MATRIX", label: "Data Matrix"},
  {value: "BC2_PDF417", label: "PDF417"},
  {value: "BC2_QR_CODE", label: "QR Code"},
]

export const barcodeZoomSelectOptions = [
  {value: 1, label: "1x"},
  {value: 2, label: "2x"},
  {value: 3, label: "3x"},
  {value: 4, label: "4x"},
]