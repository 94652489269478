import {createSlice} from "@reduxjs/toolkit";

const initialState = {
};

const eledoTabsSlice = createSlice({
  name: 'eledoTabs',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state[action.payload.tabsComponentId] = action.payload.activeTabId;
    }
  }
});

export const { setActiveTab } = eledoTabsSlice.actions;
export default eledoTabsSlice.reducer;