import classnames from "classnames";
import headerStyles from "./EledoPageHeader.module.scss";
import styles from './EledoPageLoader.module.scss';
import React from "react";

const EledoPageLoader = ({text}) => {
  return (
    <div className={styles.Loader}>

      <div className={classnames(headerStyles.EledoLogin, "container")} data-testid="EledoLogin">
        <div className="section section-small">
          <div className="hero-paragraph text-center">
            <h2><b>{text ? text : 'Loading...'}</b></h2>

            <img src={process.env.PUBLIC_URL + "/assets/images/Eledo-logoW-icon-animated.svg"}
                 loading="lazy" height="250" alt="Eledo logo loader"
                 className={styles.LoaderImage}/>

            <p>Please wait while we set <br/> things up for you!</p>
          </div>
        </div>
      </div>

    </div>
  );
};

export default EledoPageLoader;