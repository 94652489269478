import { AuthContext } from 'AuthContext';
import React, { useCallback, useContext, useState } from 'react'
import { Tracker } from 'Tracker';

export type ArticleHistory = {
  currentPath: string;
  navigate: (path: string) => void;
  goBack: () => void;
  goForward: () => void;
  canGoBack: boolean;
  canGoForward: boolean;
}

const useArticleHistory = (initialPath: string) => {
  const auth = useContext(AuthContext);

  const [history, setHistory] = useState([initialPath]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Method to navigate to a new path
  const navigate = useCallback((path: string) => {
    setHistory((prevHistory) => {
      const updatedHistory = prevHistory.slice(0, currentIndex + 1);
      updatedHistory.push(path);
      return updatedHistory;
    });
    setCurrentIndex((prevIndex) => prevIndex + 1);

    Tracker.pageView(path, {
      id: auth.user?.id || null,
      level: auth.level
    });

  }, [currentIndex, history, auth.level, auth.user?.id]);

  // Method to go backward in history
  const goBack = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  }, [currentIndex]);

  // Method to go forward in history
  const goForward = useCallback(() => {
    if (currentIndex < history.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  }, [currentIndex, history.length]);

  // Get the current path
  const currentPath = history[currentIndex];

  return {
      currentPath,
      navigate,
      goBack,
      goForward,
      canGoBack: currentIndex > 0,
      canGoForward: currentIndex < history.length - 1,
  };
}

export default useArticleHistory