import styles from './EledoEditorRoot.module.scss';
import {shallowEqual, useSelector} from "react-redux";
import {useHideMenu} from "hooks";
import {EledoError} from "components/library";
import EledoCKEditor4 from "../EledoCKEditor4/EledoCKEditor4";
import EledoPdfEditor from "../EledoPdfEditor/EledoPdfEditor";
import EledoXfaEditor from "../EledoXfaEditor/EledoXfaEditor";
import {TEMPLATE__TYPES as ttypes} from "model/enums";
import classnames from "classnames";


const EledoEditorRoot = ({isBackground, dataModel, fonts}) => {

  useHideMenu();


  const [templateId, templateVersion, templateType, activePageId] = useSelector(state => [
    state.templates.selectedTemplate?.id,
    state.templates.selectedTemplate?.realVersion,
    state.templates.selectedTemplate?.type,
    state.templates.activePageId
  ], shallowEqual);

  
  return (
    <div className={classnames(styles.EledoEditor, "stretch-vertically", "section", "section-small")}>
      
      { (templateType === ttypes.STATIC_PAGE || templateType === ttypes.DYNAMIC_FLOW) &&
        <EledoCKEditor4 key={`${templateId}-${templateVersion}-${activePageId}-${isBackground ? "bg" : "fg"}`} isBackground={isBackground} fonts={fonts} dataModel={dataModel}/>
      }
      { templateType === ttypes.FILLABLE_DOCUMENT &&
        <EledoPdfEditor dataModel={dataModel}/>
      }
      { templateType === ttypes.XFA_DOCUMENT &&
        <EledoXfaEditor />
      }
      { ![ttypes.STATIC_PAGE, ttypes.DYNAMIC_FLOW, ttypes.FILLABLE_DOCUMENT, ttypes.XFA_DOCUMENT].some(tt => tt === templateType) &&
        <EledoError title={`We're sorry, this type of template (${templateType}) is not supported yet.`}
                    description="We're working on a solution."/>
      }
    </div>
  );
}

export default EledoEditorRoot
