import eledo from "./eledo";

const BASE_URL = '/connection';
const LIST_URL = BASE_URL + 'List';

const fetchConnections = () => eledo.get(LIST_URL);
const forgetConnection = id => eledo.delete(`${BASE_URL}/${id}`);
const validateConnection = id => eledo.get(`${BASE_URL}/${id}`);
//const addConnection = connection => eledo.put(BASE_URL, connection);

const connectionsService = { fetchConnections, forgetConnection, validateConnection };
export default connectionsService;