import classnames from "classnames"

import { FieldState, FieldType } from "../EledoPdfEditor"

import TopToolbarButton from "../TopToolbarButton/TopToolbarButton"
import css from "./TopToolbar.module.scss"


type EledoPdfToolbarProps = {
  rectState: FieldState
  onInitNewField: (fieldType: FieldType) => void
}


const TopToolbar = (props: EledoPdfToolbarProps) => (
  <div className={classnames(css.eledoPdfToolbar)}>
    <span className='cke_toolbar' aria-labelledby="cke_165_label" role="toolbar">
      <span className={css.toolbarGroup}>
        <TopToolbarButton title="Text Box" icon="text" type="Text" disabled={props.rectState !== "none"} onClick={props.onInitNewField} />
        <TopToolbarButton title="Dynamic Image" icon="dynamicImage" type="ImageURL" disabled={props.rectState !== "none"} onClick={props.onInitNewField} />
        <TopToolbarButton title="Checkbox" icon="checkbox" type="Checkbox" disabled={props.rectState !== "none"} onClick={props.onInitNewField} />
        <TopToolbarButton title="Barcodes 1D" icon="barcode1D" type="BC1_EAN_8" disabled={props.rectState !== "none"} onClick={props.onInitNewField} />
        <TopToolbarButton title="Barcodes 2D" icon="barcode2D" type="BC2_QR_CODE" disabled={props.rectState !== "none"} onClick={props.onInitNewField} />
      </span>
    </span>
  </div>
)


export default TopToolbar