import React, {useEffect, useRef, useState} from 'react';
import styles from './EledoTabs.module.scss';
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {setActiveTab} from "_reducers/eledoTabsReducer";
import classnames from "classnames";

const EledoTabs = ({ id, children, tabContentStyle }) => {
  const activeTab = useSelector(state => state.tabs[id]);
  const [tabContentHeight, setTabContentHeight] = useState("1000000px");
  const dispatch = useDispatch();

  useEffect(() => {
    if(activeTab === undefined)
      dispatch(setActiveTab({tabsComponentId: id, activeTabId: 0}));
  }, [id, activeTab, dispatch]);

  const onClickTabItem = (tabIndex) => {
    dispatch(setActiveTab({tabsComponentId: id, activeTabId: tabIndex}));
  }

  if(!Array.isArray(children)){
    children = [children];
  }

  return <div className= {classnames(styles.EledoTabs, "stretch-vertically")}>
    <ol className={styles.tabList}>
      {children.map((child, index) => {
        const { label } = child.props;

        return (
          <EledoTab
            activeTab={activeTab === undefined ? 0 : activeTab}
            key={`tab-${index}`}
            index={index}
            label={label}
            onClick={() => onClickTabItem(index)}
          />
        );
      })}
    </ol>
    <div className={classnames(styles.tabContent, "my-section", "stretch-vertically")}
         style={{height: tabContentHeight, minHeight: tabContentHeight, ...tabContentStyle}}>
      {children.map((child, index) =>
        <EledoTabContent
          index={index}
          activeTab={activeTab}
          child={child}
          key={`tab-content-${index}`}
          onTabActivation={(height) => setTabContentHeight(height + 30 + "px")}
        />
      )}
    </div>
  </div>

};

const EledoTab = ( { label, index, activeTab, onClick } ) => {
  const onTabClick = (index) => {
    onClick(index);
  }

  return (
    <li className={styles.tabListItem}>
      <a className={classnames(activeTab === index ? styles.activeTab : '', styles.tabLink)}
         onClick={(e) => {e.preventDefault(); onTabClick(index)}}
         href="/">
          {label}
      </a>
    </li>
  );
}

const EledoTabContent = ({ index, activeTab, child, onTabActivation }) => {
  const tabClass = index === activeTab ?
    styles.activeTabContent :
    (index > activeTab ?
      styles.slideRight :
      styles.slideLeft);
  const thisRef = useRef(null);

  useEffect(() => {
    if(index === activeTab) {
      const height = thisRef.current?.offsetHeight;
      onTabActivation(height);
    }
  }, [thisRef, activeTab, index, onTabActivation]);

    return (
      <div className={classnames(styles.contentCard, tabClass)}
           key={`tab-content-${index}`}
           ref={thisRef}>
        {child.props.children}
      </div>
    );
};

// Props type checking
EledoTabs.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
EledoTab.propTypes = {
  label: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  activeTab: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default EledoTabs;
