export enum FormFieldTypes {
  SHORT_STRING, LONG_STRING, SELECT, CHECKBOX, RADIO, EXPRESSION, PASSWORD, DOCUMENT_FORMAT_SELECT, CUSTOM, FILE, COLOR, SUBMIT
}
export enum TEMPLATE__TYPES {
  DYNAMIC_FLOW, FILLABLE_DOCUMENT = 5, STATIC_PAGE = 6, XFA_DOCUMENT = 7
}

export enum EledoCreateTemplateTypes {
  dynamicFlow = "dynamicFlow", staticPages = "staticPages", uploadPdf = "uploadPdf", convertWord = "convertWord"
}

export enum LOG__LEVELS {
  ERROR = 3, WARNING = 2, DEBUG = 1, NONE = 0
}

export const LOG__SEVERITY_INFO = [
  {icon: "icon-info", desc: "Info", color: ""},
  {icon: "icon-bug", desc: "Debug", color: ""},
  {icon: "icon-notification yellow", desc: "Warning", color: "yellow"},
  {icon: "icon-warning red", desc: "Error", color: "red"},
];

export enum LOG__TYPES {
  GENERATE_FLOW = 1, GENERATE_PAGES = 2, FORM_FILL = 3, XFA_FILL = 4, PARSE_API = 5, PARSE_SUBMIT = 6
}

export const LOG__TYPES__INFO = [
  {icon: "", desc: ""}, //no use, it's because of missing LOG__TYPES enum value of 0
  {icon: "icon-file-text1", desc: "Flow generated"},
  {icon: "icon-file-text1", desc: "Pages generated"},
  {icon: "icon-list", desc: "Form filled"},
  {icon: "icon-list", desc: "XFA filled"},
  {icon: "icon-paste", desc: "API parsed"},
  {icon: "icon-paste", desc: "Submit parsed"}
];

export enum USER__SUBSCRIPTION_TYPE {
  ONE_MONTH, ONE_MONTH_AUTO_RENEW, ONE_YEAR,
  ONE_YEAR_AUTO_RENEW, ONE_TIME, CANCEL
}

export enum USER__PRINCING_PLAN {
  COMMUNITY, BREEZE, STORM, TORNADO, ENTERPRISE, CUSTOM
}

export const USER__PRICING_PLAN__INFO = [
  {desc: "Community", monthly: "FREE", documents: 100, templates: 3, support: "Email", per25documents: "-"},
  {desc: "Breeze", monthly: 6.90, documents: 200, templates: 6, support: "Email", per25documents: 0.95},
  {desc: "Storm", monthly: 19.90, documents: 600, templates: 9, support: "Email", per25documents: 0.93},
  {desc: "Tornado", monthly: 69.90, documents: 1800, templates: 27, support: "Email", per25documents: 0.91},
  {desc: "Enterprise", monthly: "Individual", documents: "5000+", templates: "27+", support: "Dedicated team", per25documents: "-"},
];

export enum USER__PAYMENT_METHOD {
  CREDIT_CARD, PAYPAL
}
