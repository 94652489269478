import { useEffect } from 'react'

import { useSimpleAst } from './useSimpleAst'
import { useCaretFnAndParam } from './useCaretFnAndParam'

import { TVarType } from 'featuresFed/fed_core/lang_vars/TVar'


export function useFedParamInfo(
  caretPos: number,
  tokensOrig: any[],
  requiredType: TVarType,
  on_currWantType: (cpt: TVarType) =>  void,
  on_parentFnName: (fnName: string) =>  void,
  on_error: (err: any) =>  void,
) {

  const { nodes, errors } = useSimpleAst(tokensOrig)
  const { parentFnName, currWantType } = useCaretFnAndParam(nodes, caretPos, requiredType)
  
  useEffect(() => {
    // console.log('---\non_parentFn?.(parentFn)', parentFn)
    if(parentFnName)
      on_parentFnName?.(parentFnName)
  }, [parentFnName, on_parentFnName])

  useEffect(() => {
    // console.log('on_currParam(currParam)')
    on_currWantType(currWantType)
  }, [currWantType, on_currWantType])

  useEffect(() => {
    on_error?.(errors)
  }, [errors, on_error])


  return {}
}
