import React from 'react';
import PropTypes from "prop-types";

const EledoError = ({title, description}) => (
  <div className="flex-center">
    <div className="col-66 text-center">
      <div className="yellow-card">
        <h3>{title ? title : "Something went wrong"}</h3>
        <div style={{margin: "0.5rem 0"}}>
          {description}
        </div>
      </div>
    </div>
  </div>
);
EledoError.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.element,
  ])
};

export default EledoError;
