import React, { useState, useEffect } from 'react'
import { ApolloError, useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Article, ArticleData, ArticleVars, GET_ARTICLE } from '../ArticleQueries';

const useArticle = ({id, path}: ArticleVars) => {
  // const { loading, error, data } = useQuery<ArticleData, ArticleVars>(GET_ARTICLE, {variables:{id}});
  
  const client = useApolloClient();

  const [ article, setArticle] = useState<Article>();
  const [ error, setError ] = useState<string|null>(null);
  const [ loading, setLoading ] = useState(true);

  const [ getArticle ] = useLazyQuery<ArticleData, ArticleVars>(GET_ARTICLE);
  
  const storeArticle = (data: Article) => {
    if(!data) return;

    client.cache.writeQuery({query: GET_ARTICLE, data: { article: data } })

    const article: Article = {
      id: data.id,
      version: data.version,
      activeVersion: data.activeVersion,
      realVersion: data.realVersion,
      title: data.title,
      slug: data.slug,
      root: data.root,
      path: data.path,
      parent: data.parent,
      parents: data.parents,
      order: data.order,
      published: data.published,
      nomenu: data.nomenu,
      index: data.index,
      description: data.description,
      markdown: data.markdown,
      content: data.content
    }

    setArticle(article);
  }
  
  useEffect(() => {
    if(!id && !path) {
      setLoading(false);
      return;
    }
    
    startRequest();
    
    getArticle({variables:{id, path}})
      .then(result => {
        setLoading(false);
        result.data && storeArticle(result.data.article);
      })
      .catch((data: ApolloError) => {
        setLoading(false);
        setError(data.message);
        console.log(data);
      })
  }, [id, path])

  function startRequest() {
    setLoading(true)
    setError(null)
  }
  
  return { loading, error, article }
}

export default useArticle