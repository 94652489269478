import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PuzzleRequest } from 'graphql/PuzzleRequests';

interface PuzzleRequestsState {
  allRequests: PuzzleRequest[]
  currentRequests: PuzzleRequest[]
}

const initialState: PuzzleRequestsState = {
  allRequests: [],
  currentRequests: []
}

const puzzleRequestsSlice = createSlice({
  name: 'puzzleRequests',
  initialState,
  reducers: {
    setAllRequests: (state, action: PayloadAction<PuzzleRequest[]>) => {state.allRequests = action.payload},
    activateRequestsByEvent: (state, action: PayloadAction<string>) => {state.currentRequests = state.allRequests.filter(r => r.event === action.payload)},
    removeRequest: (state, action: PayloadAction<PuzzleRequest>) => {
      state.allRequests = state.allRequests.filter(r => r.id !== action.payload.id)
      state.currentRequests = state.currentRequests.filter(r => r.id !== action.payload.id)
    }
  },
});

export const { setAllRequests, activateRequestsByEvent, removeRequest } = puzzleRequestsSlice.actions;
export default puzzleRequestsSlice.reducer;