import { useContext, useEffect } from 'react';
import classnames from "classnames";
import {useShowMenu} from "hooks";
import {
  EledoFormGenerator, EledoPendingContent, EledoPageHeader,
} from "components/library";
import styles from 'components/library/EledoPageHeader.module.scss';
import {LoginFormModel} from "model/login";
import {FormProvider, useForm} from "react-hook-form";
import ReactTooltip from "react-tooltip";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'AuthContext';
import { useMutation } from '@apollo/client';
import { AuthDataData, AuthVars, GET_AUTH } from 'graphql/AuthQueries';
import { Navigate, useLocation } from 'react-router-dom';
import { PlatformContext } from 'PlatformContext';
import { useAppDispatch } from '_store';
import { setAllRequests } from '_reducers/puzzleRequests';

interface LoginFormData {
  login: string
  password: string
  rememberMe: Boolean
}

interface LocationState {
  from: {
    pathname: string
    search: string
  }
}

const EledoLogin = () => {

  const auth = useContext(AuthContext);
  const platform = useContext(PlatformContext);
  const state = useLocation().state as LocationState;
  const navigate = useNavigate()

  const dispatch = useAppDispatch();
  const [doAuth, { loading, error }] = useMutation<AuthDataData, AuthVars>(GET_AUTH, {
    onCompleted(data, clientOptions) {
      if(data){
        const authVars = clientOptions?.variables as AuthVars
        auth.login(data.auth, authVars.type);
        if(data.auth.requests){
          dispatch(setAllRequests(data.auth.requests));
        }
      }
    },
    onError(error){
    }
  });
  
  useEffect(() => {
    if(auth.user != null){
      if(state?.from?.pathname){
        navigate(state.from.pathname + state.from.search);
      }else{
        navigate("/templates/mine");
      }
    }
  }, [platform, auth, navigate, state?.from?.pathname, state?.from?.search]);

  const formHandler = useForm<LoginFormData>({mode: "onChange"});
  const { formState: {isValid, isSubmitted} } = formHandler;

  useShowMenu();

  const onSubmitHandler = (data: LoginFormData) => {
    if(isValid) {
      platform.authService.provideAuthData()
        .then(provided => {
          const auth: AuthVars = provided || { type: "password"};
          auth.login = data.login
          auth.password = data.password
          auth.rememberMe = data.rememberMe
          auth.type = "password"
          
          doAuth({ variables: auth });
        })
    }
  }

  if(!auth.canLetLogin){
    return (
      <Navigate to={{pathname: '/start'}}/>
    )
  }
  return (
    <div>
      <EledoPageHeader/>

      <div className={classnames(styles.EledoLogin, "container")} data-testid="EledoLogin">
        <div className="section section-small">
          <div className="hero-paragraph text-center">
            <h1><b>Login</b></h1>
          </div>
          <FormProvider {...formHandler}>
            <form onSubmit={formHandler.handleSubmit(onSubmitHandler)}>
              <div style={{width: "20rem"}} className="center">
                <EledoFormGenerator formModel={LoginFormModel}
                                    editedDataObject={{}}/>

                {error && <span className="center text-center" style={{display: "block"}}>
                  <small className="error-message" style={{float: "initial"}}>Invalid credentials</small>
                </span>}

                <div className="hero-paragraph text-center">
                  <span data-tip={isSubmitted || isValid || loading ? "" : "Please check your credentials before logging in"}
                        data-for={isSubmitted || isValid || loading ? "" : 'login-button-tooltip'}>
                    <button className={"button"} type="submit"
                          {...(isValid ? {} : {"aria-disabled": "true"})}>
                      <EledoPendingContent state={loading}
                                          content="Log in"
                                          pendingContent="Logging In..."/>
                    </button>
                  <ReactTooltip
                    id="login-button-tooltip"
                    place="right"
                    type="warning"
                    effect="float"
                    multiline={true}
                  />
                  </span>
                </div>


                <p className="hero-paragraph"/>
                <p style={{lineHeight: "100%", textAlign: "right"}}>
                  If you don't have an account: <a href="https://eledo.online/register" target="_blank" rel="noreferrer">Register here</a>
                </p>
                <p style={{lineHeight: "100%", textAlign: "right"}}>
                  Forgot your password? <a href="https://eledo.online/recovery" target="_blank" rel="noreferrer">Recover it here</a>
                </p>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
}

export default EledoLogin;
