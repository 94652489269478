import { ReactElement, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from 'react'
// import { useRef } from 'react'

import { doesParamFit } from './utils/doesParamFit'
import { fnStartsWith } from './useAutocompleteOptions'

import { TNode } from 'featuresFed/fed_core/process_ast/TNode'
import { TOption } from './utils/TOption'
// import { TCurrParam } from '../paramInfo/TCurrParam'

import css from './FedAutocomplete.module.scss'
import './utils/autocompleteColors.scss'  // nejako mi nesli li farby a nechce sa mi s tym teraz babrat
// import { useFed } from '../_config/useFed'
import { TVar, TVarType } from 'featuresFed/fed_core/lang_vars/TVar'
import { useFedVariables } from 'featuresFed/fed_core/lang_vars/useFedVariables'
import { useFedFunctions } from 'featuresFed/fed_core/lang_fns/useFedFunctions'
import { FedContext } from '../_context/FedContext'
import { EledoLabel } from 'components/library'

type Props = {
  formula: string
  caretIdentifier: string
  onOptionActive?: (o: string) => void
  onApplyOption?: (needle: string, repl: string) => void
  currWantType: TVarType
}

const FedAutocomplete = forwardRef((props: Props, ref) => {
  const config = useContext(FedContext)
  const [search, setSearch] = useState<string>('')

  useImperativeHandle(ref, () => ({

    keyDown(e: any) {
      // console.log(e.key)
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        // moveSelection(e.key === 'ArrowUp' ? -1 : 1)
        // return e.preventDefault()
      }
      if (e.key === 'Enter' || e.key === 'Tab') {
        // applyOptionAt(selectedI)
        // return e.preventDefault()
      }
    }
  }))

  const applyVariable = useCallback((name: string) => {
    if(config.context) {
      name = name.substring(config.context?.length + 1);
    }
    props.onApplyOption?.(props.caretIdentifier, name);
    setSearch('')
  },[props.onApplyOption, setSearch, config.context, props.caretIdentifier])

  useEffect(() => {
    setSearch('')
  }, [props.formula])

  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{width: '50%'}}>
          <form>
            <fieldset>
              <EledoLabel id="fieldsAndFunctions" label={"Fields and Functions"}/>
            </fieldset>
          </form>
        </div>
        <div style={{width: '50%'}}>
          <div style={{display: 'flex', flexDirection: 'row', width: '100%', overflow: 'hidden'}}>
            <div style={{width: '25px', padding: '3px'}}>
              <i className="icon-search"/>
            </div>
            <div style={{flexGrow: 1}}>
              <input value={search} onChange={e => setSearch(e.target.value)} style={{width: '100%'}}/>
            </div>
          </div>
        </div>
      </div>
      <div className={css.main} style={{flexGrow: 1}}>
        <div style={{overflowY: 'auto'}}>
          <TreeView context={config.context || ""}
            path={props.caretIdentifier || ""}
            search={search}
            expectedType={props.currWantType}
            applyVariable={applyVariable}
          />
          <FunctionsLayer filter={search || props.caretIdentifier} fulltext={search ? search.length > 0 : false} expectedType={props.currWantType}
            applyFunction={(name, params) => {props.onApplyOption?.(props.caretIdentifier, name + "(" + Array(params).join(', ') + ")");setSearch('')}}
            setActive={(name) => props.onOptionActive?.(name)}/>
        </div>
      </div>
    </div>
  )

})

const TreeView = ({context, path, search, expectedType, applyVariable}: {context: string, path: string, search: string, expectedType: TVarType, applyVariable: (name: string) => void}) => {

  const { getVarTree } = useFedVariables()

  const children = getVarTree(context, path, search)

  return (
    <>
      <TreeLayer children={children}
        context={context}
        filter={path}
        fulltext={path ? path.length > 0 : false}
        expectedType={expectedType}
        depth={0}
        applyVariable={applyVariable}/>
    </>
  )
}

const TreeLayer = ({children, context, filter, fulltext, depth, expectedType, applyVariable}:{children: TVar[] | undefined, context:string, filter:string, fulltext:boolean, depth:number, expectedType: TVarType, applyVariable: (name: string) => void}):ReactElement => {
  if(children === undefined){
    return (<></>)
  }
  const options = children && children.map((c) => { return {
    ...c,
    outputType: c.type,
    info: ' (' + c.type + ')',
    identType: 'identType-' + c.type
  } as TOption})

  return (
    <div key={'ul_' + context} className={'FedAutocomplete'}>
      { options && options.map(option => (
        <div key={'li_' + depth + '.' + option.name + '.' + option.outputType}>
          {
          <div
            className={'item'
              + ' ' + (doesParamFit(option.outputType, expectedType) ? '' : 'doesntFit')
            }
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              applyVariable(context + (context ? '.' : '') + option.name)
              e.preventDefault()
              e.stopPropagation()
          }}
          >
            { depth > 0 && [...Array(depth)].map((e, i) =>
              <div key={'li_' + depth + '.' + option.name + '.' + option.outputType + i} className='fragment'><div style={{borderLeft: '1px solid black', height:'100%', width: 0, margin: 'auto'}}/></div>
              )
            }
            { (option.outputType === 'Array' || option.outputType === 'Object') ?
              <div className='fragment'>{  <>+</> }</div> :
              <div className='fragment'><div style={{borderLeft: '1px solid black', height:'100%', width: 0, margin: 'auto'}}/></div>
            }
            <div className='title'>
              <span>{option.title ? option.title + ' (' + option.name + ')' : option.name}</span>
            </div>
            <div className='type'>
              {option.info || ''}
            </div>
          </div>
          }
          <div>
            { (option.outputType === 'Array' || option.outputType === 'Object') &&
              <>
                <TreeLayer children={children.find(c => c.name === option.name)?.children} context={context + (context ? '.' : '') + option.name} filter={filter} fulltext={fulltext} depth={depth + 1} expectedType={expectedType} applyVariable={applyVariable}/>
              </>
            }
          </div>
        </div>
      ))}
    </div>
  )
}

const FunctionsLayer = ({filter, fulltext, expectedType, applyFunction, setActive}:{filter:string, fulltext:Boolean, expectedType: TVarType, applyFunction: (name: String, params: Number) => void,
    setActive: (name: string) => void}):ReactElement => {
  const { getFns } = useFedFunctions()

  const functions = getFns().filter(f => fulltext ? f.name.indexOf(filter?.toUpperCase()) >= 0 : fnStartsWith(f.name, filter))

  return (
    <div className={'FedAutocomplete'}>
      { functions && functions.map(f => (
        <div key={'li_' + f.name}
          className={'item'
            + ' ' + (doesParamFit(f.returnType, expectedType) ? '' : 'doesntFit')
          }
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            applyFunction(f.name, f.baseParams)
            e.preventDefault()
            e.stopPropagation()
          }}
          onMouseOver={(e: React.MouseEvent<HTMLDivElement>) => { setActive && setActive(f.name) }}
        >
          <div style={{width: '24px', textAlign: 'center'}}>=</div>
          <div className='title'>
            <span>{f.name}</span>
          </div>
          <span>{f.returnType}</span>
        </div>
      ))}
    </div>
  )
}

export { FedAutocomplete }




