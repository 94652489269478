// toto je fugly, ale nejako to nejde
import { createToken, Lexer } from "chevrotain"


// regexes
const reWhitespace    = /\s+/
const reLparen        = /\(/
const reRparen        = /\)/
const reFnStartLp     = /[a-zA-Z_][a-zA-Z_0-9]*\(/    // zakazujeme whitespace, lebo backend tak funguje
// const reFnEnd        = /\(/
const reOperator      = /\*|\/|\+|==|=|>|<|>=|<=|!=|%/
const reMinus         = /-/   // minus treba zvlast lebo je aj unary

const reNumber        = /\d+(?:\.\d*)?|\.\d+/
const reString        = /["](?:\\[\s\S]|[^"])*["]|['](?:\\[\s\S]|[^'])*[']/
const reVariable      = /(?:(?:\$|\$\^|#)[a-zA-Z]\w*)|(?:[a-zA-Z_]\w*(?:(?:\.|\.\$)[a-zA-Z_]\w*)*)\.?/

const reProperty      = /\[[a-zA-Z0-9$_]+\]/
const reComma         = /,/

const reBadPartial    = /\./
const reBad           = /./


// chevrotain tokens
const Whitespace = createToken({
  name: "Whitespace",
  pattern: reWhitespace,
  group: Lexer.SKIPPED,
})
const FnStartLp = createToken({
  name: 'FnStartLp',
  pattern: reFnStartLp,
})
const Lparen = createToken({
  name: 'Lparen',
  pattern: reLparen,
})
const Rparen = createToken({
  name: 'Rparen',
  pattern: reRparen,
})
const Operator = createToken({
  name: 'Operator',
  pattern: reOperator,
})
const Minus = createToken({
  name: 'Minus',
  pattern: reMinus,
})

const Number = createToken({
  name: 'Number',
  pattern: reNumber,
})
const String = createToken({
  name: 'String',
  pattern: reString,
})
const Variable = createToken({
  name: 'Variable',
  pattern: reVariable,
})
const Property = createToken({
  name: 'Property',
  pattern: reProperty,
})
const Comma = createToken({
  name: 'Comma',
  pattern: reComma,
})
const BadPartial = createToken({
  name: 'BadPartial',
  pattern: reBadPartial,
})
const Bad = createToken({
  name: 'Bad',
  pattern: reBad,
})


/* SPECIAL STUFF THAT MIGHT COME HANDY LATER

  pattern: Lexer.NA,
  categories: AdditionOperator,
*/


const allTokens = [
  Whitespace,   // "first for performance"
  FnStartLp,
  Lparen,
  Rparen,
  Operator,
  Minus,

  Number,
  String,
  Variable,

  Property,
  Comma,

  BadPartial,
  Bad,
];

const mapTokens = {
  Whitespace,   // "first for performance"
  FnStartLp,
  Lparen,
  Rparen,
  Operator,
  Minus,

  Number,
  String,
  Variable,

  Property,
  Comma,

  BadPartial,
  Bad,
};

const MyLexer = new Lexer(allTokens);


export { MyLexer, mapTokens, allTokens }
