import ReactTooltip from "react-tooltip"
import classnames from "classnames"

import { GenerationLog } from "graphql/GenerationLogQueries"
import { LOG__TYPES__INFO, LOG__SEVERITY_INFO } from "model/enums"
import utils from "utils"

import EledoModal from "components/library/EledoModal/EledoModal.lazy"
import TemplateLink from "../TemplateLink/TemplateLink"
import css from "./LogDetailModal.module.scss"


type LogDetailModalProps = {
  log: GenerationLog
  detailModalDisplayed: boolean
  onModalClosed: () => void
}


const LogDetailModal = (props: LogDetailModalProps) => {
  const detailModalContent = (<>
    <div className={classnames(css.logDetail, 'col-wrapper')}>
      <div className='col-50'>
        <label>Time</label>
        <div>{utils.objectIdToDate(props.log.id)}</div>

        <label>Template</label>
        <TemplateLink templateName={props.log.template?.name} templateId={props.log.template?.id} />

        <label>Source</label>
        <div>{props.log.source ? props.log.source : "-"}</div>

        <label>Type</label>
        <div>
          <i className={LOG__TYPES__INFO[props.log.type]?.icon} />&nbsp;
          ({LOG__TYPES__INFO[props.log.type]?.desc})
        </div>

        <label>Level</label>
        <div>
          <i className={LOG__SEVERITY_INFO[props.log.logLevel]?.icon}/>&nbsp;
          ({LOG__SEVERITY_INFO[props.log.logLevel]?.desc})
        </div>
      </div>
      <div className='col-50'>
        <label>Documents</label>
        <div>{props.log.documents}</div>

        <label>Pages</label>
        <div>{props.log.pages}</div>

        <label>Preload Time</label>
        <div>{props.log.preloadTime} ms</div>

        <label>Generation Time</label>
        <div>{props.log.genTime} ms</div>

        <label>Upload Time</label>
        <div>{props.log.uploadTime} ms</div>

        <label>Actions Time</label>
        <div>{props.log.actionsTime} ms</div>
      </div>
    </div>
    { props.log.messages &&
      <div className={classnames(css.logMessages, 'container')}>
        { props.log.messages.map((logMessage, index) => (
          <div key={index} className={classnames('w-layout-grid', 'data-grid', 'logDetail', css.messageGrid, LOG__SEVERITY_INFO[logMessage.severity+1]?.color)}>
            <div id="w-node-_8f485d4a-1d97-cad0-f4f3-52cb96d63492-dd191fb9" className='_wf-table-cell'>
              <div className={classnames('tile-title', css.tileTitle)}>Severity</div>
              <div data-tip={`Log Severity: ${LOG__SEVERITY_INFO[logMessage.severity+1]?.desc}`} data-for={"log-severity-tooltip"}>
                <i className={LOG__SEVERITY_INFO[logMessage.severity+1]?.icon}/>
              </div>
              <ReactTooltip id="log-severity-tooltip" place="top" type="dark" effect="float" multiline={false} />
            </div>
            <div className='_wf-table-cell'>
              <div className={classnames('tile-title', css.tileTitle)}>Message</div>
              <div>{ logMessage.message }</div>
            </div>
          </div>
        ))}
      </div>
    }
  </>)


  return (
    <EledoModal
      title="Log Detail"
      content={props.detailModalDisplayed && props.log ? detailModalContent : null}
      displayed={props.detailModalDisplayed}
      actions={<></>}
      onDismiss={props.onModalClosed}
    />
  )
}


export default LogDetailModal