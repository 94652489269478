import eledo from "_api/eledo"

// const REGISTER_URL = LOGIN_URL + '/register';
const USER_URL = '/user';
const USER_PROFILE_URL = USER_URL + 'Profile';
const CLOSE_ACCOUNT_URL = '/userProfileDelete';

const fetchUser = () => eledo.get(USER_PROFILE_URL);
const persistUser = user => eledo.put(`${USER_PROFILE_URL}`, user);
const wasCloseAccountRequested = () => eledo.get(CLOSE_ACCOUNT_URL);
const closeAccount = () => eledo.post(CLOSE_ACCOUNT_URL, {});
const cancelCloseAccount = () => eledo.delete(CLOSE_ACCOUNT_URL);

const userService = {
  fetchUser,
  persistUser,
  wasCloseAccountRequested,  closeAccount,  cancelCloseAccount
};

export default userService;
