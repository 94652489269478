import { TNode } from "featuresFed/fed_core/process_ast/TNode"

export function traverseFind_inArray(arr: TNode[], fn: (node: TNode) => boolean) {

    for (let i=0; i < arr.length; i++) {
        let found = traverseFind(arr[i], fn)
        if (found)
            return found
    }
}

export function traverseArray(arr: TNode[], fn: (node: TNode) => void) {

    for (let i=0; i < arr.length; i++)
        traverse(arr[i], fn)
}

export function traverse(tree: TNode, fn: (node: TNode) => void) {
    if (!tree) return
    fn(tree)

    if (tree.kids)
        tree.kids.forEach(n => traverse(n, fn))

    return tree
}

export function traverseFind(tree: TNode | undefined, fn: (node: TNode) => boolean): TNode | undefined {
    
    if (!tree) return

    let found = fn(tree)
    if (found)
        return tree

    if (tree.kids) {
        for (let i=0; i < tree.kids.length; i++) {
            let k = tree.kids[i]
            let found = traverseFind(k, fn)
            if (found)
                return found
        }

    }
}

