import { useMutation } from '@apollo/client';
import { AuthContext } from 'AuthContext';
import usePuzzleStructure from 'graphql/hooks/usePuzzleStructure';
import React, { useContext, useState } from 'react'
import { CREATE_PUZZLE_RESPONSE, PuzzleResponse } from 'graphql/PuzzleResponse';
import PuzzleView from 'components/puzzle/PuzzleView';
import { EledoLoader } from 'components/library';

function usePuzzle(name: string, onSkip?: Function, onSubmit?: Function) {
  
  const auth = useContext(AuthContext);
  
  const { loading, puzzleStructure } = usePuzzleStructure({name});

  const [data, setData] = useState({});

  const [ createPuzzleResponse ] = useMutation(CREATE_PUZZLE_RESPONSE);

  const renderPuzzle = () => (
    <React.Fragment>
      
      { loading && <EledoLoader/> }
      
      { puzzleStructure?.component !== undefined &&
        <PuzzleView puzzleStructure={puzzleStructure} data={data} setData={setData} onSubmit={() => { submitPuzzleResponse(); onSubmit && onSubmit();}} onSkip={onSkip} />
      }
    </React.Fragment>
  );

  const submitPuzzleResponse = () => {
    const puzzleResponse: PuzzleResponse = {
      structureId: puzzleStructure?.id,
      userId: auth.user?.id,
      connectionId: auth.connection?.id,
      response: data
    }
    
    createPuzzleResponse({ variables: { puzzleResponse } })
      .catch(e => {

      });
  }

  return {
    loading: loading,
    renderPuzzle: () => renderPuzzle(),
    submitPuzzleResponse: () => submitPuzzleResponse()
  };
}

export default usePuzzle