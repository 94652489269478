import React, { useEffect, useContext } from 'react';
import styles from './EledoUserProfile.module.scss';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
  fetchUser, saveUser_userValid, saveUser, persistUser,
  closeAccount as closeAccountAction,
  cancelCloseAccount as cancelCloseAccountAction,
  wasCloseAccountRequested as wasCloseAccountRequestedAction,
} from "_reducers/userReducer";
import {useForm, FormProvider} from "react-hook-form";
import {showNotyf, useErrorNotyf, useShowMenu} from "hooks";
import {
  BillingFormModel as billingModel,
  OperationalNotificationsFormModel,
  PaymentNotificationsFormModel,
  TableFieldIdValidation,
  TableFieldStringValueValidation
} from "model";
import ReactTooltip from "react-tooltip";
import {
  EledoMainPageTitle, EledoPendingIcon,
  EledoLoader, EledoError, EledoPendingContent,
  EledoFormGenerator, EledoPropertyList, EledoTabs,
  EledoLabel,
} from "components/library";
import TabGeneral from "./TabGeneral";
import TabSubscription from "./TabSubscription";
import classnames from 'classnames';
import utils from "utils";
import { fetchUserPlan } from '_reducers/pricingPlansReducer';
import { AuthContext } from 'AuthContext';
import { PlatformContext } from 'PlatformContext';
import { useMutation } from '@apollo/client';
import { GENERATE_NEW_API_KEY } from 'graphql/AuthQueries';
import NotyfContext from 'context/NotyfContext';

const EledoUserProfile = () => {
  const userInfo = useSelector(state => state.user?.user);
  const userPlan = useSelector(state => state.pricing?.userPlan);
  const userChanged = useSelector(state => state.user.userChanged);
  const userValid = useSelector(state => state.user.userValid);
  const [loading, error] = useSelector(state => [
    state.user.loading, state.user.error
  ], shallowEqual);
  const closeAccountLoading = useSelector(state => state.user.termination.loading);
  const [saving, savingError] = useSelector(state => [
    state.user.saving, state.user.savingError
  ], shallowEqual);
  const formHandler = useForm({mode: "all"});
  //!!the following two lines need to be here to activate the hook-form:
  // eslint-disable-next-line no-unused-vars
  const { formState: {isValid}, watch } = formHandler;
  const dispatch = useDispatch();
  const platform = useContext(PlatformContext);
  const isEmbedded = platform.isEmbedded;
  
  useErrorNotyf(error);
  useShowMenu();

  //fetch the user after component render
  useEffect(() => {
      dispatch(fetchUser());
      dispatch(fetchUserPlan());
  }, [dispatch]);

  useEffect(() => {
    dispatch(saveUser_userValid(isValid));
  }, [isValid, dispatch]);

  useEffect(() => {
    const subscription = watch((data) => {
      if (userInfo?.profile) {
        dispatch(saveUser(utils.deepCopy(data)));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, dispatch, userInfo]);

  useErrorNotyf(savingError, `Error saving${savingError?.message ? ': ' + savingError?.message : ''}.`);

  const handleSave = (data) => {
    if(userValid && userChanged) {
      const formUser = utils.deepCopy(formHandler.getValues());
      const newUser = {...userInfo, ...formUser};
      newUser.accounting = {...userInfo.accounting, ...formUser.accounting};
      newUser.profile = formUser.profile;
      newUser.notifications = formUser.notifications;
      dispatch(persistUser(newUser));
    }
  };

  const renderError = () =>
    <EledoError title={error?.message}
                description={(<React.Fragment>
                  We are sorry &#9785; We could not load your user data. <br/><br/>
                  {(error?.message ? error.message.toLocaleLowerCase() : error) === "network error" && "Please check your internet connection."}
                </React.Fragment>)}/>;

  return (
    <div>
      <div className={styles.EledoUserProfile} data-testid="EledoUserProfile">
        <div className={styles.TopToolbar}>
          <EledoMainPageTitle
            title="Your Profile"
          />
          <div className={classnames("section", "my-section", styles.ButtonDiv)}>
            <span
              data-tip={userValid ? userChanged ? "" : "Your profile is saved" : "Please check the validity of your input data"}
              data-for="save-invalid-tooltip">
              <button className="button outline w-button"
                      onClick={handleSave}
                      {...(userChanged && userValid ? {} : {"aria-disabled": "true"})}>
                { saving ?
                    <React.Fragment>
                      <EledoPendingIcon/> Saving...
                    </React.Fragment> :
                    <React.Fragment>
                      <i className="icon-floppy-disk"/> Save
                    </React.Fragment>
                }
              </button>
            </span>
          </div>

          <ReactTooltip
            id="save-invalid-tooltip"
            place="bottom"
            type="dark"
            effect="float"
            multiline={true}
          />
        </div>

        <div className="stretch-vertically">
          <FormProvider {...formHandler}>
            <form>
              <EledoTabs id="userProfileTabs">
                <div label="General">
                  {!error && !loading && <TabGeneral userInfo={userInfo} planInfo={userPlan}/>}
                </div>
                <div label="Properties">
                  {!error && !loading && userInfo?.profile?.properties && <EledoPropertyList
                    defaultValues={userInfo.profile.properties}
                    fields={[
                      {id: "key", label: "Property Name", type: "text", validation: TableFieldIdValidation},
                      {id: "value", label: "Property Value", type: "text", validation: TableFieldStringValueValidation}
                    ]}
                    path="profile.properties"
                  />}
                </div>
                <div label="Counters">
                  {!error && !loading && userInfo?.profile?.counters && <EledoPropertyList
                    defaultValues={userInfo.profile.counters}
                    fields={[
                      {id: "key", label: "Counter Name", type: "text", validation: TableFieldIdValidation},
                      {id: "value", label: "Counter Value", type: "number"},
                    ]}
                    path="profile.counters"
                  />}
                </div>
                <div label="API">
                  {!error && !loading && !isEmbedded && <TabAPI/>}
                </div>
                <div label="Subscription">
                  <TabSubscription/>
                </div>
                <div label="Billing">
                  {!error && !loading && <TabBilling userInfo={userInfo}/>}
                </div>
                <div label="Notifications">
                  {!error && !loading && <TabNotifications userInfo={userInfo}/>}
                </div>
                <div label="Account termination">
                  {!error && !loading && !closeAccountLoading && <TabAccountTermination userInfo={userInfo}/>}
                </div>
              </EledoTabs>
              { loading && <div className="flex-center"><EledoLoader/></div>}
              { error && renderError() }
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
}

const TabAPI = () => {
  const auth = useContext(AuthContext);
  const userData = auth.user;
  const notyf = useContext(NotyfContext);

  const [generateNewApiKey, { loading }] = useMutation(GENERATE_NEW_API_KEY, {
    update(proxy, { data }) {
      if(data){
        auth.newApiKey(data.apiToken);
      }
    },
    onError(error){
      showNotyf(error, notyf, "error");
    }
  });

  return (
    <div>
      { userData &&
        <fieldset>
          <EledoLabel id="apiKey" label="API Key"/>
          <input id="apiKey" style={{cursor: "initial"}}
                 value={auth.apiToken}
                 disabled={true}/>
        </fieldset>
      }
      <button className="button w-button outline"
              onClick={e => {e.preventDefault();generateNewApiKey()}}>
        <EledoPendingContent state={loading}
                             content="Generate new API Key"
                             pendingContent="Generating..."/>
      </button>
    </div>
  );
}

const TabBilling = ({ userInfo }) => {
  return (
    <div>
      {userInfo?.accounting?.billTo && <EledoFormGenerator
        formModel={billingModel}
        editedDataObject={userInfo}
      />}
    </div>
  );
};

const TabNotifications = ({ userInfo }) => (
  <div>
    <h3>Operational notifications</h3>
    {userInfo?.notifications && <EledoFormGenerator
      formModel={OperationalNotificationsFormModel}
      editedDataObject={userInfo}
    />}
    <h3>Payment notifications</h3>
    {userInfo?.notifications && <EledoFormGenerator
      formModel={PaymentNotificationsFormModel}
      editedDataObject={userInfo}
    />}
  </div>
);

const TabAccountTermination = ({userInfo}) => {
  const {status, error, closeAccount, cancelCloseAccount}
    = useSelector(state => state.user.termination);
  const dispatch = useDispatch();

  useEffect(() => {
    if(status === null) {
      dispatch(wasCloseAccountRequestedAction());
    }
  }, [dispatch, status]);


  useErrorNotyf(error);
  useErrorNotyf(closeAccount.error);
  useErrorNotyf(cancelCloseAccount.error);

  const handleCloseAccount = e => {
    e.preventDefault();
    dispatch(closeAccountAction());
  }

  const handleCancelTermination = e => {
    e.preventDefault();
    dispatch(cancelCloseAccountAction());
  }

  return (
    <div>
      <h3>Account termination</h3>
      <p style={{marginBottom: "1rem"}}>
        { status === true &&
          "Your account termination is being reviewed."}
        { status === false &&
          "You can get your account deleted by requesting its termination. " +
          "Your account will be deleted after a confirmation from the Eledo team."
        }
      </p>
      { status === true &&
        <button
          className="button w-button"
          onClick={e => handleCancelTermination(e)}>
          {cancelCloseAccount.loading}
          <EledoPendingContent state={cancelCloseAccount.loading}
                               content="Cancel Account Termination"
                               pendingContent="Cancelling..."/>
        </button>}
      { status === false &&
        <button
          className="button w-button red"
          onClick={e => handleCloseAccount(e)}>
          <EledoPendingContent state={closeAccount.loading}
                               content="Close my Account"
                               pendingContent="Closing your account..."/>
        </button>
      }
    </div>
  );
};

export default EledoUserProfile;

