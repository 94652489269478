import classnames from "classnames"
import ReactTooltip from "react-tooltip"

import { FieldType } from "../EledoPdfEditor"

import css from "./TopToolbarButton.module.scss"


type ToolbarButtonProps = {
  title: string
  icon: string
  type: FieldType
  disabled: boolean
  onClick: (fieldType: FieldType) => void
}


const BUTTON_ICONS: {[key: string]: number} = {
  text: -1240,
  dynamicImage: -1040,
  checkbox: -960,
  barcode1D: -600,
  barcode2D: -1120,
}


const TopToolbarButton = (props: ToolbarButtonProps) => {
  return (<>
    <button
      className={(classnames(css.toolbarButton, props.disabled && css.disabled))}
      data-tip={props.title}
      data-for={`pdf-toolbar-tooltip__${props.title.replace(' ', '-')}`}
      onClick={() => props.onClick(props.type)}
    >
      <span
        className={css.icon}
        style={{
          backgroundImage:'url("https://eledo.online/modules.gz/ckeditor/plugins/icons.png?t=M6VF")',
          backgroundPosition: `0 ${BUTTON_ICONS[props.icon]}px`,
          backgroundSize: 'auto'
        }}
      ></span>
    </button>
    <ReactTooltip id={`pdf-toolbar-tooltip__${props.title.replace(' ', '-')}`} place="bottom" type="dark" effect="float" multiline={false} />
  </>)
}


export default TopToolbarButton