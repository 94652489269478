import classnames from "classnames";
import React, {PropsWithChildren, ReactNode} from "react";
import {nanoid} from "nanoid";
import ReactTooltip from "react-tooltip";
import {Link} from 'react-router-dom';
import EledoPendingIcon from "./EledoPendingIcon";

export enum IconPosition {LEFT, RIGHT}
type Color = undefined | '' | 'red' | 'yellow' | 'white';
type TooltipPosition = undefined | 'top' | 'right' | 'bottom' | 'left';
type TooltipType = undefined | 'dark' | 'success' | 'warning' | 'error' | 'info' | 'light';
export enum ButtonSize { DEFAULT = '', SMALL = 'small-space' };

interface EledoButtonProps {
  id?: string,
  label?: string,
  primary?: boolean,
  filled?: boolean,
  submit?: boolean,
  disable?: boolean,
  color?: Color, red?: boolean, yellow?: boolean, white?: boolean,
  noBorder?: boolean,
  size?: ButtonSize;
  greenHover?: boolean,
  grow?: boolean,
  noSpace?: boolean,
  icon?: string|null|undefined,
  rectangular?: boolean,
  iconPosition?: IconPosition,
  asLink?: boolean,
  to?: string,
  pendingState?: boolean|null,
  pendingContent?: null|ReactNode|string,
  tooltip?: string|null|undefined,
  tooltipId?: string|null|undefined,
  showTooltip?: boolean,
  tooltipPosition?: TooltipPosition,
  tooltipType?: TooltipType,
  tooltipMultiline?: boolean,
  children?: ReactNode,
  style?: object,
  className?: string,
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  props?: PropsWithChildren<any>,
}

const EledoButton = ({ id, label,
                       primary = false, filled = false,
                       submit = false,
                       disable = false,
                       color = '', red = false, yellow = false, white = false,
                       noBorder = false, size = ButtonSize.DEFAULT,
                       greenHover = false, grow = false, noSpace = false,
                       icon = null, rectangular = false, iconPosition = IconPosition.LEFT,
                       asLink = false, to = '',
                       pendingState = null, pendingContent = null,
                       tooltip = null, tooltipId = null, showTooltip = false,
                       tooltipPosition = 'bottom', tooltipType = 'dark', tooltipMultiline = false,
                       children, style, className, onClick, ...props } : EledoButtonProps) => {
  const randomId = nanoid();
  const ttid = tooltipId ? tooltipId : `button-tooltip-${randomId}`;

  const iconElem = icon ? <i className={`icon-${icon}`}
                             style={(iconPosition === IconPosition.LEFT) ?
                               ((children || label) ? {} : {marginRight: 0}) : {marginLeft: 0}}/> : null;

  if(white) color = 'white';
  if(yellow) color = 'yellow';
  if(red) color = 'red';

  const renderContent = () => {
    const content = children ? children : label;
    return pendingContent && pendingState ? pendingContent : content;
  }

  const renderIcon = () => (pendingContent && pendingState) ?
    <EledoPendingIcon style={{marginRight: "0.75em"}}/>
    : iconElem;

  const classNames = classnames(
    "button", "w-button",
    (!filled && !primary && !noBorder) && "outline",
    grow && "grow",
    noSpace && "no-space",
    rectangular && "rectangular",
    color,
    greenHover && "green-hover",
    noBorder && 'none',
    size,
    className
  );

  const shouldShowTooltip = () => tooltip && showTooltip;
  const renderTooltip = () => shouldShowTooltip() && !tooltipId
    ? <ReactTooltip id={ttid}
                    place={tooltipPosition}
                    type={tooltipType}
                    effect="float"
                    multiline={tooltipMultiline}/>
    : null;

  const renderButtonContent = () => <>
    {iconPosition === IconPosition.LEFT ? renderIcon() : null}
    {renderContent()}
    {iconPosition === IconPosition.RIGHT ? renderIcon() : null}
    {renderTooltip()}
  </>

  if(asLink) return (
    <Link to={to}
          {...props} id={id}
          className={classNames}
          style={{backgroundImage: "none !important", ...style}}
          {...(tooltip ? {"data-tip": tooltip} : {})}
          {...(tooltip ? {"data-for": ttid} : {})}>
      {renderButtonContent()}
    </Link>
  )

  return (
    // @ts-ignore
    <button {...props} id={id}
            className={classNames}
            style={style}
            onClick={onClick}
            {...(submit ? {"type": "submit"} : {"type": "button"})}
            {...(disable ? {"aria-disabled": "true"} : {})}
            {...(disable ? {"disabled": true} : {})}
            {...(tooltip ? {"data-tip": tooltip} : {})}
            {...(tooltip ? {"data-for": ttid} : {})}>
      {renderButtonContent()}
    </button>
  )
};

export default EledoButton;
