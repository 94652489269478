import React, {useEffect, useRef, useState} from 'react';
import styles from './EledoStylesEditor.module.scss';
import {saveTemplate, saveTemplateStylesValid} from "_reducers/templateReducer";
import {EledoLabel} from "components/library/EledoFormsComponents";
import {useDispatch, shallowEqual, useSelector} from "react-redux";
import {EledoLoader, /*EledoTabs*/} from "components/library";
import {useHideMenu} from "hooks";
import utils from "utils";
import classnames from "classnames";

const EledoStylesEditor = ({isBackground}) => {
  const [editedTemplate, activePageId, templateLoading] = useSelector(state => [
    state.templates.editedTemplate,
    state.templates.activePageId,
    state.templates.templateLoading
  ], shallowEqual);
  const [isValid, setValid] = useState(true);
  const [styleValue, setStyleValue] = useState(null);
  const dispatch = useDispatch();
  const placeholderText = `Please define your specific ${isBackground ? 'background' : ''}
                           document style in a CSS format 
                           (the CSS applies on the ${isBackground ? 'background' : ''} document's
                           body element and all its sub-elements)`;
  const textareaRef = useRef();

  useEffect(() => {
    dispatch(saveTemplateStylesValid(isValid));
  }, [isValid, dispatch]);

  useEffect(() => {
    if(styleValue === null && !templateLoading && editedTemplate?.documents[activePageId]?.innerDocument) {
      let document = editedTemplate.documents[activePageId].innerDocument;
      let initialValue = isBackground ? document.bgStyle : document.style;
      //need to trim because of an issue in the css style editor
      if(initialValue) initialValue = initialValue.trim();
      if(initialValue === null) initialValue = "";
      setStyleValue(initialValue);
    }
  }, [editedTemplate, activePageId, templateLoading, styleValue, isBackground]);

  useHideMenu();

  const handleStyleChangeInteractive = (value) => {
    setStyleValue(value);
    setValid(value.length <= 65536);
    const newTemplate = utils.deepCopy(editedTemplate);
    const document = newTemplate.documents[activePageId].innerDocument;
    if(isBackground){
      document.bgStyle = value;
    }else{
      document.style = value;
    }
    // eslint-disable-next-line no-unused-vars
    dispatch(saveTemplate(newTemplate));
  };

  const handleStyleChangeSimple = (e) => {
    handleStyleChangeInteractive(e.target.value);
  }

  const handleKeyDownSimple = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      const selectionEnd = e.target.selectionEnd;
      const value = textareaRef.current.value;
      const newValue = [value.slice(0, selectionEnd), "\t", value.slice(selectionEnd)].join('');

      textareaRef.current.focus();
      textareaRef.current.value = newValue;
      textareaRef.current.setSelectionRange(
        selectionEnd + 1,
        selectionEnd + 1
      );

      setStyleValue(newValue);
    }
  }

  const renderLabelAndError = () => (
    <div>
      <EledoLabel id="styleLabel" label={placeholderText}/>
      <p style={{display: "inline-block"}}><small className="error-message">{isValid ? "" : "You may provide at most 65536 characters for Csscontent."}</small></p>
    </div>
  );

  const renderLoader = () => (<div className={styles.DocumentLoader}><EledoLoader/></div>);
  //const tabContentStyle = {height: "calc(100% - 60px)", minHeight: "calc(100% - 60px)", overflowY: "auto", overflowX: "hidden"};

  return (
    <div className={classnames("stretch-vertically", styles.EledoStyle)} data-testid="EledoStyle">
      <form className="w-form">
        <fieldset className="stretch-vertically">
          <div className="section section-small">
            <h3>CSS Styles for {isBackground ? 'Background Template' : 'Main Template'}</h3>
          {/*<EledoTabs id="styleEditorTabs" tabContentStyle={tabContentStyle}>*/}
            {/* <div label="Interactive editor">
              { renderLabelAndError() }
              { templateLoading || styleValue === null ?
                renderLoader() :
                <StyleEditor
                  value={styleValue}
                  placeholder={placeholderText}
                  className={styles.StyleEditor}
                  onChange={(value) => handleStyleChangeInteractive(value.trim())}
                  style={{overflow: "scroll", maxHeight: "100%"}}
                />
              }
            </div> */}
            {/*<div label="Simple editor">*/}
              { renderLabelAndError() }
              {  templateLoading || styleValue === null ?
                renderLoader() :
                <textarea className={classnames("stretch-vertically", isValid ? "" : styles.invalid)} id="csscontent" cols={80}
                          ref={textareaRef}
                          value={styleValue}
                          placeholder={placeholderText}
                          onChange={handleStyleChangeSimple}
                          onKeyDown={handleKeyDownSimple}/>
              }
          </div>
          {/*</EledoTabs>*/}
          {/*</div>*/}
        </fieldset>
      </form>
    </div>
  );
}

export default EledoStylesEditor;
