import { useEffect, useState } from 'react'

import { useFedFunctions } from '../../fed_core/lang_fns/useFedFunctions'

import css from './FedHelpUi.module.scss'
import { TFunc } from 'featuresFed/fed_core/lang_fns/TFunc'
import { marked } from 'marked'


type Props = {
    caretIdentifier?: string
    parentFnName?: string
}

export function FedHelpUi(props: Props) {

    const [fn, set_fn] = useState<TFunc | undefined>()
    const {getFnInfo} = useFedFunctions()

    useEffect(() => {
        let fnName = props.caretIdentifier
        if (!fnName)
            return

        let fnInfo = getFnInfo(fnName)
        if (!fnInfo && props.parentFnName)
            fnInfo = getFnInfo(props.parentFnName)

        set_fn(fnInfo)
    }, [props.caretIdentifier, props.parentFnName, getFnInfo])
    
    function getParamInfo(fn: TFunc) {
        return fn.params?.map(p => p.name).join(', ')
    }

    function getDoc(fn: TFunc): string {
        const html = fn.doc && marked.parse(fn.doc)
        return html as string;
    }

    return (
        <>
            {fn &&
            <div style={{whiteSpace: 'pre-wrap'}}>
                {/* ={JSON.stringify(props.caretIdentifier, null, '  ')}= */}
                {/* <br/> */}
                <div>
                    <span className={css.bold}>
                        {fn.name} ({getParamInfo(fn)})
                    </span>
                    &nbsp;-&gt; {fn.returnType}
                </div>
                <div dangerouslySetInnerHTML={{
                  __html: getDoc(fn)
                }}>
                    
                </div>

                <br/>
                {fn.params?.map((p, pi) => (
                    <div key={pi}>- {p.name} - {p.type}</div>
                ))}
            </div>}
        </>
    )
}






/*

    let help1 = `
AND(cond_1, cond_2, [...cond_n])
- Checks if all conditions are met.
- Returns TRUE if all conditions are TRUE, FALSE otherwise.
Parameters:
    cond_1 - bool
    cond_2 - bool
    [cond_n] - bools, optional, as many as you like

Example:
    AND(TRUE, TRUE) -> TRUE
Example:
    AND(FALSE, TRUE) -> FALSE
Example:
    AND(TRUE, TRUE, TRUE, FALSE) -> FALSE
`

    let help2 = `
IF(condition, if_true, [if_false])
- Choose value based on condition.
- Returns if_true if condition is TRUE, otherwise returns if_false.
Parameters:
    condition - bool
    if_true - any, returned when condition is TRUE
    if_false - any, returned when condition is FALSE

Example:
    IF(TRUE, 1, 0) -> 1
Example:
    IF(FALSE, 'yes', 'no') -> 'no'
Example:
    IF(FALSE, 1) -> null    // if_false is optional
`

    let help3 = `?`


*/

