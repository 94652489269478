import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import documentFormatsService from "_api/documentFormats";
import utils from "../utils";

const loadingStates = {listLoaded: false, listLoading: false, listError: null};
const initialState = {
  selectedPage: 1,
  itemsTotal: 0,
  documentFormats: [],
  options: [],
  ...loadingStates,
};

export const fetchDocumentFormats = createAsyncThunk(
  'documentFormats/fetchDocumentFormats',
  async ({pageId, limit}, thunkApi) => {
    const response = await documentFormatsService.fetchDocumentFormats(pageId, limit);
    return {selectedPage: pageId, ...response.data};
  }
);

const documentFormatsSlice = createSlice({
  name: 'documentFormats',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchDocumentFormats.pending, state => {
      state.documentFormats = [];
      state.listLoading = true;
      state.listError = null;
    })
    builder.addCase(fetchDocumentFormats.fulfilled, (state, action) => {
      state.documentFormats = action.payload?.documentFormats;
      state.itemsTotal = action.payload.total;
      state.selectedPage = utils.getSelectedPage(action.payload.selectedPage, action.payload.total);
      state.listLoading = false;
      state.listLoaded = true;
      state.options = action.payload?.documentFormats.map( df => ({value: df.id, label: df.name}))
    });
    builder.addCase(fetchDocumentFormats.rejected, (state, action) => {
      state.documentFormats = [];
      state.listLoading = false;
      state.itemsTotal = 0;
      state.selectedPage = 1;
      state.listError = action.error;
      state.listloaded = true;
    });
  }
});

export default documentFormatsSlice.reducer;