import { useEffect, useState } from 'react'
import { useFed } from 'featuresFed/fed_plugins/_context/useFed'

import { TToken } from '../process_tokens/TToken'

type Props = {
  tokens: any
  caretPosition: number
  onChange_caretIdentifier: ((c: string) => void) | undefined
}

export function useCaretIdentifier({tokens, caretPosition, onChange_caretIdentifier}: Props) {

  const {log} = useFed()
  const [caretIdentifier, set_caretIdentifier] = useState('')
  const [identifierStart, set_identifierStart] = useState(-1)



  useEffect(() => {


  function getCurrentFn(pos: number, tokens: TToken[]) {
    
    // log('getCurrentFn pos', pos)

    for (let i=tokens.length-1; i >= 0; i--) {
      let t = tokens[i]
      if (t.type !== 'Variable' &&
          t.type !== 'FnStartLp')
        continue

      let endOffsetExtra = 0
      if (t.type === 'Variable') endOffsetExtra = 1
      if (t.type === 'FnStartLp') endOffsetExtra = 0

      // console.log('c', c)
      // console.log('t.start', t.startOffset)
      // console.log('t.end', t.endOffset)
      // console.log('t', t)
      if (pos >= t.startOffset && pos <= (t.endOffset || 0) + endOffsetExtra) {
        // console.log('t.name', t.name)
        log('getCurrentFn hit at', pos+' '+t.type)
        // log('t.type', t.type)
        // log('t.startOffset', t.startOffset)
        // log('t.endOffset', t.endOffset)
        set_identifierStart(t.startOffset)
        return t
      }
    }
    log('getCurrentFn hit at', '-')
    set_identifierStart(-1)
  }

    function determine(cPos: number, tokens: any) {
      let cfn = getCurrentFn(cPos, tokens)
      let ci = cfn ? cfn.text : ''
      // console.log('caretIdent', ci)
  
      set_caretIdentifier(ci)
  
      if (onChange_caretIdentifier)
        onChange_caretIdentifier(ci)
    }
    
    determine(caretPosition, tokens)

  }, [caretPosition, tokens, onChange_caretIdentifier, log])

  return {caretIdentifier, identifierStart}
}
