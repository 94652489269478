import {FormFieldTypes} from "./enums";
import {EMAIL_VALIDATION_PATTERN} from "utils";

export const LoginFormModel = {
  fields:  [
    { id: "login", label: "Account name (e-mail)", type: FormFieldTypes.SHORT_STRING },
    { id: "password", label: "Password", type: FormFieldTypes.PASSWORD },
    { id: "rememberMe", label: "Remember Me", type: FormFieldTypes.CHECKBOX },
  ],
  validation: {
    login: {
      pattern: {value: EMAIL_VALIDATION_PATTERN, message: "Account name must be a valid e-mail address."},
      required: {value: true, message: "Account name cannot be empty."},
      maxLength: {value: 50, message: "Account name cannot be longer than 50 characters."},
    },
    password: {
      required: { value: true, message: "You must provide a value for Password."},
      maxLength: { value: 20, message: "You may provide at most 20 characters for Password." },
      minLength: { value: 6, message: "You must provide at least 6 characters for Password." },
    },
    rememberMe: {},
  }
};
