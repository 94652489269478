import { gql } from "@apollo/client"

export type Font = {
  id: string
	name: string
	postScriptName: string
	title: string
	weight: string
	style: string
	format: string
}

export type Fonts = {
  total: number
  items: [Font]
}

export type FontsData = {
  fonts: Fonts
}

export type FontsVars = {
  families?: [string]
  limit?: number
  page?: number
}

export const GET_FONTS = gql`
  query getFonts($families: [String], $limit: Int, $page: Int) {
    fonts(families: $families, limit: $limit, page: $page) { items { id name postScriptName title weight style format fontUrl } total }
  }
`;