import React from 'react';
import styles from './EledoPricingPlan.module.scss';
import classnames from "classnames";

const EledoPricingPlan = ({plan, request, showButton = null, planButton = null}) => (
  <div className={classnames("snow-card", "text-center", styles.Plan)}>
    { plan &&
      <>
        <h3 className={styles.PlanTitle}>{plan.name}</h3>
        <hr/>
        <div className={styles.PlanLine}>
          <h4>{!isNaN(plan.price) ? Number.parseFloat(plan.price).toFixed(2) + '€' : plan.price}</h4>
          <div className="text-larger">monthly</div>
        </div>
        <hr/>
        <div className={styles.PlanLine}>
          <h4>{plan.documents}</h4>
          <div className="text-larger">documents</div>
        </div>
        <hr/>
        <div className={styles.PlanLine}>
          <h4>{plan.templates}</h4>
          <div className="text-larger">templates</div>
        </div>
        <hr/>
        <div className={styles.PlanLine}>
          <div style={{marginTop: "1rem"}} className="text-larger">Support</div>
          <h4>{plan.support}</h4>
        </div>
        {
          showButton && <React.Fragment>
            <hr/>
            <div>
              {planButton}
            </div>
          </React.Fragment>
        }
      </>
    }

    { request &&
      <>
        <h3 className={styles.PlanTitle}>{request.service}</h3>
        <hr/>
        <div className={styles.PlanLine}>
          <h4>{!isNaN(request.paymentPrice) ? Number.parseFloat(request.paymentPrice).toFixed(2) + '€' : request.paymentPrice}</h4>
          <div className="text-larger">One Time</div>
        </div>
      </>
    }
  </div>
);

export default EledoPricingPlan;
