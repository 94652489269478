import {shallowEqual, useDispatch, useSelector} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import {showNotyf, useErrorNotyf} from "./index";
import {deleteTemplate} from "_reducers/templateReducer";
import NotyfContext from "context/NotyfContext";
import {EledoModal, EledoPendingContent} from "components/library";
import { useNavigate } from "react-router-dom";

function useDeleteTemplate(templateId, templateName) {
  const navigate = useNavigate()
  const [deleting, deletingError] = useSelector(state => [
    state.templates.deleting, state.templates.deletingError,
  ], shallowEqual);
  const [templateIdToDelete, setTemplateIdToDelete] = useState(null);
  const [deleteModalDisplayed, setDeleteModalDisplayed] = useState(false);
  const notyf = useContext(NotyfContext);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setDeleteModalDisplayed(false);
      setTemplateIdToDelete(null);
    };
  }, []);

  useErrorNotyf(deletingError);

  const deleteHandler = () => {
    setDeleteModalDisplayed(true);
    setTemplateIdToDelete(templateId);
  }

  const modalClosedHandler = () => {
    setTemplateIdToDelete(null);
    setDeleteModalDisplayed(false);
  };

  const modalConfirmedHandler = async () => {
    let success = null;
    await dispatch(deleteTemplate(templateIdToDelete)).then(response => {
        if (response.error) {
          showNotyf("Template was not deleted. " + response.payload.error, notyf, "error");
          success = false;
        } else {
          setDeleteModalDisplayed(false);
          showNotyf(`${templateName ? `"${templateName}"` : 'Template'} was deleted.`, notyf, "success");
          success = true;
          navigate("/templates/mine");
        }
      }
    );
    return success;
  }

  const deleteModalActions = (
    <React.Fragment>
      <button className="button grow w-button"
              onClick={modalClosedHandler}>
        No, Cancel
      </button>
      <button className="button red grow w-button" id="modal-delete-button"
              onClick={modalConfirmedHandler}>
        <EledoPendingContent state={deleting}
                             content="Yes, Delete"
                             pendingContent="Deleting..."/>
      </button>
    </React.Fragment>
  );

  const renderDeleteModal = () => (
    <EledoModal title={`Delete "${templateName?.length > 12 ? templateName?.substring(0, 12) + '...' : templateName}"`}
                content={`Are you sure you want to delete "${templateName}" ?`}
                displayed={deleteModalDisplayed}
                actions={deleteModalActions}
                onDismiss={modalClosedHandler}
                modalClass={"modal-delete"}/>
  );

  return {
    renderDeleteModal: () => renderDeleteModal(),
    deleteHandler: () => deleteHandler(),
  };
}

export default useDeleteTemplate;