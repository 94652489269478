import React from 'react';
import PropTypes from "prop-types";

const EledoMainPageTitle = ({title, subtitle, image, align="left"}) => image ? (
  <div className="section title my-title-section">
    <div className="container">
      <div className="col-wrapper" data-testid="EledoMainPageTitle">
        <div className="col-50" style={{marginBottom: 0}}>
          <h1 className="page-title">{title}</h1>
          <p className="title-sub-para">
            {subtitle}
          </p>
        </div>
        <div className="col-50 right-align" style={{marginBottom: 0}}>
          <img className="sub-page-hero-img" alt={`${title} Illustration`}
               height="220" width="300" loading="lazy"
               src={`${process.env.PUBLIC_URL}/assets/images/mainPageTitle/${image}`}/>
        </div>
      </div>
    </div>
  </div>
) : (
  <div className="section my-section">
    <div className="container">
      <div className="">
        <h1 align={align} className="best-points-heading">
          <b>{title}</b>
        </h1>
        {subtitle && <p align={align}>
          {subtitle}
        </p>}
      </div>
    </div>
  </div>
);

// Props type checking
EledoMainPageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: PropTypes.string,
};

export default EledoMainPageTitle;
