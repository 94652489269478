import React, {useCallback, useEffect} from 'react';
import styles from './EledoModal.module.scss';
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classnames from "classnames";

const EledoModal = ({ title, children, content, actions, displayed = false, onDismiss, topless = false,
                      wrapClass = "", modalClass = "",
                      titleClass = "", actionsClass = "",
                      style = {},
                      divider = true }) => {
  //close modal on escape
  const escapeKeyHandler = useCallback((event) => event.key === 'Escape' && onDismiss(), [onDismiss]);
  useEffect(() => {
    document.addEventListener("keydown", escapeKeyHandler, false);
    return () => {
      document.removeEventListener("keydown", escapeKeyHandler, false);
    };
  }, [escapeKeyHandler]);

  return ReactDOM.createPortal(
    <div className={classnames("modal-wrap", styles.EledoModalWrap, displayed ? styles.EledoModalWrapOpen : "", wrapClass)}
         style={style}>
      <div className="modal-closer-cover" onClick={onDismiss}/>
      <div className={classnames("eledo-modal", styles.EledoModal, displayed ? styles.EledoModalOpen : "", modalClass)}
           style={{display: "block !important", width: "min-content"}}
           onClick={(e) => {e.stopPropagation()}}>
        { !topless &&
          <div className="modal-close" onClick={onDismiss}>
            <div className="modal-close-icon w-embed">
              <svg className={styles.Overlay} viewBox="0 0 158.6 158.6" height="100%"
                  width="100%" y="0px" x="0px">
                <defs>
                </defs>
                <path
                  d="M105.5,79.3l47.7-47.7c7.2-7.2,7.2-18.9,0-26.2l0,0c-7.2-7.2-18.9-7.2-26.2,0L79.3,53.1L31.6,5.4  c-7.2-7.2-18.9-7.2-26.2,0v0c-7.2,7.2-7.2,18.9,0,26.2l47.7,47.7L5.4,127c-7.2,7.2-7.2,18.9,0,26.2v0c7.2,7.2,18.9,7.2,26.2,0  l47.7-47.7l47.7,47.7c7.2,7.2,18.9,7.2,26.2,0l0,0c7.2-7.2,7.2-18.9,0-26.2L105.5,79.3z"
                  className="st0"/>
              </svg>
            </div>
          </div>
        }

        { title && 
          <React.Fragment>
            <h4 style={{paddingRight: "3.1rem"}}
            className={titleClass}>
              {title}
            </h4>

            <div className="modal-divider" style={divider ? {} : {display: "none"}}/>
          </React.Fragment>
        }

        { !title && !topless &&
          <div style={{marginTop: "60px"}}/>
        }

        { displayed &&
          <div style={{overflow: "auto"}}>
            <div className={classnames("modal-content", styles.ModalContent)}>{content}{children}</div>
          </div>
        }

        { actions?.props?.children &&
          <div className={classnames("button-span", actionsClass)}>
            <div style={{marginTop: actions ? "1.5rem" : "0"}}>
              {actions}
            </div>
          </div>
        }

      </div>
    </div>,
    document.querySelector('#modal')
  );
}
EledoModal.propTypes = {
  title: PropTypes.string,
  content:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  actions: PropTypes.node.isRequired,
  displayed: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  children: PropTypes.node
};

export default EledoModal;
