import React, {useEffect, useState} from 'react';
import styles from './EledoTemplateListCard.module.scss';
import {shallowEqual, useSelector} from "react-redux";
import PropTypes from "prop-types";
import {Link} from 'react-router-dom';
import {EledoPendingContent} from "components/library";
import {useCopyTemplate, useDeleteTemplate} from "hooks";
import classnames from "classnames";

const THUMBNAIL_PLACEHOLDER = `${process.env.PUBLIC_URL}/assets/images/createTemplate/document-paged.png`;

const EledoTemplateListCard = ({template, isPublic}) => {
  const [loading, error] = useSelector(state => [
    state.templates.templatesLoading, state.templates.error
  ], shallowEqual);
  const [tileClass, setTileClass] = useState({});
  const [imageError, setImageError] = useState(false);

  const { copying, templateIdToCopy, copyHandler } = useCopyTemplate(isPublic, template.id, template.name);
  const { renderDeleteModal, deleteHandler } = useDeleteTemplate(template.id, template.name);

  useEffect(() => {
    return () => {
      setTileClass({});
    };
  }, []);

  const handleCopy = () => copyHandler();
  const handleDelete = () => deleteHandler();

  const openMenu = () => setTileClass(styles.tileMenuOpen)
  const closeMenu = () => setTileClass(styles.tileMenuClosed)

  const nodeRef = React.useRef(null);

  return (
    <React.Fragment>
      <div ref={nodeRef} className="template-tile"
           onMouseEnter={() => openMenu()}
           onMouseLeave={() => closeMenu()}
           key={template.id}>
        { template.thumbnailUrl && !imageError ?
          <Link className="template-link text-clamp-2"
                to={`/template/${template.id}/main`}>
            <img className="template-img"
                alt="Document thumbnail" loading="lazy"
                src={template.thumbnailUrl}
                onError={ ({currentTarget}) => {
                  currentTarget.onError = null;
                  setImageError(true);
                }}/>
          </Link> :
          <div className={classnames("template-img", styles.ThumbnailPlaceholderContainer)}>
            <img className={styles.ThumbnailPlaceholder}
                 alt="Document thumbnail" loading="lazy"
                 src={THUMBNAIL_PLACEHOLDER}/>
          </div>
        }
        <div className={classnames("template-info", tileClass)}>
          <h4 className="template-title">
            <Link className="template-link text-clamp-2"
                  to={`/template/${template.id}/main`}>{template.name}</Link>
          </h4>
          <p className="template-date">{new Date(parseInt(template.date)).toLocaleString()}</p>
          { isPublic ?
            <div className="buttons-private">
              <div className="primary-button">
                <Link to={`/view/${template.id}/main`}
                      className={classnames("button", "outline", "green-hover", "w-button", "white",
                        styles.EditButton)}
                      data-tip="View this template"
                      data-for={"edit-template-button-tooltip"}>
                  <i className={classnames("icon-pencil", styles.EditIcon)} style={{marginRight: 0}}/>
                  <span className="edit-button-label">
                    View
                  </span>
                </Link>
              </div>
              <div className="secondary-buttons">
                <button className="button outline green-hover w-button white"
                        onClick={handleCopy}>
                  <EledoPendingContent state={copying && template.id === templateIdToCopy}
                                      content="Copy"
                                      pendingContent="Copying..."/>
                </button>
              </div>
            </div>
             :
            <div className="buttons-private">
              <div className="primary-button">
                <Link to={`/create/${template.id}`}
                      className={classnames("button", "outline", "green-hover", "w-button", "white",
                        styles.EditButton)}
                      data-tip="Create document from this template"
                      data-for={"edit-template-button-tooltip"}>
                  <i className={classnames("icon-plus", styles.EditIcon)} style={{marginRight: 0}}/>
                  <span className="edit-button-label">
                    Create
                  </span>
                </Link>
                <Link to={`/template/${template.id}/main`}
                      className={classnames("button", "outline", "green-hover", "w-button", "white",
                        styles.EditButton)}
                      data-tip="Edit this template"
                      data-for={"edit-template-button-tooltip"}>
                  <i className={classnames("icon-pencil", styles.EditIcon)} style={{marginRight: 0}}/>
                  <span className="edit-button-label">
                    Edit
                  </span>
                </Link>
              </div>
              <div className="secondary-buttons">
                <button className="button w-button outline green-hover white icon rectangular"
                        onClick={handleCopy}
                        data-tip="Copy this template"
                        data-for={"copy-template-button-tooltip"}>
                  <i className="icon-copy" style={{marginRight: 0}}/>
                </button>
                <button className="button w-button red no-space icon rectangular"
                        onClick={handleDelete}
                        data-tip="Delete this template"
                        data-for={"delete-template-button-tooltip"}>
                  <i className="icon-bin" style={{marginRight: 0}}/>
                </button>
              </div>
            </div>
          }
        </div>
      </div>
      { !loading && !error && !isPublic && renderDeleteModal() }
    </React.Fragment>
  );
};
EledoTemplateListCard.propTypes = {
  template: PropTypes.object.isRequired,
  isPublic: PropTypes.bool,
};

export default EledoTemplateListCard;