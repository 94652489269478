import React, {useRef} from 'react';
import {Link} from 'react-router-dom';
import styles from './EledoDocumentPaginator.module.scss';
import classnames from "classnames";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

const EledoDocumentPaginator = ({currentPage, pagesTotal, handleSelectPage, handleAddPage, handleDeletePage}) => {
  const selectionTooltipRef = useRef();
  const selectionContainerStyle = {
    maxWidth: `calc(1.95rem*${Math.ceil(Math.sqrt(pagesTotal))})`,
  };

  const selectPageHandler = (e, id) => {
    handleClick(e);
    handleSelectPage(id);
  };

  const previousPageHandler = e => {
    handleClick(e);
    if(currentPage > 0 && currentPage > 0)
      selectPageHandler(e, currentPage - 1);
  }

  const nextPageHandler = e => {
    handleClick(e);
    if(currentPage < pagesTotal - 1 && pagesTotal > 1)
      selectPageHandler(e, currentPage + 1);
  }

  const addPageHandler = e => {
    handleClick(e);
    handleAddPage();
  };

  const deletePageHandler = e => {
    handleClick(e);
    if(pagesTotal > 1)
      handleDeletePage();
  };

  const handleClick = e => {
    e.preventDefault();
    ReactTooltip.hide();
  }

  return (
    <div className={styles.EledoDocumentPaginator} data-testid="EledoDocumentPaginator">
      <small className={styles.paginationContainer}>
        Pages: {pagesTotal} &nbsp;
        <div className={classnames("pagination", styles.pagination)}>
          <Link to="" className="button-link"
                onClick={e => previousPageHandler(e)}>
            <div className={classnames("number", "none", "first", styles.number)}
                 {...(currentPage > 0 && pagesTotal > 1 ? {} : {"aria-disabled": "true"})}>
                <i className="w-icon-slider-left"/>
            </div>
          </Link>
          <div className={classnames("number", "none", styles.number, styles.currentPage)}
               {...(pagesTotal > 1 ? {} : {"aria-disabled": "true"})}
               data-tip data-for='selectPage' data-event='click'>
            {currentPage + 1}
          </div>
          <ReactTooltip id='selectPage' place='bottom' effect='solid' clickable={true} type="dark"
                        className={styles.selectionTooltip}
                        ref={selectionTooltipRef}>
            <div className={styles.selectionContainer}
                 style={selectionContainerStyle}>
              {Array.from(Array(pagesTotal)).map((e,i) => (
                <Link to="" className="button-link"
                      key={`paginator-item-${i}`}
                      onClick={e => selectPageHandler(e, i)}>
                  <div className={classnames("number", "none", styles.number)}
                       {...(currentPage === i ? {"aria-disabled": "true"} : {})}>
                      {i + 1}
                  </div>
                </Link>
              ))}
            </div>
          </ReactTooltip>

          <Link to="" className="button-link"
                onClick={e => nextPageHandler(e)}>
            <div className={classnames("number", "none", "last", styles.number)}
                 {...(currentPage < pagesTotal - 1 && pagesTotal > 1 ? {} : {"aria-disabled": "true"})}>
                <i className="w-icon-slider-right"/>
            </div>
          </Link>
        </div>

        <Link className={classnames(styles.addLink, "button-link")} to=""
              onClick={addPageHandler}
              data-tip="Add a new template page after this one"
              data-for="add-page-tooltip">
          <i className="icon-plus"/> Add page
        </Link>
        <ReactTooltip
          id="add-page-tooltip"
          place="bottom"
          type="dark"
          effect="float"
          multiline={true}
        />
        <Link className={classnames(styles.deleteLink, "button-link")} to=""
              {...(pagesTotal > 1 ? {} : {"aria-disabled": "true"})}
              onClick={deletePageHandler}
              data-tip={pagesTotal > 1 ? "Delete active template page" : "You can't delete the last page"}
              data-for="delete-page-tooltip">
          <i className="icon-bin"/> Delete page
        </Link>
        <ReactTooltip
          id="delete-page-tooltip"
          place="bottom"
          type="dark"
          effect="float"
          multiline={true}
        />
      </small>
    </div>
  );
};
EledoDocumentPaginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pagesTotal: PropTypes.number.isRequired,
  handleSelectPage: PropTypes.func.isRequired,
  handleAddPage: PropTypes.func.isRequired,
  handleDeletePage: PropTypes.func.isRequired,
};

export default EledoDocumentPaginator;
