import { useMutation } from '@apollo/client'
import { AuthContext } from 'AuthContext'
import { ArticleVoteData, ArticleVoteVars, VOTE_ARTICLE } from 'graphql/ArticleQueries'
import { PlatformContext } from 'PlatformContext'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Tracker } from 'Tracker'

enum ArticleVoteBoxState { START, LIKE, DISLIKE, THANKYOU }
type ArticleVoteBoxProps = {
  articleId: string
}
export const EledoArticleVoteBox = ({articleId}: ArticleVoteBoxProps) => {
  const platform = useContext(PlatformContext);
  const auth = useContext(AuthContext)
  const [state, setState] = useState<ArticleVoteBoxState>(ArticleVoteBoxState.START)
  const [articleVote] = useMutation<ArticleVoteData, ArticleVoteVars>(VOTE_ARTICLE)

  const {setValue, register, handleSubmit} = useForm<ArticleVoteVars>({mode: 'onSubmit'})

  const trackerUser = {
    id: auth.user?.id || null,
    level: auth.level
  }

  useEffect(() => {
    if(state === ArticleVoteBoxState.LIKE) {
      setValue("voteHelpful", true)
    } else if(state === ArticleVoteBoxState.DISLIKE){
      setValue("voteHelpful", false)
    }
  }, [state, setValue])

  const vote = (data: ArticleVoteVars) => {

    articleVote({variables: {id: articleId, voteHelpful: data.voteHelpful, comment: data.comment, platform: platform.platform}})
      .then(result => {

        setState(ArticleVoteBoxState.THANKYOU)
      })

    Tracker.event("action.sendVote.article", trackerUser, {
      sendVote: {
        choice: data.voteHelpful ? 'yes' : 'no'
      }
    })
  }

  if(state === ArticleVoteBoxState.LIKE || state === ArticleVoteBoxState.DISLIKE){
    return (
      <div>
        <h3 className="text-center">Was this article helpful?</h3>
        <form className="w-form center" style={{maxWidth: '400px'}} onSubmit={handleSubmit(vote)}>
          <div className="text-center voteCheckbox">
            <input id="voteHelpful" type="checkbox" {...register("voteHelpful")}/>
            <label htmlFor="voteHelpful" />
          </div>
          <h3>Comment</h3>
          <textarea className="w-input" {...register("comment")} />
          <button type="submit" className="button w-button" style={{margin: '12px 0'}}>Submit</button>
        </form>
      </div>
    )
  } else if(state === ArticleVoteBoxState.THANKYOU){
    return (
      <>
        <h3 className="text-center">Thank you for your feedback!</h3>
      </>
    )
  } else {
    return (
      <>
        <h3 className="text-center">Was this article helpful?</h3>
        <div className="voteBar">
          <div className="voteAction yes" onClick={() => {
              articleVote({variables: {id: articleId, voteHelpful: true, platform: platform.platform}})
              setState(ArticleVoteBoxState.LIKE)
              
              Tracker.event("action.openVoting.article", trackerUser, {
                openVoting: {
                  choice: 'yes'
                }
              })
            }}>
            <i className="icon-thumbs-up"/>
            <p>Yes</p>
          </div>
          <div className="voteAction no" onClick={() => {
              articleVote({variables: {id: articleId, voteHelpful: false, platform: platform.platform}})
              setState(ArticleVoteBoxState.DISLIKE)

              Tracker.event("action.openVoting.article", trackerUser, {
                openVoting: {
                  choice: 'no'
                }
              })
            }}>
            <i className="icon-thumbs-down"/>
            <p>No</p>
          </div>
        </div>
      </>
    )
  }
}
