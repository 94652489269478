import React, {useEffect, useState, useContext } from 'react';
import styles from './EledoTemplateList.module.scss';
import PropTypes from "prop-types";
import {
  fetchPrivateTemplates,
  fetchPublicTemplates,
} from "_reducers/templateReducer";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useErrorNotyf, useShowMenu, usePagination} from "hooks";
import { EledoTemplateListCard } from "components";
import {
  EledoMainPageTitle, EledoLoader, EledoError
} from "components/library";
import ReactTooltip from "react-tooltip";
import { AuthContext } from 'AuthContext';
import { PlatformContext } from 'PlatformContext';
import { useAppDispatch } from '_store';
import { activateRequestsByEvent } from '_reducers/puzzleRequests';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { EledoArticlesModal } from 'components/EledoArticles/EledoArticlesModal';
import { Tracker } from 'Tracker';

const EledoTemplateList = ({isPublic}) => {
  const platform = useContext(PlatformContext)
  const auth = useContext(AuthContext)
  const [articlesModalDisplayed, setArticlesModalDisplayed] = useState(false)

  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(activateRequestsByEvent("enter_template_list"));
    return () => dispatch(activateRequestsByEvent("leave_template_list"));
  }, [dispatch])

  useShowMenu();

  return (
    <div className={styles.EledoTemplateList} data-testid="EledoTemplateList">
      {
        isPublic ?
          <EledoMainPageTitle
            title="Public Templates"
            subtitle="Get inspired by our public templates! You can copy any template and edit as your own."
            image="IllustrationsTemplatesV2-01.svg"
          /> :
          <div className="section title my-title-section">
            <div className="container">
              <div className="col-wrapper">
                <div className="col-66">
                  <h1 className="page-title">My Templates</h1>
                  <div>
                    <p>Start a new template</p>
                    <div style={{whiteSpace: 'nowrap'}}>
                      <div className={styles.StartItem}>
                        <div onClick={() => { navigate("/templates/new/dynamicFlow") }}
                              className={classNames(styles.Showcase, "snow-card new-template centred-all")}>
                          <img className="new-icon" alt="" width="40" loading="lazy"
                              src={`${process.env.PUBLIC_URL}/assets/images/Eledo-File-Icon-Jungle-Plus-01.svg`}/>
                        </div>
                        <div className={styles.Caption}>Blank template</div>
                      </div>
                      <div className={styles.StartItem}>
                        <div onClick={() => { navigate("/templates/public") }}
                              className={classNames(styles.Showcase, "snow-card new-template centred-all")}>
                          <i className={classNames(styles.Icon, "icon-library")}/>
                        </div>
                        <div className={styles.Caption}>From Library</div>
                      </div>
                      <div className={styles.StartItem}>
                        <div onClick={() => { navigate("/templates/new/uploadPdf") }}
                              className={classNames(styles.Showcase, "snow-card new-template centred-all")}>
                          <i className={classNames(styles.Icon, "icon-file-pdf")}/>
                        </div>
                        <div className={styles.Caption}>Upload PDF</div>
                      </div>
                      <div className={styles.StartItem}>
                        <div onClick={() => {
                              setArticlesModalDisplayed(true)
                              
                              const trackerUser = {
                                id: auth.user?.id || null,
                                level: auth.level
                              }
                          
                              Tracker.event("action.click.button", trackerUser, {
                                click: {
                                  button: 'docu_modal_open',
                                  placement: 'template_list'
                                }
                              })
        
                            }}
                              className={classNames(styles.Showcase, "snow-card guide centred-all")}>
                          <i className={classNames(styles.Icon, "icon-book")}/>
                        </div>
                        <div className={styles.Caption}>Guides</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-33 right-align" style={{marginBottom: 0}}>
                  <img className="sub-page-hero-img"
                      height="220" width="300" loading="lazy"
                      src={`${process.env.PUBLIC_URL}/assets/images/mainPageTitle/IllustrationsTemplatesV2-01.svg`}/>
                </div>
              </div>
            </div>
          </div>
      }

      <TemplateListCards isPublic={isPublic} />

      <EledoArticlesModal root={platform.documentationPaths.root} path={platform.documentationPaths.guides}
        modalDisplayed={articlesModalDisplayed} onDismiss={() => setArticlesModalDisplayed(false)}/>
    </div>
  );
};

const TemplateListCards = ({isPublic}) => {

  const platform = useContext(PlatformContext);
  const auth = useContext(AuthContext);
  const connectionId = auth.connection?.id;
  const [dataDownloaded, setDataDownloaded] = useState(false);
  const [
    templates, itemsTotal, selectedPage,
    loading, error
  ] = useSelector(state => [
    isPublic ? state.templates.public.list : state.templates.private.list,
    isPublic ? state.templates.public.total : state.templates.private.total,
    isPublic ? state.templates.public.selectedPage : state.templates.private.selectedPage,
    state.templates.templatesLoading,
    state.templates.error
  ], shallowEqual);
  const dispatch = useDispatch();

  const fetchData = (pageId) => {
    if (isPublic) {
      dispatch(fetchPublicTemplates({pageId, platform: platform.platform})).then(() => {
        setDataDownloaded(true);
      });
    } else {
      dispatch(fetchPrivateTemplates({pageId, connectionId: connectionId})).then(() => {
        setDataDownloaded(true);
      });
    }
  };

  const renderPagination = usePagination(selectedPage, itemsTotal, fetchData, () => {});

  useEffect(() => {
    fetchData(selectedPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isPublic, connectionId, selectedPage]);

  useErrorNotyf(error);
  
  const renderError = () =>
    <EledoError title={error?.message}
      description={(<React.Fragment>
        We are sorry &#9785; We could not load your templates. <br/><br/>
        {error?.message?.toLocaleLowerCase() === "network error" && "Please check your internet connection."}
    </React.Fragment>)}/>;

  if(isPublic && templates.length === 0) {
    return <div className="flex-center">There are no public templates here.</div>;
  }

  return (
    <div className="section my-content-section" style={{paddingTop: 0}}>

      {error && renderError()}

      {renderPagination()}

      <div className="container" style={{overflowY: "auto"}}>
        {loading && <div className="flex-center"><EledoLoader/></div>}

        {!error && !loading && dataDownloaded &&

          <div className="w-layout-grid template-grid">
            {templates.map((t, index) => (
              <EledoTemplateListCard template={t}
                                    isPublic={isPublic}
                                    key={t.id}/>
            ))}
            <ReactTooltip
              id="copy-template-button-tooltip"
              place="top"
              type="dark"
              effect="float"
              multiline={false}
            />
            <ReactTooltip
              id="edit-template-button-tooltip"
              className={styles.EditTooltip}
              place="top"
              type="dark"
              effect="float"
              multiline={false}
            />
            <ReactTooltip
              id="delete-template-button-tooltip"
              place="top"
              type="dark"
              effect="float"
              multiline={false}
            />
          </div>
        }
      </div>

      {!error && !loading && renderPagination()}

    </div>
  );
};
TemplateListCards.propTypes = {
  isPublic: PropTypes.bool,
};

export default EledoTemplateList;
