import { useEffect, useState } from 'react'
import { parseAst } from '../../fed_core/process_ast/parseAst'
import { traverse } from '../../zzz_tree/traverse/traverse'
import { useFed } from '../_context/useFed'
import { IRecognitionException } from 'chevrotain'

export function useSimpleAst(tokensOrig: any) {

    const { config } = useFed()
    const [ast, set_ast] = useState(null)
    const [nodes, set_nodes] = useState()
    const [nodesParentless, set_nodesParentless] = useState(null)
    const [astErrs, set_astErrs] = useState<IRecognitionException[]>()
    
    useEffect(() => {
      let parsed = parseAst(tokensOrig)
      let parsed2 = parseAst(tokensOrig)  // neefektivne, ale quickfix
      traverse(parsed2.nodes, n => delete n.parent)

      set_ast(parsed.ast)
      set_nodes(parsed.nodes)
      set_nodesParentless(parsed2.nodes)
      set_astErrs(parsed.errors)

      config.ast = parsed2.nodes

    }, [tokensOrig, config])
  
    return {
        ast,
        nodes,
        nodesParentless,
        errors: astErrs,
    }
}
