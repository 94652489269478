import { useState } from "react"

import { useErrorNotyf, useShowMenu, usePagination } from "hooks"
import { GenerationLog } from "graphql/GenerationLogQueries"
import useGenerationLogs from "graphql/hooks/useGenerationLogs"
import { ITEMS_PER_PAGE } from "utils"

import { EledoMainPageTitle, EledoError } from "components/library"
import LogsTable from "./LogsTable/LogsTable"
import LogDetailModal from "./LogDetailModal/LogDetailModal"


const DAYS = 30


const EledoLogs = () => {
  const [selectedPage, setSelectedPage] = useState<number>(1)
  const [detailModalDisplayed, setDetailModalDisplayed] = useState<boolean>(false)
  const [logDetail, setLogDetail] = useState<GenerationLog | null>(null)

  const { loading, error, generationLogs, total } = useGenerationLogs({ days: DAYS, page: selectedPage, limit: ITEMS_PER_PAGE})
  const renderPagination = usePagination(selectedPage, total, (newPage: number) => setSelectedPage(newPage))

  useErrorNotyf(error)
  useShowMenu()

  const onLogLineClicked = async (logId: string) => {
    const _logDetail = generationLogs?.find((log) => log.id === logId)
    if (!_logDetail)
      return

    setLogDetail(_logDetail)
    setDetailModalDisplayed(true)
  }


  return (
    <div data-testid="EledoLogs">
      <EledoMainPageTitle title="Logs" subtitle="Information on recent Eledo document operations." image="IllustrationsLogs.svg" />

      { error &&
        <EledoError
          title={error?.message}
          description={<>"We are sorry &#128577;. We could not load your logs.<br/><br/>{error.networkError ? "Please check your internet connection." : ""}</>}
        />
      }

      { !error &&
        <div className='section my-content-section'>
          { renderPagination() }
          <LogsTable logs={generationLogs} loading={loading} onLogLineClicked={onLogLineClicked} />
          { renderPagination() }
          { logDetail && detailModalDisplayed &&
            <LogDetailModal log={logDetail} detailModalDisplayed={detailModalDisplayed} onModalClosed={() => setDetailModalDisplayed(false)} />
          }
        </div>
      }
    </div>
  )
}


export default EledoLogs