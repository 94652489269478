import { AuthData } from 'graphql/AuthQueries'
import { createContext, useContext, useState } from 'react'
import { PlatformContext } from 'PlatformContext'
import { Tracker, TrackerUser } from 'Tracker'
import Cookies from 'universal-cookie'

type AuthContextState = AuthData & {
  canLetLogin: Boolean
}

type AuthContextData = AuthContextState & {
  login(authData: AuthData, authType: string): void
  newApiKey(apiKey: string): void
  logout(): void
  letLogin(): void
}

const initialState: AuthContextState = {
  user: null,
  connection: null,
  level: 0,
  canLetLogin: false,
}

const AuthContext = createContext<AuthContextData>({
  ...initialState,
  login: () => {},
  newApiKey: () => {},
  logout: () => {},
  letLogin: () => {}
})

function AuthProvider(props: any) {
  const platform = useContext(PlatformContext);

  const [state, setState] = useState<AuthContextState>({
    ...initialState,
    canLetLogin: !platform.isEmbedded
  })

  const login = (authData: AuthData, authType: String) => {
    platform.authService.storeAuthData(authData);

    if(authData.roles?.includes("NoTracking")){
      const cookies = new Cookies();
      cookies.set("disableAnalytics", "true", { path: '/' });
    }

    const trackerUser: TrackerUser = {
      id: authData.user?.id || null,
      level: authData.level
    }

    Tracker.event("action.login.user", trackerUser, {
      login: {
        method: authType
      }
    })
    setState({
      ...state,
      ...authData
    })
  }

  const newApiKey = (apiKey: string) => {
    setState({
      ...state,
      apiToken: apiKey
    })
  }

  function logout() {
    platform.authService.deleteAuthData();

    const trackerUser: TrackerUser = {
      id: state.user?.id || null,
      level: state.level
    }

    Tracker.event("action.logout.user", trackerUser, null)

    setState({
      ...state,
      user: null
    })
  }

  function letLogin() {
    setState({
      ...state,
      canLetLogin: true
    })
  }

  return (
    <AuthContext.Provider
      value={{user: state.user, connection: state.connection, level: state.level, apiToken: state.apiToken, roles: state.roles, canLetLogin: state.canLetLogin,
        letLogin, login, newApiKey, logout}}
      {...props}
    />
  )
}

export { AuthContext, AuthProvider };
