// import { useFed } from 'featuresFed/fed_plugins/_context/useFed'
import { useEffect, useState, useCallback } from 'react'

// doReadCaretAt je potrebne z dovodu, ze formula sa nemusela zmenit, ale pozicia caretu hej, a preto chceme rereadnut
type Props = {
  formula: string
  refTextarea: any
  doReadCaretAt: any
  onChange_caretPosition: any
}
export function useCaretPosition({formula, refTextarea, doReadCaretAt, onChange_caretPosition}: Props) {

  // const {log} = useFed()
  const [caretPosition, set_caretPosition] = useState(-1)

  const readCaretPosition = useCallback(() => {
    let cp = refTextarea.current?.selectionEnd

    // console.log('readCaretPosition', cp)

    set_caretPosition(cp)
    // log('readCaretPosition.caretPos', cp)

    onChange_caretPosition?.(cp)
  }, [onChange_caretPosition, refTextarea])


  useEffect(() => {
    let elem = refTextarea.current
    if (!elem)
      return

    elem.focus()
    elem.addEventListener('keydown', readCaretPosition)
    elem.addEventListener('keyup', readCaretPosition)
    elem.addEventListener('mousedown', readCaretPosition)
    elem.addEventListener('mouseup', readCaretPosition)

  }, [refTextarea, readCaretPosition])


  useEffect(() => {
    readCaretPosition()
  }, [formula, doReadCaretAt, readCaretPosition])
  

  return {caretPosition}
}
