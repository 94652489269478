import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Article, ArticlesData, ArticlesVars, GET_ARTICLES } from '../../graphql/ArticleQueries';



const useArticles = ({ids, parent, platform, filter, limit, page}: ArticlesVars) => {
  const { loading, error, data } = useQuery<ArticlesData, ArticlesVars>(GET_ARTICLES, {variables:{ids, parent, platform, filter, limit, page}});
  const [articles, setArticles] = useState<Article[]>();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if(!data) return;

    const articles: Article[] = data.articles.items.map(({id, ...props}) => ({
      id: id,
      ...props
    }))

    setArticles(articles);

    setTotal(data.articles.total);

  }, [data])

  return { loading, error, articles, total }
}

export default useArticles