import React from "react";

const EledoPageHeader = () => {
  return (
    <div className="section nav green">
      <div className="top container nav">
        <a href="/" className="w-inline-block eledo-logo"
           target="_blank" rel="noreferrer">
          <img src={process.env.PUBLIC_URL + "/assets/images/Eledo-Logo-White-01.svg"}
               loading="lazy" width="180" alt="Eledo logo"/>
        </a>
      </div>
    </div>
  );
}

export default EledoPageHeader;