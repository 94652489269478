import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import logsService from "_api/logsService";
import utils from "utils";

const loadingStates = {detailLoading: true, listLoading: true, error: null};
const initialState = {
  selectedPage: 1,
  logsTotal: 0,
  list: [],
  logDetail: {
    id: null
  },
  ...loadingStates,
};

export const fetchLogs = createAsyncThunk(
  'logs/fetchLogs',
  async pageId => {
    const response = await logsService.fetchLogs(pageId);
    return {selectedPage: pageId, ...response.data};
  }
);

export const fetchLogDetail = createAsyncThunk(
  'logs/fetchLogDetail',
  async (logId, thunkApi) => {
    const response = await logsService.fetchLogDetail(logId);
    return response.data;
  }
);


const logsSlice = createSlice({
  name: 'logs',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchLogs.pending, (state, action) => {
        state.list = [];
        //state.logsTotal = 0;
        state.listLoading = true;
        state.error = null;
      })
      .addCase(fetchLogs.fulfilled, (state, action) => {
        state.list = action.payload.logs;
        state.logsTotal = action.payload.total;
        state.selectedPage = utils.getSelectedPage(action.payload.selectedPage, action.payload.total);
        state.listLoading = false;
      })
      .addCase(fetchLogs.rejected, (state, action) => {
        state.list = [];
        state.listLoading = false;
        state.logsTotal = 0;
        state.selectedPage = 1;
        state.error = action.error;
      })
      .addCase(fetchLogDetail.pending, (state, action) => {
        state.logDetail = {id: null};
        state.detailLoading = true;
        state.error = null;
      })
      .addCase(fetchLogDetail.fulfilled, (state, action) => {
        state.logDetail = action.payload;
        state.detailLoading = false;
      })
      .addCase(fetchLogDetail.rejected, (state, action) => {
        state.logDetail = {id: null};
        state.detailLoading = false;
        state.error = action.error;
      })
  }
});

export default logsSlice.reducer;