// note, schvalne neni ts, lebo sa mu nepacia potom veci v classe, a som cital ze chevrotain ma nejake issues s ts, tak tym nejdem stracat cas

import { CstParser } from "chevrotain"

import { mapTokens, allTokens } from '../process_tokens/MyLexer'

const {
  FnStartLp,
  Lparen,
  Rparen,
  Operator,
  Minus,

  Number,
  String,
  Variable,

  Comma,
} = mapTokens

class MyParser extends CstParser {
  constructor() {
    super(allTokens);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const $ = this;

    // start expression
    $.RULE('expression', () => {
      $.OR([
        {ALT: () => $.SUBRULE($.opExpr)},
      ])
    })

    $.RULE('opExpr', () => {
      $.SUBRULE($.atomicExpr)
      $.MANY(() => {
        $.OR([
          { ALT: () => $.CONSUME(Minus) },
          { ALT: () => $.CONSUME(Operator) },
        ])
        $.SUBRULE($.expression)
      })
    })

    $.RULE('atomicExpr', () => {
      $.OR([
        {ALT: () => $.SUBRULE($.minusExpr)},
        {ALT: () => $.CONSUME(Number)},
        {ALT: () => $.CONSUME(String)},
        {ALT: () => $.SUBRULE($.fn)},
        {ALT: () => $.CONSUME(Variable)},
        {ALT: () => $.SUBRULE($.parenthesisExpression)},
      ])
    })

    $.RULE('minusExpr', () => {
      $.CONSUME(Minus)
      $.SUBRULE($.expression)
    })

    $.RULE('fn', () => {
      $.CONSUME(FnStartLp)
      // $.CONSUME(Lparen)  // lparen je uz v tom fnstart
      $.SUBRULE($.fnParams)
      $.CONSUME(Rparen)
    })


    // $.RULE('fnParams', () => {
    //   $.MANY_SEP({
    //     SEP: Comma,
    //     DEF: () => {
    //       $.SUBRULE($.expression)
    //     }
    //   })

    // })

    $.RULE('fnParams', () => {
      $.OPTION(() => {
        $.SUBRULE($.expression)
        $.MANY(() => {
            $.CONSUME(Comma)
            $.SUBRULE2($.expression)
        })
      })

      $.OPTION2(() => {
        $.MANY2(() => {
          $.CONSUME2(Comma)
        })
      })
    })


    
    $.RULE('parenthesisExpression', () => {
      $.CONSUME(Lparen)
      $.SUBRULE($.expression)
      $.CONSUME(Rparen)
    })



    this.performSelfAnalysis()
  }
}

export { MyParser }

