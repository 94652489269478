import { createContext, useContext } from 'react'

export const FedContext = createContext<any>({})

export function useFed() {
  const config = useContext(FedContext)

  return {
    config,
    log: (key: string, arg: any) => {
      // console.log(key, arg)

      try {
        JSON.stringify(arg)
      } catch(e) {
        arg = '(circ)'
      }

      config.dbg = config.dbg || {}
      config.tt = config.tt || {}

      if (config.dbg[key] !== arg)
        config.tt[key] = new Date().getTime()

      config.dbg[key] = arg


      // if (arg.kids) arg.kids = ''

      // let copy = JSON.parse(JSON.stringify(config.dbg || {}))
      // copy[key] = arg
      // set_dbg(copy)
      // config.dbg = copy
    }
  }
}
