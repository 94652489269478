import React, {useState} from 'react';
import './EledoStorage.scss';
import {useShowMenu} from "hooks";

const EledoStorage = () => {
  const [storage, setStorage] = useState(undefined);

  const storageSelectOptions = [
    {label: "Google Docs", value: 1},
    {label: "FTP Upload", value: 4},
  ];

  useShowMenu();

  const onFormSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div className="EledoStorage section section-small" data-testid="EledoStorage">
      <h3>Storage</h3>

      <p>
        Eledo does not store documents you create.
        Here you can setup your file storage, where we upload the documents for you.
      </p>

      <form className="w-form"
            onSubmit={onFormSubmit}>
        <fieldset>
          <select className="w-input"
                  name="storage"
                  value={storage}
                  onChange={(e) => setStorage(e.target.value)}>
            <option value={undefined} disabled>Please select a storage option</option>
            {storageSelectOptions.map((option) => (
              <option value={option.value} key={option.value + option.label}>{option.label}</option>
            ))}
          </select>
        </fieldset>
      </form>
    </div>
  );
}

export default EledoStorage;
