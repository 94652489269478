import React, { useContext, useState } from 'react';
import './EledoInputFields.scss';
import {useSelector} from "react-redux";
import {useShowMenu} from "hooks";
import { PlatformContext } from 'PlatformContext';
import { RootState } from '_store';
import utils from 'utils';
import { DataModel, DataModelField } from 'graphql/DataModelQueries';

const EledoInputFields = ({dataModel}: {dataModel: DataModel}) => {
  const platform = useContext(PlatformContext);
  const isEmbedded = platform.isEmbedded;
  const allFields = useSelector<RootState, any>(state => state.templates.selectedTemplate?.derived?.allFields);

  useShowMenu()

  return (
    <div className="EledoInputFields" data-testid="EledoInputFields">
      { !isEmbedded && 
        <div className="section section-small">
          <h3>Import Data Structure</h3>
          <p>
            Retrieve data structure from your data source to make template building easier.
            To do this, setup your data source connection first:
          </p>
          <button className="button w-button">Open Connections</button>
        </div>
      }

      { dataModel !== undefined && dataModel !== null &&
        <div className="section section-small">
          <h3>Imported Fields</h3>
          <Tree dataModel={dataModel} />
        </div>
      }

      <div className="section section-small">
        <h3>Fields Overrides</h3>
        { renderFieldTable(allFields) }
      </div>
    </div>
  );
}

const filterSearch = (children: any, needle: string) => {
  return children.name?.indexOf(needle) >= 0 || children.title?.toLowerCase().indexOf(needle?.toLowerCase()) >= 0
}

type TreeLayerProps = {
  dataModel: DataModel
  fields: [DataModelField] | undefined
  context: string
  depth: number
}

type TemplateVariable = {
  name: string
  type: string
  title: string
}

const Tree = ({dataModel}: {dataModel: DataModel}) => {
  const fields = dataModel.classes.find(c => c.id === dataModel.classId)?.fields

  return (
    <TreeLayer dataModel={dataModel} fields={fields} context={""} depth={0} />
  )
}

const TreeLayer = ({dataModel, fields, context, depth}: TreeLayerProps) => {
  const [expanded, setExpanded] = useState<{[key: string]: boolean}>({})

  const toggleExpanded = (name: string) => {
    let newCollapsed = utils.deepCopy(expanded)
    newCollapsed[name] = newCollapsed[name] ? !newCollapsed[name] : true
    setExpanded(newCollapsed)
  }

  const isExpanded = (name: string): boolean => {
    return expanded[name] || false;
  }

  return (
    <div key={'ul_' + context} className={'tree-layer'}>
      { fields && fields.map(children => (
        <div key={'li_' + depth + '.' + children.id + '.' + children.type}>
          <div className={'item'} onClick={() => toggleExpanded(children.id)}>
            { depth > 0 && [...Array(depth)].map((e, i) =>
              <div key={'lig_' + depth + '.' + children.id + '.' + children.type + i} style={{width: '24px', textAlign: 'center'}}><div style={{borderLeft: '1px solid black', height:'100%', width: 0, margin: 'auto'}}/></div>
              )
            }
            { (children.type === 'Array' || children.type === 'Object') ?
              <div style={{width: '24px', textAlign: 'center'}}>+</div> :
              <div style={{width: '24px', textAlign: 'center'}}><div style={{borderLeft: '1px solid black', height:'100%', width: 0, margin: 'auto'}}/></div>
            }
            <div className='title'>
              <span>{context + (context ? '.' : '') + children.id}</span>&nbsp;<span style={{color: 'lightgray'}}>({children.type})</span>
            </div>
            <div className='ext'>
              <span style={{textAlign: 'right'}}>{children.name}</span>
            </div>
          </div>
          <div>
            { isExpanded(children.id) && (children.type === 'Array' || children.type === 'Object') &&
              <>
                <TreeLayer dataModel={dataModel} fields={dataModel.classes.find(c => c.id === children.relClass)?.fields} context={context + (context ? '.' : '') + children.name} depth={depth + 1}/>
              </>
            }
          </div>
        </div>
      ))}
    </div>
  )
}

type TemplateField = {
  name: string
  type: string
  extType: string
  label: string
}

const renderFieldTable = (fields: TemplateField[]) => (
  fields && fields.length > 0 ?
    <div>
      <div className="w-layout-grid header-grid no-top-margin">
        <div className="_wf-table-cell">Field</div>
        <div className="_wf-table-cell">Type</div>
        <div className="_wf-table-cell">External Type</div>
        <div className="_wf-table-cell">Label</div>
      </div>
      { fields.map(f => (
        <div className="w-layout-grid data-grid" key={f.name}>
          <div className="_wf-table-cell">
            <div className="tile-title">Field</div>
            <div>{f.name}</div>
          </div>
          <div className="_wf-table-cell">
            <div className="tile-title">Type</div>
            <div>{f.type}</div>
          </div>
          <div className="_wf-table-cell">
            <div className="tile-title">External Type</div>
            <div>{f.extType}</div>
          </div>
          <div className="_wf-table-cell">
            <div className="tile-title">Label</div>
            <div>{f.label}</div>
          </div>
        </div>
      )) }
    </div>
    : <p className="no-fields">No fields defined yet.</p>
);

export default EledoInputFields;
