import React from 'react';
import './EledoApi.scss';
import {useShowMenu} from "hooks";
import {useSelector} from "react-redux";

const EledoApi = () => {
  const templateId = useSelector(state => state.templates.selectedTemplate.id);
  const templateVersion = useSelector(state => state.templates.selectedTemplate.version);
  const userAuthData = useSelector(state => state.user?.user);

  useShowMenu();

  return (
    <div className="EledoApi section section-small" data-testid="EledoApi">
      <h3>API Integration</h3>

      <fieldset>
        <label htmlFor="apiKey">
          API Key
        </label>
        <pre id="apiKey">
          {userAuthData?.apiKey}_{userAuthData?.apiValue}
        </pre>
      </fieldset>

      <h3>Example Generate Request</h3>

      <fieldset>
        <label htmlFor="httpRequest">
          HTTP Request
        </label>
        <pre id="httpRequest">
          POST https://eledo.online/api/RESTv1/Generate
        </pre>
      </fieldset>

      <fieldset>
        <label htmlFor="httpRequestHeaders">
          HTTP Request Headers
        </label>
        <pre id="httpRequestHeaders">
{`Content-Type: application/json
Api-Key: ${userAuthData?.apiKey}_${userAuthData?.apiValue}`}
        </pre>
      </fieldset>

      <fieldset>
        <label htmlFor="httpRequestBody">
          HTTP Request Body
        </label>
        <pre id="httpRequestBody">
{`{
	"templateId":"${templateId}",
	"templateVersion":${templateVersion},
	"file":{
		"item" : {
			"name" : "",
			"text" : "",
			"text2" : "",
			"status_1" : "",
			"close_date" : "",
			"numbers" : 0,
			"status_11" : "",
			"numbers6" : 0,
			"numbers2" : 0,
			"brokerage_fee_pd_by_client" : 0,
			"dup__of_agent_split2" : 0
		}
	}
}`}
        </pre>
      </fieldset>
    </div>
  );
}
export default EledoApi;
