import { EledoRadioButtonField, EledoTextField, EledoTextArea, EledoCheckboxField } from 'components/library'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form';
import utils from 'utils';
import { PuzzleComponentStructure } from './PuzzleStructure'
import { useNavigate } from 'react-router-dom';

const _ = require('lodash');


function PuzzleGenerator({ structure, data, setData, onSubmit, onSkip } : { structure: PuzzleComponentStructure, data: any, setData: Function, onSubmit?: Function, onSkip?: Function}) {

  const formHandler = useForm({mode: "onChange"});
  const { watch, formState: { isValid }} = formHandler;

  useEffect(() => {
    const subscription = watch((data) => {
      setData(utils.deepCopy(data));
    });
    return () => subscription.unsubscribe();
  }, [watch, data, setData]);

  return (
    <div>
      <FormProvider {...formHandler}>
        <form onSubmit={(e) => { e.preventDefault(); onSubmit && onSubmit(e); }}>
          <PuzzleLevel structure={structure} data={data} isValid={isValid} onSkip={onSkip}/>
        </form>
      </FormProvider>
    </div>
  )
}

const PuzzleLevel = function ({structure, data, isValid, onSkip}:{structure: PuzzleComponentStructure, data: any, isValid: boolean, onSkip?: Function}) {

  const navigate = useNavigate()

  switch(structure.type){
    case "container":
      switch(structure.layout){
        case "flex-row": {
            const align = structure.align === "center" ? "center" : structure.align === "right" ? "flex-end" : structure.align === "justify" ? "space-between" : "flex-start";
            return (
              <div style={{display: "flex", flexDirection: "row", justifyContent: align}}>
                { structure?.nested?.map(sub => {
                  return (
                    <PuzzleLevel key={sub.id} structure={sub} data={data} isValid={isValid} onSkip={onSkip}/>
                  );
                })}
              </div>
            )
          }
        case "flex-column": {
            const align = structure.align === "center" ? "center" : structure.align === "right" ? "flex-end" : structure.align === "justify" ? "space-between" : "flex-start";
            return (
              <div style={{display: "flex", flexDirection: "column", justifyContent: align}}>
                { structure?.nested?.map(sub => {
                  return (
                    <PuzzleLevel key={sub.id} structure={sub} data={data} isValid={isValid} onSkip={onSkip}/>
                  );
                })}
              </div>
            )
          }
        }
        break;
    case "text":
      return (
        <span>{structure.text}</span>
      )
    case "h5":
      return (
        <h5>{structure.text}</h5>
      )
    case "hspace":
        return (
          <div style={{marginTop: structure.size}}/>
        )
    case "vspace":
      return (
        <span style={{width: structure.size}}/>
      )
    case "textField":
      return (
        <EledoTextField id={structure.id} label={structure.label} path={""} defaultValue={getDefaultValue(data, "", structure.id)}/>
      )
    case "textAreaField":
      return (
        <EledoTextArea id={structure.id} label={structure.label} path={""} defaultValue={getDefaultValue(data, "", structure.id)} rows={structure.rows || 2} className={""}/>
      )
    case "radioField":
      return (
        <EledoRadioButtonField id={structure.id} path={""} groupName={structure.groupName} label={structure.label} defaultValue={getDefaultValue(data, "", structure.groupName)}/>
      )
    case "checkboxField":
      return (
        <EledoCheckboxField id={structure.id} path={""} label={structure.label} defaultValue={getDefaultValue(data, "", structure.id)}/>
      )
    case "submitButton":
      return (
        <button className={"button"} type="submit"
              {...(isValid ? {} : {"aria-disabled": "true"})}>
          {structure.label}
        </button>
      )
    case "skipButton":
      return (
        <button className={"button outline"} onClick={(e) => {e.preventDefault(); onSkip && onSkip(0) }}
              {...(isValid ? {} : {"aria-disabled": "true"})}>
          {structure.label}
        </button>
      )
    case "image":
      return (
        <img src={structure.imageUrl} alt={"Missing"} width={structure.width} height={structure.height}/>
      )
    case "link":
      return (
        <a href={structure.url} rel="noreferrer" target="_blank">{structure.text}</a>
      )
    case "navigate":
      return (
        <button className={"button"}
          onClick={(e) => {e.preventDefault(); onSkip && onSkip(1); navigate(structure.path);  }}>
          {structure.label}
        </button>
      )
  }
  return null
}

const getDefaultValue = (data: any, path: string, id: string) => {
  return _.get(data, path ? `${path}.${id}` : id);
}

export default PuzzleGenerator