import React, {useState, useContext } from 'react';
import styles from './EledoInlineHelpdesk.module.scss';
import {
  EledoModal, EledoPendingContent, EledoRichTextArea, EledoTextField
} from "components/library";
import {useForm, FormProvider} from "react-hook-form";
import classnames from "classnames";
import { EMAIL_VALIDATION_PATTERN } from 'utils';
import { AuthContext } from 'AuthContext';
import { PlatformContext } from 'PlatformContext';
import { CREATE_HELPDESK_MESSAGE, GET_HELPDESK_MESSAGES, HelpdeskMessageCreateData, HelpdeskMessageCreateVars } from 'graphql/HelpdeskMessageQueries';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const State = {
  initial: "initial",
  sending: "sending",
  success: "success",
}

export interface InlineHelpdeskMessage {
  message: string
  email: string
}

const EledoInlineHelpdesk = () => {
  const auth = useContext(AuthContext);
  const loggedIn = !!auth.user;
  const [state, setState] = useState(State.initial);
  const [helpdeskModalDisplayed, setHelpdeskModalDisplayed] = useState(false);
  const connectionId = auth.connection?.id;
  const platform = useContext(PlatformContext);
  const structureId = platform.structureId;
  const navigate = useNavigate()
  //const [messageSuccess, setMessageSuccess] = useState(false);
  const formHandler = useForm<InlineHelpdeskMessage>({mode: "onChange"});
  const { formState: {isValid} } = formHandler;

  const [ createHelpdeskMessage ] = useMutation<HelpdeskMessageCreateData, HelpdeskMessageCreateVars>(CREATE_HELPDESK_MESSAGE,
    { refetchQueries: [ { query: GET_HELPDESK_MESSAGES, variables: { connectionId, page: 1, limit: 20 } } ] });

  const onSubmit = (data:InlineHelpdeskMessage) => {
    if(data.message?.length > 0 && isValid) {
      setState(State.sending)
      createHelpdeskMessage({ variables: {connectionId, structureId, email: data.email, message: data.message}})
      .then(() => {
        formHandler.reset();
        setState(State.success);
      })
      .catch(() => {
        setState(State.initial);
      })
    }
  }

  const modalActions = (
    <React.Fragment>
      {state !== State.success &&
        <button className="button w-button"
                onClick={formHandler.handleSubmit((data) => onSubmit(data))}
                {...(!isValid ? {"aria-disabled": "true"} : {})}>
          <EledoPendingContent state={state === State.sending}
                               content={<React.Fragment><i className={classnames("icon-rocket", styles.ButtonIcon)}/>Send
                                 to Helpdesk</React.Fragment>}
                               pendingContent="Sending..."/>
        </button>
      }
    </React.Fragment>
  );

  const modalContent = (
    <div>
      {state === State.success ?
        <Success handleButtonClick={() => setState(State.initial)}
                 handleHelpdeskLinkClick={() => {
                   navigate("/helpdesk");
                   setState(State.initial);
                   setHelpdeskModalDisplayed(false);
                 }}/> :
        <FormProvider {...formHandler}>
          <form className="w-form" style={{marginTop: "2rem", marginBottom: 0}}>
            { !loggedIn &&
              <EledoTextField id="email" path={""} label="E-mail" validation={{
                required: {value: true, message: "E-mail is required."},
                pattern: {value: EMAIL_VALIDATION_PATTERN, message: "Please enter a valid email."}}
              }/>
            }
            <EledoRichTextArea id="message" path={""} label={""} onChange={() => {}}
                           className={styles.TextArea}
                           placeholder="Describe your problem in detail here, please."
                           defaultValue={""}
                           rows={6}
                           validation={{
                             required: {value: true, message: "Some message text is required."},
                             maxLength: {value: 500, message: "The message cannot have more than 500 characters."},
                           }}
            />
          </form>
        </FormProvider>
      }
    </div>
  );

  const modalTitle = state === State.success ? "Message sent successfully." : "How can we help you?";

  return (
    <>
      <div className={classnames(styles.EledoInlineHelpdesk, helpdeskModalDisplayed && styles.ButtonVisible)}>
        <button className="button outline w-button yellow"
                type="submit"
                onClick={() => setHelpdeskModalDisplayed(true)}>
          <i className="icon-lifebuoy"/>Support (free)
        </button>
      </div>

      <EledoModal wrapClass={styles.ModalWrap}
                  modalClass={styles.Modal}
                  titleClass={styles.ModalTitle}
                  actionsClass={styles.ModalActions}
                  divider={false} title={modalTitle}
                  content={modalContent}
                  displayed={helpdeskModalDisplayed}
                  actions={modalActions}
                  onDismiss={() => setHelpdeskModalDisplayed(false)}/>
    </>
  );
}

const Success = ({ handleButtonClick, handleHelpdeskLinkClick }: { handleButtonClick: Function, handleHelpdeskLinkClick: Function}) => {
  return (
    <React.Fragment>
      <p style={{margin: "2rem 0 2.5rem 0"}}>
        We have received your message and we will reply to you via
        our&nbsp;<a href="/" onClick={(e) => {
                e.preventDefault(); //!!! needs to be here to prevent the page from reloading
                handleHelpdeskLinkClick();
        }}>
          Helpdesk message center
        </a>&nbsp;soon. Thank you!
      </p>
      <button className="button w-button outline"
              onClick={() => handleButtonClick()}>
        I have one more
      </button>
    </React.Fragment>
  )
}

export default EledoInlineHelpdesk;
