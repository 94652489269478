import React, { useEffect, useContext, useState } from "react";
import {Navigate, Route, Routes, Outlet, useLocation} from "react-router-dom";

import 'styles/normalize.scss';
import 'styles/components.scss';
import 'styles/eledo.scss';
import 'styles/fix-react.scss';
import 'styles/icons/style.css';
import 'notyf/notyf.min.css';
import './EledoApp.scss';

import { EledoContentWrapper, EledoPageHeader, EledoPageLoader, EledoPendingContent } from "components/library";
import {
  EledoTemplateList, EledoConnections, EledoLogs,
  EledoHelpdesk, EledoUserProfile, EledoSpending,
  EledoLogin, EledoCreateTemplate, EledoTemplateRoot,
  EledoPricing, EledoPricingChange, EledoCreateRoot,
} from "components";
import EledoAppMenu from "./EledoAppMenu";
import EledoInlineHelpdesk from "./EledoHelpdesk/EledoInlineHelpdesk";
import { AuthContext } from "AuthContext";
import { PlatformContext } from "PlatformContext";
import { useMutation } from "@apollo/client";
import { GET_AUTH } from "graphql/AuthQueries";
import PuzzleModal from "./puzzle/PuzzleModal";
import { setAllRequests } from "_reducers/puzzleRequests";
import { useAppDispatch } from "_store";
import classNames from "classnames";
import styles from 'components/library/EledoPageHeader.module.scss';
import { Tracker } from "Tracker";
import PrivateRoute from "./utils/PrivateRoute";
import EledoArticlesPage from "./EledoArticles/EledoArticlesPage";

const EledoApp = () => {

  const auth = useContext(AuthContext);
  const platform = useContext(PlatformContext);
  const location = useLocation();

  useEffect(() => {
    Tracker.pageView(location.pathname, {
      id: auth.user?.id,
      level: auth.level
    });
  }, [location]) // keep location dependency only, otherwise the pageView event will be triggered even if not necessary

  if(auth.user != null && platform.authService.connectionRequired() && !auth?.connection?.id) {
    return <EledoPageLoader text="Requesting Oauth..."/>
  }

  return (
    <div className="App">
        <Routes>
          <Route path="/start" element={<EledoNewAccount/>} />
          <Route path="/login" element={<EledoLogin/>} />
          <Route path="/platformFailed" element={<EledoPlatformFailed/>} />

          <Route path="/template" element={
                <PrivateRoute>
                  <Outlet/>
                </PrivateRoute>}>
            <Route path=":id/*" element={<EledoTemplateRoot/>} />
          </Route>

          <Route path="/view" element={
                <PrivateRoute>
                  <Outlet/>
                </PrivateRoute>}>
            <Route path=":id/*" element={<EledoTemplateRoot readOnly={true}/>} />
          </Route>

          <Route path="/" element={
                <PrivateRoute>
                  <EledoAppMenu/>
                  <EledoContentWrapper menuHidden={false} shrink={true} id="main-wrapper">
                    <Outlet/>
                  </EledoContentWrapper>
                </PrivateRoute>}>
            <Route path="create/:id" element={<EledoCreateRoot/>} />
            <Route path="templates/mine" element={<EledoTemplateList isPublic={false}/>} />
            <Route path="templates/public" element={<EledoTemplateList isPublic={true}/>} />
            <Route path="templates/new/:type" element={<EledoCreateTemplate/>} />
            <Route path="connections" element={<EledoConnections/>} />
            <Route path="logs" element={<EledoLogs/>} />
            <Route path="documentation" element={<EledoArticlesPage/>} />
            <Route path="pricing" element={<EledoPricing/>} />
            <Route path="pricing/change" element={<EledoPricingChange/>} />
            <Route path="pricing/change/:planId" element={<EledoPricingChange/>} />
            <Route path="helpdesk" element={<EledoHelpdesk/>} />
            <Route path="spending" element={<EledoSpending/>} />
            <Route path="user" element={<EledoUserProfile/>} />

            <Route index element={<Navigate to="/templates/mine"/>} />
            <Route path="*" element={<Navigate to="/templates/mine"/>} />
          </Route>
        </Routes>

      <EledoInlineHelpdesk/>

      <PuzzleModal/>
    </div>
  );
}

const EledoNewAccount = () => {

  const appDispatch = useAppDispatch();

  const auth = useContext(AuthContext);
  const platform = useContext(PlatformContext);
  const [creating, setCreating] = useState(false);

  const [doAuth] = useMutation(GET_AUTH, {
    update(proxy, { data }) {
      if(data){
        auth.login(data.auth);
        if(data.auth.requests){
          appDispatch(setAllRequests(data.auth.requests));
        }
        setCreating(false);
      }
    },
    onError(error){
      console.log(error);
      setCreating(false);
    }
  });

  if (auth.canLetLogin) {
    return (
      <Navigate to={{pathname: '/login'}}/>
    )
  }

  return (
    <>
      <EledoPageHeader/>
      <div className={classNames(styles.EledoLogin, "container")} data-testid="EledoLogin">
        <div className="section section-small">
          <div className="hero-paragraph text-center">
            <h1><b>Welcome to Eledo</b></h1>
          </div>
          <div className="center text-center">
            <div>
              <button style={{marginRight: "6px"}}
                        className="button col-33"
                        onClick={() => {
                          platform?.authService.provideAuthData()
                            .then(authData => {
                              platform?.authService.provideAuthUserCreate()
                                .then(createData => {
                                  if(authData && createData) {
                                    setCreating(true);
                                    doAuth({ variables: { type: authData.type, token: authData.token, create: createData }});
                                  }
                                })
                              })
                        }}>
                  <h3><EledoPendingContent state={creating}
                                      content="Create Account"
                                      pendingContent="Creating..."/></h3>
                  <p>If you are new to Eledo and don't have account yet.</p>
                </button>
            </div>
            <div className="center">
              <button style={{marginRight: "6px"}}
                      className="button outline w-button col-33"
                      onClick={() => {auth.letLogin(); }}>
                        <h3>Log in</h3>
                        <p>If you already have one.</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const EledoPlatformFailed = () => {
  return (
    <>
      <EledoPageHeader/>
      <div className={classNames(styles.EledoLogin, "container")} data-testid="EledoLogin">
        <div className="section section-small">
          <div className="hero-paragraph text-center">
            <h1><b>Platform initialization failed</b></h1>
            <h3>Full reload this webpage. Please contact Eledo Helpdesk if it didn't help.</h3>
          </div>
        </div>
      </div>
    </>
  )
}

export default EledoApp;
