import { gql } from "@apollo/client"


export interface PuzzleSession {
  id?: string
  structureId?: string
  requestId?: string
  userId?: string
  connectionId?: string
  start: Date
  duration: number
}

export interface PuzzleSessionData {
  puzzleSession: PuzzleSession
}

export interface PuzzleSessionVars {
  id?: string
  puzzleSession: PuzzleSessionInput
}

export interface PuzzleSessionInput {
  structureId?: string
  requestId?: string
  userId?: string
  connectionId?: string
  start?: Date
  duration?: number
}

export interface CreatePuzzleSessionData {
  createPuzzleSession: PuzzleSession
}

export const GET_PUZZLE_SESSION = gql`
  query getPuzzleSession($id: ObjectId!) {
    puzzleSession(id: $id) { id }
  }
`;

export const CREATE_PUZZLE_SESSION = gql`
  mutation createPuzzleSession($puzzleSession: PuzzleSessionInput) {
    createPuzzleSession(puzzleSession: $puzzleSession) { id }
  }
`;
