import eledo from "./eledo";

const BASE_URL = '/structure';
const LIST_URL = BASE_URL + 'List?';

const fetchStructures = (platform, connectionId, page, limit) => {
    const searchParams = new URLSearchParams();
    if(platform){
      searchParams.append("platform", platform);
    }
    if(connectionId){
      searchParams.append("connectionId", connectionId);
    }
    if(page){
      searchParams.append("page", page);
    }
    if(limit){
      searchParams.append("limit", limit);
    }
    return eledo.get(LIST_URL + searchParams.toString());
  }

const structuresService = { fetchStructures };
export default structuresService;