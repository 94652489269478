import React, {useContext, useEffect, useState} from 'react';
import styles from './EledoConnections.module.scss';
import {
  EledoMainPageTitle, EledoPendingContent,
  EledoLoader, EledoError
} from "components/library";
import {showNotyf, useErrorNotyf, useShowMenu} from "hooks";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import classnames from 'classnames';
import {fetchConnections, forgetConnection, validateConnection} from "_reducers/connectionsReducer";
import NotyfContext from "context/NotyfContext";
import {useConfirmModal} from "hooks";
import { PlatformContext } from 'PlatformContext';

const EledoConnections = () => {
  const [loading, error] = useSelector(state => [
    state.connections.listLoading, state.connections.error
  ], shallowEqual);
  const platform = useContext(PlatformContext);
  const isEmbedded = platform.isEmbedded;
  const dispatch = useDispatch();

  useErrorNotyf(error);
  useShowMenu();

  useEffect(() => {
    dispatch(fetchConnections());
  }, [dispatch]);

  const renderError = () =>
    <EledoError title={error?.message}
                description={(<React.Fragment>
                  We are sorry &#9785; We could not load your connections. <br/><br/>
                  {error.message.toLocaleLowerCase() === "network error" && "Please check your internet connection."}
                </React.Fragment>)}/>;

  return (
    <div className={styles.EledoConnections} data-testid="EledoConnections">
      <EledoMainPageTitle
        title="Connections"
        subtitle="Manage your connected apps and view connection status."
        image="IllustrationsConnectionsCurved-03.svg"
      />

      { error && renderError() }
      {!error &&
        <div className="section my-content-section" style={{paddingTop: 0}}>
          <div className="container">
            {renderConnectionsHeader()}
            <EledoPendingContent state={loading}
                                 content={<ConnectionsTable/>}
                                 pendingContent={<div className="flex-center"><EledoLoader/></div>}
                                 showPendingIcon={false}
            />
          </div>
        </div>
      }

      { !isEmbedded && false &&
        <div className="section my-content-section">
          <div className="container" style={{paddingTop: "1rem"}}>
            <div className="template-menu-wrap"/>
            <h3>Add Connection:</h3>
            {renderAddConnection()}
          </div>
        </div>
      }

    </div>
  );
}

const renderConnectionsHeader = () =>
  <div className={classnames("w-layout-grid", "header-grid", styles.headerGrid)}>
    <div className="_wf-table-cell">
      <div>Type</div>
    </div>
    <div className="_wf-table-cell">
      <div>Status</div>
    </div>
    <div className="_wf-table-cell">
      <div>Actions</div>
    </div>
  </div>;

const ConnectionsTable = () => {
  const [selectedConnection, setSelectedConnection] = useState(null);
  const { renderModal: renderConfirmModal, setModalDisplayed } = useConfirmModal(
    'Forget Connection',
    `Really forget ${selectedConnection?.type ? selectedConnection.type : ""} connection?`,
    'Yes, Forget!',
    'No, Cancel.',
    async () => {
      const response = await dispatch(forgetConnection(selectedConnection.id));
      setSelectedConnection(null);
      if(!response.error) {
        dispatch(fetchConnections());
      }
    },
    () => setSelectedConnection(null),
    () => setSelectedConnection(null),
    true
  );

  const connections = useSelector(state => state.connections.list);
  const [validating, validateError] = useSelector(state => [
    state.connections.validating,
    state.connections.validateError
  ], shallowEqual);
  const [forgetting, forgetError] = useSelector(state => [
    state.connections.forgetting,
    state.connections.forgetError
  ], shallowEqual);
  const platform = useContext(PlatformContext);
  const isEmbedded = platform.isEmbedded;
  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  useErrorNotyf(validateError, `Error validating${validateError?.message ? ': ' + validateError?.message : ''}.`);
  useErrorNotyf(forgetError, `Error forgetting${forgetError?.message ? ': ' + forgetError?.message : ''}.`);

  if(connections && connections.length === 0) {
    return <div className="flex-center my-section">You have no connections yet.</div>;
  }

  const tileClass = classnames("tile-title", styles.tileTitle);
  const imageUrls = connections.map(c => getConnectionImageUrl(c.type));

  const handleValidate = async conn => {
    const response = await dispatch(validateConnection(conn.id));
    if(!response.error && response?.payload?.status) {
      if(response?.payload?.status === "VALID") {
        showNotyf("Your connection is valid.", notyf);
      } else {
        showNotyf("Your connection has expired.", notyf, "error");
      }
    }
  };

  return connections ? connections.map((conn, index) => (
    <div className={classnames("w-layout-grid", "data-grid", styles.dataGrid, {"red": conn.status !== "VALID"})} key={conn.id}>
      <div className="_wf-table-cell">
        <div className={tileClass}>Type</div>
        { imageUrls[index] ?
            <div><img className="integration-logo" alt="monday logo" loading="lazy"
                    src={imageUrls[index]}/></div> :
            conn.type
        }
      </div>
      <div className="_wf-table-cell">
        <div className={tileClass}>Status</div>
        <div>
          <EledoPendingContent state={validating}
                               content={conn.status}
                               pendingContent={"Validating..."}/>
        </div>
      </div>
      <div className="_wf-table-cell">
        <div className={tileClass}>Actions</div>
        <div>
          <button style={{marginRight: "6px"}}
                  className="button outline w-button"
                  onClick={() => handleValidate(conn)}>
            <EledoPendingContent state={validating}
                                 content="Validate"
                                 pendingContent="Validating..."/>
          </button>
          { !isEmbedded &&
            <button className="button outline red w-button"
                    onClick={() => {
                      setSelectedConnection(conn);
                      setModalDisplayed(true);
                    }}>
              <EledoPendingContent state={forgetting}
                                   content="Forget"
                                   pendingContent="Forgetting..."/>
            </button>
          }
        </div>
      </div>
      { renderConfirmModal() }
    </div>)) : null;
}


const renderAddConnection = () =>
  <div className="integrations-tabs w-tab-menu">
    <a className="integration-item w-inline-block w-tab-link"
       href="https://eledo.online/connections">
      <img className="integration-logo" loading="lazy"
           src={getConnectionImageUrl("MONDAY")}
           alt="Add to monday.com"/>
    </a>
    <a className="integration-item w-inline-block w-tab-link"
       href="https://eledo.online/connections/new/Tadabase">
      <img className="integration-logo" loading="lazy"
           src={getConnectionImageUrl("TADABASE")}
           alt="Add to tadabase.io"/>
    </a>
  </div>;

const getConnectionImageUrl = (connectionType) => {
  let image = "";
  switch(connectionType.toLocaleUpperCase()) {
    case "MONDAY": image = "monday-logo-unified-01.svg"; break;
    case "TADABASE": image = "tadabase.png"; break;
    default: return null;
  }
  return `${process.env.PUBLIC_URL}/assets/images/connections/${image}`;
}

export default EledoConnections;
