import { gql } from "@apollo/client"
import { TVarType } from "featuresFed/fed_core/lang_vars/TVar"

export type DataExpressionFunction = {
	name: string
	returnType: TVarType
	desc: String
	baseParams: number
	optionalParams: String
	params: [DataExpressionFunctionParam]
	category: String
	doc: string
}

export type DataExpressionFunctionParam = {
	name: String
	type: TVarType
	optional: Boolean
	returnType: Boolean
}

export type DataExpressionFunctionsData = {
	dataExpressionFunctions: [DataExpressionFunction]
}

export type DataExpressionFunctionsVars = {
}

export const GET_DATA_EXPRESSION_FUNCTIONS = gql`
  query getDataExpressionFunctions {
    dataExpressionFunctions { name returnType desc baseParams optionalParams params { name type optional returnType } category doc }
  }
`;
