import { useQuery } from '@apollo/client';
import { DataExpressionFunction, DataExpressionFunctionsData, DataExpressionFunctionsVars, GET_DATA_EXPRESSION_FUNCTIONS } from 'graphql/DataExpressionFunctionQueries';
import { useState, useEffect } from 'react'

function useDataExpressionFunctions() {
  const { loading, error, data } = useQuery<DataExpressionFunctionsData, DataExpressionFunctionsVars>(GET_DATA_EXPRESSION_FUNCTIONS, {variables:{}});
  const [dataExpressionFunctions, setDataExpressionFunctions] = useState<DataExpressionFunction[]>();

  useEffect(() => {
    if(!data) return;

    const dataExpressionFunctions: DataExpressionFunction[] = data.dataExpressionFunctions.map(({...props}) => ({...props}))

    setDataExpressionFunctions(dataExpressionFunctions)

  }, [data])

  return { loading, error, dataExpressionFunctions}
}

export default useDataExpressionFunctions