import { useContext } from 'react'

import { _funcs } from './fns.hardcoded'
import { FedContext } from '../../fed_plugins/_context/FedContext'

import { TFunc } from './TFunc'


export function useFedFunctions() {

    const config = useContext(FedContext)
        
    function getFns(): TFunc[] {
        return config.functions || _funcs
    }

    function isFn(fnName: string): boolean {
        let ret = getFnInfo(fnName)
        return !!ret
    }
    function getFnInfo(fnName: string): TFunc | undefined {
        if (!fnName)
            return

        if (fnName[fnName.length-1] === '(')
            fnName = fnName.substr(0, fnName.length-1)

        let lower = fnName.toLowerCase()
        let fns = config.functions || _funcs
        let ret = fns.find(f => lower === f.name.toLowerCase())
        return ret
    }
    function getFnParamInfo(fnName: string, nthParam: number) {
        let fnInfo = getFnInfo(fnName)
        // console.log('fname, nth -> out', fnName, nthParam, fnInfo)
        if (!fnInfo || !fnInfo.params) return

        return fnInfo.params[nthParam]
    }

    return {getFns, isFn, getFnInfo, getFnParamInfo}
}
