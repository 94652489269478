import { useEffect, useRef, useState } from 'react'
import { getHumanReadable as getHumanReadableError } from './getHumanReadable'
import { FedInput } from 'featuresFed/fed_core/_main/FedInput'
import { FedAutocomplete } from 'featuresFed/fed_plugins/autocomplete/FedAutocomplete'
import { FedContext } from 'featuresFed/fed_plugins/_context/FedContext'
import { FedHelpUi } from 'featuresFed/fed_plugins/helpUi/FedHelpUi'
import { useFedParamInfo } from 'featuresFed/fed_plugins/paramInfo/useFedParamInfo'

import { TVar, TVarType } from 'featuresFed/fed_core/lang_vars/TVar'
import useDataExpressionFunctions from 'graphql/hooks/useDataExpressionFunctions'
import { TFunc } from 'featuresFed/fed_core/lang_fns/TFunc'
import { EledoLabel } from '../EledoFormsComponents'
import { IToken } from 'chevrotain'
import { DataModel } from 'graphql/DataModelQueries'

type Props = {
  formula: string
  context?: string
  requiredType: TVarType
  variables?: {[key: string]: TVar[]}
  dataModel?: DataModel
  onChange?(f: string): void
  onConfirm?(): void
  onCancel?(): void
}
export function EledoFormulaEditor(props: Props) {

  const [caretIdentifier, set_caretIdentifier] = useState<string>('')
  const [caretPos, set_caretPos] = useState<number>(-1)

  const [parentFnName, set_parentFnName] = useState<string | undefined>()
  const [currWantType, set_currWantType] = useState<TVarType>('String')

  const [error, set_error] = useState<String[]>([])
  const [tokensOrig, set_tokensOrig] = useState<IToken []>([])
  const refAutocomplete = useRef<any>()
  const refInput = useRef<any>()

  useFedParamInfo(caretPos, tokensOrig, props.requiredType,
    cpt => set_currWantType(cpt),
    pfn => set_parentFnName(pfn),
    err => set_error(err),
  )

  const [formula, set_formula] = useState(props.formula || "")
  const [active, set_active] = useState<string>()

  const { dataExpressionFunctions, loading: loadingExpressionFunctions } = useDataExpressionFunctions();

  function onKeyDown(e: any) {
    refAutocomplete?.current.keyDown(e)
  }

  useEffect(() => {
    set_formula(props.formula)
  }, [props.formula])

  if(loadingExpressionFunctions){
    return(<>Loading...</>)
  }

  return (
    <>
      <FedContext.Provider value={{
        dataModel: props.dataModel,
        functions: dataExpressionFunctions && dataExpressionFunctions.map(f => { return { ...f } as TFunc}),
        context: props.context,
        dbg: {},
      }}>
        {/* <FedDbgVals/> */}
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <form>
          <fieldset>
            <EledoLabel id="fedInput" label="Data expression"/>
            <FedInput
              id="fedInput"
              ref={refInput}
              formula={formula}
              on_formula={f => { set_formula(f); props.onChange?.(f) } }
              on_caretIdentifier={ci => set_caretIdentifier(ci)}
              on_caretPos={cp => set_caretPos(cp)}
              on_tokensOrig={ts => set_tokensOrig(ts)}
              onKeyDown={onKeyDown}
            />
          </fieldset>
        </form>

        <div style={{flexGrow: 1, overflowY: 'auto'}}>
          <div style={{display: 'flex', flexDirection: 'row', height: '100%', overflowY: 'auto'}}>
            <div style={{display: 'flex', flexDirection: 'column', width: '50%', height: '100%', overflowY: 'auto'}}>
              <div style={{flexGrow: 1, height: '100%', overflowY: 'auto'}}>
                <FedAutocomplete
                  ref={refAutocomplete}
                  formula={props.formula}
                  caretIdentifier={caretIdentifier}
                  currWantType={currWantType}
                  onOptionActive={o => set_active(o)}
                  onApplyOption={(needle, repl) => refInput?.current.replaceToken(needle, repl)}
                />
              </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', overflowY: 'auto', width: '50%', paddingLeft: '12px'}}>
              <form>
                <fieldset>
                  <EledoLabel id="documentation" label={"Documentation"}/>
                </fieldset>
              </form>
              {/* , whiteSpace: 'pre-wrap', fontSize: '12px', lineHeight: '12px'}}> */}
              { error && error.length > 0 &&
                <p style={{color: 'red'}}>
                  {getHumanReadableError(error, formula)}
                </p>
              }
              <div style={{flexGrow: 1, overflow: 'auto'}}>
                <FedHelpUi
                  caretIdentifier={active}
                  parentFnName={parentFnName}
                />
              </div>
              {/* <p style={{whiteSpace: 'pre-wrap', margin: '4em 1em', opacity: '40%'}}>
                currWantType: {currWantType || ''}
              </p> */}
              <div style={{display: 'flex', width: '100%'}}>
                <button className="button outline grow w-button" style={{marginBottom: 0}}
                  onClick={() => props.onCancel?.()}>
                  Cancel
                </button>
                <button className="button grow w-button" style={{marginBottom: 0}}
                  onClick={() => props.onConfirm?.()}>
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div> added just to overcome col-wrapper's 100% height
        <div className="col-wrapper">
        </div>
      </div> */}
        {/* <FedDevAst/> */}

      </FedContext.Provider>
    </>
  )
}

