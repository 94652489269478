import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useContext} from "react";
import {showNotyf} from "./index";
import {copyTemplate, fetchPrivateTemplates, fetchPublicTemplates} from "../_reducers/templateReducer";
import NotyfContext from "context/NotyfContext";
import { AuthContext } from "AuthContext";
import { PlatformContext } from "PlatformContext";
import { useNavigate } from "react-router-dom";

function useCopyTemplate(isPublic, templateId, templateName) {
  const platform = useContext(PlatformContext);
  const navigate = useNavigate()

  const [
    copying, copyingError,
    selectedPagePrivate, selectedPagePublic
  ] = useSelector(state => [
    state.templates.copying, state.templates.copyingError,
    state.templates.private.selectedPage, state.templates.public.selectedPage,
  ], shallowEqual);
  const auth = useContext(AuthContext);
  const connectionId =  auth.connection?.id;

  const dispatch = useDispatch();
  const notyf = useContext(NotyfContext);

  const copyHandler = async () => {
    let success = null;
    await dispatch(copyTemplate(templateId)).then(response => {
      if(response.error) {
        const errMessage = response.payload?.data?.error ? response.payload.data.error : "";
        showNotyf("Template was not copied. " + errMessage, notyf, "error");
        success = false;
      } else {
        const newTmpltName = response.payload?.data?.name;
        const successText = newTmpltName ? `Copied to "${newTmpltName}".` : "Your template was copied!";
        showNotyf(successText, notyf);

        dispatch(isPublic ?
          fetchPublicTemplates({pageId: selectedPagePublic, platform: platform.platform}) :
          fetchPrivateTemplates({pageId: selectedPagePrivate, connectionId: connectionId})
        );
        success = true;

        const newTmpltId = response.payload?.data?.id;
        const routeTo = newTmpltId ? `/template/${newTmpltId}/main` : '/templates/mine';
        navigate(routeTo);
      }
    });
    return success;
  };

  return {
    copying, copyingError,
    copyHandler: () => copyHandler(),
  };
}

export default useCopyTemplate;